import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import logger from 'src/utils/logger';
import axiosClient from 'src/libs/axiosClient';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Pagination from 'src/components/UI/Pagination';
import { FilterBar, FilterButton } from 'src/components/FilterBar';
import { DriversFilters, DriversFiltersTooltip } from 'src/components/FilterBar/driversFilters';

import DriversTable from 'src/components/Tables/drivers/list';
import { isPermitted } from 'src/utils/permissions';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ExportModal from 'src/components/ExportModal';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Textarea from 'src/components/UI/Textarea';
import Button from 'src/components/UI/Button';

const EmptyFilters = {
  status: [],
  search: '',
};

const driversExportFields = [
  { field: 'id', title: 'Id' },
  { field: 'lastName', title: 'Фамилия' },
  { field: 'firstName', title: 'Имя' },
  { field: 'email', title: 'Почта' },
  { field: 'phoneNumber', title: 'Телефон' },
  { field: 'role', title: 'Статус' },
  { field: 'driverLicenseFront', title: 'Водительские права(Front)' },
  { field: 'driverLicenseBack', title: 'Водительские права(Back)' },
  { field: 'insurance', title: 'Страховка' },
  { field: 'w9_llc', title: 'Компания перевозчик' },
  { field: 'car_brand', title: 'Марка машины' },
  { field: 'car_model', title: 'Модель машины' },
  { field: 'car_win', title: 'Вин номер' },
  { field: 'trailer_type', title: 'Тип прицепа' },
  { field: 'carPhoto', title: 'Фото автомобиля' },
  { field: 'trailerPhoto', title: 'Фото прицепа' },
  { field: 'order', title: 'Текущий заказ' },
  { field: 'count_orders', title: 'Заказов' },
  { field: 'earnings', title: 'Заработано' },
];

const CompanyAdminDrivers: FunctionComponent<any> = () => {
  const filtersButtonRef = useRef<any>(null);

  const { id } = useParams();

  const [drivers, setDrivers] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<any>(['sort_driver', 'asc']);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(EmptyFilters);
  const [driversExportModalOpened, setDriversExportModalOpened] = useState<boolean>(false);
  const [selectedDrivers, setSelectedDrivers] = useState<any>([]);
  const [exportDriversSelected, setExportDriversSelected] = useState<any>(
    driversExportFields.map(el => el.field)
  );

  const [cause, setCause] = useState<string>('');
  const [causeModal, setCauseModal] = useState<any>(null);

  const onFilterFieldChange = (val: any, field: string) => {
    setFilters({
      ...filters,
      [field]: val,
    });
  };

  const clearFilters = () => {
    setFilters(EmptyFilters);
    getDrivers(EmptyFilters, 1);
  };

  const getQueryFilters = (filters: any) => {
    return {
      company_id: id,
      ...(filters.search && { search: filters.search }),
      ...(filters?.status?.length && { status: filters.status.join(',') }),
    };
  };
  const getDrivers = async (filters: any, currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    setIsLoading(val => !val);

    try {
      const res = await axiosClient.get(`/companies/${id}/drivers`, {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(filters),
        },
      });

      setTotal(res.data.links.lastPage);
      setDrivers((val: any) => res.data.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении водителя ${e.message}`, e);
    } finally {
      setIsLoading(val => !val);
    }
  };

  const updateStatus = async (status: string, id: string | number) => {
    setIsLoading(false);
    try {
      setCause('');
      setCauseModal(false);
      await axiosClient.post(`/drivers/${id}/change-status`, {
        status,
        comment: cause,
      });
    } catch (e: any) {
      logger.error(`Ошибка при смене статуса ${e.message}`, e);
    } finally {
      setTimeout(() => {
        getDrivers(filters, page);
      }, 1000);
    }
  };

  useEffect(() => {
    getDrivers(filters, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  return (
    <>
      <div className="flex justify-end">
        <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
          <div className="flex justify-end items-center">
            <p className="text-[12px] mr-2 text-[#9b9d9c]">
              {selectedDrivers === 'all' ? 'Выбраны все' : `Выбрано ${selectedDrivers.length}`}:
            </p>
            {isPermitted('companies_download_driver_list') && (
              <div className="mr-6">
                <ButtonSPrimary onClickHandler={() => setDriversExportModalOpened(true)}>
                  Экспорт в Excel
                </ButtonSPrimary>
              </div>
            )}

            <FilterButton
              opened={filterOpen}
              buttonRef={filtersButtonRef}
              filtersIsEmpty={!filters.search && !filters.status.length}
              onOpen={() => setFilterOpen(true)}
              onClose={() => setFilterOpen(false)}
            >
              <DriversFiltersTooltip data={filters} filtersButtonRef={filtersButtonRef} />
            </FilterButton>
          </div>

          <FilterBar
            setOpened={setFilterOpen}
            opened={filterOpen}
            onApply={() => getDrivers(filters, 1)}
            onClear={clearFilters}
          >
            <DriversFilters data={filters} onFilterFieldChange={onFilterFieldChange} />
          </FilterBar>
        </div>
      </div>
      <div className="overflow-auto bg-white">
        <DriversTable
          drivers={drivers}
          isLoading={isLoading}
          blockable={true}
          selected={selectedDrivers}
          sort={sort}
          onDriverSelect={setSelectedDrivers}
          onSortUpdate={setSort}
          onDriverStatusUpdate={(driverId: number, newSelected: any, rowIndex: number) => {
            if (newSelected.label === 'Повторная модерация') {
              setCauseModal(driverId);
            } else {
              updateStatus(newSelected.label, driverId);
            }
          }}
        />

        {!isLoading && drivers.length !== 0 && (
          <Pagination totalPages={total} activePage={page} setPage={setPage} alignment="justify-end" />
        )}
      </div>

      <ModalsSection>
        <ModalBackdrop state={causeModal}>
          <ModalWindow title="Повторная модерация" setState={setCauseModal}>
            <div className="flex flex-col">
              <p className="mb-2">Причина</p>
              <Textarea placeholder="" value={cause} setValue={e => setCause(e)} />
              <div className="w-[320px] mt-6">
                <Button
                  variant="default"
                  onClickHandler={() => updateStatus('Повторная модерация', causeModal)}
                >
                  Отправить
                </Button>
              </div>
            </div>
          </ModalWindow>
        </ModalBackdrop>

        <ExportModal
          title="Экспорт водителей"
          opened={driversExportModalOpened}
          isLoading={isLoading}
          selected={exportDriversSelected}
          options={driversExportFields}
          setSelected={setExportDriversSelected}
          setOpened={setDriversExportModalOpened}
          exportParams={{
            url: '/export/drivers',
            filename: 'companies-drivers.xlsx',
            fields: exportDriversSelected,
            filters: getQueryFilters(filters),
            selected: selectedDrivers,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default CompanyAdminDrivers;
