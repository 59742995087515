import { FunctionComponent, useEffect, useState } from 'react';
import Loader from 'src/components/Custom/Loader';
import Input from 'src/components/UI/Input';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';

export const DriverPriceModal: FunctionComponent<{
  opened?: boolean;
  isLoading?: boolean;
  driverCommission: string;
  totalPrice: string;
  setOpened: (bool: boolean) => void;
  onSave: (price: string) => void;
}> = ({
  opened = false,
  isLoading = false,
  driverCommission,
  totalPrice,
  setOpened = () => {},
  onSave = () => {},
}) => {
  const [price, setPrice] = useState(driverCommission);

  useEffect(() => {
    setPrice(driverCommission);
  }, [opened, driverCommission]);

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow title="Изменение комиссии водителя" setState={v => setOpened(v as boolean)}>
        <div className="flex flex-col items-center">
          <p className="text-[#999999] mb-1">Сумма комиссии</p>
          <Input
            className="w-[110px]"
            type="number"
            min={0}
            value={price}
            onChange={e => setPrice(e)}
            max={999999}
          />
          <p className="text-[#999999] mt-2">Сумма заказа: ${totalPrice}</p>
          {isLoading ? (
            <Loader size={100} />
          ) : (
            <div className="w-[320px] text-[1.1em] mt-6">
              <ButtonSPrimary onClickHandler={() => onSave(price)}>Сохранить</ButtonSPrimary>
            </div>
          )}
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};
