import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import './index.css';

interface IDatePicker {
  value?: string | number;
  setValue?: Dispatch<SetStateAction<string>>;
  rounded?: Boolean;
  time?: Boolean;
  min?: string;
}

const DatePickerRounded: FunctionComponent<IDatePicker> = ({
  value = '',
  setValue = () => {},
  rounded = true,
  time = false,
  min = '',
}) => {
  return (
    <>
      <input
        type={time ? 'time' : 'date'}
        value={value}
        onChange={e => {
          setValue(e.target.value);
        }}
        min={min}
        style={{
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)',
        }}
        // onFocus={"this.showPicker()"
        className={`date-time-picker ${
          rounded ? 'rounded-lg' : ''
        } text-[12px] border border-[#D9D9D9] outline-none focus:border-[#adadad] px-3 py-1 w-full max-w-xs`}
        placeholder="Date"
      />
    </>
  );
};

export default DatePickerRounded;
