import { FunctionComponent, useState, useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import Input from 'src/components/UI/Input';

interface IAddressInput {
  point?: 'from' | 'to';
  value: string;
  placeholder?: string;
  addressesTypes?: string;
  className?: string;
  onChange: any;
  allowAny?: boolean;
}

const AddressInput: FunctionComponent<IAddressInput> = ({
  point = 'from',
  placeholder,
  value,
  addressesTypes = '',
  className = '',
  onChange,
  allowAny = false,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const { ref }: { ref: any } = usePlacesWidget({
    language: 'en',

    options: {
      types: ['geocode', 'establishment'],
    },

    apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,

    onPlaceSelected: async (place: any) => {
      if (!allowAny) {
        if (!place?.formatted_address || !place?.address_components) {
          return;
        }

        if (!place.address_components.some(e => e.types && e.types.includes('street_number'))) {
          return;
        }

        let formattedAddressPartsTypes = place.address_components.map((e: any) => e.types.join(' '));
        formattedAddressPartsTypes = formattedAddressPartsTypes.join();

        if (addressesTypes.length && !formattedAddressPartsTypes.includes(addressesTypes)) {
          return;
        }
      }

      const address = { title: place.formatted_address, lat: '', lng: '' };
      address.lat = await place.geometry.location.lat();
      address.lng = await place.geometry.location.lng();

      onChange(address);
      setInputValue(address.title);
    },
  });

  useEffect(() => {
    if (!allowAny) {
      setInputValue(value);
    }
    if (!value) {
      setInputValue('');
    }
  }, [value]);

  useEffect(() => {
    if (allowAny) {
      onChange({ title: inputValue });
    }
  }, [inputValue]);

  return (
    <Input
      ref={ref}
      value={inputValue}
      theme={point === 'from' ? 'success' : 'primary'}
      placeholder={placeholder || (point === 'from' ? 'Откуда' : 'Куда')}
      className={className}
      onChange={setInputValue}
    >
      <div
        className={`bg-white my-2 ml-2 w-[15px] h-[14px] border-4 rounded-full ${
          point === 'from' ? 'border-[#3cb15b]' : 'border-[#2a68b1]'
        }`}
      />
    </Input>
  );
};

export default AddressInput;
