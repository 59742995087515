import { FunctionComponent, useEffect, useState } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Pagination from 'src/components/UI/Pagination';

// Table
import ClientsPaymentsTable from 'src/components/Tables/clients/payments';
import ExportModal from 'src/components/ExportModal';
import axiosClient from 'src/libs/axiosClient';
import logger from 'src/utils/logger';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';

interface ClientPaymentsHistoryTypes {
  clientId: number;
}

const ExportFields = [
  { title: 'ID', exportField: 'id' },
  { title: 'Время', exportField: 'date' },
  { title: 'Тип', exportField: 'type' },
  { title: 'Сумма', exportField: 'amount' },
  { title: 'Чек', exportField: 'receipt' },
  { title: 'Статус', exportField: 'status' },
];

const ClientPaymentsHistory: FunctionComponent<ClientPaymentsHistoryTypes> = ({ clientId }) => {
  const [payments, setPayments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_id', 'asc']);

  const [exportModal, setExportModal] = useState<boolean>(false);
  const [exportSelected, setExportSelected] = useState<any>(ExportFields.map(e => e.exportField));
  const [selected, setSelected] = useState<IExportSelected>([]);

  const getPayments = async (p?: number) => {
    const updatedPage = p || page;
    try {
      setIsLoading(val => !val);
      const res = await axiosClient.get(`/clients/${clientId}/payments`, {
        params: {
          page: updatedPage,
          [sort[0]]: sort[1],
        },
      });
      setTotal(res.data.links.lastPage);
      setPayments((val: any) => res.data.data);
      setPage(updatedPage);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(val => !val);
    }
  };

  useEffect(() => {
    getPayments(page);
  }, [page, sort]);

  return (
    <>
      <div className={`bg-[#fff] h-screen`}>
        <div className="flex justify-end px-[17px] pt-[14px] pb-[12px]">
          <div className="flex items-center">
            <p className="text-[12px] mr-2 text-[#9b9d9c]">
              {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
            </p>
            {isPermitted('clients_download_history_payment') && (
              <div className="mr-6">
                <ButtonSPrimary onClickHandler={() => setExportModal(true)}>
                  Экспорт в Excel
                </ButtonSPrimary>
              </div>
            )}
          </div>
        </div>

        <div className="overflow-auto bg-white">
          <ClientsPaymentsTable
            payments={payments}
            isLoading={isLoading}
            selected={selected}
            sort={sort}
            onSortUpdate={setSort}
            onPaymentSelect={setSelected}
          />

          {!isLoading && payments.length !== 0 && (
            <Pagination totalPages={total} activePage={page} setPage={setPage} alignment="justify-end" />
          )}
        </div>
      </div>
      <ModalsSection>
        <ExportModal
          opened={exportModal}
          isLoading={isLoading}
          selected={exportSelected}
          options={ExportFields}
          field="exportField"
          setSelected={setExportSelected}
          setOpened={setExportModal}
          exportParams={{
            url: `/export/${clientId}/client-payments`,
            filename: `client-payments-${clientId}.xlsx`,
            fields: exportSelected,
            filters: {},
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default ClientPaymentsHistory;
