import { FunctionComponent, useEffect } from 'react';
import Input from 'src/components/UI/Input';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Dropdown from 'src/components/UI/Dropdown';
import Loader from 'src/components/Custom/Loader';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import { useState } from 'react';
import { isPermitted } from 'src/utils/permissions';

export const PersonInfo: FunctionComponent<any> = ({ role, data = {}, onEditClick = () => {} }) => {
  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <p className="text-[#333333] font-bold text-[13px]">{role}</p>
        {isPermitted('orders_change_sender_receiver') && (
          <img
            src="/assets/img/pencil.svg"
            alt="change"
            className="cursor-pointer"
            onClick={onEditClick}
          />
        )}
      </div>
      <div className="flex">
        <div>
          <p>
            {data?.first_name || '----'} {data?.last_name || '----'}
          </p>
          <div className="flex">
            <p className="text-[#999999] text-[9px]">{data?.phone_number || '----'}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export const PersonInfoChangeModal: FunctionComponent<any> = ({
  opened = false,
  isLoading = false,
  role,
  data,
  setOpened = () => {},
  onSave = () => {},
  sender_type,
}) => {
  const [selected, setSelected] = useState<any>();
  const [sender, setSender] = useState<any>({});

  useEffect(() => {
    setSender(data);
  }, [opened, data]);

  const updateField = (value: any, field: string) => {
    console.log({ value, field });
    setSender({ ...data, [field]: value });
  };

  const updateFieldSelect = (payload: any) => {
    setSender(payload);
  };

  if (!sender) {
    return (
      <ModalBackdrop state={opened}>
        <ModalWindow title={role} setState={setOpened}>
          <Loader size={40} />
        </ModalWindow>
      </ModalBackdrop>
    );
  }

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow title={role} setState={setOpened}>
        <div className="flex flex-col items-center">
          {sender_type === 'admin' ? (
            <div>
              <div className="mb-2 w-full">
                <Input
                  className="w-[100%]"
                  placeholder="Имя"
                  value={sender.first_name || ''}
                  onChange={name => updateField(name, 'first_name')}
                />
              </div>
              <div className="mb-2 w-full">
                <Input
                  className="w-[100%]"
                  placeholder="Фамилия"
                  value={sender.last_name || ''}
                  onChange={lastName => updateField(lastName, 'last_name')}
                />
              </div>
              <div className="w-full">
                <Input
                  className="w-[100%]"
                  placeholder="Номер телефона"
                  value={sender.phone_number || ''}
                  onChange={phone => updateField(phone, 'phone_number')}
                />
              </div>
            </div>
          ) : (
            <div className="w-full">
              <Dropdown
                value={selected}
                placeholder="Выберите клиента"
                type="clients"
                onSelect={(selectedUser: any) => {
                  setSelected(selectedUser);
                  updateFieldSelect({
                    first_name: selectedUser.fullName.split(' ')[0],
                    last_name: selectedUser.fullName.split(' ')[1],
                    phone_number: selectedUser.phone,
                  });
                }}
              />
            </div>
          )}

          {isLoading ? (
            <Loader size={100} />
          ) : (
            <div className="w-[320px] text-[1.1em] mt-6">
              <ButtonSPrimary onClickHandler={() => onSave(sender)}>Сохранить</ButtonSPrimary>
            </div>
          )}
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};
