import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import TimeCell from '../BASIC_CELLS/TimeCell';
import OrderInfoCell from '../BASIC_CELLS/OrderInfoCell';
import { orderStatuses } from 'src/utils/order-statuses';
import { parseDate } from 'src/utils/format-date';

const statuses = orderStatuses.reduce((obj, status) => {
  obj[status.id] = status.title;
  return obj;
}, {});

interface IMailingsTable {
  notifications: Array<any>;
  isLoading?: boolean;
  sort: string[];
  onSortUpdate: (newSort: Array<string>) => any;
}

const NotificationsListTable: FunctionComponent<IMailingsTable> = ({
  notifications,
  isLoading = false,
  sort,

  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();
  const TableFields = {
    recipient: {
      label: 'Уведомление',
      sortBy: 'sort_message',
      width: '40%',
      component: (recipient: string, index: number, notification: any) => (
        <NotificationMessageCell
          recipient={recipient}
          message={notification.message}
          type={notification.type}
          notification={notification}
          backgroundColor={notification.isRead ? 'white' : '#f5f5f5'}
        />
      ),
    },

    order: {
      label: 'Заказ',
      sortBy: 'sort_order',
      width: '45%',
      component: (order: any, index: number, notification: any) => (
        <OrderInfoCell
          order={order}
          maxWidth="20rem"
          fromPath={order?.start_to}
          toPath={order?.finish_to}
          status={statuses[order?.status || 0]}
          price={order?.total_price}
          backgroundColor={notification.isRead ? 'white' : '#f5f5f5'}
          onClick={() => navigate(`/order/${order?.id}`)}
        />
      ),
    },

    date_send: {
      label: 'Дата и время',
      sortBy: 'sort_date',
      width: '15%',
      component: (date: string, index: number, notification: any) => {
        return (
          <TimeCell
            date={parseDate(date, 'YYYY-MM-DD HH:mm:ss')}
            backgroundColor={notification.isRead ? 'white' : '#f5f5f5'}
          />
        );
      },
    },
  };

  return (
    <GridTable
      data={notifications}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

/* ----------------------- TABLE CELLS ----------------------- */

const violationTypesIcons = {
  'violation-rest': 'bed',
  'violation-count-period': 'warning',
  'violation-speeding': 'speed',
  'violation-night-rides': 'night',
};

const messages = {
  notSearch: 'Не передан в поиск',
  driverNotAssigned: 'Водитель не назначен',
  driverAssigned: 'Водитель принял заказ',
  arrive: 'Водитель прибудет через',
  arrived: 'Водитель прибыл в точку начала',
  acceptanceReport: 'Создание акта отправки',
  onWay: 'Водитель доставляет заказ',
  finish: 'Прибудет в точку выдачи через',
  finished: 'Водитель прибыл в точку выдачи',
  finishReport: 'Создание акта приемки',
  done: 'Заказ завершен',
  canceled: 'Отменен',
  cancel: 'Отменен',
};

const minutes = {
  '15': '15 минут',
  '60': '1 час',
  '240': '4 часа',
};

const NotificationMessageCell: FunctionComponent<any> = ({
  notification,
  recipient,
  message,
  type,
  backgroundColor = 'white',
}) => {
  const isViolation = Object.keys(violationTypesIcons).includes(type);

  return (
    <GridTableCell className="pl-4" style={{ justifyContent: 'flex-start', backgroundColor }}>
      {message === 'cancel' || message === 'canceled' ? (
        <div className="flex items-center">
          <span className={`px-4 py-2 bg-[#EE02021A] text-[#EE0202] text-[12px]`}>
            {messages[message]}
          </span>
          <div className={`flex flex-col px-4 py-2 text-[12px]`}>
            <span className="font-bold">Отмена клиентом - Причина отмены</span>
            <span>{notification?.data?.comment ?? 'Не указана'}</span>
          </div>
        </div>
      ) : (
        <>
          {isViolation && (
            <img
              className="w-[2.5em] h-[2.5em] bg-[#ededed] rounded-[100%] p-[0.5em]"
              src={`/assets/img/${violationTypesIcons[type]}.svg`}
              alt=""
            />
          )}
          <span className={`px-4 py-2 ${isViolation ? '' : 'bg-[#e7f0f7] text-[#1066b1]'}`}>
            {messages[message]
              ? messages[message]
              : message.slice(0, 100) + (message.length > 100 ? '...' : '')}
            {notification.data?.minutes ? ' ' + minutes[notification.data.minutes] : ''}
          </span>
        </>
      )}
    </GridTableCell>
  );
};

export default NotificationsListTable;
