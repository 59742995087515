import { FunctionComponent, useMemo, useState } from 'react';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Select from 'src/components/UI/Select';
import { countStatuses } from 'src/utils/order-statuses';
import { TimeModal } from './TimeModal';
import { DoneConfirmModal } from './DoneConfirm';
import { CancelModal } from './CancelModal';

const OrderStatusSelect: FunctionComponent<{
  order: any;
  status: number;
  updateOrder: (order: any | { status: number }) => Promise<void>;
}> = ({ order, status, updateOrder }) => {
  const [activeStatus, setActiveStatus] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const statuses = useMemo(() => {
    const counted = countStatuses(order);

    const activeStatus = counted.find(e => e.id === status);

    return counted.filter(e => e.id === status || activeStatus?.availableStatuses.includes(e.id));
  }, [order, status]);

  const onStatusSelect = async (newStatus: number) => {
    if (status === newStatus) {
      return;
    }

    if ([4, 8, 11, 12].includes(newStatus)) {
      setActiveStatus(newStatus);
    } else {
      await updateOrder({ status: newStatus });
    }
  };

  const onDriverWillBeAtStartSave = async (value: string) => {
    setIsLoading(true);

    await updateOrder({ status: 4, arrive: Number(value) });

    setIsLoading(false);
  };

  const onDriverWillBeAtEndSave = async (value: string) => {
    setIsLoading(true);

    await updateOrder({ status: 8, deliver: Number(value) });

    setIsLoading(false);
  };

  const onDoneSave = async () => {
    setIsLoading(true);

    await updateOrder({ status: 11 });

    setIsLoading(false);
  };

  const onCancelSave = async ({ value, description }) => {
    setIsLoading(true);

    await updateOrder({ status: 'cancel', cancel_type: value, cancel_comment: description });

    setIsLoading(false);
  };

  return (
    <>
      <Select
        value={statuses.find(s => s.id === status)}
        options={statuses}
        defaultColor="#1066b126"
        defaultTextColor="#1066B1"
        label="title"
        onSelect={(newStatus: any) => onStatusSelect(newStatus.id)}
        disabled={statuses.length <= 1}
      />

      <ModalsSection>
        <TimeModal
          title="Водитель будет в точке получения"
          opened={activeStatus === 4}
          setOpened={() => setActiveStatus(null)}
          isLoading={isLoading}
          onSave={onDriverWillBeAtStartSave}
        />
        <TimeModal
          title="Водитель будет в точке выдачи"
          opened={activeStatus === 8}
          setOpened={() => setActiveStatus(null)}
          isLoading={isLoading}
          onSave={onDriverWillBeAtEndSave}
        />

        <DoneConfirmModal
          opened={activeStatus === 11}
          setOpened={() => setActiveStatus(null)}
          isLoading={isLoading}
          onSave={onDoneSave}
        />

        <CancelModal
          opened={activeStatus === 12}
          setOpened={() => setActiveStatus(null)}
          isLoading={isLoading}
          onSave={onCancelSave}
        />
      </ModalsSection>
    </>
  );
};

export default OrderStatusSelect;
