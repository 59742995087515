import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import axiosClient from 'src/libs/axiosClient';
import { TypedDispatch } from 'src/redux';
import UnAuthorizedLayout from 'src/layouts/UnAuthorized';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Input from 'src/components/UI/Input';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';

const AuthorizationPage: FunctionComponent = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: TypedDispatch = useDispatch();
  const [recoverModalEmail, setRecoverModalEmail] = useState<boolean>(false);
  const [recoverEmail, setRecoverEmail] = useState<string>('');
  const [recoverError, setRecoverError] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  async function onSignInHandler(): Promise<void> {
    try {
      const { data } = await axiosClient.post(`/login`, {
        username,
        password,
      });

      let user: any = { role: 'admin' };

      if (data?.user) {
        user = { ...data.user, ...user };
      }

      if (user?.timezone) {
        localStorage.setItem('timezone', user.timezone);
      }

      dispatch({
        type: 'SET_USER',
        payload: {
          accessToken: data.value,
          refreshToken: data.refreshToken,
          ...user,
        },
      });
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('accessToken', data.value);
      localStorage.setItem('userRole', 'admin');
      if (user.permissions) {
        localStorage.setItem(
          'permissions',
          user.permissions.map(e => e.name)
        );
      }

      navigate('/', { replace: true });
    } catch (error: any) {
      setError(error.response.data.errors);
    }
  }

  async function onRecoverHandler(): Promise<void> {
    try {
      await axiosClient.post(`/forgot-password`, {
        email: recoverEmail,
      });
      setRecoverModalEmail(false);
    } catch (error: any) {
      setRecoverError(error.response.data.errors);
    }
  }

  return (
    <>
      <UnAuthorizedLayout>
        <ModalsSection>
          {/* <ModalBackdrop state={authModal}> */}
          <ModalWindow title="Авторизация администратора" close={false}>
            <div className="flex flex-col">
              <div>
                <p className="mb-2">Никнейм</p>
                <Input value={username} onChange={setUsername} placeholder="Никнейм" />
              </div>
              <div>
                <div className="flex mb-2 mt-8 justify-between">
                  <p>Пароль</p>
                  <p
                    className="text-[#2F80ED] cursor-pointer"
                    onClick={() => setRecoverModalEmail(true)}
                  >
                    Забыли пароль?
                  </p>
                </div>
                <Input value={password} onChange={setPassword} placeholder="Пароль" type="password" />
              </div>
              <div className="w-[320px] mt-6 text-[1.1em]">
                <ButtonSPrimary disabled={!username || !password} onClickHandler={onSignInHandler}>
                  Войти
                </ButtonSPrimary>
              </div>

              {error && <p className="text-red-600">{error[Object.keys(error)[0]][0]}</p>}
            </div>
          </ModalWindow>
          {/* </ModalBackdrop> */}
          <ModalBackdrop state={recoverModalEmail}>
            <ModalWindow title="Восстановление пароля" setState={setRecoverModalEmail}>
              <div className="flex flex-col items-center">
                <p className="text-center w-[420px] mb-5">
                  На вашу почту будет выслано письмо с инструкцией по восстановлению пароля
                </p>
                <div className="w-[320px]">
                  <p className="mb-2">Email</p>
                  <Input placeholder="Email" value={recoverEmail} onChange={e => setRecoverEmail(e)} />
                </div>
                <div className="w-[320px] mt-6 text-[1.1em]">
                  <ButtonSPrimary disabled={!recoverEmail} onClickHandler={onRecoverHandler}>
                    Ок
                  </ButtonSPrimary>
                </div>
                {recoverError && (
                  <p className="text-red-600">{recoverError[Object.keys(recoverError)[0]][0]}</p>
                )}
              </div>
            </ModalWindow>
          </ModalBackdrop>
        </ModalsSection>
      </UnAuthorizedLayout>
    </>
  );
};

export default AuthorizationPage;
