import axios from 'axios';
import axiosClient from 'src/libs/axiosClient';
import { IStore, TypedDispatch } from 'src/redux';
import { getAccessKey } from './auth-store';
import { setPermissions } from './permissions';

export function jwtInterceptor(dispatch: TypedDispatch, store: IStore) {
  axiosClient.interceptors.response.use(
    async function (response: any) {
      return response;
    },
    async function (error) {
      if (error?.response?.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      const config = error.config;
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken && refreshToken !== 'undefined') {
        let res: any;
        try {
          res = await axios.post(`/authentication/refresh`, {
            refreshToken: localStorage.getItem('refreshToken'),
          });
        } catch (error) {
          window.location.href = '/authorization';
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('userRole');
        }
        config.headers.Authorization = `Bearer ${res.data.accessToken}`;
        localStorage.setItem('refreshToken', res.data.refreshToken);
        localStorage.setItem('accessToken', res.data.accessToken);

        if (res.data.permissions) {
          setPermissions(res.data.permissions.data.map(e => e.name) as any);
        }

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      } else {
        if (!window.location.href?.includes('authorization')) {
          window.location.href = '/authorization';
        }
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userRole');
      }

      return Promise.reject(error);
    }
  );

  axiosClient.interceptors.request.use(
    async function (config: any) {
      if (getAccessKey()) {
        config.headers.Authorization = `${getAccessKey()}`;
      }
      return config;
    },
    async function (error) {
      return Promise.reject(error);
    }
  );
}
