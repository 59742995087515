import { FunctionComponent, useEffect, useState } from 'react';
import Loader from 'src/components/Custom/Loader';
import Input from 'src/components/UI/Input';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';

export const OrderPriceModal: FunctionComponent<{
  opened?: boolean;
  isLoading?: boolean;
  totalPrice: string;
  miles: string;
  setOpened: (bool: boolean) => void;
  onSave: (price: string) => void;
}> = ({
  opened = false,
  isLoading = false,
  totalPrice,
  miles,
  setOpened = () => {},
  onSave = () => {},
}) => {
  const [price, setPrice] = useState(totalPrice);

  useEffect(() => {
    setPrice(totalPrice);
  }, [opened, totalPrice]);

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow title="Изменение цены" setState={v => setOpened(v as boolean)}>
        <div className="flex flex-col items-center">
          <p className="text-[#999999] mb-1">Итоговая цена</p>
          <Input
            className="w-[110px]"
            type="number"
            min={0}
            value={price}
            onChange={e => setPrice(e)}
            max={999999}
          />
          <p className="text-[#999999] mt-2">
            {miles}mi {Number(miles) > 0 ? `(${(Number(price) / Number(miles)).toFixed(1)}/mi)` : ''}
          </p>
          {isLoading ? (
            <Loader size={100} />
          ) : (
            <div className="w-[320px] text-[1.1em] mt-6">
              <ButtonSPrimary onClickHandler={() => onSave(price)}>Сохранить</ButtonSPrimary>
            </div>
          )}
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};
