import React, { FunctionComponent, useEffect, useState } from 'react';
import logger from 'src/utils/logger';
import MainSection from 'src/components/Semantic/MainSection';
import Pagination from 'src/components/UI/Pagination';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, IStore } from 'src/redux';

import NotificationsListTable from 'src/components/Tables/notifications/list';

const NotificationsList: FunctionComponent = () => {
  const user = useSelector((store: IStore) => store.user);
  const [data, setData] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<any>(['sort_date', 'desc']);
  const dispatch: TypedDispatch = useDispatch();

  const readNotifications = async (notificationsIds: Array<number | string>) => {
    if (!Array.isArray(notificationsIds) || !notificationsIds.length) {
      return;
    }

    try {
      await axiosClient.post(`/notifications/update-read`, {
        ids: notificationsIds.join(','),
      });

      const updatedNotificationsCount = (user?.notificationsCount || 0) - notificationsIds.length;

      dispatch({
        type: 'SET_USER',
        payload: { notificationsCount: updatedNotificationsCount > 0 ? updatedNotificationsCount : 0 },
      });
    } catch (err: any) {
      logger.error(`Ошибка при прочтении уведомлений ${err.message}`);
    }
  };

  const getNotifications = async () => {
    setIsLoading(true);

    try {
      const { data: res } = await axiosClient.get('/notifications', {
        params: {
          [sort[0]]: sort[1],
          page,
        },
      });

      if (Array.isArray(res.data)) {
        setData(res.data);
        setTotal(res.links.lastPage);
      }

      await readNotifications(res.data.filter(n => !n.isRead).map(n => n.id));
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
              <div className="flex items-center">
                <h2 className="text-[24px] text-[#363B39] font-bold">Уведомления</h2>
              </div>
            </div>
            <div className="overflow-auto bg-white p-4">
              <NotificationsListTable
                notifications={data}
                isLoading={isLoading}
                sort={sort}
                onSortUpdate={setSort}
              />

              {!isLoading && data.length > 0 && (
                <Pagination
                  totalPages={total}
                  activePage={page}
                  disabled={isLoading}
                  setPage={setPage}
                  alignment="justify-end"
                />
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
    </>
  );
};

export default NotificationsList;
