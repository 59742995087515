import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const Logo: FunctionComponent = () => {
  return (
    <>
      <Link to="/">
        <h1 className="font-medium text-[22px] text-[#D70C17]">RITZIP</h1>
      </Link>
    </>
  );
};

export default Logo;
