import React, { FunctionComponent, KeyboardEventHandler, useEffect, useState } from 'react';
import Loader from 'src/components/Custom/Loader';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import logger from 'src/utils/logger';
import MainSection from 'src/components/Semantic/MainSection';
import Tabs from 'src/components/Tabs';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import DriversCar from './car';
import DriversGeneral from './general';
import DriversOrdersHistory from './ordersHistory';
import DriversViolationsHistory from './violationsHistory';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ModalBackdrop from 'src/components/UI/Modal';
import ConfirmationModal from 'src/components/Custom/ConfirmationModal';
import { isPermitted } from 'src/utils/permissions';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const editableDriverFields = [
  'lastName',
  'firstName',
  'email',
  'phoneNumber',
  'car_win',
  'car_model',
  'trailer_type',
  'companyId',
  'truck_registration_document',
  'trailer_registration_document',
];

// всю эту логику нужно переписать, разбить на компоненты отдельные со своим состоянием чтоб не пришлось data передавать
const Driver: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const links = [
    { title: 'Общие данные', link: `/drivers/list/${id}/general` },
    { title: 'Автомобиль', link: `/drivers/list/${id}/car` },
  ];
  if (isPermitted('drivers_history_orders')) {
    links.push({ title: 'История заказов', link: `/drivers/list/${id}/orders-history` });
  }
  if (isPermitted('drivers_history_violations')) {
    links.push({ title: 'История нарушений', link: `/drivers/list/${id}/violations-history` });
  }

  const [activeSlide, setActiveSlide] = useState(0);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselImages, setCarouselImages] = useState([
    'https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dmlld3xlbnwwfHwwfHw%3D&w=1000&q=80',
    '',
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    avatar: '',
    car_back: '',
    car_brand: '',
    car_front: '',
    car_left: '',
    car_model: '',
    car_right: '',
    car_win: '',
    company: '',
    driverLicenseBack: '',
    driverLicenseFront: '',
    driverLicenseWithUser: '',
    earned: 0,
    email: '',
    firstName: '',
    insurance: '',
    lastName: '',
    id: 0,
    uin: '',
    phoneNumber: '',
    status: '',
    trailer_back: '',
    trailer_front: '',
    trailer_type: '',
    trailer_params: {},
  });
  const [companies, setCompanies] = useState([]);
  const [response, setResponse] = useState('');
  const [infoClearModalOpened, setInfoClearModalOpened] = useState<boolean>(false);
  const [errors, setErrors] = useState({});

  const handleKeyDown = e => {
    if (!carouselOpen) return;
    if (e.key === 'ArrowRight')
      setActiveSlide(prevSlide => (prevSlide === carouselImages.length - 1 ? 0 : prevSlide + 1));
    if (e.key === 'ArrowLeft')
      setActiveSlide(prevSlide => (prevSlide === 0 ? carouselImages.length - 1 : prevSlide - 1));
    if (e.key === 'Escape') setCarouselOpen(false);
  };

  const customAlert = (msg = '', delay = 3000) => {
    setResponse(msg);
    setTimeout(() => setResponse(''), delay);
  };

  const getDriver = async () => {
    setIsLoading(true);

    try {
      const { data: driver } = await axiosClient.get(`/drivers/${id}`);
      console.log(driver);
      setData(driver);
    } catch (e: any) {
      logger.error(`Ошибка при получении водителя ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanies = async () => {
    setIsLoading(true);
    try {
      const { data: res } = await axiosClient.get(`/companies`);
      if (Array.isArray(res.data)) {
        setCompanies(
          res.data.map(c => {
            return { name: c.name || '----', id: c.id };
          })
        );
      }
    } catch (e: any) {
      logger.error(`Ошибка при получении компаний ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadW9 = async () => {
    try {
      const res = await axiosClient.get(`/drivers/${id}/download-w9`);
      window.open(res.data.link, '_blank');
    } catch (e: any) {
      logger.error(`Ошибка при получении w9 ${e.message}`, e);
    }
  };

  const clearDriverInfo = async (id: any) => {
    setIsLoading(true);
    setInfoClearModalOpened(false);

    const formData = new FormData();
    formData.append('uin', data.uin);

    try {
      await axiosClient.delete(`/drivers/${id}`);
      navigate('/drivers/list');
    } catch (e: any) {
      customAlert('Не удалось удалить данные о водителе');
    } finally {
      setIsLoading(false);
    }
  };

  const updateAvatar = async newAvatar => {
    setIsLoading(true);

    try {
      await axiosClient.post(`/drivers/${id}`, newAvatar);
      setIsLoading(false);
    } catch (e: any) {
      customAlert('Не удалось обновить аватар');
      setIsLoading(false);
      throw e;
    }
  };

  const deleteAvatar = async () => {
    try {
      await axiosClient.post(`/drivers/${id}`, {
        avatar: null,
      });
    } catch (e: any) {
      customAlert('Не удалось удалить аватар');
      setIsLoading(false);
      throw e;
    }
  };

  const loadDocument = async (key: string, file: File) => {
    if (!file) {
      logger.error('Файл не найден');
      return;
    }

    const update = new FormData();
    update.append(key, file);

    try {
      await axiosClient.post(`/drivers/${id}`, update);
      setData({
        ...data,
        [key]: URL.createObjectURL(file),
      });
      logger.success('Документ успешно загружен');
    } catch (e: any) {
      logger.error(`Ошибка при загрузки документа ${e.message}`, e);
      throw e;
    }
  };

  const deleteDocument = async (key: string) => {
    try {
      await axiosClient.post(`/drivers/${id}`, {
        [key]: null,
      });
      setData({
        ...data,
        [key]: null,
      });
    } catch (err: any) {
      logger.error('Не удалось удалить документ');
      throw err;
    }
  };

  const updateDriver = async (changes: any) => {
    if (!data.phoneNumber) {
      logger.error('Все поля обязательны к заполнению');
      return;
    }

    const update = new FormData();

    editableDriverFields.forEach((field: string) => {
      switch (field) {
        case 'phoneNumber':
        case 'phone':
          update.append(field, data.phoneNumber);
          break;

        case 'car_model':
          update.append('car_model', data[field]);
          update.append('car_brand', data[field]);
          break;

        case 'truck_registration_document':
        case 'trailer_registration_document':
          if (data[field] instanceof File) {
            update.append(field, data[field]);
          }
          break;

        case 'trailer_type':
          if (typeof data?.trailer_type === 'string') {
            update.append('trailer_type', data.trailer_type);
          }
          break;

        default:
          update.append(field, data[field]);
          break;
      }
    });

    setIsLoading(true);

    try {
      await axiosClient.post(`/drivers/${id}`, update);
      await getDriver();
      logger.success('Изменения успешно сохранены');
    } catch (e: any) {
      logger.error(`Ошибка при обновлении данных ${e?.response?.data?.message ?? e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateStatus = async (status: string, comment: string) => {
    setIsLoading(true);

    try {
      await axiosClient.post(`/drivers/${id}/change-status`, {
        status,
        comment,
      });
      setIsLoading(false);
      logger.success('Изменения успешно сохранены');
    } catch (e: any) {
      logger.error(`Ошибка при изменении данных ${e.message}`, e);
      setIsLoading(false);
      throw e;
    }
  };

  const pageUpdate = async () => {
    if (window.location.href.includes('general')) {
      await getDriver();
      await getCompanies();
    } else if (!data.id) {
      await getDriver();
    }
  };

  useEffect(() => {
    pageUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    if (!isPermitted('drivers_show')) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-full w-full min-h-[100vh] pb-10`}>
            <div className="text-[#999999] text-[12px] mb-2 pt-3 pl-5">
              <span className="cursor-pointer" onClick={() => navigate('/drivers/list')}>
                Водители
              </span>{' '}
              &gt; {data.firstName} {data.lastName}
            </div>
            <div className="flex justify-between items-center border-b-[1px] border-[#EAEAEA] pl-[6px]">
              <Tabs links={links} />
              <div className="flex">
                <p
                  className="text-[#1066B1] text-xs mr-4 font-semibold cursor-pointer"
                  onClick={downloadW9}
                >
                  Скачать W9
                </p>
                <p
                  className="text-[#DB482C] text-xs mr-4 font-semibold cursor-pointer"
                  onClick={() => setInfoClearModalOpened(true)}
                >
                  Удалить
                </p>
              </div>
            </div>
            {isLoading ? (
              <Loader size={100} />
            ) : (
              <>
                <Routes>
                  <Route
                    path="/general"
                    element={
                      <DriversGeneral
                        data={data}
                        setData={setData}
                        getData={getDriver}
                        saveData={updateDriver}
                        updateAvatar={updateAvatar}
                        deleteAvatar={deleteAvatar}
                        updateStatus={updateStatus}
                        setIsLoading={setIsLoading}
                        setCarouselImages={setCarouselImages}
                        setCarouselOpen={setCarouselOpen}
                        setActiveSlide={setActiveSlide}
                        companies={companies}
                        setCompanies={setCompanies}
                        response={response}
                        customAlert={customAlert}
                      />
                    }
                  />
                  <Route
                    path="/car"
                    element={
                      <DriversCar
                        data={data}
                        setData={setData}
                        saveData={updateDriver}
                        setCarouselImages={setCarouselImages}
                        setCarouselOpen={setCarouselOpen}
                        setActiveSlide={setActiveSlide}
                        loadDocument={loadDocument}
                        deleteDocument={deleteDocument}
                        response={response}
                        customAlert={customAlert}
                      />
                    }
                  />
                  {isPermitted('drivers_history_orders') && (
                    <Route
                      path="/orders-history"
                      element={
                        <DriversOrdersHistory
                          data={data}
                          setData={setData}
                          setIsLoading={setIsLoading}
                        />
                      }
                    />
                  )}
                  {isPermitted('drivers_history_violations') && (
                    <Route
                      path="/violations-history"
                      element={
                        <DriversViolationsHistory
                          data={data}
                          setData={setData}
                          setIsLoading={setIsLoading}
                        />
                      }
                    />
                  )}
                </Routes>
              </>
            )}
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ConfirmationModal
          opened={infoClearModalOpened}
          type="danger"
          title="Удалить информацию о водителе?"
          confirmButtonText="Удалить"
          setOpened={setInfoClearModalOpened}
          onConfirm={() => clearDriverInfo(id)}
        >
          Если вы подтвердите данное действие, то этот пользователь не сможет войти в свой аккаунт, а все
          данные о нем будут удалены из админки.
        </ConfirmationModal>

        <ModalBackdrop state={carouselOpen}>
          <div onKeyDown={handleKeyDown} className="flex items-center h-full">
            <button
              onClick={() => {
                setCarouselOpen(false);
              }}
              className={`absolute right-5 top-6`}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7M13 13L7 7M7 7L13 1M7 7L1 13"
                  stroke="white"
                  strokeOpacity="1"
                  strokeWidth="1.5"
                />
              </svg>
            </button>
            <div className="w-full content-between">
              <Carousel
                autoFocus
                useKeyboardArrows
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                selectedItem={activeSlide}
                onChange={setActiveSlide}
                renderArrowPrev={() => (
                  <button
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      width: carouselImages[1] === '' ? 0 : 60,
                      height: 60,
                      minWidth: carouselImages[1] === '' ? 0 : 60,
                      color: '#fff',
                      zIndex: 1000,
                    }}
                    type="button"
                    onClick={() =>
                      setActiveSlide(prevSlide =>
                        prevSlide !== 0 ? prevSlide - 1 : carouselImages.length - 1
                      )
                    }
                  >
                    {'<'}
                  </button>
                )}
                renderArrowNext={() => (
                  <button
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: 0,
                      width: carouselImages[1] === '' ? 0 : 60,
                      height: 60,
                      minWidth: carouselImages[1] === '' ? 0 : 60,
                      color: '#fff',
                    }}
                    type="button"
                    onClick={() =>
                      setActiveSlide(prevSlide =>
                        prevSlide !== carouselImages.length - 1 ? prevSlide + 1 : 0
                      )
                    }
                  >
                    {'>'}
                  </button>
                )}
              >
                {carouselImages.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: 'block',
                        marginInline: 'auto',
                        background: 'white',
                        width: window.innerWidth - 200,
                        height: '500px',
                        textAlign: 'center',
                        lineHeight: '240px',
                        boxSizing: 'border-box',
                      }}
                      key={index}
                    >
                      <img
                        src={item}
                        alt="Картинка не загрузилась"
                        className="object-contain w-full h-full"
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </ModalBackdrop>
      </ModalsSection>
    </>
  );
};

export default Driver;
