import { FunctionComponent, useEffect, useState } from 'react';
import Pagination from 'src/components/UI/Pagination';

import LegalsManagersListTable from 'src/components/Tables/legals/managers';
import axiosClient from 'src/libs/axiosClient';
import logger from 'src/utils/logger';
import Loader from 'src/components/Custom/Loader';

interface LegalsOrdersHistoryTypes {
  legal: any;
}

const LegalsManagers: FunctionComponent<LegalsOrdersHistoryTypes> = ({ legal }) => {
  const [managers, setManagers] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<any>(['sort_orders', 'asc']);

  const setStatus = async (managerId: number, status: number) => {
    if (!managerId) {
      logger.error('Менеджер не найден');
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      if (status) {
        await axiosClient.get(`/clients/${managerId}/block`);
        logger.success('Менеджер успешно заблокирован');
      } else {
        await axiosClient.get(`/clients/${managerId}/unblock`);
        logger.success('Менеджер успешно разблокирован');
      }

      await getManagers(page);

      setIsLoading(false);
    } catch (e: any) {
      logger.error(`Ошибка при обновлении данных ${e.message}`, e);
      setIsLoading(false);
    }
  };

  const getManagers = async (currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    setIsLoading(true);
    try {
      const { data } = await axiosClient.get(`/legal-entities/${legal.id}/managers`, {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
        },
      });

      setTotal(data.links.lastPage);
      setManagers(data.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (legal) {
      getManagers(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legal]);

  return (
    <>
      <div className="mt-6 overflow-auto bg-white">
        {isLoading ? (
          <Loader />
        ) : (
          <LegalsManagersListTable
            managers={managers}
            sort={sort}
            isLoading={isLoading}
            onSortUpdate={setSort}
            onManagerStatusUpdate={setStatus}
          />
        )}

        {!isLoading && managers.length !== 0 && (
          <Pagination totalPages={total} activePage={page} setPage={setPage} alignment="justify-end" />
        )}
      </div>
    </>
  );
};

export default LegalsManagers;
