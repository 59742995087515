import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import timeCount from 'src/utils/timeCounter';

import { GridTable, GridTableCell } from 'src/components/GridTable';

// Cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import OverflowText from 'src/components/OverflowText';
import { getDateString, getDiffInSeconds, getTimeString } from 'src/utils/format-date';
import { useTimer } from 'src/hooks/use-timer';

interface INewOrdersListTable {
  orders: Array<any>;
  isLoading: boolean;
  selected?: IExportSelected;
  selectFullOrder?: boolean;
  sort?: Array<string>;

  onSortUpdate: (newSort: Array<string>) => any;
  onOrderSelect: (newSelected: IExportSelected) => any;
}

const SearchTimeDisplay: FunctionComponent<{ initTimer: number }> = ({ initTimer }) => {
  const { timer, setNewTimer, timerText } = useTimer(initTimer, 'top');

  useEffect(() => {
    setNewTimer(initTimer);
  }, [initTimer]);

  return (
    <GridTableCell>
      {timer > 0 ? (
        <span className={`text-[12px] pl-1 ${timer > 15 * 60 ? 'text-[#ee0202]' : 'text-[#9b9d9c]'}`}>
          Поиск идёт: {timerText}
        </span>
      ) : (
        <span className="text-[#9b9d9c]">-</span>
      )}
    </GridTableCell>
  );
};

const NewOrdersListTable: FunctionComponent<INewOrdersListTable> = ({
  orders,
  isLoading = false,
  selected = [],
  selectFullOrder = false,
  sort = [],

  onOrderSelect = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: !selectFullOrder
      ? ExportCheckboxesColumn({
          selected,
          onSelect: onOrderSelect,
          additionalParams: { width: '30px' },
        })
      : ExportCheckboxesColumn({
          selected,
          field: null,
          checkTest: (a, b) => a.id === b.id,
          onSelect: onOrderSelect,
          additionalParams: { width: '30px' },
        }),

    // DRIVER
    id: {
      label: 'ID',
      sortBy: 'sort_id',
      width: '160px',
      component: (driver: any, index: number, order: any) =>
        order?.id ? (
          <GridTableCell>
            <p
              className="text-[#1066B1] underline text-[10px] pl-[6px] cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                navigate(`/order/${order.id}`);
              }}
            >
              #{order.id}
            </p>
          </GridTableCell>
        ) : (
          <GridTableCell>-</GridTableCell>
        ),
    },

    // TIME
    start_at: {
      label: 'Время',
      sortBy: 'sort_start',
      width: '160px',
      component: (time: string, index: number, order: any) => (
        <GridTableCell className="text-[#1066B1] text-[14px]">
          <div className="flex flex-col">
            {order?.status <= 2 && <span>Starts:</span>}
            <span>{getDateString(time)}</span>
            <span>
              {getTimeString(time)}
              <span className="text-[12px] text-[#9b9d9c] pl-1">({timeCount(time)} ост)</span>
            </span>
          </div>
        </GridTableCell>
      ),
    },

    // Search time
    updated_at: {
      label: 'Статус поиска',
      sortBy: 'sort_updated_at',
      width: '160px',
      component: (updatedAt, index: number, order: any) =>
        order?.status <= 2 ? (
          <SearchTimeDisplay initTimer={getDiffInSeconds(updatedAt)} />
        ) : (
          <GridTableCell>
            <span className={`text-[12px] pl-1 text-[#27ae60]`}>Водитель найден</span>
          </GridTableCell>
        ),
    },

    // START ADDRESS
    start_to: {
      label: 'Точка начала',
      sortBy: 'sort_from',
      component: (address: string) => (
        <GridTableCell className="text-xs">
          <OverflowText width="100%" lineHeight={3}>
            <span className="pr-2">{address}</span>
          </OverflowText>
        </GridTableCell>
      ),
    },

    // END ADDRESS
    finish_to: {
      label: 'Точка назначения',
      sortBy: 'sort_to',
      component: (address: string) => (
        <GridTableCell className="text-xs">
          <OverflowText width="100%" lineHeight={3}>
            <span className="pr-2">{address}</span>
          </OverflowText>
        </GridTableCell>
      ),
    },

    // MILES
    miles: {
      label: 'Расстояние',
      sortBy: 'sort_miles',
      width: '140px',
      component: (miles: any) => <GridTableCell className="text-xs">{miles}</GridTableCell>,
    },

    // PRICE FOR CARS
    total_price: {
      label: 'Цена',
      sortBy: 'sort_price',
      width: '140px',
      component: (price: number, index: number, order: any) => (
        <GridTableCell className="text-xs break-all">
          <div className="flex flex-col">
            <p>
              <span className="mr-1">$</span>
              {price}
            </p>
            {Array.isArray(order?.items) && order.items.length ? (
              <p className="text-[#9b9d9c] text-[10px]">for {order.items.length} cars</p>
            ) : (
              ''
            )}
          </div>
        </GridTableCell>
      ),
    },
  };

  return (
    <GridTable
      data={orders}
      fields={TableFields}
      isLoading={isLoading}
      align="start"
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default NewOrdersListTable;
