/*
---------------------- USAGE ----------------------
item = Some js object, like: { name: '', email: '', phone: '' }

data = {[item, item, item]}

fields = { {
  someKey: {
    label: "It's column name" or (someKey: string, columnIndex: number) => FunctionComponent,
  
    // Gives possibility to format cell value
    formatter: (item[someKey], cellIndex, item, itemIndex) => newFormattedStringValue,
    
    // Gives possibility to custom cell
    component: (item[someKey], cellIndex, item, itemIndex) => FunctionComponent,

    sortBy: "sortingName"
  } 
} }

onSortUpdate = (newSort: [fields[sortedField].sortBy, 'asc' | 'desc']) => any

children: // If exist, would be visible, when data is empty
*/

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';

import './index.css';

interface GridTableProps {
  data?: Array<any>;
  fields?: any;
  isLoading?: boolean;
  loadingRow?: number;
  sort?: Array<string>;
  onSortUpdate?: any;
  align?: 'start' | 'center' | 'end';
  maxContentHeight?: string;
  headerType?: 'default' | 'fixed';
  className?: string;
  children?: any;
}

export const GridTableCell: FunctionComponent<any> = props => {
  return (
    <div
      {...props}
      className={`${props.className} grid-table-cell text-[14px] bg-white flex items-center justify-center p-2 `}
    >
      {props.children}
    </div>
  );
};

export const GridTable: FunctionComponent<GridTableProps> = ({
  data = [],
  fields = {},
  isLoading = false,
  loadingRow = NaN,
  sort = [],
  onSortUpdate = () => {},
  align = 'start',
  maxContentHeight,
  headerType,
  children,
  className = '',
}) => {
  const [dataParsed, setParsedData] = useState<Array<any>>([]);

  const parseData = () => {
    let dataBuff: Array<any> = [];

    for (let i = 0; i < data.length; i++) {
      let item: any = data[i];

      for (let field of Object.keys(fields)) {
        dataBuff.push({
          key: field,
          row: item,
          rowIndex: i,
        });
      }
    }

    setParsedData(dataBuff);
  };

  useEffect(() => {
    parseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fields]);

  const defaultClassNames = `grid-table w-full grid gap-y-[2px] bg-[#f1f1f1] border-[#f1f1f1] border-b-[2px] rounded-lg grid-table-align-${align}`;
  return (
    <Fragment>
      <div
        className={`${defaultClassNames} ${className}`}
        style={{
          gridTemplateColumns: Object.keys(fields)
            .map(key => fields[key]?.width || 'auto')
            .join(' '),
          maxHeight: maxContentHeight || 'unset',
        }}
      >
        {/* COLUMNS */}
        {Object.keys(fields).map((fieldName: string, index: number) => (
          <ColumnCell
            key={`${fieldName}+${index}`}
            field={fields[fieldName]}
            fieldName={fieldName}
            index={index}
            isLoading={isLoading}
            sortType={sort[1]}
            sortField={sort[0]}
            onClick={onSortUpdate}
            headerType={headerType}
          />
        ))}

        {/* LOADER */}
        {isLoading && (
          <Row fieldsCount={Object.keys(fields).length}>
            <Loader />
          </Row>
        )}

        {/* CELLS */}
        {!isLoading &&
          !!dataParsed.length &&
          dataParsed.map((item, index) => (
            <DataCell
              key={`${item.key}+${index}`}
              item={item}
              index={index}
              fields={fields}
              loadingRow={loadingRow}
            />
          ))}

        {/* NO DATA */}
        {!isLoading && !dataParsed.length && (
          <Row className="py-6" fieldsCount={Object.keys(fields).length}>
            {children ? children : 'No data'}
          </Row>
        )}
      </div>
    </Fragment>
  );
};

const Loader: FunctionComponent<any> = ({ size = 100 }) => (
  <Oval
    ariaLabel="loading-indicator"
    height={size}
    width={size}
    strokeWidth={5}
    strokeWidthSecondary={1}
    color="#1066B1"
    secondaryColor="white"
  />
);

const Row: FunctionComponent<any> = ({ className, fieldsCount, children }) => {
  return (
    <GridTableCell
      className={className}
      style={{ justifyContent: 'center', gridColumn: `1/${fieldsCount + 1}` }}
    >
      {children}
    </GridTableCell>
  );
};

const ColumnCell: FunctionComponent<any> = ({
  field = {},
  fieldName,
  index,
  isLoading = false,
  sortType,
  sortField,
  onClick = () => {},
  headerType,
}) => {
  if (typeof field.label === 'function') {
    return field.label(fieldName, index);
  }

  return (
    <GridTableCell
      className={`relative grid-table-column-title ${
        headerType === 'fixed' ? 'grid-table-column-title-fixed' : ''
      } text-[#808080] text-center group ${
        field.sortBy && !isLoading ? 'cursor-pointer' : 'pointer-events-none'
      }`}
      key={`${fieldName}+${index}`}
      onClick={() => onClick([field.sortBy, sortType === 'asc' ? 'desc' : 'asc'])}
    >
      <svg
        width="6"
        height="11"
        viewBox="0 0 6 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`sort-svg absolute left-3 transition mr-[6px] opacity-${
          field.sortBy === sortField ? '100' : '0'
        }`}
        style={{ left: '0px' }}
      >
        <path
          d="M2.99999 11L0.401917 8H5.59807L2.99999 11Z"
          fill="#808080"
          fillOpacity={`${sortType !== 'asc' ? '1' : '0.4'}`}
        />
        <path
          d="M2.99999 0L5.59807 3H0.401917L2.99999 0Z"
          fill="#808080"
          fillOpacity={`${sortType === 'asc' ? '1' : '0.4'}`}
        />
      </svg>

      <span className={field.sortBy ? `mr-[12px]` : ''} style={{ marginLeft: '1px' }}>
        {field.label || ''}
      </span>
    </GridTableCell>
  );
};

const DataCell: FunctionComponent<any> = ({ item, index, fields, loadingRow = NaN }) => {
  const field = fields[item.key];
  const fieldsNames = Object.keys(fields);

  if (loadingRow === item.rowIndex) {
    return item.key === fieldsNames[0] ? (
      <Row fieldsCount={fieldsNames.length}>
        <Loader size={40} />
      </Row>
    ) : (
      <></>
    );
  }

  if (field.component) {
    return field.component(item.row[item.key], index, item.row, item.rowIndex);
  }

  let data = item.row[item.key];
  data = typeof data == 'number' || typeof data == 'string' ? data : '-';

  if (field.formatter) {
    data = field.formatter(item.row[item.key], index, item.row, item.rowIndex);
  }

  return <GridTableCell key={index}>{data}</GridTableCell>;
};

export default GridTable;
