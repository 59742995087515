import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridTable } from 'src/components/GridTable';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import CompanyInfoCell from '../BASIC_CELLS/CompanyInfoCell';
import UserLockCell from '../BASIC_CELLS/UserLockCell';
import StatusSelectCell from '../BASIC_CELLS/StatusSelectCell';
import { isPermitted } from 'src/utils/permissions';

const statuses: Array<string> = ['Утвержден', 'На модерации', 'Заблокирован', 'Повторная модерация'];

interface ICompaniesListTable {
  companies: Array<any>;
  selected: IExportSelected;
  isLoading?: boolean;
  loadingRow?: number;
  sort: Array<string>;

  onCompanySelect: (newSelected: IExportSelected) => any;
  onSortUpdate: (newSort: Array<string>) => any;

  onCompanyStatusUpdate?: (companyId: number, newStatus: string, rowIndex: number) => any;
  onCompanyLock?: (companyId: number, rowIndex: number) => any;
  onCompanyUnlock?: (companyId: number, rowIndex: number) => any;
}

const CompaniesListTable: FunctionComponent<ICompaniesListTable> = ({
  companies,
  selected = [],
  isLoading = false,
  loadingRow = NaN,
  sort = [],

  onCompanySelect = () => {},
  onCompanyStatusUpdate = () => {},
  onCompanyLock = () => {},
  onCompanyUnlock = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: onCompanySelect,
    }),

    id: {
      label: 'Компания',
      sortBy: 'sort_name',

      component: (id: number, index: number, company: any) => (
        <CompanyInfoCell
          id={id}
          name={company?.name}
          logo={company?.logo}
          onIdClick={() => navigate(`/transport-companies/list/${id}/general`)}
        />
      ),
    },

    driver_count: { label: 'Водители', sortBy: 'sort_driver' },
    admin_count: { label: 'Админы', sortBy: 'sort_admin' },
    state: { label: 'Штат', sortBy: 'sort_state' },

    status: {
      label: 'Статус',
      sortBy: 'sort_status',

      component: (status: string, index: number, company: any, rowIndex: number) => (
        <StatusSelectCell
          value={statuses[status ? +status - 1 : 3]}
          disabled={!isPermitted('companies_change_status') || !isNaN(loadingRow)}
          onSelect={(newSelected: any) =>
            onCompanyStatusUpdate(company?.id, newSelected.label, rowIndex)
          }
        />
      ),
    },

    events: {
      component: (v: any, i: number, company: any, rowIndex: number) => {
        return (
          <UserLockCell
            locked={company.status === 3}
            onLock={() => onCompanyLock(company?.id, rowIndex)}
            onUnlock={() => onCompanyUnlock(company?.id, rowIndex)}
          />
        );
      },
    },
  };

  return (
    <GridTable
      data={companies}
      fields={TableFields}
      isLoading={isLoading}
      loadingRow={loadingRow}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default CompaniesListTable;
