import React, { FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface linkObj {
  link: string;
  title: string;
}

interface TabsTypes {
  links: Array<linkObj>;
}

const Tabs: FunctionComponent<TabsTypes> = ({ links }) => {
  let navigate = useNavigate();
  const location: any = useLocation();
  return (
    <div className="flex">
      {links.map((item: any, index: number) => {
        const link = item.link.split('/');
        return (
          <div className="cursor-pointer" key={index}>
            <p
              onClick={() => navigate(item.link)}
              className={`text-xs text-[#333333] pb-[15px] px-[14px] pt-2 ${
                location.pathname.includes(link[link.length - 1])
                  ? 'font-bold border-b-2 border-[#3072C4]'
                  : ''
              }`}
            >
              {item.title}
            </p>
          </div>
        );
      })}
      <div></div>
    </div>
  );
};

export default Tabs;
