import { FunctionComponent } from 'react';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Button from 'src/components/UI/Button';

interface IConfirmationModal {
  opened: boolean;
  type?: 'primary' | 'default' | 'danger';
  title?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  setOpened: any;
  onConfirm?: () => any;
  onCancel?: () => any;
  children?: any;
}

const ConfirmationModal: FunctionComponent<IConfirmationModal> = ({
  opened,
  type = 'primary',
  title = 'Вы уверены?',
  confirmButtonText = 'Да',
  cancelButtonText = 'Отмена',
  setOpened = () => {},
  onConfirm = () => {},
  onCancel = () => {},
  children,
}) => {
  return (
    <ModalBackdrop state={opened}>
      <ModalWindow setState={setOpened}>
        <div className="flex flex-col">
          <p className="font-bold text-2xl mt-4 px-5 text-center">{title}</p>
          <p className="text-center mt-4 px-5">{children}</p>
          <div className="flex mt-6 items-center justify-center">
            <div className=" mr-4">
              <Button
                variant="default"
                onClickHandler={() => {
                  setOpened(false);
                  onCancel();
                }}
              >
                {cancelButtonText}
              </Button>
            </div>
            <div className="">
              <Button
                variant={type}
                onClickHandler={() => {
                  setOpened(false);
                  onConfirm();
                }}
              >
                {confirmButtonText}
              </Button>
            </div>
          </div>
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};

export default ConfirmationModal;
