import React, { FunctionComponent, useEffect, useState } from 'react';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Input from 'src/components/UI/Input';
import Pagination from 'src/components/UI/Pagination';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DatePickerRounded from 'src/components/UI/DatePickerRounded';
import axiosClient from 'src/libs/axiosClient';

// Table
import OrdersListTable from 'src/components/Tables/orders/list';
import ExportModal from 'src/components/ExportModal';
import AddressInput from 'src/components/Custom/AdressInput';
import { parseDateFormat } from 'src/utils/format-date';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import { connectToSocket } from 'src/hooks/use-socket';

const EmptyFilters = {
  status: [],
  start_from: '',
  start_to: '',
  start_from_time: '',
  start_to_time: '',
  search_from: '',
  search_to: '',
  price_from: '',
  price_to: '',
};

/*
const statuses = [
  'На модерации',
  'Утвержден',
  'Заблокирован',
  'Повторная модерация',
  'Есть текущий заказ',
  'Давно не был онлайн ',
];
*/
const ExportFields = [
  // { title: 'ID', exportField: 'id' },
  { title: 'Водитель и ID', exportField: 'driver' },
  { title: 'Время', exportField: 'start_at' },
  { title: 'Статус', exportField: 'status' },
  { title: 'Оценка', exportField: 'rating' },
  { title: 'Точка начала', exportField: 'start_to' },
  { title: 'Точка назначения', exportField: 'finish_to' },
  { title: 'Расстояние', exportField: 'miles' },
  { title: 'Цена', exportField: 'total_price' },
];

interface DriversOrdersHistoryTypes {
  data: any;
  setData: any;
  setIsLoading: any;
}

const DriversOrdersHistory: FunctionComponent<DriversOrdersHistoryTypes> = ({
  data,
  setData,
  setIsLoading,
}) => {
  const [exportModal, setExportModal] = useState<boolean>(false);

  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [tableData, setTableData] = useState<any>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_status', 'asc']);
  const [exportSelected, setExportSelected] = useState<any>(ExportFields.map(item => item.exportField));
  const [selected, setSelected] = useState<IExportSelected>([]);
  const [filters, setFilters] = useState<any>(EmptyFilters);

  const onFilterFieldChange = (val: any, field: string) => {
    const update = {
      [field]: val,
    };

    if (field === 'start_to' && !filters.start_to_time) {
      update.start_to_time = '23:59';
    }

    if (field === 'start_from' && !filters.start_from_time) {
      update.start_from_time = '00:00';
    }

    setFilters(filters => {
      return {
        ...filters,
        ...update,
      };
    });
  };

  const getQueryFilters = (filters: any) => {
    return Object.keys(filters).reduce(
      (obj, key) => {
        if (!filters[key]) {
          return obj;
        }

        if (key === 'start_from_time' || key === 'start_to_time') {
          return obj;
        }

        switch (key) {
          case 'start_from':
          case 'start_to':
            obj[key] = parseDateFormat(`${filters[key]} ${filters[`${key}_time`]}`, 'YYYY-MM-DD HH:mm');
            break;
          case 'status':
            if (filters[key].length) {
              obj[key] = filters[key].join(',');
            }
            break;
          case 'search_from':
          case 'search_to':
            obj[key] = filters[key]?.title;
            break;
          default:
            obj[key] = filters[key];
        }

        return obj;
      },
      {
        driver_id: data.id,
      }
    );
  };

  const getOrders = async (filters: any, currentPage: number, withLoading: boolean = true) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    if (withLoading) {
      setIsTableLoading(true);
    }
    try {
      const res = await axiosClient.get(`/drivers/${data.id}/history`, {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(filters),
        },
      });

      setDataTotal(res.data.links.lastPage);
      setTableData((val: any) => res.data.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении заказов ${e.message}`, e);
    } finally {
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    if (data.id) {
      getOrders(filters, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, data]);

  useEffect(() => {
    const handler = () => {
      getOrders(filters, page, false);
    };

    const channels = [
      ['admin.orders', '.create.order'],
      ['admin.orders', '.update.order'],
      ['admin.drivers', '.create.driver'],
      ['admin.drivers', '.update.driver'],
    ].map(ch => connectToSocket(ch[0], ch[1], handler));

    channels.forEach(ch => ch.connect());

    return () => channels.forEach(ch => ch.leave());
  }, []);

  return (
    <>
      <div className="mb-3 px-[17px] pt-[14px] flex justify-end items-center">
        <div className="flex justify-end items-center">
          <p className="text-[12px] mr-2 text-[#9b9d9c]">
            {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
          </p>
          {isPermitted('drivers_download_history_orders') && (
            <div className="mr-6">
              <ButtonSPrimary onClickHandler={() => setExportModal(true)}>
                Экспорт в Excel
              </ButtonSPrimary>
            </div>
          )}
          <div
            className="h-[30px] z-[20] mr-2 flex justify-center items-center cursor-pointer transition-all inherit right-[20px] rounded-[8px] p-[12px]"
            style={{ boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 10px' }}
            onClick={() => setFilterOpen(true)}
          >
            <img src="/assets/img/filter.svg" alt="" />
            <p className="text-[#1066B1] text-xs ml-1">Фильтры</p>
          </div>
          <div
            className={`h-[30px] z-[10] flex justify-center items-center cursor-pointer transition-all fixed w-[30px] right-[0px] rounded-full duration-200 bg-white`}
            style={{
              boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 10px',
              opacity: Number(filterOpen),
              transform: `translateX(${filterOpen ? -220 : 0}px)`,
            }}
            onClick={() => setFilterOpen(false)}
          >
            <img className="rotate-180" src="/assets/img/back.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="overflow-auto bg-white">
        <OrdersListTable
          orders={tableData}
          isLoading={isTableLoading}
          selected={selected}
          sort={sort}
          onOrderSelect={setSelected}
          onSortUpdate={setSort}
        />

        {!isTableLoading && tableData.length !== 0 && (
          <Pagination
            totalPages={dataTotal}
            activePage={page}
            setPage={setPage}
            alignment="justify-end"
          />
        )}
      </div>
      <div
        className={`h-full fixed z-[51] top-0  w-[208px] ${
          filterOpen ? 'right-0' : 'right-[-208px] w-[65px] px-1'
        } shadow-md flex flex-col bg-white transition-all duration-200`}
        style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="flex justify-between items-center pt-4 px-3">
          <p className="font-bold text-[14px]">Фильтры</p>
          <p
            className="text-[#808080] text-[14px] cursor-pointer"
            onClick={() => {
              setFilters(EmptyFilters);
              getOrders(EmptyFilters, 1);
            }}
          >
            Сбросить
          </p>
        </div>
        <div>
          <PerfectScrollbar
            style={{ maxHeight: '94vh' }}
            options={{
              suppressScrollX: true,
              useBothWheelAxes: false,
            }}
            className="pb-3 px-3 w-full"
          >
            <div>
              <div className="mt-3 text-sm">
                <p className="text-[#808080] text-sm">Маршрут</p>
                <div className="w-full mt-2">
                  <AddressInput
                    value={filters.search_from?.title || ''}
                    point="from"
                    onChange={(address: any) => onFilterFieldChange(address, 'search_from')}
                    allowAny={true}
                  />
                </div>
                <div className="w-full mt-2">
                  <AddressInput
                    value={filters.search_to?.title || ''}
                    point="to"
                    onChange={(address: any) => onFilterFieldChange(address, 'search_to')}
                    allowAny={true}
                  />
                </div>
              </div>
              <div className="mt-5 text-sm">
                <p className="text-[#808080] text-sm">Стоимость заказа</p>
                <div className="w-full mt-2 grid grid-cols-[1fr_1px_1fr] border border-[#D9D9D9] rounded-[8px]">
                  <Input
                    value={filters.price_from}
                    type="number"
                    placeholder="Min"
                    className="w-full rounded-l-lg rounded-r-none"
                    withShadow={false}
                    onChange={value => onFilterFieldChange(value, 'price_from')}
                  >
                    <div className="ml-2 my-2">
                      <img className="w-[0.8em]" src="/assets/img/dollar.svg" alt="" />
                    </div>
                  </Input>
                  <div className="w-full bg-[#D9D9D9]"></div>
                  <Input
                    value={filters.price_to}
                    type="number"
                    placeholder="Max"
                    className="w-full rounded-l-none rounded-r-lg"
                    withShadow={false}
                    onChange={value => onFilterFieldChange(value, 'price_to')}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-[#808080] text-sm">Дата начала</p>
                <div className="w-full mt-2 flex items-center">
                  <p className="text-[#808080] text-xs mr-2">от</p>
                  <DatePickerRounded
                    value={filters.start_from}
                    setValue={e => onFilterFieldChange(e, 'start_from')}
                  />
                  <DatePickerRounded
                    value={filters.start_from_time}
                    time={true}
                    setValue={e => onFilterFieldChange(e, 'start_from_time')}
                  />
                </div>
                <div className="w-full mt-2 flex items-center">
                  <p className="text-[#808080] text-xs mr-2">до</p>
                  <DatePickerRounded
                    value={filters.start_to}
                    setValue={e => onFilterFieldChange(e, 'start_to')}
                  />
                  <DatePickerRounded
                    value={filters.start_to_time}
                    time={true}
                    setValue={e => onFilterFieldChange(e, 'start_to_time')}
                  />
                </div>
              </div>
            </div>
          </PerfectScrollbar>
          <div className="mt-3 px-3">
            <ButtonSPrimary onClickHandler={() => getOrders(filters, 1)}>Применить</ButtonSPrimary>
          </div>
        </div>
      </div>
      <ModalsSection>
        <ExportModal
          opened={exportModal}
          isLoading={isTableLoading}
          selected={exportSelected}
          options={ExportFields}
          field="exportField"
          setSelected={setExportSelected}
          setOpened={setExportModal}
          exportParams={{
            url: '/export/orders',
            filename: `driver-history-${data.id}.xlsx`,
            fields: exportSelected,
            filters: getQueryFilters(filters),
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default DriversOrdersHistory;
