import { FunctionComponent, useEffect, useState } from 'react';

// Components
import MarkerWithWindow from './MarkerWithWindow';
import DriverInfoWindow from './DriverInfoWindow';
import OrderInfoWindow from './OrderInfoWindow';
import { IDriverData } from './DriverMap';
import { Clusterer } from '@react-google-maps/marker-clusterer';

interface IDriverMarker {
  driver: any;
  order?: any;
  handleClick?: (driver: IDriverData) => void;
  clusterer?: Clusterer;
}

const DriverMarker: FunctionComponent<IDriverMarker> = ({ driver, order, handleClick, clusterer }) => {
  const [type, setType] = useState<'driver' | 'order'>('driver');

  useEffect(() => {
    if (order) setType('order');
  }, [order]);

  const onClick = () => {
    if (handleClick) handleClick(driver);
  };

  return (
    <MarkerWithWindow
      clusterer={clusterer}
      icon="/assets/img/car.png"
      position={{
        lat: +driver.coordinates.lat,
        lng: +driver.coordinates.lng,
      }}
      isAlwaysOpened={false}
      onClick={onClick}
    >
      <>
        {type === 'driver' && <DriverInfoWindow driver={driver} />}
        {type === 'order' && <OrderInfoWindow order={order} />}
      </>
    </MarkerWithWindow>
  );
};

export default DriverMarker;
