import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';

interface ICheckbox {
  value?: boolean;
  disabled?: boolean;
  setValue?: Dispatch<SetStateAction<boolean>>;
  onClickHandler?: () => any;
}

const Checkbox: FunctionComponent<ICheckbox> = ({
  value = false,
  disabled = false,
  setValue = () => {},
  onClickHandler = () => {},
}) => {
  function onClick(e: any) {
    e.stopPropagation();
    e.preventDefault();

    if (disabled) {
      return;
    }

    setValue(!value);
    onClickHandler();
  }

  const handleKeyDown = e => {
    if (e.target !== document.activeElement) return;
    if (e.key !== 'Enter') return;
    onClick(e);
  };

  return (
    <span onKeyDown={handleKeyDown} tabIndex={0} className={disabled ? 'opacity-70' : 'opacity-100'}>
      {value ? (
        <svg
          width="16"
          height="32"
          viewBox="0 0 16 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
        >
          <rect x="0.5" y="8.5" width="15" height="15" rx="1.5" fill="#1D85D0" stroke="#1D85D0" />
          <path
            d="M6.21667 17.1667L12.1333 11.25L13.9667 13.1L6.21667 20.85L2.01667 16.65L3.86667 14.8167L6.21667 17.1667Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="32"
          viewBox="0 0 16 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
        >
          <rect x="1" y="9" width="14" height="14" rx="1" fill="url(#paint0_linear_13_104)" />
          <rect y="8" width="16" height="16" rx="2" fill="white" fillOpacity="0.01" />
          <rect x="0.5" y="8.5" width="15" height="15" rx="1.5" stroke="black" strokeOpacity="0.15" />
          <defs>
            <linearGradient
              id="paint0_linear_13_104"
              x1="1"
              y1="9"
              x2="1"
              y2="23"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="#EBEBEB" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </span>
  );
};

export default Checkbox;
