import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Input from 'src/components/UI/Input';
import AddressInput from 'src/components/Custom/AdressInput';
import Pagination from 'src/components/UI/Pagination';
import DatePickerRounded from 'src/components/UI/DatePickerRounded';
import { FilterBar, FilterButton } from 'src/components/FilterBar';

import OrdersListTable from 'src/components/Tables/orders/list';
import ExportModal from 'src/components/ExportModal';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import axiosClient from 'src/libs/axiosClient';
import logger from 'src/utils/logger';
import { parseDateFormat } from 'src/utils/format-date';

/*
const statuses = [
  'На модерации',
  'Утвержден',
  'Заблокирован',
  'Повторная модерация',
  'Есть текущий заказ',
  'Давно не был онлайн ',
];
*/

const EmptyFilters = {
  status: [],
  start_from: '',
  start_to: '',
  start_from_time: '',
  start_to_time: '',
  search_from: '',
  search_to: '',
  price_from: '',
  price_to: '',
};

const ExportFields = [
  // { title: 'ID', exportField: 'id' },
  { title: 'Водитель и ID', exportField: 'driver' },
  { title: 'Время', exportField: 'start_at' },
  { title: 'Статус', exportField: 'status' },
  { title: 'Оценка', exportField: 'rating' },
  { title: 'Точка начала', exportField: 'start_to' },
  { title: 'Точка назначения', exportField: 'finish_to' },
  { title: 'Расстояние', exportField: 'miles' },
  { title: 'Цена', exportField: 'total_price' },
];

interface LegalsOrdersHistoryTypes {
  legalId?: any;
}

const LegalsOrdersHistory: FunctionComponent<LegalsOrdersHistoryTypes> = ({ legalId }) => {
  const filtersButtonRef = useRef<any>(null);
  const [ordersHistory, setOrdersHistory] = useState<Array<any>>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportModal, setExportModal] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<any>(['sort_status', 'asc']);
  const [exportSelected, setExportSelected] = useState<any>(ExportFields.map(item => item.exportField));
  const [selected, setSelected] = useState<IExportSelected>([]);
  const [filters, setFilters] = useState<any>(EmptyFilters);

  const onFilterFieldChange = (val: any, field: string) => {
    const update = {
      [field]: val,
    };

    if (field === 'start_to' && !filters.start_to_time) {
      update.start_to_time = '23:59';
    }

    if (field === 'start_from' && !filters.start_from_time) {
      update.start_from_time = '00:00';
    }

    setFilters(filters => {
      return {
        ...filters,
        ...update,
      };
    });
  };

  const getQueryFilters = (filters: any) => {
    return Object.keys(filters).reduce((obj, key) => {
      if (!filters[key]) {
        return obj;
      }

      if (key === 'start_from_time' || key === 'start_to_time') {
        return obj;
      }

      switch (key) {
        case 'start_from':
        case 'start_to':
          obj[key] = parseDateFormat(`${filters[key]} ${filters[`${key}_time`]}`, 'YYYY-MM-DD HH:mm');
          break;
        case 'status':
          if (filters[key].length) {
            obj[key] = filters[key].join(',');
          }
          break;
        default:
          obj[key] = filters[key];
      }

      return obj;
    }, {});
  };

  const getLegalsOrdersHistory = async (filters: any, currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    setIsLoading(true);
    try {
      const { data } = await axiosClient.get('/orders', {
        params: {
          client_id: Number(legalId),
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(filters),
        },
      });

      setDataTotal(data.links.lastPage);
      setOrdersHistory(data.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (legalId) {
      getLegalsOrdersHistory(filters, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalId, sort]);

  return (
    <>
      <div className="mb-3 px-[17px] pt-[14px] flex justify-end items-center">
        <div className="flex justify-end items-center">
          <p className="text-[12px] mr-2 text-[#9b9d9c]">
            {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
          </p>
          {isPermitted('legal_entities_download_history_orders') && (
            <div className="mr-6">
              <ButtonSPrimary onClickHandler={() => setExportModal(true)}>
                Экспорт в Excel
              </ButtonSPrimary>
            </div>
          )}

          <FilterButton
            opened={filterOpen}
            buttonRef={filtersButtonRef}
            filtersIsEmpty={!filters.search && !filters.status.length}
            onOpen={() => setFilterOpen(true)}
            onClose={() => setFilterOpen(false)}
            offset={290}
          />

          <FilterBar
            setOpened={setFilterOpen}
            opened={filterOpen}
            onApply={() => getLegalsOrdersHistory(filters, 1)}
            onClear={() => {
              getLegalsOrdersHistory(EmptyFilters, 1);
            }}
            width={280}
          >
            <div className="mt-3">
              <p className="text-[#808080] text-sm">Маршрут</p>
              <div className="w-full mt-2 text-sm">
                <AddressInput
                  value={filters.search_from}
                  point="from"
                  onChange={(address: any) => onFilterFieldChange(address?.title || '', 'search_from')}
                />
              </div>
              <div className="w-full mt-2 text-sm">
                <AddressInput
                  value={filters.search_to}
                  point="to"
                  onChange={(address: any) => onFilterFieldChange(address?.title || '', 'search_to')}
                />
              </div>
            </div>
            <div className="mt-5 text-sm">
              <p className="text-[#808080] text-sm">Стоимость заказа</p>
              <div className="w-full mt-2 grid grid-cols-[1fr_1px_1fr] border border-[#D9D9D9] rounded-[8px]">
                <Input
                  value={filters.price_from}
                  type="number"
                  placeholder="Min"
                  className="w-full rounded-l-lg rounded-r-none"
                  withShadow={false}
                  onChange={value => onFilterFieldChange(value, 'price_from')}
                >
                  <div className="ml-2 my-2">
                    <img className="w-[0.8em]" src="/assets/img/dollar.svg" alt="" />
                  </div>
                </Input>
                <div className="w-full bg-[#D9D9D9]"></div>
                <Input
                  value={filters.price_to}
                  type="number"
                  placeholder="Max"
                  className="w-full rounded-l-none rounded-r-lg"
                  withShadow={false}
                  onChange={value => onFilterFieldChange(value, 'price_to')}
                />
              </div>
            </div>
            <div className="mt-5">
              <p className="text-[#808080] text-sm">Дата начала</p>
              <div className="w-full mt-2 flex items-center">
                <p className="text-[#808080] text-xs mr-2">от</p>
                <DatePickerRounded
                  value={filters.start_from}
                  setValue={e => onFilterFieldChange(e, 'start_from')}
                />
                <DatePickerRounded
                  value={filters.start_from_time}
                  time={true}
                  setValue={e => onFilterFieldChange(e, 'start_from_time')}
                />
              </div>
              <div className="w-full mt-2 flex items-center">
                <p className="text-[#808080] text-xs mr-2">до</p>
                <DatePickerRounded
                  value={filters.start_to}
                  setValue={e => onFilterFieldChange(e, 'start_to')}
                />
                <DatePickerRounded
                  value={filters.start_to_time}
                  time={true}
                  setValue={e => onFilterFieldChange(e, 'start_to_time')}
                />
              </div>
            </div>
          </FilterBar>
        </div>
      </div>
      <div className="overflow-auto bg-white">
        <OrdersListTable
          orders={ordersHistory}
          isLoading={isLoading}
          selected={selected}
          sort={sort}
          onOrderSelect={setSelected}
          onSortUpdate={setSort}
        />

        {!isLoading && ordersHistory.length !== 0 && (
          <Pagination
            totalPages={dataTotal}
            activePage={page}
            setPage={p => getLegalsOrdersHistory(filters, p as number)}
            alignment="justify-end"
          />
        )}
      </div>

      <ModalsSection>
        <ExportModal
          opened={exportModal}
          isLoading={isLoading}
          selected={exportSelected}
          options={ExportFields}
          field="exportField"
          setSelected={setExportSelected}
          setOpened={setExportModal}
          exportParams={{
            url: '/export/orders',
            filename: `legal-${legalId}-orders.xlsx`,
            fields: exportSelected,
            filters: {
              ...getQueryFilters(filters),
              legal_id: legalId,
            },
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default LegalsOrdersHistory;
