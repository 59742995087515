import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import MainSection from 'src/components/Semantic/MainSection';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Input from 'src/components/UI/Input';
import Pagination from 'src/components/UI/Pagination';
import VerticalLayout from 'src/layouts/VerticalLayout';
import Tooltip from 'src/components/UI/Tooltip';
import axiosClient from 'src/libs/axiosClient';
import { FilterBar, FilterButton } from 'src/components/FilterBar';
import logger from 'src/utils/logger';

// Table
import ClientsTable from 'src/components/Tables/clients/list';
import ExportModal from 'src/components/ExportModal';
import useDebounce from 'src/hooks/use-debounce';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';

interface IOrder {
  id: number;
  fromPath: string;
  toPath: string;
  status: string;
  price: any;
}

interface IClient {
  id: number;
  fullName: string;
  email?: string;
  phone?: string;
  lastOrder?: IOrder;
  orders: string;
  companyName: string;
  status: string | number;
}

const exportColumns = [
  { title: 'ID', field: 'id' },
  { title: 'Имя', field: 'first_name' },
  { title: 'Фамилия', field: 'last_name' },
  { title: 'Последний заказ', field: 'last_order' },
  { title: 'Почта', field: 'email' },
  { title: 'Телефон', field: 'phone_number' },
  { title: 'Заказов', field: 'orders_count' },
  { title: 'Компания', field: 'company' },
];

const ClientsList: FunctionComponent = () => {
  const filtersButtonRef = useRef<any>(null);

  // main table
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<Array<IClient>>([]);

  //filters
  const [sort, setSort] = useState<any>(['sort_orders_count', 'desc']);
  const [filterOpened, setFilterOpened] = useState<boolean>(false);
  const [idSearch, setIdSearch] = useState<string>('');
  const debouncedSearchTerm = useDebounce(idSearch, 500);
  //selected clients
  const [selected, setSelected] = useState<IExportSelected>([]);
  //excel export
  const [exportModalOpened, setExportModalOpened] = useState<boolean>(false);
  const [exportTableColumns, setExportTableColumns] = useState<Array<any>>(
    exportColumns.map(c => c.field)
  );

  const getQueryFilters = () => {
    return idSearch.length ? { id_search: idSearch } : {};
  };

  const getClients = async (currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    setIsLoading(true);
    setData([]);

    try {
      const { data: res } = await axiosClient.get('/clients', {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(),
        },
      });

      setDataTotal(res.links.lastPage);
      setData(res.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getClients(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  useEffect(() => {
    getClients(1);
  }, [debouncedSearchTerm]);

  const setStatus = async (clientId: number, status: boolean) => {
    if (!clientId) {
      logger.error('Клиент не найден');
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      if (status) {
        await axiosClient.get(`/clients/${clientId}/block`);
        logger.success('Клиент успешно заблокирован');
      } else {
        await axiosClient.get(`/clients/${clientId}/unblock`);
        logger.success('Клиент успешно разблокирован');
      }

      await getClients(page);

      setIsLoading(false);
    } catch (e: any) {
      logger.error(`Ошибка при обновлении данных ${e.message}`, e);
      setIsLoading(false);
    }
  };

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
              <div className="flex items-center">
                <h2 className="text-[24px] text-[#363B39] font-bold">Клиенты</h2>
              </div>
              <div className="flex justify-end items-center overflow-visible">
                <p className="text-[12px] mr-2 text-[#9b9d9c]">
                  {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
                </p>
                {isPermitted('clients_download_list') && (
                  <div className="mr-6">
                    <ButtonSPrimary onClickHandler={() => setExportModalOpened(true)}>
                      Экспорт в Excel
                    </ButtonSPrimary>
                  </div>
                )}
                <FilterButton
                  opened={filterOpened}
                  buttonRef={filtersButtonRef}
                  filtersIsEmpty={!idSearch.length}
                  onOpen={() => setFilterOpened(true)}
                  onClose={() => setFilterOpened(false)}
                >
                  <Tooltip
                    parentRef={filtersButtonRef}
                    disabled={!idSearch.length}
                    align="left"
                    className="w-[15em]"
                  >
                    <div className="mb-1">
                      <p className="text-sm text-[#9f9f9f]">ID Клиента</p>
                      <p className="text-sm">{idSearch}</p>
                    </div>
                  </Tooltip>
                </FilterButton>

                <FilterBar
                  setOpened={setFilterOpened}
                  opened={filterOpened}
                  onClear={() => setIdSearch('')}
                >
                  <div className="mt-3">
                    <p className="text-[#808080] text-sm">ID клиента</p>
                    <div className="w-full mt-2 text-sm">
                      <Input className="w-full" value={idSearch} onChange={setIdSearch} />
                    </div>
                  </div>
                </FilterBar>
              </div>
            </div>
            <div className="max-w-[95vw] overflow-auto bg-white">
              <ClientsTable
                clients={data}
                isLoading={isLoading}
                sort={sort}
                selected={selected}
                onClientSelect={setSelected}
                onClientStatusUpdate={setStatus}
                onSortUpdate={setSort}
              />

              {!isLoading && data.length !== 0 && (
                <Pagination
                  totalPages={dataTotal}
                  activePage={page}
                  setPage={setPage}
                  alignment="justify-end"
                />
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ExportModal
          opened={exportModalOpened}
          isLoading={isLoading}
          selected={exportTableColumns}
          options={exportColumns}
          setSelected={setExportTableColumns}
          setOpened={setExportModalOpened}
          exportParams={{
            url: '/export/clients',
            filename: 'clients.xlsx',
            fields: exportTableColumns,
            filters: getQueryFilters(),
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default ClientsList;
