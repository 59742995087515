import React, { FunctionComponent } from 'react';
import { Link, useLocation, Location, useNavigate, NavigateFunction } from 'react-router-dom';
import Logo from 'src/components/UI/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { IStore, TypedDispatch } from 'src/redux';
import NavLink from './NavLink';
import { isPermitted } from 'src/utils/permissions';
import { IS_SUPER_ADMIN } from 'src/redux/userReducer/getters';

const TheSideNavbar: FunctionComponent = () => {
  const { sidebarOpen } = useSelector((store: IStore) => store.layout);
  const user = useSelector((store: IStore) => store.user);
  const isSuperAdmin = useSelector(IS_SUPER_ADMIN);
  const dispatch: TypedDispatch = useDispatch();
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  const userRole = localStorage.getItem('userRole') || user.role;
  async function onLogoutHandler() {
    navigate(`/authorization${userRole === 'companyAdmin' ? '/company' : ''}`, { replace: true });

    //const accessToken = localStorage.getItem('accessToken');
    //const refreshToken = localStorage.getItem('refreshToken');

    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');

    // if (refreshToken) {
    //   await axiosClient.post(`${process.env.REACT_APP_API_BASE_URL_USER}/authentication/revoke`, {
    //     refreshToken,
    //   });
    // }
  }

  return (
    <>
      <nav
        className={`h-full fixed ${
          sidebarOpen ? 'w-[200px] px-4' : 'w-[65px] px-1'
        } shadow-md flex flex-col bg-white transition-all duration-200 mr-4`}
      >
        <div
          className={`flex ${
            sidebarOpen ? 'justify-between' : 'justify-center'
          } pt-[10px] items-center mb-7 transition-all`}
        >
          {sidebarOpen && <Logo />}
          <div
            className={`w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer transition-all ${
              !sidebarOpen && 'rotate-180'
            } duration-200`}
            style={{ boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.25)' }}
            onClick={() => {
              dispatch({ type: 'SET_SIDEBAR', payload: !sidebarOpen });
            }}
          >
            <img
              src="/assets/img/back.svg"
              style={sidebarOpen ? { marginRight: '2px' } : { marginLeft: '-2px' }}
              alt=""
            />
          </div>
        </div>
        <div className="flex justify-between flex-col h-full">
          <div>
            <Link to="/progress">
              <NavLink
                src="/assets/img/inProgress.svg"
                text={sidebarOpen ? 'Заказы в работе' : 'В работе'}
                isActive={location.pathname.includes('/progress')}
              />
            </Link>
            <Link to="/new-orders">
              <NavLink
                src="/assets/img/new.svg"
                text={sidebarOpen ? 'Новые заказы' : 'Новые'}
                isActive={location.pathname.includes('/new')}
              />
            </Link>
            <Link to="/history">
              <NavLink
                src="/assets/img/history.svg"
                text={sidebarOpen ? 'История заказов' : 'История'}
                isActive={location.pathname.includes('/history')}
              />
            </Link>
            {(isPermitted('drivers_show') || isPermitted('drivers_payment')) && (
              <div>
                <NavLink
                  src="/assets/img/drivers.svg"
                  text="Водители"
                  isActive={
                    location.pathname.includes('/drivers') &&
                    !location.pathname.includes('/transport-companies')
                  }
                  withDrop={true}
                  links={(() => {
                    const l: { title: string; link: string }[] = [];
                    if (isPermitted('drivers_show')) {
                      l.push({ title: 'Список водителей', link: '/drivers/list' });
                    }
                    if (isPermitted('drivers_payment')) {
                      l.push({ title: 'Выплаты водителям', link: '/drivers/payments' });
                    }

                    return l;
                  })()}
                />
              </div>
            )}
            <Link to="/clients/list">
              <NavLink
                src="/assets/img/clients.svg"
                text="Клиенты"
                isActive={location.pathname.includes('/clients')}
              />
            </Link>
            <Link to="/legals/list">
              <NavLink
                src="/assets/img/legal.svg"
                text="Юр.лица"
                isActive={location.pathname.includes('/legal')}
              />
            </Link>
            {(isPermitted('pushes_create') || isPermitted('pushes_edit')) && (
              <Link to="/mailing">
                <NavLink
                  src="/assets/img/plane.svg"
                  text={sidebarOpen ? 'Рассылки' : 'Рассыл.'}
                  isActive={location.pathname.includes('/mailing')}
                />
              </Link>
            )}
            {isPermitted('statistics_show') && (
              <Link to="/stats">
                <NavLink
                  src="/assets/img/stats.svg"
                  text={sidebarOpen ? 'Статистика и отчеты' : 'Статистик.'}
                  isActive={location.pathname.includes('/stats')}
                />
              </Link>
            )}
            <div>
              <NavLink
                src="/assets/img/settings.svg"
                text="Настройки"
                isActive={location.pathname.includes('/settings')}
                withDrop={true}
                links={(() => {
                  const l: { title: string; link: string }[] = [];
                  if (isPermitted('settings_admins_list')) {
                    l.push({ title: 'Администраторы', link: '/settings/adminstrators' });
                  }

                  l.push({ title: 'Общие настройки', link: '/settings/common' });
                  l.push({ title: 'База авто', link: '/settings/auto' });

                  if (isSuperAdmin) {
                    l.push({ title: 'Формула цены', link: '/settings/price' });
                  }

                  return l;
                })()}
              />
            </div>
            {userRole === 'companyAdmin' ? (
              <Link to={`/transport-companies/list/${user.company_id || user.id}/general`}>
                <NavLink
                  src="/assets/img/transportCompanies.svg"
                  text="Моя компания"
                  isActive={location.pathname.includes(
                    `/transport-companies/list/${user.company_id || user.id}`
                  )}
                />
              </Link>
            ) : (
              <>
                <div>
                  <NavLink
                    src="/assets/img/transportCompanies.svg"
                    text={sidebarOpen ? 'Компании перевоз.' : 'Перевозч.'}
                    isActive={location.pathname.includes('/transport-companies')}
                    withDrop={true}
                    links={[
                      { title: 'Выплаты компаниям', link: '/transport-companies/payments/list' },
                      { title: 'Список компаний', link: '/transport-companies/list' },
                      { title: 'Моя компания', link: `/transport-companies/list/0/general` },
                    ]}
                  />
                </div>
              </>
            )}
            <div className={'flex items-center' + (sidebarOpen ? '' : ' flex-col justify-center')}>
              <Link to="/notifications" className={sidebarOpen ? 'mr-2' : ''}>
                <NavLink
                  src="/assets/img/notifications.svg"
                  text={sidebarOpen ? 'Уведомления' : 'Уведом.'}
                  isActive={location.pathname.includes('/notifications')}
                  withMargin={!user.notificationsCount && sidebarOpen}
                />
              </Link>
              {!!user?.notificationsCount && (
                <div className="flex justify-center items-center w-[1.35rem] h-[1.35rem] text-xs text-white font-semibold bg-[#EE0202] rounded-full">
                  {user.notificationsCount > 9 ? '9+' : user.notificationsCount}
                </div>
              )}
            </div>
          </div>
          <div
            onClick={onLogoutHandler}
            className={`text-[#EE0202]`}
            // className={`${sidebarOpen ? 'mt-[144px]' : 'mt-[66px]'} text-[#EE0202]`}
          >
            <NavLink src="/assets/img/exit.svg" text={sidebarOpen ? 'Выйти' : ''} />
          </div>
        </div>
      </nav>
    </>
  );
};

export default TheSideNavbar;
