import { useState, useEffect } from 'react';

type Timer = (
  initTimer: number,
  direction: 'top' | 'bottom'
) => {
  timer: number;
  timerText: string;
  setNewTimer: (time: number) => void;
};

const getTimerText = (timer: number) => {
  const totalMinutes = Math.floor(timer / 60);

  const seconds = timer % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (timer < 0) {
    return '';
  }

  const hoursText = `${hours}h`;

  const minutesText = `${minutes}m`;

  const secondsText = `${seconds}s`;

  return `${hours === 0 ? '' : hoursText + ' '}${minutes === 0 ? '' : minutesText + ' '}${
    seconds === 0 ? '' : secondsText + ' '
  }`;
};

export const useTimer: Timer = (initTimer, direction) => {
  const [timer, setTimer] = useState(initTimer);

  useEffect(() => {
    let timerId: ReturnType<typeof setInterval>;

    if (timer !== undefined && timer >= 0) {
      timerId = setInterval(() => setTimer(timer - 1 * (direction === 'bottom' ? 1 : -1)), 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [timer, direction]);

  // const getTimeRest = (timerEnd: string) => Math.round((Date.parse(timerEnd) - Date.now()) / 1000);
  const setNewTimer = (time: number | null) => {
    setTimer(time ? time : 0);
  };

  return {
    timer,
    timerText: getTimerText(timer),
    setNewTimer,
  };
};
