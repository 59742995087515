import { FunctionComponent, useState } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Loader from 'src/components/Custom/Loader';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';

export const TimeModal: FunctionComponent<{
  title: string;
  opened: boolean;
  setOpened: (v: boolean) => void;
  isLoading: boolean;
  onSave: (time: string) => void;
}> = ({ title, opened, setOpened, isLoading, onSave }) => {
  const [value, setValue] = useState('');

  const options = [
    { value: '15', title: '15 минут' },
    { value: '60', title: '1 час' },
    { value: '240', title: '4 часа' },
  ];

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow title={title} setState={e => setOpened(e as boolean)}>
        <div>
          {options.map(option => (
            <div
              className="text-sm flex items-center cursor-pointer mb-2"
              onClick={() => setValue(option.value)}
            >
              <div
                className="flex items-center justify-center w-5 h-5 rounded-full mr-2"
                style={{
                  border: '1px solid',
                  borderColor: option.value === value ? '#1066B1' : 'rgba(0, 0, 0, 0.1)',
                }}
              >
                {option.value === value && (
                  <div className="w-2 h-2 rounded-full" style={{ backgroundColor: '#1066B1' }} />
                )}
              </div>

              <p style={{ color: option.value === value ? '#000000' : 'rgba(0, 0, 0, 0.5)' }}>
                {option.title}
              </p>
            </div>
          ))}

          {isLoading ? (
            <Loader size={100} />
          ) : (
            <div className="w-[320px] text-[1.1em] mt-6">
              <ButtonSPrimary disabled={!value} onClickHandler={() => onSave(value)}>
                Сохранить
              </ButtonSPrimary>
            </div>
          )}
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};
