import React, { FunctionComponent } from 'react';

interface IButtonSPrimary {
  isSpanFlex?: boolean;
  disabled?: boolean;
  onClickHandler?: () => any;
}

const ButtonSPrimary: FunctionComponent<IButtonSPrimary> = ({
  children,
  disabled = false,
  onClickHandler = () => {},
  isSpanFlex = true,
}) => {
  return (
    <button
      className={`text-[1rem] w-full py-[0.35em] px-[2em] bg-[#1066B1] text-white rounded-md border border-[#4F7E9F] transition-all duration-150 ${
        disabled ? 'opacity-60' : 'cursor-pointer hover:opacity-90 active:scale-[0.98]'
      }`}
      onClick={onClickHandler}
      disabled={disabled}
      style={{
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <span
        className={`text-[0.85em] w-full font-medium ${
          isSpanFlex ? 'flex' : ''
        } items-center justify-center text-center`}
      >
        {children}
      </span>
    </button>
  );
};

export default ButtonSPrimary;
