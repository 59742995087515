import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import logger from 'src/utils/logger';
import MainSection from 'src/components/Semantic/MainSection';
import VerticalLayout from 'src/layouts/VerticalLayout';
import Input from 'src/components/UI/Input';
import axiosClient from 'src/libs/axiosClient';
import Loader from 'src/components/Custom/Loader';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';

interface ISettingsPrice {
  mile_price: string;
  closed_trailer_multiplier: string;

  distance_multiplier: {
    distance_from: string;
    multiplier: string;
  }[];
}

const Common: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<ISettingsPrice>({
    mile_price: '',
    closed_trailer_multiplier: '',
    distance_multiplier: [],
  });

  const onFormDataChange = (field: string, value: string) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onDistance_multiplierChange = (index: number, field: string, value: string) => {
    setFormData({
      ...formData,
      distance_multiplier: formData.distance_multiplier.map((e, i) =>
        index === i ? { ...e, [field]: value } : e
      ),
    });
  };

  const onAddDistance_multiplier = () => {
    setFormData({
      ...formData,
      distance_multiplier: [...formData.distance_multiplier, { distance_from: '', multiplier: '' }],
    });
  };

  const onRemoveDistance_multiplier = (index: number) => {
    setFormData({
      ...formData,
      distance_multiplier: formData.distance_multiplier.filter((_, i) => index !== i),
    });
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await axiosClient.get<{ price_settings: ISettingsPrice }>('/settings');

      setFormData(res.data.price_settings || formData);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const updateSettings = async () => {
    setIsLoading(true);

    try {
      await axiosClient.post('/settings', {
        price_settings: formData,
      });

      logger.success('Изменения успешно сохранены');
    } catch (e: any) {
      logger.error(`Ошибка при сохранении формулы цены ${e.message}`, e);
    }

    setIsLoading(false);
  };

  const isFormValid = useMemo<boolean>(() => {
    return (
      !!formData.closed_trailer_multiplier &&
      !!formData.mile_price &&
      formData.distance_multiplier.every(e => !!e.distance_from && !!e.multiplier)
    );
  }, [formData]);

  const priceForOneCar = useMemo<number>(() => {
    const closed_trailer_multiplier = Number(formData.closed_trailer_multiplier || 1);

    const multipliers = [
      { distance_from: '0', multiplier: '1' },
      ...(formData.distance_multiplier || []),
    ]
      .map(e => ({ distance_from: Number(e.distance_from), multiplier: Number(e.multiplier) }))
      .sort((x, y) => x.distance_from - y.distance_from);

    const DISTANCE = 100;

    const sum = multipliers.reduce((s, distance_multiplier, index) => {
      const nextDistance = multipliers[index + 1];

      const distanceTo = nextDistance ? nextDistance.distance_from : DISTANCE;
      const miles = distanceTo - distance_multiplier.distance_from;

      return s + Number(formData.mile_price) * miles * (distance_multiplier.multiplier || 1);
    }, 0);

    return sum * closed_trailer_multiplier;
  }, [formData]);

  if (isLoading) {
    return (
      <MainSection>
        <VerticalLayout>
          <Loader size={100} />
        </VerticalLayout>
      </MainSection>
    );
  }

  return (
    <MainSection>
      <VerticalLayout>
        <div className="min-h-full w-96 flex flex-col justify-between text-[#363B39] bg-[#fff] pl-[18px] pt-3 pb-5">
          <div>
            <h3 className="text-2xl font-bold mb-4">Формула цены</h3>

            <div className="mb-4">
              <p className="text-[#808080] mb-1">Стоимось мили</p>
              <div className="flex items-center relative">
                <span className="absolute left-1.5 top-1.5 text-[#808080] text-sm">$</span>
                <Input
                  value={formData.mile_price}
                  type="number"
                  onChange={e => onFormDataChange('mile_price', e)}
                  className="pl-1"
                  min={0}
                />
              </div>
            </div>

            <div className="mb-4">
              <p className="text-[#808080] mb-1">Коэффициент закрытого трейлера</p>
              <Input
                value={formData.closed_trailer_multiplier}
                type="number"
                onChange={e => onFormDataChange('closed_trailer_multiplier', e)}
                min={1}
              />
            </div>

            <div className="mb-4 mt-1">
              <p className="font-bold mb-1">Коэффициент расстояния</p>

              <div>
                {formData.distance_multiplier.length > 0 && (
                  <div className="flex items-center text-[#808080] mb-1">
                    <p className="w-32">От ... милей</p>
                    <p className="w-32 ml-3 mr-3">Коэффициент</p>
                  </div>
                )}

                {formData.distance_multiplier.map((distance_multiplier, i) => (
                  <div key={i} className="flex items-center mb-3">
                    <Input
                      value={formData.distance_multiplier[i].distance_from}
                      type="number"
                      min={1}
                      className="w-32"
                      onChange={e => onDistance_multiplierChange(i, 'distance_from', e)}
                    />

                    <Input
                      value={formData.distance_multiplier[i].multiplier}
                      type="number"
                      min={1}
                      className="w-32 ml-3 mr-3"
                      onChange={e => onDistance_multiplierChange(i, 'multiplier', e)}
                    />

                    <div className="cursor-pointer" onClick={() => onRemoveDistance_multiplier(i)}>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z"
                          fill="#E56455"
                        />
                      </svg>
                    </div>
                  </div>
                ))}

                <p className="cursor-pointer text-[#1066B1]" onClick={() => onAddDistance_multiplier()}>
                  + Добавить
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="w-full text-[1.25em]">
              <div className="mb-4">
                <p className="text-sm text-[#808080]">
                  Цена за 1 машину в закрытом трейлере на 100 миль
                </p>
                <p className="text-xl">${priceForOneCar}</p>
              </div>

              <ButtonSPrimary disabled={!isFormValid} onClickHandler={updateSettings}>
                Сохранить
              </ButtonSPrimary>
            </div>
          </div>
        </div>
      </VerticalLayout>
    </MainSection>
  );
};

export default Common;
