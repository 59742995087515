import { FunctionComponent } from 'react';
import { DriverConnectionStatus } from '../Driver/ConnectionStatus';

const DriverInfo: FunctionComponent<any> = ({ driver }) => {
  return (
    <div className="flex items-center">
      {driver?.avatar && (
        <div className="flex justify-center items-center overflow-hidden w-[2em] h-[2em] mr-2 rounded-full">
          <img className="w-full h-full" src={driver.avatar} alt="avatar" />
        </div>
      )}
      <div className="flex flex-col gap-1">
        <span className="text-[#000000] text-[1.25em]">
          {driver?.lastName || '------'} {driver?.firstName || '------'}
        </span>
        <div className="flex gap-2 text-[0.8em]">
          <a
            className="text-[#1066B1] underline text-[9px] cursor-pointer"
            href={`/drivers/list/${driver.id}/general`}
          >
            {driver.id}
          </a>
          <span>+{driver?.phoneNumber}</span>
          <DriverConnectionStatus
            connection_lost={driver.connection_lost}
            connection_lost_at={driver.connection_lost_at}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverInfo;
