import React, { FunctionComponent, useEffect, useState } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import Loader from 'src/components/Custom/Loader';
import MainSection from 'src/components/Semantic/MainSection';
import Tabs from 'src/components/Tabs';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ModalBackdrop from 'src/components/UI/Modal';
import ClientsGeneral from './general';
import ClientsOrdersHistory from './ordersHistory';
import ClientsPaymentsHistory from './paymentsHistory';
import logger from 'src/utils/logger';
import { isPermitted } from 'src/utils/permissions';

interface IClient {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  created?: string;
  company?: any;
  is_blocked?: boolean;
}

const Client: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const links = [{ title: 'Общие данные', link: `/clients/list/${id}/general` }];
  if (isPermitted('clients_history_orders')) {
    links.push({ title: 'История заказов', link: `/clients/list/${id}/orders-history` });
  }
  if (isPermitted('clients_history_payment')) {
    links.push({ title: 'История оплат', link: `/clients/list/${id}/payments-history` });
  }

  const [client, setClient] = useState<IClient>({});
  const [infoClearModalOpened, setInfoClearModalOpened] = useState(false);

  const getClient = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosClient.get(`/clients/${id}`);
      setClient(data);
    } catch (e: any) {
      logger.error(`Ошибка при получении клиентов ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const clearClientInfo = async () => {
    setInfoClearModalOpened(false);
    setIsLoading(val => true);

    try {
      await axiosClient.delete(`/clients/${id}`);
      navigate('/clients/list');
    } catch (e: any) {
      logger.error(`Ошибка при удалении клиента ${e.message}`, e);
    } finally {
      setIsLoading(val => false);
    }
  };

  const blockClient = async () => {
    try {
      await axiosClient.get(`/clients/${id}/block`);
      logger.success('Клиент успешно заблокирован');
      await getClient();
    } catch (err: any) {
      logger.error(`Ошибка при попытке заблокировать клиента ${err.message}`, err);
    }
  };
  const unblockClient = async () => {
    try {
      await axiosClient.get(`/clients/${id}/unblock`);
      logger.success('Клиент успешно разблокирован');
      await getClient();
    } catch (err: any) {
      logger.error(`Ошибка при попытке разблокировать клиента ${err.message}`, err);
    }
  };

  const updateMailingStatus = async (status: 1 | 0) => {
    await axiosClient.put(`/clients/${id}/mailing`, {
      mailing: status,
    });
    await getClient();
  };

  useEffect(() => {
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`h-full`}>
            <div className="text-[#999999] text-[12px] mb-2 pt-3 pl-5">
              <span className="cursor-pointer" onClick={() => navigate('/clients/list')}>
                Клиенты
              </span>{' '}
              &gt; {client.firstName} {client.lastName}
            </div>
            <div className="flex justify-between items-center border-b-[1px] border-[#EAEAEA] pl-[6px]">
              <Tabs links={links} />
              <div className="flex">
                {isPermitted('clients_block') ? (
                  <>
                    {client.is_blocked ? (
                      <p
                        className="text-[#EE0202] text-xs mr-4 font-semibold cursor-pointer"
                        onClick={() => unblockClient()}
                      >
                        Разблокировать
                      </p>
                    ) : (
                      <p
                        className="text-[#EE0202] text-xs mr-4 font-semibold cursor-pointer"
                        onClick={() => blockClient()}
                      >
                        Блокировать
                      </p>
                    )}
                  </>
                ) : (
                  <p className="text-[#EE0202] text-xs mr-4 font-semibold cursor-pointer">
                    {client.is_blocked ? 'Заблокирован' : 'Активен'}
                  </p>
                )}

                <p
                  className="text-[#EE0202] text-xs mr-4 font-semibold cursor-pointer"
                  onClick={() => setInfoClearModalOpened(true)}
                >
                  Удалить
                </p>
              </div>
            </div>
            {isLoading ? (
              <Loader size={100} />
            ) : (
              <>
                <Routes>
                  <Route
                    path="/general"
                    element={
                      <ClientsGeneral client={client} updateMailingStatus={updateMailingStatus} />
                    }
                  />
                  <Route
                    path="/orders-history"
                    element={<ClientsOrdersHistory clientId={Number(id) || 0} />}
                  />
                  <Route
                    path="/payments-history"
                    element={<ClientsPaymentsHistory clientId={Number(id) || 0} />}
                  />
                </Routes>
              </>
            )}
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ModalBackdrop state={infoClearModalOpened}>
          <div className="flex justify-center items-center h-full">
            <button
              onClick={() => {
                setInfoClearModalOpened(false);
              }}
              className={`absolute right-5 top-6`}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7M13 13L7 7M7 7L13 1M7 7L1 13"
                  stroke="white"
                  strokeOpacity="1"
                  strokeWidth="1.5"
                />
              </svg>
            </button>
            <div className="flex flex-col items-center w-2/5 bg-white p-10">
              <h3 className="text-2xl font-bold mb-4 text-center">Удалить информацию о клиенте?</h3>
              <p className="mb-8 text-center">
                Если вы подтвердите данное действие, то все данные о нем будут удалены из админки.
              </p>
              <div className="grid grid-cols-2 gap-4 w-3/5">
                <button
                  className="py-[10px] px-7 bg-[#F2F2F2] text-black rounded-sm border border-[#D9D9D9] cursor-pointer"
                  onClick={() => setInfoClearModalOpened(false)}
                  style={{
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <span className="flex justify-center text-base flex items-center">Отмена</span>
                </button>

                <button
                  className="py-[10px] px-7 bg-[#DB482C] text-white rounded-sm border border-[#C2564B] cursor-pointer"
                  onClick={() => clearClientInfo()}
                  style={{
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <span className="flex justify-center text-base flex items-center">Удалить</span>
                </button>
              </div>
            </div>
          </div>
        </ModalBackdrop>
      </ModalsSection>
    </>
  );
};

export default Client;
