import { FunctionComponent } from 'react';
import { isPermitted } from 'src/utils/permissions';

const ReportsList: FunctionComponent<any> = ({ data }: any) => {
  if (!data?.length) {
    return <p className="ml-1 text-[14px]">-----</p>;
  }

  if (!isPermitted('orders_show_reports')) {
    return <p className="ml-1 text-[14px]">Скрыто</p>;
  }

  return (
    <>
      {data.map((report: string, index: number) => {
        return (
          <div key={index}>
            <a download href={report} className="underline" target="_blank" rel="noreferrer">
              <div className="flex flex-row items-center cursor-pointer">
                <img src="/assets/img/file.svg" alt="change" />
                <p className="ml-1 text-[#1066B1] text-[14px]">Скачать</p>
              </div>
            </a>
          </div>
        );
      })}
    </>
  );
};

export default ReportsList;
