import { FunctionComponent } from 'react';
import { DriverConnectionStatus } from 'src/components/Driver/ConnectionStatus';

export const DriversSelectedField: FunctionComponent<any> = ({ data = {}, placeholder = '' }) => {
  return (
    <div className="flex w-[95%] max-w-[95%] items-center">
      <img
        src={data?.avatar ? data.avatar : '/assets/img/user.png'}
        className="w-[30px] h-[30px] object-cover rounded-full"
        alt=""
      />
      <div className="ml-2">
        <p className="text-[12px]">
          {data?.lastName ? data?.lastName : '-----'} {data?.firstName ? data?.firstName : '-----'}
        </p>
        <div className="flex">
          <p className="text-[10px] text-[#1066B1]">{data?.id ? data?.id : '-----'}</p>
          {data?.trailer_type && <p className="text-[10px] ml-1">{data?.trailer_type}</p>}
          <p className="text-[10px] ml-1">
            {data?.trailer_params
              ? `${data?.trailer_params?.length || '-'} x ${data?.trailer_params?.width || '-'} x
                        ${data?.trailer_params?.height || '-'}`
              : ''}
            {data?.trailer_params?.weight ? ` ${data?.trailer_params?.weight}lb` : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

export const DriverOption: FunctionComponent<{ option: any; type: 'normal' | 'near' }> = ({
  option,
  type,
}) => {
  return (
    <div className="flex w-[95%] max-w-[95%] items-center px-1 cursor-pointer">
      <img
        src={option?.avatar || '/assets/img/clients.svg'}
        className="w-[30px] h-[30px] object-cover rounded-full"
        alt=""
      />

      {type === 'normal' ? (
        <div className="ml-2">
          <p className="text-[12px]">
            {option?.firstName} {option?.lastName}
          </p>
          <div className="flex">
            <p className="text-[10px] text-[#1066B1] ml-1">{option?.id}</p>
            {option?.trailer_params?.trailer_type && (
              <p className="text-[10px] font-semibold ml-1">
                {option?.trailer_params?.trailer_type === 2
                  ? 'Enclosed'
                  : option?.trailer_params?.trailer_type === 1
                  ? 'Closed'
                  : '-'}
              </p>
            )}
            <p className="text-[10px] ml-1">
              {option?.trailer_params
                ? `${option?.trailer_params?.length || '-'} x ${option?.trailer_params?.width || '-'} x
                              ${option?.trailer_params?.height || '-'}`
                : ''}{' '}
              {option?.trailer_params?.weight ? ` ${option?.trailer_params?.weight}lb` : ''}
            </p>
          </div>
        </div>
      ) : (
        <div className="w-full ml-2 flex item-center justify-between">
          <div>
            <div className="flex">
              <p className="text-[12px]">
                {option?.firstName} {option?.lastName}
              </p>
              <span className="text-[12px] text-[#1066B1] ml-1">{option?.id}</span>
            </div>
            <div className="flex">
              {option?.trailer_params?.trailer_type && (
                <p className="text-[10px] font-semibold ml-1">
                  {option?.trailer_params?.trailer_type === 2
                    ? 'Enclosed'
                    : option?.trailer_params?.trailer_type === 1
                    ? 'Closed'
                    : '-'}
                </p>
              )}
              <div className="flex text-[10px]">
                <p className="mr-1">
                  До точки A: <span className="text-[#27AE60]">2,4km</span>
                </p>
                <DriverConnectionStatus
                  connection_lost={option.connection_lost}
                  connection_lost_at={option.connection_lost_at}
                />
              </div>
            </div>
          </div>

          <div className="w-10 ml-1">
            <p className="text-[10px] text-[#363B39BF] opacity-90" style={{ lineHeight: '11px' }}>
              {option.countOrders} заказ завершён
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
