import { FunctionComponent } from 'react';
import { GridTable, GridTableCell } from 'src/components/GridTable';
import { parseDate } from 'src/utils/format-date';

// Basic cells
import TimeCell from '../BASIC_CELLS/TimeCell';

interface IMailingsTable {
  data: Array<any>;
  isLoading?: boolean;
  sort: string[];
  onSortUpdate: (newSort: Array<string>) => any;
}

const violationTypesIcons = {
  'violation-rest': 'bed',
  'violation-count-period': 'warning',
  'violation-speeding': 'speed',
  'violation-night-rides': 'night',
};

const messages = {
  notSearch: 'Не передан в поиск',
  driverNotAssigned: 'Водитель не назначен',
  driverAssigned: 'Водитель принял заказ',
  arrive: 'Водитель прибудет через',
  arrived: 'Водитель прибыл в точку начала',
  acceptanceReport: 'Создание акта отправки',
  onWay: 'Водитель доставляет заказ',
  finish: 'Прибудет в точку выдачи через',
  finished: 'Водитель прибыл в точку выдачи',
  finishReport: 'Создание акта приемки',
  done: 'Заказ завершен',
  canceled: 'Отменен',
  cancel: 'Отменен',
};

const minutes = {
  '15': '15 минут',
  '60': '1 час',
  '240': '4 часа',
};

const StatusChangesTable: FunctionComponent<IMailingsTable> = ({
  data,
  isLoading,
  sort,
  onSortUpdate,
}) => {
  const TableFields = {
    recipient: {
      label: 'Статусы и сообщения о нарушениях',
      align: 'start',
      sortBy: 'sort_message',
      component: function (recipient: string, index: number, violation: any) {
        const isViolation = Object.keys(violationTypesIcons).includes(violation.type);

        //console.log(violation);

        return (
          <GridTableCell>
            {isViolation ? (
              <div className="flex items-center">
                <img
                  className="w-[2.5em] h-[2.5em] bg-[#ededed] rounded-[100%] p-[0.5em]"
                  src={`/assets/img/${violationTypesIcons[violation.type]}.svg`}
                  alt=""
                />
                <span className={`px-4 py-2 text-[12px]`}>{violation.message}</span>
              </div>
            ) : violation.message === 'cancel' || violation.message === 'canceled' ? (
              <div className="flex items-center">
                <span className={`px-4 py-2 bg-[#EE02021A] text-[#EE0202] text-[12px]`}>
                  {messages[violation.message]}
                </span>
                <div className={`flex flex-col px-4 py-2 text-[12px]`}>
                  <span className="font-bold">Заказ отменен - Причина отмены</span>
                  <span>{violation?.data?.comment ?? 'Не указана'}</span>
                </div>
              </div>
            ) : (
              <span
                className={`px-4 py-2 ${
                  recipient !== 'Водители' ? '' : 'bg-[#e7f0f7] text-[#1066b1] text-[12px]'
                }`}
              >
                {messages[violation.message]
                  ? messages[violation.message]
                  : violation.message.slice(0, 100) + (violation.message.length > 100 ? '...' : '')}
                {violation.data?.minutes ? ' ' + minutes[violation.data.minutes] : ''}
              </span>
            )}
          </GridTableCell>
        );
      },
    },

    date_send: {
      label: 'Дата отправки',
      align: 'end',
      sortBy: 'sort_date',
      component: function (date: string) {
        return <TimeCell date={parseDate(date)} />;
      },
    },
  };

  return (
    <GridTable
      data={data}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
      maxContentHeight={'300px'}
      headerType={'fixed'}
      className="overflow-auto"
    />
  );
};

export default StatusChangesTable;
