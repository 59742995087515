import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Input from 'src/components/UI/Input';
import Pagination from 'src/components/UI/Pagination';
import DatePicker from 'src/components/UI/DatePicker';
import axiosClient from 'src/libs/axiosClient';
import { defaultFromDayRange, defaultToDayRange } from 'src/utils/calendar';

import CompanyAdminsTable from 'src/components/Tables/companies/admins';
import { isPermitted } from 'src/utils/permissions';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ExportModal from 'src/components/ExportModal';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';

const adminsExportFields = [
  { field: 'id', title: 'Id' },
  { field: 'lastName', title: 'Фамилия' },
  { field: 'firstName', title: 'Имя' },
  { field: 'email', title: 'Почта' },
  { field: 'phoneNumber', title: 'Телефон' },
];

const CompanyAdmins: FunctionComponent<any> = () => {
  const { id } = useParams();
  const [admins, setAdmins] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<any>(['sort_driver', 'asc']);
  const [filters, setFilters] = useState<any>({
    search: '',
    dateFrom: defaultFromDayRange,
    dateTo: defaultToDayRange,
  });

  const [adminsExportModalOpened, setAdminsExportModalOpened] = useState<boolean>(false);
  const [selectedAdmins, setSelectedAdmins] = useState<IExportSelected>([]);
  const [exportAdminsSelected, setExportAdminsSelected] = useState<any>(
    adminsExportFields.map(el => el.field)
  );

  const updateFilterField = (filterValue: any, field: string) => {
    setFilters({ ...filters, [field]: filterValue });
  };

  const getAdmins = async (currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    if (!isLoading) {
      setIsLoading(true);
    }

    try {
      const { data: res } = await axiosClient.get(`/companies/${id}/admins`, {
        params: {
          page: currentPage,
        },
      });

      if (res.links) {
        setTotal(res.links.lastPage);
      }

      setAdmins(res.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении администратора ${e.message}`, e);
    } finally {
      setIsLoading(false);
      //getData();
    }
  };

  useEffect(() => {
    getAdmins(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
        <div className="flex">
          <div className="text-sm mr-4">
            <Input
              value={filters.search}
              type="search"
              placeholder="Поиск"
              onChange={value => updateFilterField(value, 'search')}
            />
          </div>
          <div className="flex items-center mr-4 text-[0.95em]">
            <p className="whitespace-nowrap mr-4">Начиная с:</p>
            <div className="max-w-[12em]">
              <DatePicker
                value={filters.dateFrom}
                onChange={date => updateFilterField(date, 'dateFrom')}
              />
            </div>
          </div>
          <div className="flex items-center text-[0.95em]">
            <p className="whitespace-nowrap mr-4">до:</p>
            <div className="max-w-[12em]">
              <DatePicker value={filters.dateTo} onChange={date => updateFilterField(date, 'dateTo')} />
            </div>
          </div>
        </div>

        <div className="flex justify-end items-center">
          <p className="text-[12px] mr-2 text-[#9b9d9c]">
            {selectedAdmins === 'all' ? 'Выбраны все' : `Выбрано ${selectedAdmins.length}`}:
          </p>
          {isPermitted('companies_download_admins_list') && (
            <div className="mr-18">
              <ButtonSPrimary onClickHandler={() => setAdminsExportModalOpened(true)}>
                Экспорт в Excel
              </ButtonSPrimary>
            </div>
          )}
        </div>
      </div>
      <div className="overflow-auto bg-white">
        <CompanyAdminsTable
          admins={admins}
          isLoading={isLoading}
          sort={sort}
          selected={selectedAdmins}
          onAdminSelect={setSelectedAdmins}
          onSortUpdate={setSort}
        />

        {!isLoading && admins.length !== 0 && (
          <Pagination totalPages={total} activePage={page} setPage={setPage} alignment="justify-end" />
        )}
      </div>

      <ModalsSection>
        <ExportModal
          title="Экспорт админов компании"
          opened={adminsExportModalOpened}
          isLoading={isLoading}
          selected={exportAdminsSelected}
          options={adminsExportFields}
          setSelected={setExportAdminsSelected}
          setOpened={setAdminsExportModalOpened}
          exportParams={{
            url: '/export/admins',
            filename: 'companies-admins.xlsx',
            fields: exportAdminsSelected,
            filters: {
              company_id: id,
            },
            selected: selectedAdmins,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default CompanyAdmins;
