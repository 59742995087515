import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';

interface ITextarea {
  value?: string | number;
  setValue?: Dispatch<SetStateAction<string>>;
  placeholder?: string;
  maxLength?: number;
}

const Textarea: FunctionComponent<ITextarea> = ({
  value = '',
  setValue = () => {},
  placeholder = '',
  maxLength = 300,
}) => {
  return (
    <>
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={e => {
          setValue(e.target.value);
        }}
        maxLength={maxLength}
        style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)' }}
        className="border text-sm border-[#D9D9D9] outline-none focus:border-[#adadad] px-3 py-2 w-full max-w-xs rounded-[8px]"
      />
    </>
  );
};

export default Textarea;
