import React, { FunctionComponent } from 'react';

const SearchICO: FunctionComponent = () => {
  return (
    <>
      <svg
        className="m-auto w-[1em] h-[1em]"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.71979 0.959961C3.71854 0.959961 1.27979 3.39871 1.27979 6.39996C1.27979 9.40121 3.71854 11.84 6.71979 11.84C7.90729 11.84 9.00479 11.4575 9.89979 10.81L14.1098 15.01L15.0098 14.11L10.8498 9.93996C11.6673 8.98746 12.1598 7.75121 12.1598 6.39996C12.1598 3.39871 9.72104 0.959961 6.71979 0.959961ZM6.71979 1.59996C9.37479 1.59996 11.5198 3.74496 11.5198 6.39996C11.5198 9.05496 9.37479 11.2 6.71979 11.2C4.06479 11.2 1.91979 9.05496 1.91979 6.39996C1.91979 3.74496 4.06479 1.59996 6.71979 1.59996Z"
          fill="black"
          fillOpacity="0.5"
        />
      </svg>
    </>
  );
};

export default SearchICO;
