import React, { FunctionComponent, useEffect, useState, useRef } from 'react';

interface ITooltip {
  parentRef: React.RefObject<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  align?: 'left' | 'right' | 'center';
  children?: any;
}

const Tooltip: FunctionComponent<ITooltip> = ({
  parentRef,
  align = 'center',
  className = '',
  disabled = false,
  children,
}) => {
  const tooltipRef = useRef<any>(true);
  const [opened, setOpened] = useState<boolean>(true);
  const [yOffset, setYOffset] = useState<number>(0);
  const [xOffset, setXOffset] = useState<number>(0);

  useEffect(() => {
    const { current: parentEl } = parentRef;

    if (!parentEl) {
      return;
    }

    parentEl.onmouseout = () => setOpened(false);

    return () => {
      parentEl.onmouseout = () => {};
      parentEl.onmouseover = () => {};
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef]);

  useEffect(() => {
    const { current: parentEl } = parentRef;

    if (!parentEl) {
      return;
    }

    if (disabled) {
      parentEl.onmouseover = () => {};
    } else {
      parentEl.onmouseover = () => setOpened(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    const { current: tooltipEl } = tooltipRef;

    if (tooltipEl?.clientHeight) {
      setYOffset(tooltipEl.clientHeight + (parentRef.current?.clientHeight || 0) + 15);
    }

    if (align === 'center' || !tooltipEl?.clientWidth) {
      setXOffset(0);
    } else {
      setXOffset((align === 'left' ? 1 : -1) * (tooltipEl.clientWidth + parentRef.current?.clientWidth));
    }

    setOpened(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipRef, children]);

  return (
    <div
      className={`absolute text-[black] min-w-[10em] min-h-[3em] bg-white shadow-md p-4 ${
        opened ? '' : 'opacity-0 translate-x-[-100vw]'
      } ${className}`}
      style={{ marginTop: yOffset + 'px', marginRight: xOffset + 'px' }}
      ref={tooltipRef}
    >
      {children}
    </div>
  );
};

export default Tooltip;
