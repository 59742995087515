import { FunctionComponent, useEffect, useState } from 'react';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import {
  PaymentStatus,
  TransactionForm,
  TransactionType,
  allPaymentStatuses,
  transactionTypeStatuses,
} from '../transaction-form';
import GridTable, { GridTableCell } from 'src/components/GridTable';
import ExportCheckboxesColumn from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate } from 'src/utils/format-date';
import ExportModal from 'src/components/ExportModal';
import axiosClient from 'src/libs/axiosClient';
import logger from 'src/utils/logger';
import Cross from 'src/components/UI/Cross';
import { isPermitted } from 'src/utils/permissions';

export interface ITransaction {
  id: number;
  order_id: number;
  transaction_type: 'client' | 'driver';
  status: PaymentStatus | 7;
  payment_type: TransactionType;
  amount: number;
  remainder: number;
  data: {
    receipt?: string;
    order_transaction_payment_id?: number;
    author_id?: number;
    author_model?: 'admin';
    author_avatar?: string;
    total_price?: number;
    old_total_price?: number;
    old_driver_commission?: number;
    driver_commission?: number;
  };
  author?: {
    id: number;
    full_name: string;
    avatar: string | null;
  };
  created_at: string;
}

const ExportFields = [
  { title: 'Сумма', field: 'amount' },
  { title: 'Создано', field: 'author' },
  { title: 'Дата и время', field: 'created_at' },
  { title: 'Статус', field: 'status' },
  { title: 'Тип оплаты', field: 'payment_type' },
  { title: 'Остаток', field: 'remainder' },
];

export const TransactionTable: FunctionComponent<{
  orderId: number;
  setOpened: (bool: boolean) => void;
  opened?: boolean;
  type: 'client' | 'driver';

  totalPayed: string;
  totalPrice: string;

  onTransactionCreated: () => void;
}> = ({
  orderId,
  setOpened,
  opened = false,
  type,

  totalPayed,
  totalPrice,

  onTransactionCreated,
}) => {
  const [selected, setSelected] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [exportModal, setExportModal] = useState<boolean>(false);
  const [exportSelected, setExportSelected] = useState<any>(ExportFields.map(e => e.field));

  const [data, setData] = useState<ITransaction[]>([]);
  // const [pagination, setPagination] = useState({
  //   totalPages: 1,
  //   activePage: 1,
  // });

  const getTransactions = async () => {
    setIsLoading(true);

    try {
      let { data } = await axiosClient.get(`/orders/${orderId}/transactions`, {
        params: {
          transaction_type: type,
        },
      });

      setData(data.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const onSave = async () => {
    onTransactionCreated();
  };

  const navigate = useNavigate();
  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: setSelected,
      additionalParams: { width: '30px' },
    }),

    amount: {
      label: 'Сумма',
      width: '160px',
      component: (amount?: string) =>
        amount ? (
          <GridTableCell>
            <p>${amount}</p>
          </GridTableCell>
        ) : (
          <GridTableCell>-</GridTableCell>
        ),
    },

    data: {
      label: 'Создано',
      width: '160px',
      component: (createdBy: ITransaction['data'], _, row: ITransaction) => (
        <GridTableCell>
          {createdBy.author_model !== 'admin' ? (
            <p className="text-[#BDBDBD] text-sm tracking-wide">В приложении</p>
          ) : (
            <div className="flex items-center">
              {row.author?.avatar && (
                <img className="w-5 h-5 rounded-full" src={row.author.avatar} alt="admin" />
              )}
              <p
                className="text-[#1066B1] underline text-[10px] pl-[6px] cursor-pointer"
                onClick={e => {
                  e.stopPropagation();
                  navigate(`/settings/adminstrators/${createdBy.author_id}/general`);
                }}
              >
                #{createdBy.author_id}
              </p>
            </div>
          )}
        </GridTableCell>
      ),
    },

    created_at: {
      label: 'Дата и время',
      width: '160px',
      component: (time: string, index: number, order: any) => (
        <GridTableCell className="text-[#BDBDBD] text-sm tracking-wide">
          <p>{getFormattedDate(time, 'DD.MM.YYYY HH:mm')}</p>
        </GridTableCell>
      ),
    },

    status: {
      label: 'Статус',
      component: (status: number, _, transaction: ITransaction) => {
        const paymentStatus = allPaymentStatuses.find(e => e.id === status);

        return (
          <GridTableCell>
            {status === 7 ? (
              <div>
                Изменена необходимая сумма с $
                {transaction?.data?.old_driver_commission || transaction.data?.old_total_price} на $
                {transaction?.data?.driver_commission || transaction?.data?.total_price}
              </div>
            ) : (
              <div
                className="px-[6px] py-[2px] rounded-[8px] text-[#1066B1] text-[12px]"
                style={{
                  backgroundColor: `rgba(${paymentStatus?.color}, 0.15)`,
                  color: `rgb(${paymentStatus?.color})`,
                }}
              >
                {paymentStatus?.title}
              </div>
            )}
          </GridTableCell>
        );
      },
    },

    payment_type: {
      label: 'Тип оплаты',
      width: '160px',
      component: (payment_type: number, _, transaction?: ITransaction) => (
        <GridTableCell>
          {transaction?.status !== 7 && payment_type ? (
            <span>
              {[...transactionTypeStatuses, { id: 2, title: 'Stripe' }].find(e => e.id === payment_type)
                ?.title ?? payment_type}
            </span>
          ) : (
            <span>-</span>
          )}
        </GridTableCell>
      ),
    },

    remainder: {
      label: 'Остаток',
      width: '160px',
      component: (remainder: number, _, transaction?: ITransaction) => (
        <GridTableCell>
          {transaction?.status !== 7 && remainder !== null ? <span>${remainder}</span> : <span>-</span>}
        </GridTableCell>
      ),
    },
  };

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow
        setState={v => setOpened(v as boolean)}
        onClose={() => setOpened(false)}
        className="max-w-5xl min-w-[300px] max-h-[80vh] flex rounded"
        close={false}
      >
        <>
          <div className={`bg-[#fff] h-screen`}>
            <div className="flex justify-between px-[17px] pt-[14px] pb-[12px]">
              <h2 className="text-[24px] text-[#363B39] font-bold">
                Транзакции {type === 'client' ? 'клиента' : 'водителя'}
              </h2>
              <div className="flex">
                <div className="flex justify-end items-center mb-3 px-[17px]">
                  <p className="text-[12px] mr-2 text-[#9b9d9c]">
                    {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
                  </p>
                  <div className="mr-1">
                    <ButtonSPrimary onClickHandler={() => setExportModal(true)}>
                      Экспорт в Excel
                    </ButtonSPrimary>
                  </div>
                </div>
                {isPermitted('orders_change_price') && (
                  <div className="mr-3">
                    <ButtonSPrimary onClickHandler={() => setCreateModal(true)}>
                      + Создать транзакцию
                    </ButtonSPrimary>
                  </div>
                )}
              </div>
            </div>
            <>
              <div className="overflow-auto bg-white">
                <GridTable
                  data={data}
                  fields={TableFields}
                  sort={['', '']}
                  isLoading={isLoading}
                  align="start"
                />

                {/* {!isLoading && data.length !== 0 && (
                  <Pagination
                    totalPages={pagination.totalPages}
                    activePage={pagination.activePage}
                    setPage={p => setPagination({ ...pagination, activePage: p as number })}
                    alignment="justify-end"
                  />
                )} */}
              </div>
            </>
          </div>
          <ModalsSection>
            <ExportModal
              opened={exportModal}
              isLoading={isLoading}
              selected={exportSelected}
              options={ExportFields}
              setSelected={setExportSelected}
              setOpened={setExportModal}
              exportParams={{
                url: `/export/orders/${orderId}/transactions`,
                filename: 'transactions.xlsx',
                fields: exportSelected,
                filters: {
                  transaction_type: type,
                },
                selected,
                setIsLoading,
              }}
            />

            <TransactionForm
              orderId={orderId}
              type={type}
              opened={createModal}
              setOpened={setCreateModal}
              totalPayed={totalPayed}
              totalPrice={totalPrice}
              onTransactionCreated={() => onSave()}
            />
          </ModalsSection>

          <button onClick={() => setOpened(false)} className={`absolute -right-5 -top-6`}>
            <Cross stroke="white" strokeWidth="3" strokeOpacity="1" />
          </button>
        </>
      </ModalWindow>
    </ModalBackdrop>
  );
};
