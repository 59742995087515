import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logger from 'src/utils/logger';
import Button from 'src/components/UI/Button';
import axiosClient from 'src/libs/axiosClient';

import CheckList from 'src/components/UI/CheckList';
import Loader from 'src/components/Custom/Loader';

const checkLists = {
  orders: {
    title: 'Заказы',
    options: [
      { title: 'Выгрузка списка', field: 'orders_download_list' },
      { title: 'Изменение цены', field: 'orders_change_price' },
      { title: 'Изменение адресов', field: 'orders_change_address' },
      { title: 'Изменение водителя', field: 'orders_change_driver' },
      { title: 'Изменения отправителя и получателя', field: 'orders_change_sender_receiver' },
      { title: 'Cкачивание актов приемки и отправки', field: 'orders_show_reports' },
      { title: 'Cоздание заказа', field: 'orders_create' },
    ],
  },

  notifications: {
    title: 'Рассылки',
    options: [
      { title: 'Cоздание рассылки', field: 'pushes_create' },
      { title: 'Редактирование рассылки', field: 'pushes_edit' },
    ],
  },

  settings: {
    title: 'Настройки',
    options: [
      { title: 'Стоимость заказа', field: 'settings_order_price' },
      { title: 'Настройки штрафа', field: 'settings_fine' },
      { title: 'Информация о компании', field: 'settings_company' },
      { title: 'Договоры приложений', field: 'settings_contract' },
      {
        title: 'Просмотр списка администраторов',
        field: 'settings_admins_list',
        sub: [
          { title: 'Приглашение', field: 'settings_admins_invitation' },
          { title: 'Выгрузка списка', field: 'settings_admins_download_list' },
          { title: 'Редактирование общих данных', field: 'settings_admins_edit' },
          { title: 'Блокировка администратора', field: 'settings_admins_block' },
          { title: 'Выдача прав доступа', field: 'settings_admins_permissions' },
          { title: 'История действий', field: 'settings_admins_history_actions' },
          { title: 'История входов', field: 'settings_admins_history_login' },
        ],
      },
    ],
  },

  clients: {
    title: 'Клиенты',
    options: [
      { title: 'Выгрузка списка', field: 'clients_download_list' },
      { title: 'Блокировка пользователя', field: 'clients_block' },
      {
        title: 'История заказов',
        field: 'clients_history_orders',
        sub: [{ title: 'Выгрузка истории', field: 'clients_download_history_orders' }],
      },
      {
        title: 'История оплат',
        field: 'clients_history_payment',
        sub: [{ title: 'Выгрузка истории', field: 'clients_download_history_payment' }],
      },
    ],
  },

  legals: {
    title: 'Юр. лица',
    options: [
      { title: 'Выгрузка списка', field: 'legal_entities_list' },
      { title: 'Смена статуса', field: 'legal_entities_change_status' },
      {
        title: 'История заказов',
        field: 'legal_entities_history_orders',
        sub: [{ title: 'Выгрузка истории', field: 'legal_entities_download_history_orders' }],
      },
      {
        title: 'История оплат',
        field: 'legal_entities_history_payment',
        sub: [{ title: 'Выгрузка оплат', field: 'legal_entities_download_history_payment' }],
      },
      { title: 'Список менеджеров', field: 'legal_entities_list_managers' },
    ],
  },

  drivers: {
    title: 'Водители',
    options: [
      { title: 'Выгрузка списка', field: 'drivers_download_list' },
      { title: 'Изменение статуса', field: 'drivers_change_status' },
      {
        title: 'Просмотр личных данных',
        field: 'drivers_show',
        sub: [{ title: 'Изменение компании перевозчика', field: 'drivers_change_company' }],
      },
      {
        title: 'Просмотр истории заказов',
        field: 'drivers_history_orders',
        sub: [{ title: 'Выгрузка истории заказов', field: 'drivers_download_history_orders' }],
      },
      {
        title: 'История нарушений',
        field: 'drivers_history_violations',
        sub: [{ title: 'Выгрузка истории нарушений', field: 'drivers_download_history_violations' }],
      },
      {
        title: 'Управление выплатами водителей',
        field: 'drivers_payment',
        sub: [{ title: 'Выгрузка истории выплат', field: 'drivers_download_payment' }],
      },
    ],
  },

  stats: {
    title: 'Статистика',
    options: [{ title: 'Просмотр статистики и отчетов', field: 'statistics_show' }],
  },

  companies: {
    title: 'Компании',
    options: [
      { title: 'Выгрузка списка', field: 'companies_download_list' },
      { title: 'Смена статуса', field: 'companies_change_status' },
      { title: 'Изменение общих данных', field: 'companies_edit' },
      {
        title: 'Список водителей',
        field: 'companies_driver_list',
        sub: [{ title: 'Выгрузка списка', field: 'companies_download_driver_list' }],
      },
      {
        title: 'Список администраторов',
        field: 'companies_admins_list',
        sub: [{ title: 'Выгрузка списка', field: 'companies_download_admins_list' }],
      },
    ],
  },
};

const AccessRights: FunctionComponent = () => {
  const { id } = useParams();

  const [permissions, setPermissions] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const optionTypes = Object.keys(checkLists);

  const half = Math.floor(optionTypes.length / 2);
  const columns = [optionTypes.slice(0, half), optionTypes.slice(half)];

  const getPermissions = async () => {
    setIsLoading(true);

    try {
      const { data } = await axiosClient.get(`/permissions/${id}`);

      if (Array.isArray(data)) {
        setPermissions(data.map(permission => permission.name));
      }
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const savePermissions = async () => {
    setIsLoading(true);

    try {
      await axiosClient.post(`/permissions/${id}`, { permissions });
    } catch (e: any) {
      logger.error(`Ошибка при сохранении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {isLoading && <Loader size={100} />}

      <div className={`flex flex-col ${isLoading ? 'hidden' : ''}`}>
        <div className={`grid grid-cols-[max-content_max-content] w-[70vw] gap-[5em] ml-4 mt-4 `}>
          {columns.map((column: Array<string>, index: number) => {
            return (
              <div key={index} className="flex flex-col">
                {column.map((optionType: string) => {
                  const checkListInfo = checkLists[optionType];

                  return (
                    <div key={optionType} className="mb-4">
                      <h1>{checkListInfo.title}</h1>
                      <div className="ml-2">
                        <CheckList
                          selected={permissions}
                          options={checkListInfo?.options || []}
                          field="field"
                          label="title"
                          onChange={setPermissions}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div className="mt-8 pb-7">
          <Button variant="default" onClickHandler={savePermissions}>
            Сохранить
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default AccessRights;
