import { FunctionComponent, useEffect, useState } from 'react';
import Input from 'src/components/UI/Input';
import Select from 'src/components/UI/Select';
import states from 'src/utils/states';

interface ISecondRegistrationStep {
  formData: any;
  onFormDataFieldChange?: (value: any, field: string) => any;
  setError: (msg: string) => void;
  addFile: (
    e: any,
    types: Array<string>,
    onSuccess: (f: any) => void,
    onError: (f: any) => void
  ) => void;
}

const SecondRegistrationStep: FunctionComponent<ISecondRegistrationStep> = ({
  formData,
  onFormDataFieldChange = () => {},
  setError = () => {},
  addFile = () => {},
}) => {
  const [loadedLogoPath, setLoadedLogoPath] = useState<string>('');

  useEffect(() => {
    setLoadedLogoPath(formData.logo === null ? '' : URL.createObjectURL(formData.logo));
  }, [formData.logo]);

  return (
    <>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Логотип</p>
        {formData.logo ? (
          <div className="flex items-center">
            <div className="flex justify-center items-center bg-[#f2e9ff] overflow-hidden max-w-[5rem] max-h-[5rem] min-w-[5rem] min-h-[5rem] rounded-full">
              <img alt="logo" className="object-cover h-[5rem] max-w-[none]" src={loadedLogoPath} />
            </div>
            <div
              className="flex items-center ml-4 cursor-pointer"
              onClick={() => {
                //setLoadedLogoPath('');
                onFormDataFieldChange(null, 'logo');
              }}
            >
              <img alt="delete" src="/assets/img/close.svg" />
              <p className="font-semibold text-[#EE0202] ml-1">Удалить фото</p>
            </div>
          </div>
        ) : (
          <label>
            <p className="text-[#1066B1] text-sm font-semibold cursor-pointer">+ Добавить</p>
            <input
              type="file"
              accept="image/jpeg,image/png"
              className="hidden"
              onChange={e =>
                addFile(
                  e,
                  ['image/jpeg', 'image/png'],
                  file => onFormDataFieldChange(file, 'logo'),
                  () => setError('Недопустимый формат логотипа')
                )
              }
            />
          </label>
        )}
      </div>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Название компании</p>
        <Input
          placeholder="Название компании"
          value={formData.company_name}
          onChange={company => onFormDataFieldChange(company, 'company_name')}
        />
      </div>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Лет на рынке</p>
        <Input
          placeholder="Лет на рынке"
          value={formData.years}
          onChange={(year: any) => {
            if (year.length && !/^\d+$/.test(year)) return;
            onFormDataFieldChange(Number(year), 'years');
          }}
        />
      </div>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Штат</p>
        <Select
          options={states}
          value={formData.state}
          onSelect={(state: any) => onFormDataFieldChange(state, 'state')}
          maxHeight={12}
        />
      </div>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Номер банка</p>
        <Input
          placeholder="Номер банка"
          value={formData.bank_account_number}
          onChange={bankNumber => onFormDataFieldChange(bankNumber, 'bank_account_number')}
        />
      </div>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Номер маршрута</p>
        <Input
          placeholder="Номер маршрута"
          value={formData.routing_number}
          onChange={routingNumber => onFormDataFieldChange(routingNumber, 'routing_number')}
        />
      </div>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Название банка</p>
        <Input
          placeholder="Название банка"
          value={formData.bank_name}
          onChange={bank => onFormDataFieldChange(bank, 'bank_name')}
        />
      </div>
    </>
  );
};

export default SecondRegistrationStep;
