import { FunctionComponent } from 'react';
import Textarea from 'src/components/UI/Textarea';
import DatePickerRounded from 'src/components/UI/DatePickerRounded';
import AddressInput from 'src/components/Custom/AdressInput';
import { getFormDate } from 'src/utils/format-date';

interface IFirstOrderCreateStep {
  formData: any;
  onFormDataChange?: (value: any, field: string) => any;
}

const FirstOrderCreateStep: FunctionComponent<IFirstOrderCreateStep> = ({
  formData,
  onFormDataChange = () => {},
}) => {
  const isValidDate = () => {
    const [year, month, day] = formData.startAtDate.split('-');
    return (
      new Date().getTime() <
      getFormDate(
        {
          year,
          month,
          day,
        },
        formData.startAtTime
      ).getTime()
    );
  };

  return (
    <>
      <p className="text-[#808080] text-sm w-[293px]">Маршрут</p>
      <div className="w-full text-sm mt-2">
        <AddressInput
          value={formData.startTo}
          point="from"
          onChange={(address: { title: string; lat: number; lng: number } | null) => {
            console.log({ address });
            onFormDataChange(address?.lat || null, 'start_lat');
            onFormDataChange(address?.lng || null, 'start_lng');
            onFormDataChange(address?.title || '', 'startTo');
          }}
        />
      </div>
      <div className="w-full text-sm mt-2">
        <AddressInput
          value={formData.finishTo}
          point="to"
          onChange={(address: { title: string; lat: number; lng: number } | null) => {
            onFormDataChange(address?.lat || null, 'finish_lat');
            onFormDataChange(address?.lng || null, 'finish_lng');
            onFormDataChange(address?.title || '', 'finishTo');
          }}
        />
      </div>
      <p className="text-[#808080] text-sm mt-4">Время забора авто</p>
      <div className="w-full mt-2 flex items-center">
        <p className="text-[#808080] text-xs mr-2">от</p>
        <div className="w-[130px]">
          <DatePickerRounded
            min={new Date().toISOString().split('T')[0]}
            value={formData.startAtDate}
            setValue={e => onFormDataChange(e, 'startAtDate')}
          />
        </div>
        <div className="w-[120px] ml-[6px] text-sm">
          <DatePickerRounded
            min={new Date().toISOString().split('T')[1].split('.')[0].slice(0, 5)}
            value={formData.startAtTime}
            time={true}
            setValue={e => onFormDataChange(e, 'startAtTime')}
          />
        </div>
      </div>
      {formData.startAtDate && formData.startAtTime && !isValidDate() && (
        <p className="text-sm text-red-600">* Введите время которое еще не прошло</p>
      )}
      <p className="text-[#808080] text-sm mt-4 mb-1">Комментарий</p>
      <Textarea value={formData.comment} setValue={e => onFormDataChange(e, 'comment')} />
    </>
  );
};

export default FirstOrderCreateStep;
