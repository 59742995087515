import { GridTableCell } from 'src/components/GridTable';
import { FunctionComponent } from 'react';

interface IUserLockCell {
  locked: boolean;
  disabled?: boolean;
  onLock: () => any;
  onUnlock: () => any;
}

const UserLockCell: FunctionComponent<IUserLockCell> = ({
  locked = false,
  disabled = false,
  onLock = () => {},
  onUnlock = () => {},
}) => {
  return (
    <GridTableCell
      onClick={() => {
        if (disabled) return;

        if (locked) {
          onUnlock();
        } else {
          onLock();
        }
      }}
      className="!justify-end"
    >
      {disabled ? (
        <div
          className="w-40 flex flex-col cursor-pointer items-center text-sm rounded-lg px-4 py-2 hover:bg-[#E3E3E3]"
          style={{ color: !locked ? '#4580ca' : '#EE0202' }}
        >
          <p className="font-semibold">{locked ? 'Заблокирован' : 'Активен'}</p>
        </div>
      ) : (
        <div
          className="w-40 flex flex-col cursor-pointer items-center text-sm rounded-lg px-4 py-2 hover:bg-[#E3E3E3]"
          style={{ color: locked ? '#4580ca' : '#EE0202' }}
        >
          <img
            src={`/assets/img/${locked ? 'unblock' : 'close'}.svg`}
            className="w-[1.25rem] h-[1.25rem]"
            alt=""
          />
          <p className="font-semibold">{locked ? 'Разблокировать' : 'Блокировать'}</p>
        </div>
      )}
    </GridTableCell>
  );
};

export default UserLockCell;
