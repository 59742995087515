import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import TimeCell from '../BASIC_CELLS/TimeCell';

interface ICompanyAdminsTable {
  admins: Array<any>;
  isLoading: boolean;
  sort: Array<string>;
  selected: IExportSelected;

  onAdminSelect: (newSelected: IExportSelected) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const CompanyAdminsTable: FunctionComponent<ICompanyAdminsTable> = ({
  admins,
  isLoading = false,
  sort = [],
  selected = [],

  onAdminSelect = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: onAdminSelect,
    }),

    id: {
      label: 'ID',
      component: (adminId: number) => (
        <GridTableCell className="text-[#4580ca] text-sm underline">
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/settings/adminstrators/${adminId}/general`)}
          >
            {adminId}
          </span>
        </GridTableCell>
      ),
    },

    user: {
      label: 'Пользователь',
      component: (v: any, index: number, admin: any) => (
        <GridTableCell>
          <div className="flex items-center">
            <img
              alt=""
              src={admin?.avatar}
              className="min-w-[22px] h-[22px] mr-1 object-cover rounded-full"
            />
            <div className="pl-[6px]">
              <p className="text-sm">{admin?.username}</p>
              <p className="text-[#333333] text-xs">
                {admin?.name ? admin?.name : '----'} {admin?.surname ? admin?.surname : '----'}
              </p>
            </div>
          </div>
        </GridTableCell>
      ),
    },

    email: { label: 'Почта' },
    phone: { label: 'Телефон' },
    last_login: {
      label: 'Дата послед. входа',
      component: (date: string, index: number, row: any) => <TimeCell date={new Date(date)} />,
    },
  };

  return (
    <GridTable
      data={admins}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default CompanyAdminsTable;
