import React, { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ButtonSPrimary from '../Custom/ButtonSPrimary';

interface IFilterBar {
  opened: boolean;
  width?: number;
  onClear?: () => any;
  onApply?: () => any;
  children?: any;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

const FilterBar: FunctionComponent<IFilterBar> = ({
  opened = false,
  width = 210,
  onClear = () => {},
  onApply,
  children,
  setOpened,
}) => {
  useEffect(() => {
    const closeFilterBar = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      setOpened(false);
    };

    document.addEventListener('keydown', e => closeFilterBar(e));

    return () => document.removeEventListener('keydown', e => closeFilterBar(e));
  }, []);

  useEffect(() => {
    const filterBar = document.querySelector('.filter-bar') as HTMLDivElement;
    if (opened) filterBar.focus();
  }, [opened]);

  const handleEnterKey = e => {
    if (document.activeElement?.nodeName !== 'INPUT' || e.key !== 'Enter') return;
    const filterBar = document.querySelector('.filter-bar') as HTMLDivElement;
    const focusedElement = document.activeElement;
    if (!filterBar.contains(focusedElement)) return;
    const focusableElements = filterBar.querySelectorAll('input');
    const focusableArr = Array.from(focusableElements);
    const currentIndex = focusableArr.indexOf(document.activeElement as HTMLInputElement);
    if (currentIndex === focusableArr.length - 1) {
      const submitBtn = Array.from(filterBar.querySelectorAll('button')).at(-1);
      if (!submitBtn) return;
      submitBtn.focus();
      e.preventDefault();
    }
    if (currentIndex + 1 === focusableArr.length) return;
    focusableArr[currentIndex + 1].focus();
  };

  return (
    <div
      tabIndex={0}
      onKeyDown={handleEnterKey}
      className={`filter-bar h-full fixed z-[51] top-0 right-0 shadow-md flex flex-col bg-white transition-all duration-200 focus:outline-none`}
      style={{
        width: width + 'px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)',
        transform: `translateX(${Number(!opened) * 100}%)`,
      }}
    >
      <div className="flex justify-between items-center pt-4 px-3">
        <p className="font-bold text-[14px]">Фильтры</p>
        <p className="text-[#808080] text-[14px] cursor-pointer" onClick={onClear}>
          Сбросить
        </p>
      </div>
      <PerfectScrollbar
        style={{ maxHeight: onApply ? '85vh' : '95vh' }}
        options={{
          suppressScrollX: true,
          useBothWheelAxes: false,
        }}
        className="px-3 w-full"
      >
        {children}
      </PerfectScrollbar>
      {onApply && (
        <div className="mt-3 mb-5 px-3">
          <ButtonSPrimary isSpanFlex={false} onClickHandler={onApply}>
            Применить
          </ButtonSPrimary>
        </div>
      )}
    </div>
  );
};

const FilterButton: FunctionComponent<any> = ({
  opened = false,
  filtersIsEmpty = true,
  buttonRef,
  onOpen = () => {},
  onClose = () => {},
  offset = 220,
  children,
}) => {
  return (
    <>
      <div
        className={`${
          !filtersIsEmpty ? 'text-[#f7941e] fill-[#f7941e]' : 'fill-[#1066B1] text-[#1066B1]'
        } text-[1rem] py-[0.35em] px-[1em] z-[20] mr-2 flex justify-center items-center cursor-pointer transition-all inherit right-[20px] rounded-[8px]`}
        style={{ boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 10px' }}
        ref={buttonRef}
        onClick={onOpen}
      >
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="inherit"
            d="M1.13321 0.133354C1.06254 0.132355 0.992375 0.145411 0.926791 0.171765C0.861208 0.198119 0.801517 0.237245 0.751187 0.286869C0.700856 0.336492 0.66089 0.395625 0.633611 0.460828C0.606333 0.526032 0.592285 0.596007 0.592285 0.666688C0.592285 0.737368 0.606333 0.807343 0.633611 0.872547C0.66089 0.937751 0.700856 0.996883 0.751187 1.04651C0.801517 1.09613 0.861208 1.13526 0.926791 1.16161C0.992375 1.18796 1.06254 1.20102 1.13321 1.20002H1.5603L5.39988 6.00002H8.59988L12.4395 1.20002H12.8665C12.9372 1.20102 13.0074 1.18796 13.073 1.16161C13.1386 1.13526 13.1982 1.09613 13.2486 1.04651C13.2989 0.996883 13.3389 0.937751 13.3661 0.872547C13.3934 0.807343 13.4075 0.737368 13.4075 0.666688C13.4075 0.596007 13.3934 0.526032 13.3661 0.460828C13.3389 0.395625 13.2989 0.336492 13.2486 0.286869C13.1982 0.237245 13.1386 0.198119 13.073 0.171765C13.0074 0.145411 12.9372 0.132355 12.8665 0.133354H1.13321ZM5.39988 7.06669V11.8667L8.59988 10.8V7.06669H5.39988Z"
          />
        </svg>
        <p className="text-[0.85em] ml-1">Фильтры</p>
        {children}
      </div>

      <div
        className={`h-[30px] z-[10] flex justify-center items-center cursor-pointer transition-all fixed w-[30px] right-[0px] rounded-full duration-200 bg-white`}
        style={{
          boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 10px',
          opacity: Number(opened),
          transform: `translateX(${opened ? -1 * offset : 0}px)`,
        }}
        onClick={onClose}
      >
        <img className="rotate-180" src="/assets/img/back.svg" alt="" />
      </div>
    </>
  );
};

export { FilterBar, FilterButton };
