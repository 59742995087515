import { FunctionComponent, useState } from 'react';
import logger from 'src/utils/logger';

const ClipedFiles: FunctionComponent<any> = ({ data = [], onAdd = f => {}, onRemove = f_id => {} }) => {
  const [fictionFile, setFictionFile] = useState<any>(null);

  const setFictionLoadPercentage = (percentage, filename) => {
    return new Promise(res => {
      setTimeout(() => {
        setFictionFile({
          filename,
          percentage,
        });
        res('');
      }, 10);
    });
  };

  const onClipedFileAdd = async e => {
    if (!e.target || !e.target.files || !e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];

    for (let i = 0; i <= 99; i++) {
      await setFictionLoadPercentage(i, file.name);
    }

    try {
      await onAdd(file);
    } catch (e: any) {
      logger.error(`Ошибка при сохранении файла ${e.message}`);
    } finally {
      setFictionFile(null);
    }

    e.target.files = null;
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <p className="text-[#999999] mb-1">Привязанные файлы</p>
        <label>
          <p className="text-[#1066B1] font-bold cursor-pointer mb-1">+ Добавить</p>
          <input type="file" className="hidden" onChange={onClipedFileAdd} />
        </label>
      </div>
      {Array.isArray(data) && data.length
        ? data.map((file, ind) => {
            return (
              <div className="flex items-center justify-between mb-1" key={ind}>
                <p
                  className="text-[#1066B1] font-semibold cursor-pointer max-w-[75%] overflow-hidden"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <a
                    href={file.link}
                    target="_blank"
                    rel="noreferrer"
                    download={file.link?.slice(file.link.lastIndexOf('/') + 1)}
                  >
                    {file.name}
                  </a>
                </p>
                <p
                  className="text-[#EE0202] font-semibold cursor-pointer"
                  onClick={() => onRemove(file.id)}
                >
                  Удалить
                </p>
              </div>
            );
          })
        : fictionFile
        ? ''
        : '-'}
      {fictionFile && (
        <div className="flex items-center justify-between mb-1">
          <p className="font-semibold cursor-pointer max-w-[75%] overflow-hidden">
            {fictionFile.percentage}
            {'%...'}
            {fictionFile.filename.slice(0, 25)}
          </p>
          <p className="text-[#EE0202] font-semibold cursor-pointer opacity-50">Удалить</p>
        </div>
      )}
    </>
  );
};

export default ClipedFiles;
