import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

interface IFile {
  title: string;
  file: any | null;
  setFile: (f: any | null) => void;
}

const CommonFile: FunctionComponent<IFile> = ({ title, file, setFile }) => {
  const fileInputRef = useRef<any>(null);
  //   const [loadFile, setLoadFile] = useState<any>(null);

  //   useEffect(() => {
  //     setLoadFile(file)
  //   }, [file])
  return (
    <div>
      <p className="mt-4">{title}</p>
      <input
        ref={fileInputRef}
        type="file"
        name="avatar"
        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, image/*"
        className="hidden"
        onChange={e => {
          if (e?.target.files) {
            setFile(e?.target?.files[0]);
          }
        }}
      />
      {file ? (
        <div className="flex w-[320px] justify-between text-sm">
          <p>{file?.name}</p>
          <p
            className="text-[#EE0202] cursor-pointer"
            onClick={() => {
              //   data.privacy_policy[0] = undefined;
              setFile('');
            }}
          >
            Delete file
          </p>
        </div>
      ) : (
        <p
          className="text-[#1066B1] text-sm font-bold mt-2 cursor-pointer"
          onClick={() => {
            fileInputRef?.current.click();
          }}
        >
          + Add file
        </p>
      )}
    </div>
  );
};

export default CommonFile;
