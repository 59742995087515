import React, { FunctionComponent } from 'react';
import Input from '../UI/Input';
import DatePickerRounded from '../UI/DatePickerRounded';
import Tooltip from '../UI/Tooltip';
import Checkbox from '../UI/Checkbox';
import CheckList from '../UI/CheckList';
import AddressInput from '../Custom/AdressInput';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { orderStatuses as statuses } from 'src/utils/order-statuses';

interface IOrdersFilters {
  data: any;
  onFilterFieldChange: (v: any, field: string) => any;
  orderStatuses?: Array<{ id: number; title: string }>;
}

interface IOrdersFiltersTooltip {
  data: any;
  filtersButtonRef: React.RefObject<HTMLInputElement>;
  filtersIsEmpty?: boolean;
}

const OrdersFilters: FunctionComponent<IOrdersFilters> = ({
  data,
  onFilterFieldChange = () => {},
  orderStatuses = statuses,
}) => {
  return (
    <div className="text-[0.8em]">
      <div className="mt-3">
        <p className="text-[#808080] text-sm">Маршрут</p>
        <div className="w-full mt-2">
          <AddressInput
            value={data.search_from}
            point="from"
            onChange={(address: any) => onFilterFieldChange(address?.title || '', 'search_from')}
            allowAny={true}
          />
        </div>
        <div className="w-full mt-2">
          <AddressInput
            value={data.search_to}
            point="to"
            onChange={(address: any) => onFilterFieldChange(address?.title || '', 'search_to')}
            allowAny={true}
          />
        </div>
      </div>
      <div className="mt-5">
        <p className="text-[#808080] text-sm">Стоимость заказа</p>
        <div className="w-full mt-2 grid grid-cols-[1fr_1px_1fr] border border-[#D9D9D9] rounded-[8px]">
          <Input
            value={data.price_from}
            type="number"
            placeholder="Min"
            className="w-full rounded-l-lg rounded-r-none"
            withShadow={false}
            onChange={value => onFilterFieldChange(value, 'price_from')}
          >
            <div className="ml-2 my-2">
              <img className="w-[0.8em]" src="/assets/img/dollar.svg" alt="" />
            </div>
          </Input>
          <div className="w-full bg-[#D9D9D9]"></div>
          <Input
            value={data.price_to}
            type="number"
            placeholder="Max"
            className="w-full rounded-l-none rounded-r-lg"
            withShadow={false}
            onChange={value => onFilterFieldChange(value, 'price_to')}
          />
        </div>
      </div>
      <div className="mt-5">
        <p className="text-[#808080] text-sm">Дата начала</p>
        <div className="w-full mt-2 flex items-center">
          <p className="text-[#808080] text-xs mr-1">от</p>
          <DatePickerRounded
            value={data.start_from}
            setValue={e => onFilterFieldChange(e, 'start_from')}
          />
          <div className="width-[90px]">
            <DatePickerRounded
              value={data.start_from_time}
              time={true}
              setValue={e => onFilterFieldChange(e, 'start_from_time')}
            />
          </div>
        </div>
        <div className="w-full mt-2 flex items-center">
          <p className="text-[#808080] text-xs mr-1">до</p>
          <DatePickerRounded value={data.start_to} setValue={e => onFilterFieldChange(e, 'start_to')} />
          <div className="width-[90px]">
            <DatePickerRounded
              value={data.start_to_time}
              time={true}
              setValue={e => onFilterFieldChange(e, 'start_to_time')}
            />
          </div>
        </div>
        <div className="w-full mt-4 flex flex-col">
          <p className="text-[#808080] text-xs mr-1">ID водителя или ФИО</p>
          <Input
            value={data.search_driver}
            placeholder="ID водителя или ФИО"
            className="mt-2"
            onChange={value => onFilterFieldChange(value, 'search_driver')}
          />
        </div>
        <div className="w-full mt-4 flex flex-col">
          <p className="text-[#808080] text-xs mr-1">ID заказа</p>
          <Input
            value={data.order_id}
            type="number"
            placeholder="ID заказа"
            className="mt-2"
            onChange={value => onFilterFieldChange(value, 'order_id')}
          />
        </div>
      </div>
      <div className="mt-5">
        <p className="text-[#808080] text-sm">Статусы</p>
        <div className="w-full mt-2">
          <div className="flex items-center text-sm">
            <Checkbox
              value={data.status.length === orderStatuses.length}
              setValue={checked =>
                onFilterFieldChange(checked ? orderStatuses.map(s => s.id) : [], 'status')
              }
            />
            <span className="ml-2">Выбрать все</span>
          </div>

          <CheckList
            selected={data.status}
            options={orderStatuses}
            label="title"
            onChange={selectedStatuses => onFilterFieldChange(selectedStatuses, 'status')}
          />
        </div>
      </div>
    </div>
  );
};

const OrdersFiltersTooltip: FunctionComponent<IOrdersFiltersTooltip> = ({
  data: filters,
  filtersButtonRef,
  filtersIsEmpty = true,
}) => {
  return (
    <Tooltip parentRef={filtersButtonRef} disabled={filtersIsEmpty} align="left" className="w-[15em]">
      {!!filters.search_from.length && (
        <div className="mb-1">
          <p className="text-sm text-[#9f9f9f]">Маршрут (откуда)</p>
          <p className="text-sm">{filters.search_from}</p>
        </div>
      )}
      {!!filters.search_to.length && (
        <div className="mb-1">
          <p className="text-sm text-[#9f9f9f]">Маршрут (куда)</p>
          <p className="text-sm">{filters.search_to}</p>
        </div>
      )}
      {(filters.price_from || filters.price_to) && (
        <div className="mb-1">
          <p className="text-sm text-[#9f9f9f]">Стоимость</p>
          <p className="text-sm">
            {`${filters.price_from ? `От: ${filters.price_from}` : ''}`}
            {`${filters.price_to ? ` До: ${filters.price_to}` : ''}`}
          </p>
        </div>
      )}
      {(filters.start_from || filters.start_to) && (
        <div className="mb-1">
          <p className="text-sm text-[#9f9f9f]">Дата начала</p>
          <p className="flex flex-col text-sm">
            <span>{`${
              filters.start_from ? `Начиная с: ${filters.start_from} ${filters.start_from_time}` : ''
            }`}</span>
            <span>{`${
              filters.start_to ? ` До: ${filters.start_to} ${filters.start_to_time}` : ''
            }`}</span>
          </p>
        </div>
      )}
      {filters.search_driver && (
        <div className="mb-1">
          <p className="text-sm text-[#9f9f9f]">Водитель</p>
          <p className="text-sm">{filters.search_driver}</p>
        </div>
      )}
      {filters.order_id && (
        <div className="mb-1">
          <p className="text-sm text-[#9f9f9f]">ID заказа</p>
          <p className="text-sm">{filters.order_id}</p>
        </div>
      )}

      {!!filters.status.length && (
        <div className="flex flex-col">
          <p className="text-sm text-[#9f9f9f]">Статусы</p>
          {filters.status.map(statusId => (
            <p key={statusId} className="text-sm">
              {statuses.find(status => status.id === statusId)?.title}
            </p>
          ))}
        </div>
      )}
    </Tooltip>
  );
};

export { OrdersFilters, OrdersFiltersTooltip };
