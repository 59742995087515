import React, { FunctionComponent, useEffect, useState } from 'react';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import MainSection from 'src/components/Semantic/MainSection';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Button from 'src/components/UI/Button';
import DatePicker from 'src/components/UI/DatePicker';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Pagination from 'src/components/UI/Pagination';
import Textarea from 'src/components/UI/Textarea';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';

import AdminsListTalbe from 'src/components/Tables/admins/list';
import ExportModal from 'src/components/ExportModal';
import Loader from 'src/components/Custom/Loader';

import { formatDate } from 'src/utils/calendar';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import Input from 'src/components/UI/Input';

const exportFields = [
  { exportField: 'id', title: 'ID' },
  { exportField: 'username', title: 'Никнейм' },
  { exportField: 'name', title: 'Имя' },
  { exportField: 'surname', title: 'Фамилия' },
  { exportField: 'email', title: 'Почта' },
  { exportField: 'phone', title: 'Телефон' },
  { exportField: 'is_blocked', title: 'Блокировка' },
  { exportField: 'avatar', title: 'Аватар' },
  { exportField: 'last_login', title: 'Дата посл. входа' },
];

const Admin: FunctionComponent = () => {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [exportModal, setExportModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_last_login', 'desc']);
  const [exportSelected, setExportSelected] = useState<any>(exportFields.map(e => e.exportField));
  const [selected, setSelected] = useState<IExportSelected>([]);

  const [filters, setFilters] = useState<any>({
    search: '',
    from: null,
    to: null,
  });

  const [invitationAdmins, setInvitationAdmins] = useState<string>('');
  const [invitationAdminsError, setInvitationAdminsError] = useState<string>('');

  const sendInvitationAdmins = async () => {
    setIsLoading(val => !val);
    try {
      const emails = invitationAdmins.split(/\r?\n/);
      let error = false;
      emails.forEach(item => {
        // eslint-disable-next-line no-useless-escape
        if (
          String(item)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          return true;
        }
        error = true;
        setInvitationAdminsError('Неверный формат почты');
      });
      if (!error) {
        setInvitationAdminsError('');
        try {
          await axiosClient.post('/invitation-admins', { emails });
          setInvitationAdmins('');
          setCreateModal(false);
        } catch (e: any) {
          let msg = e?.response?.data?.message ?? e.message ?? 'Ошибка при попытке пригласить';
          msg = msg.includes('has already been taken')
            ? 'На указанный адрес уже было выслано письмо с ссылкой'
            : msg;
          logger.error(msg, e);
        }
      }
      setIsLoading(val => !val);
    } catch (e: any) {
      setIsLoading(val => !val);
      logger.error(`Ошибка при отправке приглашений ${e.message}`, e);
    }
  };

  const onFilterFieldChange = (val: any, field: string) => {
    setFilters({
      ...filters,
      [field]: val,
    });
  };

  const getQueryFilters = (filters: any) => {
    return {
      ...(filters.from && { from: formatDate(filters.from, '-') }),
      ...(filters.to && { to: formatDate(filters.to, '-') }),
      ...(filters.search && { search: filters.search }),
    };
  };

  const getAdmins = async (filters: any, currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    try {
      setIsLoading(val => !val);
      setData([]);
      const res = await axiosClient.get('/admins', {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(filters),
        },
      });
      setDataTotal(res.data.links.lastPage);
      setData(res.data.data);
      setIsLoading(val => !val);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    }
  };

  useEffect(() => {
    getAdmins(filters, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  useEffect(() => {
    getAdmins(filters, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
              <div className="flex flex-wrap items-center">
                <div className="text-sm mr-2 mt-1">
                  <Input
                    value={filters.search}
                    onChange={e => onFilterFieldChange(e, 'search')}
                    type="search"
                    placeholder="Поиск"
                    clearable
                  />
                </div>

                <div className="flex items-center">
                  <p className="mr-1 mr-4">Начиная с:</p>
                  <div className="text-[0.95em] max-w-[12em]">
                    <DatePicker
                      value={filters.from}
                      placeholder="дд.мм.гггг"
                      onChange={e => onFilterFieldChange(e, 'from')}
                    />
                  </div>
                  <p className="ml-4 mr-4">до:</p>
                  <div className="text-[0.95em] max-w-[12em]">
                    <DatePicker
                      value={filters.to}
                      placeholder="дд.мм.гггг"
                      onChange={e => onFilterFieldChange(e, 'to')}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center">
                <p className="text-[12px] mr-2 text-[#9b9d9c]">
                  {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
                </p>
                {isPermitted('settings_admins_download_list') && (
                  <div className="mr-2">
                    <ButtonSPrimary onClickHandler={() => setExportModal(true)}>
                      Экспорт в Excel
                    </ButtonSPrimary>
                  </div>
                )}
                {isPermitted('settings_admins_invitation') && (
                  <div className="mr-2">
                    <ButtonSPrimary onClickHandler={() => setCreateModal(true)}>Добавить</ButtonSPrimary>
                  </div>
                )}
              </div>
            </div>
            <div className="overflow-auto bg-white">
              <AdminsListTalbe
                admins={data}
                isLoading={isLoading}
                sort={sort}
                selected={selected}
                onSortUpdate={setSort}
                onSelect={setSelected}
              />

              {!isLoading && data.length !== 0 && (
                <Pagination
                  totalPages={dataTotal}
                  activePage={page}
                  setPage={setPage}
                  alignment="justify-end"
                />
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ExportModal
          opened={exportModal}
          isLoading={isLoading}
          selected={exportSelected}
          options={exportFields}
          field="exportField"
          setSelected={setExportSelected}
          setOpened={setExportModal}
          exportParams={{
            url: '/export/admins',
            filename: 'admins.xlsx',
            fields: exportSelected,
            filters: getQueryFilters(filters),
            selected,
            setIsLoading,
          }}
        />

        <ModalBackdrop state={createModal}>
          <ModalWindow title="Приглашение админов" setState={setCreateModal}>
            <div className="flex flex-col">
              <p className="mb-2">Email (каждый на новой строке)</p>
              <Textarea placeholder="Эл.Почта" value={invitationAdmins} setValue={setInvitationAdmins} />
              {invitationAdminsError && <p className="text-red-600">{invitationAdminsError}</p>}
              <div className="w-[320px] mt-6">
                {isLoading ? (
                  <Loader size={100} />
                ) : (
                  <Button
                    disabled={() => !invitationAdmins.length}
                    variant="default"
                    onClickHandler={() => sendInvitationAdmins()}
                  >
                    Пригласить
                  </Button>
                )}
              </div>
            </div>
          </ModalWindow>
        </ModalBackdrop>
      </ModalsSection>
    </>
  );
};

export default Admin;
