import React, { FunctionComponent, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import axiosClient from 'src/libs/axiosClient';
import UnAuthorizedLayout from 'src/layouts/UnAuthorized';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import { ModalWindow } from 'src/components/UI/Modal';
import Loader from 'src/components/Custom/Loader';

// Steps
import FirstRegistrationStep from './steps/first';
import SecondRegistrationStep from './steps/second';
import ThirdRegistrationStep from './steps/third';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';

const RegistrationCompanyPage: FunctionComponent = () => {
  const navigate: NavigateFunction = useNavigate();

  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    email: '',
    password: '',
    password_confirmation: '',
    logo: null,
    company_name: '',
    years: 0,
    state: '',
    w9: null,
    coi: null,
    motor: null,
    owners_id_front: null,
    owners_id_back: null,
    dot_mc: '',
    bank_account_number: '',
    routing_number: '',
    bank_name: '',
  });

  const onFormDataFieldChange = (value: any, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const addFile = (
    e: any,
    types: Array<string>,
    onSuccess = (f: any) => {},
    onError = (f: any) => {}
  ) => {
    if (!e.target || !e.target.files || !e.target.files.length) {
      return;
    }

    const file: any = e.target.files[0];

    if (!types.includes(file.type) && types.length > 0) {
      onError(file);
      return;
    }

    onSuccess(file);
  };

  const firstStepCheckErrors = () => {
    if (!formData.email.length || !formData.password_confirmation.length || !formData.password.length) {
      setError('Все поля обязательны к заполнению');
      return true;
    }

    if (
      formData.password.length < 6 ||
      !/[a-z]/.test(formData.password) ||
      !/[0-9]/.test(formData.password)
    ) {
      setError(
        'Пароль должен быть длиной не менее 6 символов и должен содержать как буквы, так и цифры'
      );
      return true;
    }

    if (formData.password !== formData.password_confirmation) {
      setError('Пароли должны совпадать');
      return true;
    }

    setError('');
    return false;
  };

  const secondStepCheckErrors = () => {
    if (
      formData.logo &&
      formData.company_name.length &&
      formData.years >= 0 &&
      formData.years <= 999 &&
      formData.state.length
    ) {
      setError('');
      return false;
    }

    setError('Все поля обязательны к заполнению');
    return true;
  };

  const thirdStepCheckErrors = () => {
    if (
      formData.w9 &&
      formData.coi &&
      formData.owners_id_front &&
      formData.owners_id_back &&
      formData.dot_mc.length &&
      formData.bank_account_number.length &&
      formData.routing_number.length &&
      formData.bank_name.length
    ) {
      setError('');
      return false;
    }

    setError('Все поля обязательны к заполнению');
    return true;
  };

  const checkStepErrors = (currentStep: null | number = null) => {
    switch (currentStep || step) {
      case 1:
        return firstStepCheckErrors();
      case 2:
        return secondStepCheckErrors();
      case 3:
        return thirdStepCheckErrors();
    }
  };

  const checkAllErrors = () => {
    return firstStepCheckErrors() || secondStepCheckErrors() || thirdStepCheckErrors();
  };

  const registerCompany = async () => {
    setIsLoading(true);

    try {
      const newCompany = new FormData();

      newCompany.append('email', formData.email);
      newCompany.append('password', formData.password);
      newCompany.append('password_confirmation', formData.password_confirmation);
      newCompany.append('logo', formData.logo);
      newCompany.append('name', formData.company_name);
      newCompany.append('years_on_market', formData.years);
      newCompany.append('state', formData.state);
      newCompany.append('region', formData.state);
      newCompany.append('w9', formData.w9);
      newCompany.append('coi', formData.coi);
      newCompany.append('motor', formData.motor);
      newCompany.append('owners_id_front', formData.owners_id_front);
      newCompany.append('owners_id_back', formData.owners_id_back);
      newCompany.append('dot_mc', formData.dot_mc);
      newCompany.append('bank_account_number', formData.bank_account_number);
      newCompany.append('routing_number', formData.routing_number);
      newCompany.append('bank_name', formData.bank_name);

      await axiosClient.post(`/companies/registration`, newCompany);
      navigate('/authorization/company');
    } catch (error: any) {
      let errorsList = error.response?.data?.errors;

      if (errorsList) {
        errorsList = Object.keys(errorsList)
          .reduce((res, errField) => res.concat(errorsList[errField]), [])
          .join('\n');
      }

      setError(errorsList || error.message);
      setStep(1);
      console.error(error.response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (step === 0) {
      setStep(1);
      return;
    }

    checkStepErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <>
      <UnAuthorizedLayout>
        <div className="w-full h-full flex items-center justify-center">
          <div className="max-w-7xl p-8 bg-white relative">
            <h1 className="text-lg font-bold mb-4">Регистрация компании</h1>

            {isLoading && <Loader size={100} />}

            <div
              className="gap-10 flex-wrap flex-row items-start"
              style={{ display: isLoading ? 'none' : 'flex' }}
            >
              <div>
                <FirstRegistrationStep
                  formData={formData}
                  onFormDataFieldChange={onFormDataFieldChange}
                />
              </div>
              {/* {step === 1 && (
  )} */}
              <div>
                <SecondRegistrationStep
                  formData={formData}
                  onFormDataFieldChange={onFormDataFieldChange}
                  setError={setError}
                  addFile={addFile}
                />
              </div>
              {/* {step === 2 && (
  )} */}
              <div>
                <ThirdRegistrationStep
                  formData={formData}
                  onFormDataFieldChange={onFormDataFieldChange}
                  setError={setError}
                  addFile={addFile}
                />
              </div>
              {/* {step === 3 && (
  )} */}
            </div>
            <div className="w-[320px] mt-8 text-[1em]">
              <ButtonSPrimary
                disabled={isLoading || !!error.length}
                onClickHandler={() => {
                  const errorExist = checkAllErrors();
                  if (errorExist) return;
                  registerCompany();
                  // const errorExist = step !== 3 ? checkStepErrors() : checkAllErrors();
                  // if (errorExist) return;
                  // if (step < 3) {
                  //   setStep(step + 1);
                  // } else {
                  //   registerCompany();
                  // }
                }}
              >
                Зарегистрироваться
              </ButtonSPrimary>
              {!!error.length && <p className="text-[#EE0202] px-4 mt-4">{error}</p>}
            </div>
          </div>
        </div>
      </UnAuthorizedLayout>
    </>
  );
};

export default RegistrationCompanyPage;
