import React, { FunctionComponent } from 'react';
import Select from 'src/components/UI/Select';
import Input from 'src/components/UI/Input';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';

interface DriversCarTypes {
  data: any;
  setData: any;
  saveData: any;
  response: any;
  setCarouselImages: any;
  setCarouselOpen: any;
  setActiveSlide: any;
  customAlert: any;
  loadDocument: (key: string, file: File) => Promise<void>;
  deleteDocument: (key: string) => Promise<void>;
}

const changableCarFields = [
  { key: 'car_model', title: 'Марка и модель автомобиля' },
  { key: 'car_win', title: 'VIN' },
];

const trailerParamFields = [
  { key: 'height', title: 'Высота автомобиля' },
  { key: 'width', title: 'Ширина автомобиля' },
  { key: 'length', title: 'Длина автомобиля' },
  { key: 'weight', title: 'Максимальный вес груза' },
];

const documents = [
  { key: 'truck_registration_document', title: 'Регистрационные документы на авто' },
  { key: 'trailer_registration_document', title: 'Регистрационные документы на прицеп' },
];

const DriversCar: FunctionComponent<DriversCarTypes> = ({
  data,
  setData,
  saveData,
  response,
  loadDocument,
  deleteDocument,
  setCarouselImages,
  setCarouselOpen,
  setActiveSlide,
  customAlert,
}) => {
  const updateDataField = (value: any, field: string) => {
    setData({ ...data, [field]: value });
  };

  const updateTrailerParamField = (value: any, field: string) => {
    setData({ ...data, trailer_params: { ...data.trailer_params, [field]: value } });
  };

  return (
    <>
      <div className="pl-5 mt-6">
        <div className="flex">
          {changableCarFields.map(field => (
            <div key={field.key} className="mt-6 mr-8">
              <p className="mb-2 text-[#999]">{field.title}</p>
              <Input
                value={data[field.key] || ''}
                placeholder={field.title}
                onChange={value => updateDataField(value, field.key)}
              />
            </div>
          ))}
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">Тип прицепа</p>
            <Select
              options={['enclosed', 'open']}
              value={data.trailer_type}
              onSelect={option => updateDataField(option, 'trailer_type')}
            />
          </div>
        </div>
        <div className="flex">
          {data.trailer_params &&
            trailerParamFields.map(field => (
              <div key={field.key} className="mt-6 mr-8">
                <p className="mb-2 text-[#999]">{field.title}</p>
                <Input
                  value={data.trailer_params[field.key] || ''}
                  placeholder={field.title}
                  onChange={value => updateDataField(value, field.key)}
                />
              </div>
            ))}
        </div>
        <div className="mt-6">
          <h2 className="text-lg font-bold">Автомобиль</h2>
          <div className="flex text-sm">
            <div className="rounded-[4px] w-[120px] overflow-hidden mr-5">
              <p className="mb-2 text-[#999]">Перед</p>
              <img
                onClick={() => {
                  setCarouselImages(() => [
                    data.car_front,
                    data.car_back,
                    data.car_left,
                    data.car_right,
                  ]);
                  setActiveSlide(() => 0);
                  setCarouselOpen(() => true);
                }}
                src={data.car_front}
                alt=""
                className="w-[120px] h-[120px] bg-[#DADADA] object-cover border-0 cursor-pointer"
              />
            </div>
            <div className="rounded-[4px] w-[120px] overflow-hidden mr-5">
              <p className="mb-2 text-[#999]">Задняя часть</p>
              <img
                onClick={() => {
                  setCarouselImages(() => [
                    data.car_front,
                    data.car_back,
                    data.car_left,
                    data.car_right,
                  ]);
                  setActiveSlide(() => 1);
                  setCarouselOpen(() => true);
                }}
                src={data.car_back}
                alt=""
                className="w-[120px] h-[120px] bg-[#DADADA] object-cover border-0 cursor-pointer"
              />
            </div>
            <div className="rounded-[4px] w-[120px] overflow-hidden mr-5">
              <p className="mb-2 text-[#999]">Левая сторона</p>
              <img
                onClick={() => {
                  setCarouselImages(() => [
                    data.car_front,
                    data.car_back,
                    data.car_left,
                    data.car_right,
                  ]);
                  setActiveSlide(() => 2);
                  setCarouselOpen(() => true);
                }}
                src={data.car_left}
                alt=""
                className="w-[120px] h-[120px] bg-[#DADADA] object-cover border-0 cursor-pointer"
              />
            </div>
            <div className="rounded-[4px] w-[120px] overflow-hidden mr-5">
              <p className="mb-2 text-[#999]">Правая сторона</p>
              <img
                onClick={() => {
                  setCarouselImages(() => [
                    data.car_front,
                    data.car_back,
                    data.car_left,
                    data.car_right,
                  ]);
                  setActiveSlide(() => 3);
                  setCarouselOpen(() => true);
                }}
                src={data.car_right}
                alt=""
                className="w-[120px] h-[120px] bg-[#DADADA] object-cover border-0 cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div className="mt-6">
          <h2 className="text-lg font-bold">Прицеп</h2>
          <div className="flex text-sm">
            <div className="rounded-[4px] w-[120px] overflow-hidden mr-5">
              <p className="mb-2 text-[#999]">Левая сторона</p>
              <img
                onClick={() => {
                  setCarouselImages(() => [data.trailer_front, data.trailer_back]);
                  setActiveSlide(() => 0);
                  setCarouselOpen(() => true);
                }}
                src={data.trailer_front}
                alt=""
                className="w-[120px] h-[120px] bg-[#DADADA] object-cover border-0 cursor-pointer"
              />
            </div>
            <div className="rounded-[4px] w-[120px] overflow-hidden mr-5">
              <p className="mb-2 text-[#999]">Правая сторона</p>
              <img
                onClick={() => {
                  setCarouselImages(() => [data.trailer_front, data.trailer_back]);
                  setActiveSlide(() => 1);
                  setCarouselOpen(() => true);
                }}
                src={data.trailer_back}
                alt=""
                className="w-[120px] h-[120px] bg-[#DADADA] object-cover border-0 cursor-pointer"
              />
            </div>
          </div>

          {documents.map(document => (
            <div className="mt-10" key={document.key}>
              <p className="mb-2 text-[#999]">{document.title}</p>
              {data[document.key] ? (
                <div className="flex items-center cursor-pointer">
                  <img
                    onClick={() => {
                      setCarouselImages(() => [data[document.key], '']);
                      setActiveSlide(() => 0);
                      setCarouselOpen(() => true);
                    }}
                    src={data[document.key]}
                    alt={document.title}
                    className="w-40 h-40 object-contain"
                  />
                  {/* <span
                  className="text-[#EE0202] cursor-pointer"
                  onClick={() => deleteDocument(document.key)}
                >
                  Delete file
                </span> */}
                </div>
              ) : (
                <label>
                  <input
                    type="file"
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, image/*"
                    className="hidden"
                    onInput={(e: any) => {
                      loadDocument(document.key, e?.target?.files?.length ? e.target.files[0] : null);
                    }}
                  />
                  <span className="text-[#1066B1] text-sm font-bold mt-2 cursor-pointer">
                    + Add file
                  </span>
                </label>
              )}
            </div>
          ))}
        </div>
        <div className="mt-6">
          <div className="max-w-[15em] text-[1.25em]">
            <ButtonSPrimary onClickHandler={saveData}>Сохранить</ButtonSPrimary>
          </div>
          {response && (
            <p
              className={response === 'Изменения успешно сохранены' ? 'text-green-500' : 'text-red-500'}
            >
              {response}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default DriversCar;
