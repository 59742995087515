import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'src/redux';
import 'src/styles/index.css';
import reportWebVitals from 'src/reportWebVitals';
import TheLayout from 'src/layouts';
import TheRouter from './router';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <TheLayout>
          <TheRouter />
          <ToastContainer newestOnTop position="bottom-right" />
        </TheLayout>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
