import { FunctionComponent, useEffect, useState } from 'react';
import logger from 'src/utils/logger';
import 'moment/locale/ru';
import axiosClient from 'src/libs/axiosClient';
import MainSection from 'src/components/Semantic/MainSection';
import VerticalLayout from 'src/layouts/VerticalLayout';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'src/components/UI/Card';
import CardRow from 'src/components/UI/Card/CardRow';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import RoundedCheckbox from 'src/components/Custom/RoundCheckbox';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux';
import timeCount from 'src/utils/timeCounter';
import Loader from 'src/components/Custom/Loader';
import Map from 'src/components/Map';

//ORDER PARTS
import { Price } from './orderPageParts/price/index';
import { OrderPriceModal } from './orderPageParts/price/order-price-modal';
import { Addresses, AddressChangeModal } from './orderPageParts/address';
import { Driver, DriverChangeModal } from './orderPageParts/driver';
import { PersonInfo, PersonInfoChangeModal } from './orderPageParts/personInfo';
import ClipedFiles from './orderPageParts/clipedFiles';
import ReportsList from './orderPageParts/reportsList';
import Rating from 'react-rating';
import StatusChangesTable from 'src/components/Tables/orders/statusChanges';
import Select from 'src/components/UI/Select';
import { countStatuses } from 'src/utils/order-statuses';
import { connectToSocket } from 'src/hooks/use-socket';
import { parseDateFormat } from 'src/utils/format-date';
import OrderStatusSelect from 'src/components/Orders/StatusesSelect';
import { DriverPriceModal } from './orderPageParts/price/driver-price-modal';

const Star: FunctionComponent<any> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
      fill={fill}
    >
      <path d="m5.825 22l1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625l7.2.625l-5.45 4.725L18.175 22L12 18.275Z" />
    </svg>
  );
};

const OrderPage: FunctionComponent = () => {
  const { id: orderId } = useParams();

  const [order, setOrder] = useState<any>({
    total_price: '',
    receiver: {},
    sender: {},
    payer: 'admin',
    start_to: '',
    start_lng: '',
    start_lat: '',
    finish_to: '',
    finish_lat: '',
    finish_lng: '',
  });
  const [invoice, setInvoice] = useState<string>('');
  const [statusChangesHistory, setStatusChangesHistory] = useState<Array<any>>([]);

  //const [clientRate, setClientRate] = useState<number | undefined>(undefined);
  //const [clientComemnt, setClientComment] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { sidebarOpen } = useSelector((store: IStore) => store.layout);
  const navigate = useNavigate();

  //Modals Openers
  const [orderPriceModal, setIsOrderPriceModal] = useState<boolean>(false);
  const [driverPriceModal, setIsDriverPriceModal] = useState<boolean>(false);
  const [startAddressModal, setStartAddressModal] = useState<boolean>(false);
  const [finishAddressModal, setFinishAddressModal] = useState<boolean>(false);
  const [driverModal, setDriverModal] = useState<boolean>(false);
  const [senderModal, setSenderModal] = useState<boolean>(false);
  const [receiverModal, setReceiverModal] = useState<boolean>(false);
  const [sort, setSort] = useState<any>(['sort_date', 'desc']);

  const getOrder = async (withLoading = true) => {
    if (withLoading) {
      setIsLoading(true);
    }

    try {
      let { data } = await axiosClient.get(`/orders/${orderId}`);

      const updatedData = transformOrder({
        ...order,
        ...data,
      });

      setOrder(updatedData);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const transformOrder = data => {
    const transformedData = { ...data };

    transformedData.sender = typeof data.sender === 'string' ? JSON.parse(data.sender) : data.sender;
    transformedData.receiver =
      typeof data.receiver === 'string' ? JSON.parse(data.receiver) : data.receiver;

    transformedData.driver = { ...data.driver };

    return transformedData;
  };

  const updateOrder = async (update: any = {}) => {
    setIsLoading(true);

    try {
      await axiosClient.patch(`/orders/${orderId}`, {
        // Если убрать одно из полей, то ошибка 500 вылезает
        sender: order?.sender,
        sender_type: order?.sender_type,
        receiver: order?.receiver,
        ...update,
      });

      getOrder(false);
      getStatusChangesHistory();

      setIsOrderPriceModal(false);
      setIsDriverPriceModal(false);
      setStartAddressModal(false);
      setFinishAddressModal(false);
      setDriverModal(false);
      setSenderModal(false);
      setReceiverModal(false);
    } catch (e: any) {
      logger.error(`Ошибка при обновлении данных ${e.message}`, e);
      console.log(e, e.response);
    } finally {
      setIsLoading(false);
    }
  };

  const getMiles = (orderData: any) => {
    if (!orderData.start_lat || !orderData.start_lng || !orderData.finish_lat || !orderData.finish_lng) {
      return null;
    }

    const origin = new google.maps.LatLng(+orderData.start_lat, +orderData.start_lng);
    const destination = new google.maps.LatLng(+orderData.finish_lat, +orderData.finish_lng);
    const service = new google.maps.DistanceMatrixService();

    return new Promise(resolve => {
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        },
        (res, status) => {
          if (
            status !== 'OK' ||
            !Array.isArray(res?.rows) ||
            !res ||
            !res.rows.length ||
            !Array.isArray(res.rows[0]?.elements) ||
            !res.rows[0].elements.length ||
            !res.rows[0].elements[0].distance?.value
          ) {
            return resolve(null);
          }

          const answer = res.rows[0].elements[0].distance.value * 0.000621371192;
          return resolve(answer.toFixed(2));
        }
      );
    });
  };

  const updateAddress = async (update: any = {}) => {
    const orderData = {
      ...order,
      ...update,
    };

    const updateData = {
      ...update,
    };

    const miles = await getMiles(orderData);
    if (miles) {
      updateData.miles = Number(miles);
    }

    await updateOrder(updateData);
  };

  const getInvoice = async () => {
    try {
      const { data } = await axiosClient.get(`/orders/${orderId}/export-invoice`);

      if (data && typeof data.link === 'string') {
        setInvoice(data.link);
      }
    } catch (e: any) {
      logger.error(`Ошибка при получение счёта ${e.message}`, e);
    }
  };

  const getStatusChangesHistory = async () => {
    try {
      const { data: res } = await axiosClient.get(`/orders/${orderId}/state-history`, {
        params: {
          [sort[0]]: sort[1],
        },
      });

      setStatusChangesHistory(res.history);
    } catch (e: any) {
      logger.error(`Ошибка при получении истории действий ${e.message}`, e);
    }
  };

  const getClientRate = async () => {
    // try {
    //   const { data } = await axiosClient.get(`/orders/${orderId}/client/rate`);
    //   setClientRate(data);
    // } catch (e: any) {
    //   console.log(e, e.response);
    // }
  };

  const getClientComment = async () => {
    // try {
    //   const { data } = await axiosClient.get(`/orders/${orderId}/client/comment`);
    //   setClientComment(data);
    // } catch (e: any) {
    //   console.log(e, e.response);
    // }
  };

  const updateTrackStatus = async (status: boolean) => {
    setIsLoading(true);

    try {
      setOrder({ ...order, track: status });
      await axiosClient.post(`/orders/${orderId}/update-track`, {
        track: Number(status),
      });
      await getOrder();
    } catch (e: any) {
      logger.error(`Ошибка при обновлении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const addClipedFile = async file => {
    const fileForm = new FormData();
    fileForm.append('file', file);

    try {
      await axiosClient.post(`/orders/${orderId}/add-file`, fileForm);
      await getOrder();
    } catch (e: any) {
      logger.error(`Ошибка при добавления файла ${e.message}`, e);
    }
  };

  const removeClipedFile = async (file_id: number) => {
    try {
      await axiosClient.delete(`/orders/files/${file_id}`);
      await getOrder();
    } catch (e: any) {
      logger.error(`Ошибка при удалении файла ${e.message}`, e);
    }
  };

  useEffect(() => {
    getOrder();
    getStatusChangesHistory();
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStatusChangesHistory();
  }, [sort]);

  useEffect(() => {
    getClientRate();
    getClientComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.client]);

  useEffect(() => {
    const { connect, leave } = connectToSocket(`admin.orders.${orderId}`, '.update.order', payload => {
      const updatedData = transformOrder({
        ...order,
        ...payload,
      });

      setOrder(updatedData);
    });

    connect();

    return () => leave();
  }, [orderId]);

  useEffect(() => {
    const { connect, leave } = connectToSocket(
      `admin.orders.${orderId}.notifications`,
      '.create.notification',
      payload => {
        if (!payload?.id) {
          setStatusChangesHistory(val => [payload, ...val]);
        }
      }
    );

    connect();

    return () => leave();
  }, [orderId]);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className="mt-[10px] ml-[16px] transition-all duration-200">
            <div className="text-[#999999] text-[12px] mb-2">
              <span className="cursor-pointer" onClick={() => navigate('/history')}>
                История заказов
              </span>{' '}
              &gt; {orderId}
            </div>
            <div className="flex">
              <Card className="w-[30%] min-w-[226px] text-[12px] mr-2 mb-2">
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <CardRow>
                      <div className="flex flex-row items-center">
                        <p className="text-[#333333] font-bold">
                          Заказ <span className="text-[#999999]">#{orderId}</span>
                        </p>
                      </div>
                    </CardRow>
                    <CardRow>
                      <p className="text-[#999999] mb-2">Статус:</p>
                      <OrderStatusSelect
                        order={order}
                        status={order.status}
                        updateOrder={v => updateOrder(v)}
                      />
                    </CardRow>
                    {/* <CardRow>
                      <div className="flex items-center">
                        <RoundedCheckbox
                          isSelected={order.track || false}
                          onChange={(track: boolean) => updateTrackStatus(!track)}
                        />
                        <p className="ml-4 text-sm">Отслеживать уведомления</p>
                      </div>
                    </CardRow> */}
                    {order && order.rating ? (
                      <CardRow>
                        <p className="text-[#999999] mb-2">Оценка:</p>

                        <div className="flex items-center">
                          <Rating
                            initialRating={order?.rating || 0}
                            emptySymbol={<Star fill={'#5F5F5F'} />}
                            fullSymbol={<Star fill={'#feaf51'} />}
                            readonly
                          />
                        </div>
                      </CardRow>
                    ) : (
                      <></>
                    )}
                    <CardRow>
                      <p className="text-[#999999] mb-1">Время забора авто</p>
                      <div>
                        <p className="text-[#1066B1]">
                          {parseDateFormat(order?.start_at, 'YYYY-MM-DD hh:mm:ss A')}
                        </p>
                        <p className="text-[#999999]">({timeCount(order?.start_at, false)})</p>
                      </div>
                    </CardRow>
                    {order?.finish_driver_at && (
                      <CardRow>
                        <p className="text-[#999999] mb-1">Ожидаемое время доставки</p>
                        <div>
                          <p className="text-[#1066B1]">
                            {parseDateFormat(order?.finish_driver_at, 'YYYY-MM-DD hh:mm:ss A')}
                          </p>
                          <p className="text-[#999999]">({timeCount(order?.finish_driver_at, false)})</p>
                        </div>
                      </CardRow>
                    )}

                    <CardRow>
                      <div className="flex items-center">
                        <div>
                          <p className="text-[#999999] mb-1">Акт отправки</p>
                          <ReportsList data={order?.acceptance_reports} />
                        </div>
                        <div className="ml-6">
                          <p className="text-[#999999] mb-1">Акт приемки</p>
                          <ReportsList data={order?.finish_reports} />
                        </div>
                      </div>
                    </CardRow>
                    <CardRow>
                      <div className="flex items-center">
                        <div>
                          <p className="text-[#999999] mb-1">Инвойс</p>
                          {invoice.length ? (
                            <div>
                              <a
                                download
                                href={invoice}
                                className="underline"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="flex flex-row items-center cursor-pointer">
                                  <img src="/assets/img/file.svg" alt="change" />
                                  <p className="ml-1 text-[#1066B1] text-[14px]">Скачать</p>
                                </div>
                              </a>
                            </div>
                          ) : (
                            <p className="ml-1 text-[14px]">-----</p>
                          )}
                        </div>
                      </div>
                    </CardRow>
                    <CardRow>
                      <Addresses
                        start={order?.start_to}
                        finish={order?.finish_to}
                        totalPrice={order?.total_price}
                        miles={order.miles}
                        onStartEditClick={() => setStartAddressModal(true)}
                        onFinishEditClick={() => setFinishAddressModal(true)}
                      />
                    </CardRow>
                    <CardRow>
                      <p className="text-[#999999] mb-1">Комментарий</p>
                      <p style={{ overflowWrap: 'break-word' }}>{order?.comment}</p>
                    </CardRow>
                    <CardRow>
                      <p className="text-[#999999] mb-1">Машины</p>
                      {order?.items &&
                        order.items.map((car: any, index: number) => {
                          return (
                            <div className="mb-1" key={index}>
                              <p>
                                {index + 1}. {car.car_name}
                              </p>
                              <p className="text-[#999999]">{car.dimensions}</p>
                            </div>
                          );
                        })}
                    </CardRow>
                    <CardRow>
                      <Price
                        orderId={order.id}
                        title="Оплата клиента"
                        onEditClick={() => setIsOrderPriceModal(true)}
                        onUpdated={() => getOrder()}
                        type="client"
                        totalPrice={order.total_price}
                        carsCount={order?.items?.length || 0}
                      />
                    </CardRow>

                    <br />
                    <CardRow>
                      <Price
                        orderId={order.id}
                        title="Оплата комис. водит."
                        onEditClick={() => setIsDriverPriceModal(true)}
                        onUpdated={() => getOrder()}
                        type="driver"
                        totalPrice={order.driver_commission}
                      />
                    </CardRow>

                    <br />
                    <CardRow>
                      <ClipedFiles
                        data={order?.files}
                        onAdd={addClipedFile}
                        onRemove={removeClipedFile}
                      />
                    </CardRow>
                    <CardRow>
                      <p className="text-[#999999] mb-1">Менеджер создавший заказ</p>
                      <div className="flex items-center">
                        <div className="w-[22px] h-[22px] rounded-full bg-[#DADADA]">
                          {order.manager?.avatar && <img src={order.manager.avatar} alt="avatar" />}
                        </div>
                        <div className="ml-[6px]">
                          <p>{`${order.manager?.first_name || ''} ${order.manager?.last_name || ''}`}</p>
                          <p className="underline text-[#3072C4] cursor-pointer">
                            {order.manager?.phone || 'Не указан'}
                          </p>
                        </div>
                      </div>
                    </CardRow>
                  </>
                )}
              </Card>
              <div
                className="w-full grid grid-cols-[1fr]"
                style={{
                  gridTemplateRows: `${
                    sidebarOpen ? 'min-content auto' : 'auto min-content'
                  } min-content`,
                }}
              >
                <div className={`flex w-[100%]`}>
                  <Card className="w-[33%] min-w-[185px] text-[12px] mr-2 mb-2 h-[93px]">
                    {isLoading ? (
                      <Loader size={70} />
                    ) : (
                      <CardRow>
                        <Driver data={order?.driver} onEditClick={() => setDriverModal(true)} />
                      </CardRow>
                    )}
                  </Card>
                  <Card className="w-[66%] min-w-[361px] text-[12px] mr-2 mb-2 h-[93px]">
                    {isLoading ? (
                      <Loader size={70} />
                    ) : (
                      <div className="flex">
                        <div className="w-[50%] pr-3 border-r-[1px] border-[#F5F5F5] h-full">
                          <PersonInfo
                            role="Отправитель"
                            data={order?.sender}
                            onEditClick={() => setSenderModal(true)}
                          />
                        </div>
                        <div className="w-[50%] ml-3 h-full">
                          <PersonInfo
                            role="Получатель"
                            data={order?.receiver}
                            onEditClick={() => setReceiverModal(true)}
                          />
                        </div>
                      </div>
                    )}
                  </Card>
                </div>
                {isLoading || !order ? (
                  <Loader size={85} />
                ) : (
                  <div className={`h-[95%] my-[auto] mr-2 row-[${sidebarOpen ? '2_/_3' : '1_/_2'}]`}>
                    <Map data={[order]} />
                  </div>
                )}
                <Card className="w-[99%] min-w-[556px] text-[12px] min-h-[187px]">
                  <CardRow>
                    <StatusChangesTable
                      data={statusChangesHistory}
                      isLoading={isLoading}
                      sort={sort}
                      onSortUpdate={setSort}
                    />
                  </CardRow>
                </Card>
              </div>
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <OrderPriceModal
          opened={orderPriceModal}
          isLoading={isLoading}
          totalPrice={order.total_price}
          miles={order.miles}
          setOpened={setIsOrderPriceModal}
          onSave={price => updateOrder({ total_price: price ? `${price}` : '0' })}
        />

        <DriverPriceModal
          opened={driverPriceModal}
          isLoading={isLoading}
          driverCommission={order.driver_commission}
          totalPrice={order.total_price}
          setOpened={setIsDriverPriceModal}
          onSave={price => updateOrder({ driver_commission: price ? `${price}` : '0' })}
        />

        <AddressChangeModal
          type="from"
          opened={startAddressModal}
          title="Адрес начала заказа"
          isLoading={isLoading}
          data={order?.start_to}
          setOpened={setStartAddressModal}
          onSave={address =>
            updateAddress({
              start_to: address.title,
              start_lat: String(address.lat),
              start_lng: String(address.lng),
            })
          }
        />

        <AddressChangeModal
          type="to"
          opened={finishAddressModal}
          title="Адрес конца заказа"
          isLoading={isLoading}
          data={order?.finish_to}
          setOpened={setFinishAddressModal}
          onSave={address =>
            updateAddress({
              finish_to: address.title,
              finish_lat: String(address.lat),
              finish_lng: String(address.lng),
            })
          }
        />

        <DriverChangeModal
          opened={driverModal}
          setOpened={setDriverModal}
          data={order?.driver}
          isLoading={isLoading}
          onChange={(newDriver: any) =>
            setOrder({
              ...order,
              driver_id: newDriver.id,
              driver: { ...newDriver },
            })
          }
          onSave={driver => updateOrder({ driver_id: driver.id })}
        />

        <PersonInfoChangeModal
          opened={senderModal}
          isLoading={isLoading}
          role="Отправитель"
          data={order?.sender}
          sender_type={order.sender_type}
          setOpened={setSenderModal}
          onSave={sender => updateOrder({ sender })}
        />

        <PersonInfoChangeModal
          opened={receiverModal}
          isLoading={isLoading}
          role="Получатель"
          data={order?.receiver}
          sender_type={order.sender_type}
          setOpened={setReceiverModal}
          onSave={receiver => updateOrder({ receiver })}
        />
      </ModalsSection>
    </>
  );
};

export default OrderPage;
