import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { IStore } from 'src/redux';

interface INavLink {
  text: string;
  src: string;
  isActive?: boolean;
  withDrop?: boolean;
  withMargin?: boolean;
  links?: Array<any>;
}

const NavLink: FunctionComponent<INavLink> = ({
  text = '',
  src = '',
  isActive = false,
  withDrop = false,
  withMargin = true,
  links = [],
}) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { sidebarOpen } = useSelector((store: IStore) => store.layout);
  const [marginBottom, setMarginBottom] = useState('1.5rem');

  useEffect(() => {
    let marginUpdate;

    if (!withMargin) {
      marginUpdate = '0';
    } else if (sidebarOpen) {
      marginUpdate = open ? '1rem' : '1.5rem';
    } else {
      marginUpdate = '14px';
    }

    setMarginBottom(marginUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, sidebarOpen, withMargin]);

  return (
    <div style={{ marginBottom }} className="transition-all">
      <div
        onClick={() => withDrop && setOpen(!open)}
        className={`flex cursor-pointer items-center ${
          sidebarOpen ? 'flex-row' : 'flex-col'
        } overflow-hidden transition-all duration-200`}
      >
        <div
          className={`${
            sidebarOpen ? 'min-w-[16px] min-h-[16px]' : 'min-w-[20px] min-h-[20px]'
          } flex justify-center items-center transition-all duration-200`}
        >
          <img
            src={src}
            alt={text}
            style={isActive ? { filter: 'invert(0) sepia(2) saturate(51) hue-rotate(-160deg)' } : {}}
          />
        </div>
        <div
          className={`text-sm transition-all duration-200 ${isActive && 'text-[#1066B1]'} ${
            !sidebarOpen ? 'text-[11px]' : 'ml-2'
          }`}
        >
          {text}
        </div>
      </div>
      {withDrop && (
        <div
          className={`overflow-hidden transition-all duration-200 w-full origin-top ${
            sidebarOpen ? 'ml-7' : 'ml-0'
          }`}
          style={{ maxHeight: (open || isActive ? 50 * links.length : 0) + 'px', height: 'auto' }}
        >
          {links.map((item: any, index: number) => {
            return (
              <Link key={index} to={item.link}>
                <div
                  className={`${sidebarOpen ? 'mt-3 text-xs' : 'mt-1 text-[10px] text-center'} ${
                    location.pathname.includes(item.link) &&
                    !(
                      item.title === 'Список компаний' &&
                      location.pathname.includes('/transport-companies/list/0')
                    ) &&
                    'text-[#1066B1]'
                  }`}
                >
                  {item.title}
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavLink;
