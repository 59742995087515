import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import logger from 'src/utils/logger';

// Components
import RouteComponent from './Route';
import Loader from 'src/components/Custom/Loader';
import { connectToSocket } from 'src/hooks/use-socket';
import axiosClient from 'src/libs/axiosClient';
import DriverMarker from './DriverMarker';

export const Map = ({ children, center, zoom }) => {
  const containerStyle = {
    height: '100%',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'map',
    googleMapsApiKey: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(map => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  const options = {
    zoom,
    clickableIcons: true,
    disableDoubleClickZoom: false,
    draggable: true,
    fullscreenControl: true,
    keyboardShortcuts: true,
    mapTypeControl: true,
    mapTypeControlOptions: { style: 0 },
    mapTypeId: 'roadmap',
    rotateControl: true,
    scaleControl: true,
    scrollwheel: true,
    streetViewControl: true,
    styles: [
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#444444' }],
      },
      { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#f2f2f2' }] },
      { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [{ saturation: -100 }, { lightness: 45 }],
      },
      { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'simplified' }] },
      { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [{ color: '#cbd4d8' }, { visibility: 'on' }],
      },
    ],
    zoomControl: true,
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
    >
      {children}
    </GoogleMap>
  ) : (
    <Loader size={50} />
  );
};

export interface IDriverCoordinate {
  connection_lost: boolean;
  connection_lost_at: string;
  distance: number;
  lat: string;
  lng: string;
  order_id: number;
}

interface IMapComponent extends google.maps.MapOptions {
  data: Array<any>;
}

const MapComponent: FunctionComponent<IMapComponent> = ({ data = [] }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [routeLoaded, setRouteLoaded] = useState(false);
  const [currentZoom, setCurrentZoom] = useState(4);
  const [defaultCenter, setDefaultCenter] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const [directions, setDirections] = useState<google.maps.DirectionsResult[]>([]);

  const getDirection = async order => {
    return new Promise<google.maps.DirectionsResult | null>(resolve => {
      const directionsService = new google.maps.DirectionsService();

      const origin = { lat: Number(order.start_lat), lng: Number(order.start_lng) };
      const destination = { lat: Number(order.finish_lat), lng: Number(order.finish_lng) };

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            resolve(result);
          } else {
            resolve(null);
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    });
  };

  useEffect(() => {
    if (!isLoading && data.length > 0 && !routeLoaded) {
      (async () => {
        try {
          const results: Array<google.maps.DirectionsResult | null> = [];
          for (const order of data) {
            const result = await getDirection(order);
            if (result) {
              results.push(result);
            } else {
              logger.error(`No direction for order "${order.id}"`);
            }
          }
          setDirections(results.filter(e => !!e) as google.maps.DirectionsResult[]);
        } catch (err) {
        } finally {
          setRouteLoaded(true);
        }
      })();

      const firstDataWithLocation = data.find(e => e.start_lat && e.start_lng);
      if (firstDataWithLocation) {
        setDefaultCenter({
          lat: Number(firstDataWithLocation.start_lat),
          lng: Number(firstDataWithLocation.start_lng),
        });
      }
    }
  }, [data, isLoading, directions, routeLoaded]);

  const orders = useMemo(() => {
    if (!Array.isArray(data) || !data.length) {
      return [];
    }

    return data;
  }, [data]);

  const [driverCoordinates, setDriverCoordinates] = useState<
    Array<IDriverCoordinate & { driverId: number }>
  >([]);
  const setInitDriverCoordinates = async (driverIds: number[]) => {
    setDriverCoordinates(
      await Promise.all(
        driverIds.map(async driverId => {
          const result = await axiosClient.get<IDriverCoordinate>(
            `/drivers/${driverId}/last-coordinate`
          );

          const order = orders.find(e => e.driver?.id === driverId);

          return {
            driverId,
            ...(order?.driver || {}),
            ...result.data,
            coordinates: {
              lat: result.data.lat,
              lng: result.data.lng,
            },
          };
        })
      )
    );
  };
  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const driverIds = data.map(e => e.driver?.id).filter(e => !!e);

    setInitDriverCoordinates(driverIds);

    const driverSockets = driverIds.map(driverId => [
      driverId,
      connectToSocket(
        `admin.drivers.${driverId}.coordinate`,
        '.create.coordinate',
        (payload, driverId) => {
          setDriverCoordinates(
            driverCoordinates.map(e =>
              e.driverId === driverId
                ? { ...payload, driverId, coordinates: { lat: payload.lat, lng: payload.lng } }
                : e
            )
          );
        }
      ),
    ]);

    driverSockets.forEach(([driverId, { connect }]) => connect(driverId));

    return () => driverSockets.forEach(([_, { leave }]) => leave());
  }, [data]);

  return (
    <Map key={'map'} zoom={currentZoom} center={defaultCenter}>
      {isLoading ? (
        <Loader size={50} />
      ) : (
        <>
          {directions.map((direction, i) => (
            <DirectionsRenderer key={i} directions={direction} options={{ suppressMarkers: true }} />
          ))}
          {orders.map((order, i) => (
            <RouteComponent key={i} order={order} />
          ))}

          {driverCoordinates.map(driver => (
            <DriverMarker
              key={`driver-${driver.driverId}`}
              driver={driver}
              order={orders.find(e => e.driver?.id === driver.driverId)}
            />
          ))}
        </>
      )}
    </Map>
  );
};

export default MapComponent;
