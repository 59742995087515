import React, { FunctionComponent, useState } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Input from 'src/components/UI/Input';
import logger from 'src/utils/logger';

const CompanyAdminBranding: FunctionComponent<any> = ({
  branding = {},
  setBranding = () => {},
  onSave = () => {},
}) => {
  const [response, setResponse] = useState<string>('');

  const updateBrandingField = (value: any, field: string) => {
    setBranding({ ...branding, [field]: value });
  };

  const updateLogo = (e: any, key: string) => {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];

    if ((file.type !== 'image/jpeg' && file.type !== 'image/png') || file.size / 1024 / 1024 >= 5) {
      return;
    }

    updateBrandingField(file, key);
  };

  const throwValidationError = () => {
    setResponse('Все поля обязательны к заполнению');
    setTimeout(() => setResponse(''), 3000);
  };

  const updateCompanyBranding = async () => {
    const form = new FormData();
    let company = '';
    let webSite = '';
    let logo: any = null;

    for (let key of ['driver', 'client']) {
      company = branding[`${key}_company_name`];
      webSite = branding[`${key}_web_site`];
      logo = branding[`${key}_logo`];

      if (!company || !webSite) {
        throwValidationError();
        return;
      }

      if (logo instanceof File) {
        form.append(`${key}[logo]`, logo);
      }

      form.append(`${key}[company_name]`, company || '');
      form.append(`${key}[web_site]`, webSite || '');
    }

    try {
      await onSave(form);
    } catch (e: any) {
      logger.error(`Ошибка при сохранении ${e.message}`, e);
    }
  };

  return (
    <>
      <div className="flex flex-col pl-5 mt-6">
        <div className="flex">
          {['client', 'driver'].map(key => {
            let logoKey = `${key}_logo`;
            let companyKey = `${key}_company_name`;
            let webSiteKey = `${key}_web_site`;

            return (
              <div className="flex flex-col w-3/6" key={key}>
                <h1 className="text-[18px] text-[#363B39] font-bold">
                  {key === 'driver' ? 'Для водителей' : 'Для клиентов (дочерняя компания)'}
                </h1>
                <div className="mt-6 mr-8">
                  <p className="mb-4 text-[#999]">Логотип</p>
                  <div className="flex items-center">
                    <div className="flex justify-center items-center bg-[#f2e9ff] overflow-hidden max-w-[5rem] max-h-[5rem] min-w-[5rem] min-h-[5rem] rounded-full">
                      <img
                        alt="avatar"
                        className="object-cover h-[5rem] max-w-[none]"
                        src={
                          branding[logoKey]
                            ? branding[logoKey] instanceof File
                              ? URL.createObjectURL(branding[logoKey])
                              : branding[logoKey]
                            : '/assets/img/clients.svg'
                        }
                      />
                    </div>
                    {branding[logoKey] ? (
                      <div className="ml-4">
                        <p
                          className="font-semibold text-[#EE0202] cursor-pointer"
                          onClick={() => updateBrandingField(null, logoKey)}
                        >
                          Удалить фото
                        </p>
                      </div>
                    ) : (
                      <div className="ml-4 flex flex-col">
                        <label>
                          <p className="font-bold text-[#1066B1] cursor-pointer">+ Добавить фото</p>
                          <input
                            value={branding[logoKey]}
                            type="file"
                            accept="image/jpeg,image/png"
                            onChange={e => updateLogo(e, logoKey)}
                            className="hidden"
                          />
                        </label>
                        <p className="flex flex-col text-xs text-[#A9A1A1]">
                          <span>не более 5.00mb jpg, png, jpeg.</span>
                          <span>Рекомендуемый размер 150x150</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex w-[70%]">
                  <div className="mt-6 mr-8 w-full">
                    <p className="mb-2 text-[#999]">Название компании</p>
                    <Input
                      value={branding[companyKey] || ''}
                      placeholder="Название компании"
                      onChange={value => updateBrandingField(value, companyKey)}
                    />
                  </div>
                </div>
                <div className="flex w-[70%]">
                  <div className="mt-6 mr-8 w-full">
                    <p className="mb-2 text-[#999]">Web сайт</p>
                    <Input
                      value={branding[webSiteKey] || ''}
                      placeholder="Web сайт"
                      onChange={value => updateBrandingField(value, webSiteKey)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-6">
          <div className="text-[1.15em] max-w-[15em]">
            <ButtonSPrimary onClickHandler={updateCompanyBranding}>Сохранить</ButtonSPrimary>
          </div>

          {response && (
            <p
              className={response === 'Изменения успешно сохранены' ? 'text-green-500' : 'text-red-500'}
            >
              {response}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyAdminBranding;
