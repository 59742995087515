import { FunctionComponent, useEffect, useState } from 'react';
import Loader from 'src/components/Custom/Loader';
import Input from 'src/components/UI/Input';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Select from 'src/components/UI/Select';
import axiosClient from 'src/libs/axiosClient';
import logger from 'src/utils/logger';

export const transactionTypeStatuses = [
  { id: 1, title: 'Наличными' },
  { id: 3, title: 'На счёт' },
];

export type TransactionType = 1 | 2 | 3;

export const paymentStatusesWaiting = [
  { id: 1, color: '48, 114, 196', title: 'Ожидает оплаты' },
  { id: 2, color: '48, 114, 196', title: 'Ожидает оплаты после выполнения' },
];

export const paymentStatuses = [
  { id: 3, color: '229, 145, 85', title: 'Частично оплачено' },
  { id: 4, color: '39, 174, 96', title: 'Оплачено' },
];

export const paymentStatusesRefund = [
  { id: 5, color: '238, 2, 2', title: 'Частичный возврат' },
  { id: 6, color: '238, 2, 2', title: 'Полный возврат' },
];

export const allPaymentStatuses = [
  ...paymentStatusesWaiting,
  ...paymentStatuses,
  ...paymentStatusesRefund,
];

export type PaymentStatus = 1 | 2 | 3 | 4 | 5 | 6;
// | 'waiting'
// | 'waiting-complete'
// | 'partial'
// | 'payed'
// | 'partial-refund'
// | 'refund';

export const TransactionForm: FunctionComponent<{
  orderId: number;
  setOpened: (bool: boolean) => void;
  opened?: boolean;
  isLoading?: boolean;

  type: 'client' | 'driver';
  paymentStatus?: PaymentStatus;

  totalPayed: string;
  totalPrice: string;

  onTransactionCreated: () => void;
}> = ({
  orderId,
  setOpened,
  opened = false,
  isLoading = false,

  type,
  paymentStatus,

  totalPayed,
  totalPrice,

  onTransactionCreated,
}) => {
  const [price, setPrice] = useState('');
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<PaymentStatus | null>(
    paymentStatus ?? null
  );
  const [transactionType, setTransactionType] = useState<TransactionType | null>(null);

  useEffect(() => {
    setSelectedPaymentStatus(paymentStatus ?? null);
  }, [paymentStatus]);

  const onSave = async () => {
    try {
      await axiosClient.post(`/orders/${orderId}/transactions`, {
        transaction_type: type,
        amount: price,
        status: selectedPaymentStatus,
        payment_type: transactionType,
      });
      onTransactionCreated();
    } catch (e: any) {
      logger.error(e?.response?.data?.message ?? e.message, e);
    }
  };

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow
        title={
          paymentStatus
            ? allPaymentStatuses.find(e => e.id === paymentStatus)?.title
            : 'Создание транзакции'
        }
        setState={v => setOpened(v as boolean)}
      >
        <div className="flex flex-col">
          {!paymentStatus && (
            <div className="mb-3">
              <p className="text-[#999999] mb-1">Тип</p>

              <Select
                value={allPaymentStatuses.find(e => e.id === selectedPaymentStatus)}
                options={allPaymentStatuses}
                defaultColor="#1066b126"
                defaultTextColor="#1066B1"
                label="title"
                onSelect={(value: any) => setSelectedPaymentStatus(value.id)}
              />
            </div>
          )}

          <div className="mb-3">
            <p className="text-[#999999] mb-1">Сумма транзакции</p>

            <div className="flex justify-between gap-4">
              <div className="flex items-center relative">
                <span className="absolute left-1.5 top-1.5 text-sm">$</span>
                <Input value={price} type="number" onChange={e => setPrice(e)} min={0} />
              </div>
              <Select
                value={transactionTypeStatuses.find(e => e.id === transactionType)}
                options={transactionTypeStatuses}
                defaultColor="#1066b126"
                defaultTextColor="#1066B1"
                label="title"
                onSelect={(value: any) => setTransactionType(value.id)}
              />
            </div>
          </div>

          <p className="text-sm text-[#999999] mb-1">
            Сумма заказа: ${totalPayed}/{totalPrice}
          </p>
          {isLoading ? (
            <Loader size={100} />
          ) : (
            <div className="w-[320px] text-[1.1em]">
              <ButtonSPrimary onClickHandler={() => onSave()}>Создать транзакцию</ButtonSPrimary>
            </div>
          )}
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};
