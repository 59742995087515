import { FunctionComponent } from 'react';

interface LegalGeneralTypes {
  legal: any;
}

const LegalsGeneral: FunctionComponent<LegalGeneralTypes> = ({ legal = {} }) => {
  return (
    <>
      <div className="px-4 py-2">
        <div className="mt-6">
          <p className="mb-1 text-[#999] text-sm">Название</p>
          <p>{legal.company || 'Не указан'}</p>
        </div>
        <div className="mt-6">
          <p className="mb-1 text-[#999] text-sm">Tax ID number</p>
          <p>{legal.tax_id || 'Не указан'}</p>
        </div>
        <div className="mt-6">
          <p className="mb-1 text-[#999] text-sm">Почта</p>
          <p>{legal.email || 'Не указан'}</p>
        </div>
      </div>
    </>
  );
};

export default LegalsGeneral;
