import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from 'src/components/Custom/Loader';
import logger from 'src/utils/logger';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import MainSection from 'src/components/Semantic/MainSection';
import Select from 'src/components/UI/Select';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import DatePicker from 'src/components/UI/DatePicker';
import { DayRange } from 'react-modern-calendar-datepicker';
import { defaultFromDayRange, defaultToDayRange, formatDate } from 'src/utils/calendar';

const statTypes = [
  { field: 'new_orders', label: 'Новые заказы' },
  { field: 'canceled_orders', label: 'Отмененые заказы' },
  { field: 'average_price', label: 'Средняя цена заказа' },
  { field: 'all_drivers', label: 'Всего водителей' },
  { field: 'drivers_online', label: 'Водителей в работе' },
  { field: 'drivers_offline', label: 'Водителей офлайн более суток' },
  { field: 'drivers_new', label: 'Новых пользователей' },
  { field: 'drivers_earned', label: 'Выручка' },
];

const dateTypes = [
  { label: 'День', field: 'day' },
  { label: 'Неделя', field: 'week' },
  { label: 'Месяц', field: 'month' },
];

const Stats: FunctionComponent = () => {
  const [statTypeBlue, setStatTypeBlue] = useState<any>(statTypes[0]);
  const [statTypeGreen, setStatTypeGreen] = useState<any>();
  const [timeStep, setTimeStep] = useState<any>(dateTypes[0]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const [dayRange, setDayRange] = useState<DayRange>({
    from: defaultFromDayRange,
    to: defaultToDayRange,
  });

  const getStats = async () => {
    if (!dayRange.from || !dayRange.to) {
      return;
    }

    setIsLoading(true);

    try {
      let statParams = statTypeBlue.field;
      if (statTypeGreen) {
        statParams += ',' + statTypeGreen.field;
      }
      const { data } = await axiosClient.get('/statistics', {
        params: {
          params: statParams,
          from: formatDate(dayRange.from, '-'),
          to: formatDate(dayRange.to, '-'),
          type: timeStep.field,
        },
      });

      const groupedByDates = data.reduce((obj, item) => {
        obj[item.date] = obj[item.date] || {};
        obj[item.date] = {
          ...obj[item.date],
          ...item,
        };
        return obj;
      }, {});
      const stats = Object.keys(groupedByDates).map(date => {
        const item = groupedByDates[date];
        const stat = { date };
        stat[statTypeBlue.label] = item[statTypeBlue.field] || 0;
        if (statTypeGreen) {
          stat[statTypeGreen.label] = item[statTypeGreen.field] || 0;
        }

        return stat;
      });

      setData(stats);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStep, statTypeBlue, statTypeGreen, dayRange]);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="flex justify-between px-[17px] pt-[14px] pb-[12px]">
              <div className="flex">
                <div className="mr-2 min-w-[16rem]">
                  <Select
                    value={statTypeBlue}
                    options={statTypes}
                    selectedRenderer={v => <RenderSelectStat label={v.label} bgColor="#4558FF" />}
                    onSelect={v => {
                      setStatTypeBlue(v);
                      setStatTypeGreen(null);
                    }}
                  />
                </div>
                <div className="min-w-[16rem]">
                  <Select
                    placeholder="Выберите дополнительный показатель"
                    value={statTypeGreen}
                    options={statTypes.filter(e => e.field !== statTypeBlue.field)}
                    selectedRenderer={v => <RenderSelectStat label={v.label} bgColor="#27AE60" />}
                    onSelect={setStatTypeGreen}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="mr-2 max-w-[17em]">
                  <DatePicker
                    value={dayRange}
                    placeholder="дд.мм.гггг - дд.мм.гггг"
                    onChange={(newRange: DayRange) => setDayRange(newRange)}
                  />
                </div>
                <div className="min-w-[10em]">
                  <Select value={timeStep} options={dateTypes} onSelect={setTimeStep} />
                </div>
              </div>
            </div>
            <div className="pr-10 mt-4">
              {isLoading ? (
                <Loader size={100} />
              ) : (
                <ResponsiveContainer width="100%" height={600}>
                  <LineChart data={data}>
                    <XAxis dataKey={'date'} />
                    <YAxis />
                    <CartesianGrid stroke="#eee" strokeDasharray="10 10" />
                    <Line
                      type="monotone"
                      dataKey={statTypeBlue.label}
                      stroke="#4558FF"
                      strokeWidth={2}
                      activeDot={{ r: 5 }}
                    />
                    {statTypeGreen && (
                      <Line
                        type="monotone"
                        dataKey={statTypeGreen.label}
                        stroke="#27AE60"
                        strokeWidth={2}
                        activeDot={{ r: 5 }}
                      />
                    )}
                    <Tooltip
                      content={(props: any) => (
                        <CustomTooltip label={props.label} payload={props.payload} />
                      )}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
    </>
  );
};

const RenderSelectStat = ({ label, bgColor }) => {
  return (
    <span className="flex items-center">
      <span className="w-2 h-2 mr-1 rounded-full" style={{ backgroundColor: bgColor }} />
      <span>{String(label)}</span>
    </span>
  );
};

const priceStatOptions = ['Средняя цена заказа'];
const CustomTooltip = ({ label, payload }) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
        padding: '10px',
        border: '0px',
        outline: 'none',
      }}
    >
      <p className="mb-1" style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
        {label}
      </p>
      {payload.map(p => (
        <div key={p.type} className="flex items-center">
          <span className="block w-2 h-2 mr-1 rounded-full" style={{ backgroundColor: p.color }} />
          <span>
            {p.dataKey} - {priceStatOptions.includes(p.dataKey) ? '$' : ''}
            {p.value}
          </span>
        </div>
      ))}
    </div>
  );
};
export default Stats;
