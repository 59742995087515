import { FunctionComponent, useState } from 'react';
import { GridTable, GridTableCell } from 'src/components/GridTable';
import { isPushSended } from 'src/pages/mailing';
import { parseDate } from 'src/utils/format-date';

// Basic cells
import TimeCell from '../BASIC_CELLS/TimeCell';
import { isPermitted } from 'src/utils/permissions';

interface IMailingsTable {
  mails: Array<any>;
  isLoading?: boolean;
  sort: Array<string>;
  onMailEdit?: (mailId: number) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const MailingsTable: FunctionComponent<IMailingsTable> = ({
  mails,
  isLoading = false,
  sort = [],

  onMailEdit = () => {},
  onSortUpdate = () => {},
}) => {
  const TableFields = {
    recipient: {
      label: 'Получатели',
      sortBy: 'sort_recipient',
    },

    status: {
      label: 'Статус',
      sortBy: 'sort_status',
      component: (status: string, _, row) => (
        <GridTableCell>
          <div
            className={`p-2 ${
              isPushSended(row.status) ? 'text-[#27AE60] bg-[#e9f7ef]' : 'text-[#7F22FD] bg-[#f2e9ff]'
            } rounded-sm`}
          >
            {status}
          </div>
        </GridTableCell>
      ),
    },

    date_send: {
      label: 'Дата отправки',
      sortBy: 'sort_date',
      component: (date: string) => <TimeCell date={parseDate(date, 'YYYY-MM-DD HH:mm:ss')} />,
    },

    message: {
      label: 'Уведомление',
      sortBy: 'sort_message',
      component: (message: string) => <MailMessageCell message={message} />,
    },

    id: {
      component: (mailId: number, _, row) => (
        <GridTableCell>
          {!isPushSended(row.status) && isPermitted('pushes_edit') && (
            <div className="flex cursor-pointer">
              <img src="/assets/img/pencil.svg" alt="" width={14} height={14} className="mr-1" />
              <p className="text-[#1066B1] text-xs font-semibold" onClick={() => onMailEdit(mailId)}>
                Редактировать
              </p>
            </div>
          )}
        </GridTableCell>
      ),
    },
  };

  return (
    <GridTable
      data={mails}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

/* ----------------------- TABLE CELLS ----------------------- */

const MAX_MESSAGE_LENGTH = 80;

const MailMessageCell: FunctionComponent<any> = ({ message }) => {
  const [show, setShow] = useState<boolean>(message.length <= MAX_MESSAGE_LENGTH);

  return (
    <GridTableCell>
      <div className="flex flex-col">
        <div className="max-w-[40rem] break-all">
          {show ? message : message.slice(0, MAX_MESSAGE_LENGTH)}
          {!show && message.length > MAX_MESSAGE_LENGTH && '...'}
        </div>

        {message.length > MAX_MESSAGE_LENGTH && (
          <span className="mt-2 text-xs text-blue-400 cursor-pointer" onClick={() => setShow(!show)}>
            {show ? 'Скрыть' : 'Показать больше'}
          </span>
        )}
      </div>
    </GridTableCell>
  );
};

export default MailingsTable;
