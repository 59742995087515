import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import UserLockCell from '../BASIC_CELLS/UserLockCell';
import OrderInfoCell from '../BASIC_CELLS/OrderInfoCell';
import { isPermitted } from 'src/utils/permissions';

interface IClientsTable {
  clients: Array<any>;
  isLoading: boolean;
  sort: Array<string>;
  selected: IExportSelected;

  onClientSelect: (newSelected: IExportSelected) => any;
  onClientStatusUpdate: (clientId: number, newStatus: boolean) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const ClientsTable: FunctionComponent<IClientsTable> = ({
  clients,
  isLoading = false,
  sort = [],
  selected = [],

  onClientSelect = () => {},
  onClientStatusUpdate = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: onClientSelect,
      additionalParams: { width: '30px' },
    }),

    id: {
      label: 'ID',
      sortBy: 'sort_id',
      width: '70px',
      component: (clientId: number) => (
        <ClientIdCell
          clientId={clientId}
          onClick={() => navigate(`/clients/list/${clientId}/general`)}
        />
      ),
    },

    fullName: {
      label: 'Пользователь',
      sortBy: 'sort_user',
      formatter: (v: string) => (v.length > 1 ? v : '-'),
    },

    lastOrder: {
      label: 'Последний заказ',
      sortBy: 'sort_last_order',

      component: (lastOrder: any) =>
        lastOrder ? (
          <OrderInfoCell
            order={lastOrder}
            fromPath={lastOrder.fromPath}
            toPath={lastOrder.toPath}
            status={lastOrder.status}
            price={lastOrder.price}
            maxWidth="100%"
            onClick={() => navigate(`/order/${lastOrder?.id}`)}
          />
        ) : (
          <GridTableCell>-</GridTableCell>
        ),
    },

    email: { label: 'Почта', sortBy: 'sort_email' },
    phone: { label: 'Телефон', width: '150px', sortBy: 'sort_phone' },
    orders_count: { label: 'Заказов', width: '80px', sortBy: 'sort_orders_count' },
    company: {
      label: 'Компания',
      sortBy: 'sort_company',
      component: (c: any) => (
        <GridTableCell>
          {c ? (
            <div className="flex flex-col">
              <p>{c.company}</p>
              <p
                className="text-[#4580ca] text-sm cursor-pointer underline"
                onClick={() => navigate(`/legals/list/${c.id}/general`)}
              >
                {c.id}
              </p>
            </div>
          ) : (
            <span>-</span>
          )}
        </GridTableCell>
      ),
    },

    events: {
      component: (v: any, i: number, client: any) => (
        <UserLockCell
          locked={client.is_blocked}
          disabled={!isPermitted('clients_block')}
          onLock={() => onClientStatusUpdate(client.id, true)}
          onUnlock={() => onClientStatusUpdate(client.id, false)}
        />
      ),
    },
  };

  return (
    <GridTable
      data={clients}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

/* ----------------------- TABLE CELLS ----------------------- */

const ClientIdCell: FunctionComponent<any> = ({ clientId, onClick = () => {} }) => {
  return (
    <GridTableCell className="text-[#4580ca] text-sm cursor-pointer underline" onClick={onClick}>
      {clientId}
    </GridTableCell>
  );
};

export default ClientsTable;
