import { FunctionComponent, useState } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Loader from 'src/components/Custom/Loader';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Textarea from 'src/components/UI/Textarea';

export const CancelModal: FunctionComponent<{
  opened: boolean;
  setOpened: (v: boolean) => void;
  isLoading: boolean;
  onSave: (obj: { value: string; description: string }) => void;
}> = ({ opened, setOpened, isLoading, onSave }) => {
  const [value, setValue] = useState('');
  const [description, setDescription] = useState('');

  const options = [
    { value: 'fail', title: 'Ошибочный заказ' },
    { value: 'client-refuse', title: 'Клиент октазался' },
    { value: 'no-driver', title: 'Нет водителя' },
    { value: 'accident', title: 'Авария' },
    { value: 'other', title: 'Другое' },
  ];

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow title={'Отмена'} setState={e => setOpened(e as boolean)}>
        <div>
          {options.map(option => (
            <div
              className="text-sm flex items-center cursor-pointer mb-2"
              onClick={() => setValue(option.value)}
            >
              <div
                className="flex items-center justify-center w-5 h-5 rounded-full mr-2"
                style={{
                  border: '1px solid',
                  borderColor: option.value === value ? '#1066B1' : 'rgba(0, 0, 0, 0.1)',
                }}
              >
                {option.value === value && (
                  <div className="w-2 h-2 rounded-full" style={{ backgroundColor: '#1066B1' }} />
                )}
              </div>
              <p style={{ color: option.value === value ? '#000000' : 'rgba(0, 0, 0, 0.5)' }}>
                {option.title}
              </p>
            </div>
          ))}

          <div className="mt-3">
            <p className="text-[#808080] text-sm mb-1">Комментарий (обязательно)</p>
            <div className="w-full">
              <Textarea value={description} setValue={setDescription} />
            </div>
          </div>

          {isLoading ? (
            <Loader size={100} />
          ) : (
            <div className="w-[320px] text-[1.1em] mt-6">
              <ButtonSPrimary
                disabled={!value || !description}
                onClickHandler={() => onSave({ value, description })}
              >
                Сохранить
              </ButtonSPrimary>
            </div>
          )}
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};
