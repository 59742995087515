import { FunctionComponent } from 'react';
import { GridTable, GridTableCell } from 'src/components/GridTable';
import { getFormattedDate } from 'src/utils/format-date';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';

interface IClientsTable {
  autos: Array<any>;
  isLoading: boolean;
  sort: Array<string>;
  selected: IExportSelected;

  onAutoSelect: (newSelected: IExportSelected) => any;
  onEditClick?: (auto: any) => any;
  onTrashClick?: (auto: number) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const AutosListTable: FunctionComponent<IClientsTable> = ({
  autos,
  isLoading = false,
  sort = [],
  selected = [],

  onAutoSelect = () => {},
  onEditClick = () => {},
  onTrashClick = () => {},
  onSortUpdate = () => {},
}) => {
  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: onAutoSelect,
      additionalParams: { width: '30px' },
    }),

    id: {
      label: 'ID',
      width: '70px',
      component: (id: number) => (
        <GridTableCell className="text-[#1066B1] cursor-default">{id}</GridTableCell>
      ),
    },

    name: { label: 'Название' },
    length: { label: 'Длина' },
    width: { label: 'Ширина' },
    height: { label: 'Высота' },
    weight: { label: 'Вес' },

    updated: {
      label: 'Дата изменения',
      sortBy: 'sort_date',
      formatter: (date: string) => {
        return date ? getFormattedDate(date, 'MM/DD/YYYY, hh:mm:ss A') : '';
      },
    },

    events: {
      component: (v: any, index: number, auto: any) => (
        <GridTableCell>
          <div className="grid grid-cols-2 gap-8 m-auto">
            <div className="m-auto cursor-pointer" onClick={() => onEditClick(auto)}>
              <img src="/assets/img/pencil.svg" className="w-[1.25rem] h-[1.25rem]" alt="" />
            </div>
            <div className="m-auto cursor-pointer" onClick={() => onTrashClick(auto)}>
              <img src="/assets/img/delete.svg" className="w-[1.25rem] h-[1.25rem]" alt="" />
            </div>
          </div>
        </GridTableCell>
      ),
    },
  };

  return (
    <GridTable
      data={autos}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default AutosListTable;
