import { FunctionComponent, useState, useEffect } from 'react';
import { Marker, MarkerProps, InfoWindow } from '@react-google-maps/api';

interface IMarkerWithWindow extends MarkerProps {
  isAlwaysOpened?: boolean;
  onClick?: () => void;
}

const MarkerWithWindow: FunctionComponent<IMarkerWithWindow> = ({
  children,
  isAlwaysOpened = false,
  onClick = () => {},
  ...options
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    if (isAlwaysOpened) {
      setShowInfoWindow(true);
    }
  }, [showInfoWindow, isAlwaysOpened]);

  return (
    <Marker
      {...options}
      onClick={() => {
        setShowInfoWindow(true);
        onClick();
      }}
    >
      {showInfoWindow && (
        <InfoWindow onCloseClick={() => setShowInfoWindow(false)}>{children}</InfoWindow>
      )}
    </Marker>
  );
};

export default MarkerWithWindow;
