import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/Custom/Loader';
import Dropdown from 'src/components/UI/Dropdown';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import { isPermitted } from 'src/utils/permissions';
import { DriverConnectionStatus } from 'src/components/Driver/ConnectionStatus';

export const Driver: FunctionComponent<any> = ({ data = {}, onEditClick = () => {} }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <p className="text-[#333333] font-bold text-[13px]">Водитель и авто</p>
        {isPermitted('orders_change_driver') && (
          <img
            src="/assets/img/pencil.svg"
            alt="change"
            className="cursor-pointer"
            onClick={onEditClick}
          />
        )}
      </div>
      {data && data.id ? (
        <div className="flex">
          <div className="flex flex-col items-center">
            <img
              src={data?.avatar}
              alt="avatar"
              className="min-w-[22px] h-[22px] rounded-full object-cover"
            />
            <DriverConnectionStatus
              connection_lost={data.connection_lost}
              connection_lost_at={data.connection_lost_at}
              className="mt-1"
            />
          </div>
          <div className="ml-[6px]">
            <p>
              {data?.lastName} {data?.firstName}
            </p>
            <div className="flex">
              <p
                className="underline text-[#3072C4] cursor-pointer text-[9px]"
                onClick={() => navigate(`/drivers/list/${data?.id}/general`)}
              >
                {data?.id}
              </p>
              <p className="text-[#999999] text-[9px] ml-1">{data?.phoneNumber}</p>
            </div>
            <p className="text-[#999999] text-[9px]">
              {data?.car_brand} {data?.car_model}({data?.trailer_type})
            </p>
          </div>
        </div>
      ) : (
        <p>Нет водителя</p>
      )}
    </>
  );
};

export const DriverChangeModal: FunctionComponent<any> = ({
  opened = false,
  setOpened,
  data,
  isLoading = false,
  onSave = () => {},
}) => {
  const [driver, setDriver] = useState();

  useEffect(() => {
    setDriver(data);
  }, [opened, data]);

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow title="Водитель" setState={setOpened}>
        <div className="flex flex-col items-center">
          <Dropdown value={driver} placeholder="Водитель" type="drivers" onSelect={setDriver} />
          {isLoading ? (
            <Loader size={100} />
          ) : (
            <div className="w-[320px] text-[1.1em] mt-6">
              <ButtonSPrimary onClickHandler={() => onSave(driver)}>Сохранить</ButtonSPrimary>
            </div>
          )}
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};
