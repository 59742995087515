import React, { FunctionComponent } from 'react';

interface IRadioLOption {
  title: string;
  value: any;
  disabled?: boolean;
}

interface IRadioList {
  value: any;
  options: Array<IRadioLOption>;
  onChange?: (opt: any) => any;
}

const RadioList: FunctionComponent<IRadioList> = ({
  value = null,
  options = [],
  onChange = () => {},
}) => {
  return (
    <>
      {options.map(option => (
        <label
          key={option.value}
          className={`form-check-label inline-block text-gray-800 mr-3 ${
            option.disabled && 'opacity-50'
          }`}
        >
          <div className="form-check mt-1">
            <input
              className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              onChange={() => {
                if (!option.disabled) onChange(option);
              }}
              checked={option.value === value}
            />
            <span className="cursor-pointer">{option.title}</span>
          </div>
        </label>
      ))}
    </>
  );
};

export default RadioList;
