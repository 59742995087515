import { FunctionComponent } from 'react';
import { GridTableCell } from 'src/components/GridTable';
import Select from 'src/components/UI/Select';

const statuses = [
  { label: 'Утвержден', color: '#e9f7ef', textColor: '#27ae60' },
  { label: 'Заполнение', color: '#f6f0ff' },
  { label: 'На модерации' },
  { label: 'Заблокирован', color: '#fde6e6', textColor: '#ee0202' },
  { label: 'Повторная модерация' },
];

interface IStatusSelectCell {
  value: string;
  disabled?: boolean;
  onSelect: (newSelected: Array<any>) => any;
}

const StatusSelectCell: FunctionComponent<IStatusSelectCell> = ({
  value = 'Утвержден',
  disabled = false,
  onSelect = () => {},
}) => {
  return (
    <GridTableCell>
      <Select
        options={statuses}
        value={statuses.find(({ label }) => value === label)}
        disabled={disabled}
        placeholder="Статус"
        defaultColor="#f2e9ff"
        defaultTextColor="#7f22fd"
        maxHeight={12}
        onSelect={onSelect}
      />
    </GridTableCell>
  );
};

export default StatusSelectCell;
