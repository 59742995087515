import { FunctionComponent, useEffect, useState } from 'react';
import Input from 'src/components/UI/Input';

interface IThirdRegistrationStep {
  formData: any;
  onFormDataFieldChange?: (value: any, field: string) => any;
  setError: (msg: string) => void;
  addFile: (
    e: any,
    types: Array<string>,
    onSuccess: (f: any) => void,
    onError: (f: any) => void
  ) => void;
}

const pdfFileFields = [
  { field: 'coi', title: 'COI - certificcate of insurance (pdf)' },
  { field: 'motor', title: 'Motor Carrier Authorization (pdf)' },
];

const ownerIdFields = [
  { field: 'owners_id_front', title: 'Передняя страница' },
  { field: 'owners_id_back', title: 'Задняя страница' },
];

const ThirdRegistrationStep: FunctionComponent<IThirdRegistrationStep> = ({
  formData,
  onFormDataFieldChange = () => {},
  setError = () => {},
  addFile = () => {},
}) => {
  const [ownerIdPagePaths, setOwnerIdPagePaths] = useState<any>({
    owners_id_front: '',
    owners_id_back: '',
  });

  useEffect(() => {
    setOwnerIdPagePaths({
      owners_id_front:
        formData.owners_id_front === null ? '' : URL.createObjectURL(formData.owners_id_front),
      owners_id_back:
        formData.owners_id_back === null ? '' : URL.createObjectURL(formData.owners_id_back),
    });
  }, [formData.owners_id_back, formData.owners_id_front]);

  return (
    <>
      <div className="w-[320px] mt-4">
        <p className="mb-2">W9 документ (pdf)</p>
        {formData.w9 ? (
          <div className="flex justify-between">
            <p className="font-semibold text-[#1066B1] mr-2">
              {formData.w9?.name.length > 25 ? `${formData.w9?.name.slice(0, 25)}...` : formData.w9.name}
            </p>
            <p
              className="font-semibold text-[#EE0202] cursor-pointer"
              onClick={() => onFormDataFieldChange(null, 'w9')}
            >
              Удалить
            </p>
          </div>
        ) : (
          <label>
            <p className="text-[#1066B1] text-sm font-semibold cursor-pointer">+ Добавить</p>
            <input
              type="file"
              accept="application/pdf"
              className="hidden"
              onChange={e =>
                addFile(
                  e,
                  ['application/pdf'],
                  file => onFormDataFieldChange(file, 'w9'),
                  () => setError(`Недопустимый формат документа w9`)
                )
              }
            />
          </label>
        )}
      </div>
      <div className="w-[320px] mt-4">
        <p className="mb-2">ID владельца (фото)</p>
        {ownerIdFields.map(el => (
          <div
            key={el.field}
            className="w-full flex flex-col items-center border-2 border-dashed rounded-lg p-4 mb-4"
          >
            {formData[el.field] ? (
              <div className="grid grid-cols-[100%] grid-rows-[100%] max-w-[80%] h-[auto] w-auto">
                <div
                  className="flex items-center justify-center row-[1_/_2] col-[1_/_2] bg-[#ffffff7a] opacity-0 hover:opacity-100 z-10 cursor-pointer"
                  onClick={() => onFormDataFieldChange(null, el.field)}
                >
                  <img src="/assets/img/close.svg" alt="delete" />
                </div>
                <img
                  src={ownerIdPagePaths[el.field]}
                  alt="front"
                  className="row-[1_/_2] col-[1_/_2] object-cover h-[auto] max-w-[100%]"
                />
              </div>
            ) : (
              <>
                <div className="mb-4">{el.title}</div>
                <label>
                  <p className="py-[5px] px-7 bg-[#1066B1] text-white rounded-lg border border-[#4F7E9F] cursor-pointer">
                    + Добавить фото
                  </p>
                  <input
                    type="file"
                    accept="image/jpeg,image/png"
                    className="hidden"
                    onChange={e =>
                      addFile(
                        e,
                        ['image/jpeg', 'image/png'],
                        file => onFormDataFieldChange(file, el.field),
                        () => setError('Недопустимый формат страницы id владельца')
                      )
                    }
                  />
                </label>
              </>
            )}
          </div>
        ))}
      </div>
      {pdfFileFields.map(el => (
        <div className="w-[320px] mt-4" key={el.field}>
          <p className="mb-2">{el.title}</p>
          {formData[el.field] ? (
            <div className="flex justify-between">
              <p className="font-semibold text-[#1066B1] mr-2">
                {formData[el.field]?.name.length > 25
                  ? `${formData[el.field]?.name.slice(0, 25)}...`
                  : formData[el.field].name}
              </p>
              <p
                className="font-semibold text-[#EE0202] cursor-pointer"
                onClick={() => onFormDataFieldChange(null, el.field)}
              >
                Удалить
              </p>
            </div>
          ) : (
            <label>
              <p className="text-[#1066B1] text-sm font-semibold cursor-pointer">+ Добавить</p>
              <input
                type="file"
                accept="application/pdf"
                className="hidden"
                onChange={e =>
                  addFile(
                    e,
                    ['application/pdf'],
                    file => onFormDataFieldChange(file, el.field),
                    () => setError(`Недопустимый формат документа ${el.field}`)
                  )
                }
              />
            </label>
          )}
        </div>
      ))}
      <div className="w-[320px] mt-4">
        <p className="mb-2">DOT/MC</p>
        <Input
          placeholder="DOT/MC"
          value={formData.dot_mc}
          onChange={dotMc => onFormDataFieldChange(dotMc, 'dot_mc')}
        />
      </div>
    </>
  );
};

export default ThirdRegistrationStep;
