import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import MainSection from 'src/components/Semantic/MainSection';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Pagination from 'src/components/UI/Pagination';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import Select from 'src/components/UI/Select';
import Tooltip from 'src/components/UI/Tooltip';
import CheckList from 'src/components/UI/CheckList';
import { FilterButton, FilterBar } from 'src/components/FilterBar';

import CompanyPaymentsTable from 'src/components/Tables/companies/payments';
import ExportModal from 'src/components/ExportModal';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';

const exportColumns = [
  { title: 'Компания', field: 'company_name' },
  { title: 'Статуc', field: 'status' },
  { title: 'Заказов', field: 'countOrder' },
  { title: 'Период', field: 'period' },
  { title: 'Сумма', field: 'summa' },
];

const statuses = [
  { label: 'Отложена', value: 'postponed', id: 1, color: '#f2e9ff', textColor: '#7f22fd' },
  { label: 'Выплачено', value: 'paid', id: 3 },
  { label: 'Одобрена', value: 'approved', id: 2, color: '#e9f7ef', textColor: '#27ae60' },
  { label: 'Отменена', value: 'canceled', id: 4, color: '#fde6e6', textColor: '#ee0202' },
];

interface IPayment {
  id: number;
  company_id?: null | string;
  company_logo?: null | string;
  company_name?: null | string;
  currency?: string | null;
  status?: 'wait' | 'done' | 'approved' | 'cancelled';
  orderCount?: number | string | null;
  period?: string | null;
  summa?: number | string | null;
}

const CompaniesPaymentsList: FunctionComponent = () => {
  const filtersButtonRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingRow, setLoadingRow] = useState<number>(NaN);

  const [payments, setPayments] = useState<Array<IPayment>>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_status', 'asc']);
  const [filterOpened, setFilterOpened] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({
    status: [],
    driverId: '',
    orderId: '',
  });
  const [selected, setSelected] = useState<IExportSelected>([]);
  const [exportModalOpened, setExportModalOpened] = useState<boolean>(false);
  const [exportTableColumns, setExportTableColumns] = useState<Array<any>>(
    exportColumns.map(c => c.field)
  );

  const getParsedFilters = () => {
    let answer: any = {};

    if (filters.orderId) {
      answer.order = `${filters.orderId}`;
    }

    if (filters.driverId) {
      answer.driver = `${filters.driverId}`;
    }

    if (filters.status.length) {
      answer.status = filters.status.join(',');
    }

    return answer;
  };

  const getPayments = async (currentPage: number, withLoading: boolean = true) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    if (withLoading) setIsLoading(true);

    setSelected([]);

    try {
      const { data: res }: any = await axiosClient.get(`/transactions`, {
        params: {
          page: selectedPage,
          ...getParsedFilters(),
          [sort[0]]: sort[1],
        },
      });

      setPayments(res.data);
      setTotal(Math.ceil(res.links.total / 10));
    } catch (e: any) {
      logger.error(`Ошибка при получении выплат ${e.message}`, e);
    } finally {
      if (withLoading) setIsLoading(false);
    }
  };

  const setPaymentStatus = async (id: number, status: any, rowIndex: number = NaN) => {
    setLoadingRow(rowIndex);

    try {
      await axiosClient.put(`/transactions/${id}/change-status`, {
        status: String(status),
      });
    } catch (e: any) {
      logger.error(`Ошибка при смене статуса ${e.message}`, e);
    } finally {
      await getPayments(page, false);
      setLoadingRow(NaN);
    }
  };

  const setPaymentsGroupStatus = async (status: number) => {
    if (!selected.length) return;
    if (selected === 'all') return;

    setIsLoading(true);
    try {
      await axiosClient.put('/transactions/group/change-status', {
        ids: selected.join(','),
        status: String(status),
      });
    } catch (e: any) {
      logger.error(`Ошибка при смене статуса ${e.message}`, e);
    } finally {
      await getPayments(page);
      setIsLoading(false);
    }
  };

  const dropFilters = () => {
    setFilters({
      status: [],
      driverId: '',
      orderId: '',
    });
    getPayments(1);
  };

  useEffect(() => {
    getPayments(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);
  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
              <div className="flex items-center">
                <h2 className="text-[24px] text-[#363B39] font-bold">Выплаты компаниям</h2>
              </div>
              <div className="flex justify-end items-center">
                <p className="text-[12px] mr-2 text-[#9b9d9c]">
                  {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
                </p>
                <div className="mr-6">
                  <ButtonSPrimary onClickHandler={() => setExportModalOpened(true)}>
                    Экспорт в Excel
                  </ButtonSPrimary>
                </div>
                <div className="mr-6 w-36">
                  <Select
                    options={statuses}
                    value="статус"
                    onSelect={status => setPaymentsGroupStatus(status.id)}
                    disabled={selected === 'all'}
                  />
                </div>

                <FilterButton
                  opened={filterOpened}
                  buttonRef={filtersButtonRef}
                  filtersIsEmpty={
                    !filters.driverId.length && !filters.orderId.length && !filters.status.length
                  }
                  onOpen={() => setFilterOpened(true)}
                  onClose={() => setFilterOpened(false)}
                >
                  <Tooltip
                    parentRef={filtersButtonRef}
                    disabled={
                      !filters.driverId.length && !filters.orderId.length && !filters.status.length
                    }
                    align="left"
                    className="w-[15em]"
                  >
                    {!!filters.driverId.length && (
                      <div className="mb-1">
                        <p className="text-sm text-[#9f9f9f]">ID Водителя</p>
                        <p className="text-sm">{filters.driverId}</p>
                      </div>
                    )}
                    {!!filters.orderId.length && (
                      <div className="mb-1">
                        <p className="text-sm text-[#9f9f9f]">ID Заказа</p>
                        <p className="text-sm">{filters.orderId}</p>
                      </div>
                    )}
                    {!!filters.status.length && (
                      <div>
                        <p className="text-sm text-[#9f9f9f]">Статусы</p>
                        <div className="flex flex-col">
                          {statuses
                            .filter(status => filters.status.includes(status.id))
                            .map(status => (
                              <p className="text-sm" key={status.id}>
                                {status.label}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </Tooltip>
                </FilterButton>

                <FilterBar
                  setOpened={setFilterOpened}
                  opened={filterOpened}
                  onClear={dropFilters}
                  onApply={() => getPayments(1)}
                >
                  <div className="mt-4">
                    <CheckList
                      selected={filters.status}
                      options={statuses}
                      onChange={selectedStatuses => setFilters({ ...filters, status: selectedStatuses })}
                    />
                    {/* <div className="mt-3">
                      <p className="text-[#808080] text-sm">ID водителя</p>
                      <div className="w-full mt-2 text-sm">
                        <Input
                          className="w-full"
                          value={filters.driverId}
                          onChange={value => setFilters({ ...filters, driverId: value })}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="text-[#808080] text-sm">ID заказа</p>
                      <div className="w-full mt-2 text-sm">
                        <Input
                          className="w-full"
                          value={filters.orderId}
                          onChange={value => setFilters({ ...filters, orderId: value })}
                        />
                      </div>
                    </div> */}
                  </div>
                </FilterBar>
              </div>
            </div>
            <div className="overflow-auto bg-white">
              <CompanyPaymentsTable
                payments={payments}
                isLoading={isLoading}
                loadingRow={loadingRow}
                sort={sort}
                selected={selected}
                onPaymentSelect={setSelected}
                onPaymentStatusUpdate={setPaymentStatus}
                onSortUpdate={setSort}
              />

              {!isLoading && payments.length > 0 && (
                <Pagination
                  totalPages={total}
                  activePage={page}
                  setPage={setPage}
                  alignment="justify-end"
                />
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ExportModal
          opened={exportModalOpened}
          isLoading={isLoading}
          selected={exportTableColumns}
          options={exportColumns}
          setSelected={setExportTableColumns}
          setOpened={setExportModalOpened}
          exportParams={{
            url: '/export/transactions',
            filename: 'companies-payments.xlsx',
            fields: exportTableColumns,
            filters: {},
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default CompaniesPaymentsList;
