import { toast } from 'react-toastify';

const logger = {
  success(message: string) {
    toast.success(message);
  },

  error(message: string, err: any = null) {
    let result: string = message;

    switch (err?.response?.status) {
      case 403:
        result = 'У вас нет прав доступа на выполнение этой операции';
        break;
    }

    toast.error(result);
  },
};

export default logger;
