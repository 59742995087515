import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import OrderInfoCell from '../BASIC_CELLS/OrderInfoCell';
import UserLockCell from '../BASIC_CELLS/UserLockCell';

interface ILegalsManagersListTable {
  managers: Array<any>;
  isLoading: boolean;
  sort?: Array<string>;

  onSortUpdate?: (newSort: Array<string>) => any;
  onManagerStatusUpdate?: (managerId: number, newStatus: number) => Promise<void>;
}

const LegalsManagersListTable: FunctionComponent<ILegalsManagersListTable> = ({
  managers,
  isLoading = false,
  sort = [],

  onSortUpdate = () => {},
  onManagerStatusUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    user: {
      label: 'Пользователь',
      sortBy: 'sort_id',

      component: (v: any, index: number, manager: any) => (
        <GridTableCell>
          <div className="flex flex-col">
            <p className="text-[12px]">{manager.fullName}</p>
            <div className="flex items-center">
              <p
                className="text-[#1066B1] underline text-[10px] cursor-pointer"
                onClick={e => {
                  e.stopPropagation();
                  navigate(`/clients/list/${manager.id}/general`);
                }}
              >
                #{manager.id}
              </p>
            </div>
          </div>
        </GridTableCell>
      ),
    },

    lastOrder: {
      label: 'Последний заказ',
      sortBy: 'sort_last_order',

      component: (lastOrder: any) =>
        lastOrder ? (
          <OrderInfoCell
            order={lastOrder}
            fromPath={lastOrder.fromPath}
            toPath={lastOrder.toPath}
            status={lastOrder.status}
            price={lastOrder.price}
            onClick={() => navigate(`/order/${lastOrder?.id}`)}
          />
        ) : (
          <GridTableCell>-</GridTableCell>
        ),
    },

    orders_count: {
      label: 'Заказов',
      sortBy: 'sort_orders_count',
    },

    events: {
      component: (v: any, i: number, manager: any) => {
        return (
          <UserLockCell
            locked={manager.is_blocked === 1}
            onLock={() => onManagerStatusUpdate(manager.id, 1)}
            onUnlock={() => onManagerStatusUpdate(manager.id, 0)}
          />
        );
      },
    },
  };

  return (
    <div className="pl-3">
      <GridTable
        data={managers}
        fields={TableFields}
        isLoading={isLoading}
        sort={sort}
        onSortUpdate={onSortUpdate}
      />
    </div>
  );
};

export default LegalsManagersListTable;
