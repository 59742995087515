import React, { FunctionComponent } from 'react';

interface IButton {
  disabled?: any;
  onClickHandler?: () => any;
  variant?: 'default' | 'danger' | 'primary';
}

const DefaultButton: FunctionComponent<IButton> = ({ disabled, children }) => {
  return (
    <button className="w-full max-w-xs py-2 px-4 rounded-sm border border-[#C3C3C3] border-b-[#A5A5A5] bg-gradient-to-b from-white to-[#EBEBEB] cursor-pointer">
      <span className="text-sm">{children}</span>
    </button>
  );
};

const DangerButton: FunctionComponent<IButton> = ({ disabled, children }) => {
  return (
    <button className="w-full max-w-xs py-2 px-4 bg-[#DE4A2E] rounded-sm border border-[#C6584D] cursor-pointer text-white">
      <span className="text-sm">{children}</span>
    </button>
  );
};

const PrimaryButton: FunctionComponent<IButton> = ({ disabled, children }) => {
  return (
    <button
      className={`w-full max-w-xs py-2 px-4 bg-[#006BB2] rounded-sm border border-[#006BB2] cursor-pointer text-white ${
        disabled() && 'bg-[#ccc] border-[#ccc] cursor-not-allowed'
      }`}
    >
      <span className="text-sm">{children}</span>
    </button>
  );
};

const Button: FunctionComponent<IButton> = ({
  disabled = () => false,
  onClickHandler = () => {},
  children,
  variant = 'default',
}) => {
  return (
    <>
      <div
        style={{ opacity: disabled() ? 0.5 : 1 }}
        className="w-full"
        onClick={() => {
          if (!disabled()) {
            onClickHandler();
          }
        }}
      >
        {variant === 'default' && <DefaultButton disabled={disabled}>{children}</DefaultButton>}
        {variant === 'danger' && <DangerButton disabled={disabled}>{children}</DangerButton>}
        {variant === 'primary' && <PrimaryButton disabled={disabled}>{children}</PrimaryButton>}
      </div>
    </>
  );
};

export default Button;
