import React, { FunctionComponent } from 'react';

interface ICard {
  className: string;
}

const Card: FunctionComponent<ICard> = ({ children, className }) => {
  return (
    <div
      className={`bg-[#fff] p-3 ${className}`}
      style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)' }}
    >
      {children}
    </div>
  );
};

export default Card;
