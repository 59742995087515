import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import TimeCell from '../BASIC_CELLS/TimeCell';

interface IDriversViolationsTable {
  violations: Array<any>;
  isLoading?: boolean;
  sort: Array<string>;
  selected: IExportSelected;

  onViolationSelect: (newSelected: IExportSelected) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const violationTypesIcons = {
  rest: 'bed',
  warning: 'warning',
  'driver-not-online': 'warning',
  speed: 'speed',
  speeding: 'speed',
  night: 'night',
  'night-rides': 'night',
};

const DriversViolationsTable: FunctionComponent<IDriversViolationsTable> = ({
  violations,
  isLoading = false,
  sort = [],
  selected = [],

  onViolationSelect = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: onViolationSelect,
    }),

    date: {
      label: 'Время',
      sortBy: 'sort_date',
      component: (date: string) => <TimeCell date={new Date(date)} />,
    },

    type: {
      label: 'Нарушение',
      sortBy: 'sort_message',
      component: (type: string, index: number, violation: any) => {
        return (
          <GridTableCell>
            {violationTypesIcons[type] && (
              <img
                className="p-[7px] bg-[#EDEDED] rounded-full w-[27px] h-[27px]"
                src={`/assets/img/${violationTypesIcons[type]}.svg`}
                alt=""
              />
            )}
            <p className="ml-[5px] text-sm">{violation?.violation || ''}</p>;
          </GridTableCell>
        );
      },
    },

    order: {
      label: 'ID заказа',
      sortBy: 'sort_order',
      component: (orderId: number | string) => (
        <GridTableCell
          className="text-[#1066B1] text-xs underline"
          onClick={() => navigate(`/order/${orderId}`, { replace: true })}
        >
          #{orderId}
        </GridTableCell>
      ),
    },
  };

  return (
    <GridTable
      data={violations}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default DriversViolationsTable;
