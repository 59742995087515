import { FunctionComponent } from 'react';
import Input from 'src/components/UI/Input';

interface IFirstRegistrationStep {
  formData: any;
  onFormDataFieldChange?: (value: any, field: string) => any;
}

const FirstRegistrationStep: FunctionComponent<IFirstRegistrationStep> = ({
  formData,
  onFormDataFieldChange = () => {},
}) => {
  return (
    <>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Email</p>
        <Input
          placeholder="Email"
          value={formData.email}
          onChange={mail => onFormDataFieldChange(mail, 'email')}
        />
      </div>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Пароль</p>
        <Input
          placeholder="Пароль"
          type="password"
          value={formData.password}
          onChange={pass => onFormDataFieldChange(pass, 'password')}
        />
      </div>
      <div className="w-[320px] mt-4">
        <p className="mb-2">Повторите пароль</p>
        <Input
          placeholder="Повторите пароль"
          type="password"
          value={formData.password_confirmation}
          onChange={passConfirmation => onFormDataFieldChange(passConfirmation, 'password_confirmation')}
        />
      </div>
    </>
  );
};

export default FirstRegistrationStep;
