import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import TheSideNavbar from 'src/components/TheSideNavbar';
import { IStore } from 'src/redux';

const VerticalLayout: FunctionComponent = ({ children }) => {
  const { sidebarOpen } = useSelector((store: IStore) => store.layout);
  return (
    <div className="flex w-full">
      <TheSideNavbar />
      <div
        className={`${
          sidebarOpen ? 'ml-[200px]' : 'ml-[65px]'
        } bg-white transition-all duration-200 w-full min-h-[100vh]`}
      >
        {children}
      </div>
    </div>
  );
};

export default VerticalLayout;
