import moment from 'moment';

const getUtcOffset = (): number => {
  const timezone = localStorage.getItem('timezone') ?? `GMT${new Date().getTimezoneOffset() / 60}`;
  const utcOffset = Number(timezone.slice(3)) * 60;
  return utcOffset;
};

export const getDateString = (d: string) => {
  return moment(d).locale('en').utcOffset(getUtcOffset()).format('ddd DD.MM');
};

export const getTimeString = (d: string) => {
  return moment.utc(d).locale('en').utcOffset(getUtcOffset()).format('hh:mm A');
};

export const parseDate = (d: string, format = 'DD.MM.YYYY HH:mm') => {
  return moment.utc(d, format).utcOffset(getUtcOffset()).locale('en').toDate();
};

export const getFormDate = (date_send, time_send) => {
  if (!date_send || !time_send) {
    return moment().utcOffset(getUtcOffset()).toDate();
  }
  return moment(`${date_send.year}-${date_send.month}-${date_send.day} ${time_send}`, 'YYYY-MM-DD HH:mm')
    .utcOffset(getUtcOffset())
    .toDate();
};

export const parseDateFormat = (d: string, format: string, isReverse = false): string => {
  return moment
    .utc(d, format)
    .utcOffset(getUtcOffset() * (isReverse ? -1 : 1))
    .locale('en')
    .format(format);
};

export const getFormattedDate = (d: Date | string, format: string): string => {
  return moment(d).utcOffset(getUtcOffset()).locale('en').format(format);
};

export const toDate = (d: Date): Date => {
  return moment(d).utcOffset(getUtcOffset()).toDate();
};

export const timeGone = time => {
  return moment.utc(time).locale('ru').from(moment());
};

export const getDiffInSeconds = (date: string): number => {
  return moment
    .utc()
    .utcOffset(getUtcOffset())
    .diff(moment.utc(date, 'YYYY-MM-DD HH:mm:ss').utcOffset(getUtcOffset()), 'seconds');
};
