import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Select from 'src/components/UI/Select';
import {
  PaymentStatus,
  TransactionForm,
  TransactionType,
  paymentStatuses,
  paymentStatusesWaiting,
  transactionTypeStatuses,
} from './transaction-form';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import { TransactionTable } from './transaction-table';
import axiosClient from 'src/libs/axiosClient';
import logger from 'src/utils/logger';
import { isPermitted } from 'src/utils/permissions';

export const Price: FunctionComponent<{
  orderId: number;
  title: string;

  onEditClick: () => void;
  type: 'client' | 'driver';

  onUpdated: () => void;

  totalPrice: string;
  carsCount?: number;
}> = ({
  orderId,
  title,

  onEditClick = () => {},
  type,

  onUpdated,

  totalPrice,
  carsCount,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState<any[]>([]);

  const getPriceInfo = async (withLoading = true) => {
    if (withLoading) {
      setIsLoading(true);
    }

    try {
      let { data } = await axiosClient.get(`/orders/${orderId}/transactions`, {
        params: {
          transaction_type: type,
        },
      });

      setTransactions(data.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const [isTransactionListOpened, setIsTransactionListOpened] = useState(false);
  const [toUpdatePaymentStatus, setToUpdatePaymentStatus] = useState<PaymentStatus | null>(null);

  const totalPayed = useMemo(() => {
    const lastTransaction = transactions.filter(e => e.status === 4)[0];

    if (!lastTransaction) {
      return '0';
    }

    return String(Number(totalPrice) - Number(lastTransaction.remainder)) ?? '0';
  }, [transactions, totalPrice]);

  const receipt = useMemo(() => {
    const lastTransaction = transactions.filter(e => !!e.payment_type)[0];

    if (!lastTransaction || lastTransaction.payment_type !== 2 || !lastTransaction?.data?.receipt) {
      return null;
    }

    return {
      link: lastTransaction.data.receipt,
      num: lastTransaction.data.order_transaction_payment_id,
    };
  }, [transactions]);

  const paymentType = useMemo(() => {
    const lastTransaction = transactions.filter(e => !!e.payment_type)[0];

    if (!lastTransaction) {
      return null;
    }

    return lastTransaction.payment_type;
  }, [transactions]);

  const paymentTypes = useMemo(() => {
    const lastTransaction = transactions.filter(e => !!e.payment_type)[0];

    if (!lastTransaction) {
      return null;
    }

    if (lastTransaction.payment_type === 2) {
      return [...transactionTypeStatuses, { id: 'stripe', title: 'Stripe' }];
    }

    return transactionTypeStatuses;
  }, [transactions]);

  const paymentStatus = useMemo(() => {
    const lastTransaction = transactions.filter(e => e.status !== 7)[0];

    if (!lastTransaction) {
      return null;
    }

    if (lastTransaction.remainder !== 0 && lastTransaction.amount < totalPrice) {
      return null;
    }

    return lastTransaction.status;
  }, [transactions, totalPrice]);

  const statuses = useMemo(() => {
    const lastTransaction = transactions.filter(e => e.status !== 7)[0];

    if (!lastTransaction) {
      return null;
    }

    if (lastTransaction.status === 3 || lastTransaction.status === 4) {
      return paymentStatuses;
    }

    return [...paymentStatusesWaiting, ...paymentStatuses];
  }, [transactions]);

  const openReceipt = () => {
    if (!receipt) {
      return;
    }

    window.open(receipt.link, '_blank', 'noreferrer');
  };

  const onTransactionTypeChange = (value: TransactionType) => {
    // api request
    onUpdated();
  };

  const onPaymentStatusChange = (value: PaymentStatus) => {
    setToUpdatePaymentStatus(value);
  };

  useEffect(() => {
    getPriceInfo();
  }, []);

  return (
    <>
      <div>
        <div className="flex justify-between">
          <p className="text-[#999999] mb-1">{title}</p>
          <p className="cursor-pointer text-[#1066B1]" onClick={() => setIsTransactionListOpened(true)}>
            Все транзакции
          </p>
        </div>

        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center">
            <p className="text-[14px] mr-1">
              ${totalPayed}/{totalPrice}
            </p>
            {isPermitted('orders_change_price') && (
              <img
                src="/assets/img/pencil.svg"
                alt="change"
                className="cursor-pointer"
                onClick={onEditClick}
              />
            )}
          </div>

          {receipt && (
            <div className="cursor-pointer flex items-center" onClick={() => openReceipt()}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1243 0.395702C11.1069 0.396252 11.0895 0.397773 11.0723 0.40026H8.33337C8.27153 0.399385 8.21013 0.41081 8.15275 0.433869C8.09536 0.456929 8.04313 0.491164 7.99909 0.534585C7.95505 0.578006 7.92008 0.629746 7.89621 0.686799C7.87234 0.743853 7.86005 0.805081 7.86005 0.866926C7.86005 0.928771 7.87234 0.99 7.89621 1.04705C7.92008 1.10411 7.95505 1.15585 7.99909 1.19927C8.04313 1.24269 8.09536 1.27692 8.15275 1.29998C8.21013 1.32304 8.27153 1.33447 8.33337 1.33359H10.0068L5.20342 6.13698C5.15863 6.17998 5.12287 6.23148 5.09824 6.28848C5.07361 6.34547 5.06059 6.40681 5.05996 6.46889C5.05933 6.53098 5.07109 6.59257 5.09456 6.65005C5.11803 6.70753 5.15273 6.75976 5.19663 6.80366C5.24054 6.84756 5.29276 6.88226 5.35024 6.90573C5.40772 6.9292 5.46931 6.94096 5.5314 6.94033C5.59348 6.9397 5.65482 6.92669 5.71181 6.90205C5.76881 6.87742 5.82031 6.84166 5.86331 6.79687L10.6667 1.99349V3.66693C10.6658 3.72877 10.6772 3.79016 10.7003 3.84755C10.7234 3.90493 10.7576 3.95716 10.801 4.0012C10.8444 4.04524 10.8962 4.08021 10.9532 4.10408C11.0103 4.12795 11.0715 4.14024 11.1334 4.14024C11.1952 4.14024 11.2564 4.12795 11.3135 4.10408C11.3705 4.08021 11.4223 4.04524 11.4657 4.0012C11.5091 3.95716 11.5434 3.90493 11.5664 3.84755C11.5895 3.79016 11.6009 3.72877 11.6 3.66693V0.926171C11.6093 0.858983 11.6039 0.790576 11.584 0.725714C11.5642 0.660853 11.5304 0.601094 11.4852 0.550601C11.4399 0.500107 11.3841 0.460091 11.3218 0.43334C11.2595 0.406589 11.1921 0.393745 11.1243 0.395702ZM1.80003 2.26693C1.28994 2.26693 0.866699 2.69016 0.866699 3.20026V10.2003C0.866699 10.7104 1.28994 11.1336 1.80003 11.1336H8.80003C9.31013 11.1336 9.73337 10.7104 9.73337 10.2003V5.53359V4.33047L8.80003 5.2638V6.46693V10.2003H1.80003V3.20026H5.53337H6.4667H6.73649L7.66982 2.26693H6.4667H5.53337H1.80003Z"
                  fill="#1066B1"
                />
              </svg>

              <span className="ml-1 text-[#999999]">№{receipt.num}</span>
            </div>
          )}
        </div>
        {carsCount && <p className="text-[#999999] mt-1 leading-3">Цена (за {carsCount} авто)</p>}

        <div className="mt-2 flex justify-between gap-4">
          {paymentTypes && (
            <Select
              value={paymentTypes.find(s => s.id === paymentType)}
              options={paymentTypes}
              defaultColor="#1066b126"
              defaultTextColor="#1066B1"
              label="title"
              onSelect={(value: any) => onTransactionTypeChange(value.id)}
            />
          )}

          {statuses && (
            <Select
              value={statuses.find(s => s.id === paymentStatus)}
              options={statuses}
              disabled={paymentStatus === 4}
              defaultColor="#1066b126"
              defaultTextColor="#1066B1"
              label="title"
              onSelect={(value: any) => onPaymentStatusChange(value.id)}
            />
          )}
        </div>
      </div>

      <ModalsSection>
        <TransactionTable
          orderId={orderId}
          type={type}
          opened={isTransactionListOpened}
          setOpened={v => setIsTransactionListOpened(v)}
          totalPayed={totalPayed}
          totalPrice={totalPrice}
          onTransactionCreated={() => onUpdated()}
        />

        <TransactionForm
          orderId={orderId}
          type={type}
          opened={!!toUpdatePaymentStatus}
          setOpened={() => setToUpdatePaymentStatus(null)}
          paymentStatus={toUpdatePaymentStatus as PaymentStatus}
          totalPayed={totalPayed}
          totalPrice={totalPrice}
          onTransactionCreated={() => onUpdated()}
        />
      </ModalsSection>
    </>
  );
};
