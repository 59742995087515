import { Dispatch, forwardRef, FunctionComponent, SetStateAction, useState } from 'react';
import Cross from '../Cross';
import EyeICO from './EyeICO';
import SearchICO from './SearchICO';
import CalendarICO from './CalendarICO';
import ClockICO from './ClockICO';

import './index.css';

interface IInput {
  type?: 'text' | 'password' | 'search' | 'number' | 'date' | 'time';
  value: string | number;
  min?: number | string;
  max?: number | string;
  theme?: 'primary' | 'danger' | 'success';
  placeholder?: string;
  withShadow?: boolean;
  rounded?: boolean;
  readonly?: boolean;
  clearable?: boolean;
  className?: string;
  style?: any;
  children?: any;
  ref?: any;
  onChange?: Dispatch<SetStateAction<string | any>>;
  error?: boolean;
}

interface IInputIcon {
  hided?: boolean;
  className?: string;
  children: any;
  onClick?: () => any;
}

const InputIcon: FunctionComponent<IInputIcon> = ({
  hided = false,
  className = '',
  onClick = () => {},
  children,
}) => {
  return (
    <div
      className={
        className +
        ` col-[1_/_2] row-[1_/_2] transition-all duration-200 w-full py-[0.35em] px-[0.75em] ${
          hided ? 'opacity-0 z-10' : 'opacity-100 z-20'
        }`
      }
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const Input: FunctionComponent<IInput> = forwardRef(
  (
    {
      value,
      min,
      max,
      type = 'text',
      theme = 'primary',
      className = '',
      style = {},
      placeholder = '',
      withShadow = true,
      rounded = true,
      readonly = false,
      clearable = false,
      children,
      onChange = () => {},
      error = false,
    },
    ref: any
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isPassHided, setIsPassHided] = useState<boolean>(true);

    const getType = (): string => {
      switch (type) {
        case 'number':
        case 'text':
        case 'time':
          return type;

        case 'password':
          return isPassHided ? type : 'text';
      }

      return 'text';
    };

    const changeListener = (event: any) => {
      const value: string | number = `${event?.target?.value || ''}`;

      if (type !== 'number') {
        onChange(value);
        return;
      }

      let newValue: string | number = value;
      if (min !== undefined && Number(newValue) < Number(min)) {
        if (newValue === '') {
          newValue = '';
        } else if (min === 0) {
          newValue = Number(newValue) * -1;
        } else {
          newValue = Number(min);
        }
      }

      if (max !== undefined && Number(newValue) > Number(max)) {
        newValue = Number(max);
      }

      onChange(newValue);
    };

    return (
      <div
        className={`${className} ${theme} ${rounded ? 'rounded-lg' : ''} ${
          isFocused ? 'focused' : ''
        } text-[0.9rem] flex items-center border-[1px] ${
          error ? 'border-red-400' : 'border-white'
        } w-full transition-all duration-200 overflow-hidden`}
        style={{ boxShadow: withShadow ? '0px 2px 6px 0px rgba(0, 0, 0, 0.2)' : '' }}
        onClick={() => {
          if (!ref?.current) {
            return;
          }

          if (type === 'time' || type === 'date') {
            ref.current.focus();

            if (type === 'time') {
              ref.current.showPicker();
            }
          }
        }}
      >
        {children}
        <input
          ref={ref}
          value={value}
          type={getType()}
          className="custom-input w-full px-[0.8em] py-[0.35em] outline-0"
          style={style}
          placeholder={placeholder}
          readOnly={readonly}
          onChange={changeListener}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <div className="grid grid-cols-[1fr] grid-rows-[1fr]">
          {clearable && (
            <InputIcon
              className="cursor-pointer"
              hided={type === 'password' || !`${value}`.length}
              onClick={() => onChange('')}
            >
              <Cross />
            </InputIcon>
          )}

          {type === 'search' && (
            <InputIcon hided={clearable && !!`${value}`.length}>
              <SearchICO />
            </InputIcon>
          )}

          {type === 'password' && (
            <InputIcon className="cursor-pointer" onClick={() => setIsPassHided(!isPassHided)}>
              <span className={isPassHided ? 'opacity-60' : 'opacity-100'}>
                <EyeICO />
              </span>
            </InputIcon>
          )}

          {type === 'date' && (
            <InputIcon className="cursor-pointer">
              <CalendarICO color={isFocused ? '#006bb2' : 'black'} />
            </InputIcon>
          )}

          {type === 'time' && (
            <InputIcon className="cursor-pointer">
              <ClockICO color={isFocused ? '#006bb2' : 'black'} />
            </InputIcon>
          )}
        </div>
      </div>
    );
  }
);

export default Input;
