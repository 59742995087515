import { FunctionComponent } from "react";
import { Oval } from "react-loader-spinner";

interface ILoader {
  size?: number;
}

const Loader: FunctionComponent<ILoader> = ({ size = 100 }) => (
  <div className="flex justify-center">
    <Oval
      ariaLabel="loading-indicator"
      height={size}
      width={size}
      strokeWidth={5}
      strokeWidthSecondary={1}
      color="#1066B1"
      secondaryColor="white"
    />
  </div>
);


export default Loader