import { FunctionComponent, useEffect, useState } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import logger from 'src/utils/logger';
import axiosClient from 'src/libs/axiosClient';

import MainSection from 'src/components/Semantic/MainSection';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import VerticalLayout from 'src/layouts/VerticalLayout';
import Tabs from 'src/components/Tabs';
import BreadCrumbs from 'src/components/UI/BreadCrumbs';
import ConfirmationModal from 'src/components/Custom/ConfirmationModal';
import Loader from 'src/components/Custom/Loader';

// Pages
import General from './general';
import LoginHistory from './loginHistory';
import AccessRights from './accessRights';
import ActionsHistory from './actionsHistory';
import { isPermitted } from 'src/utils/permissions';

const defaultAdmin = {
  email: '',
  username: '',
  name: '',
  surname: '',
  phone: '',
  avatar: '',
};

const Admin: FunctionComponent = () => {
  const { id } = useParams();

  const tabLinks = [{ title: 'Общие данные', link: `/settings/adminstrators/${id}/general` }];
  if (isPermitted('settings_admins_permissions')) {
    tabLinks.push({ title: 'Права доступа', link: `/settings/adminstrators/${id}/access-rights` });
  }
  if (isPermitted('settings_admins_history_actions')) {
    tabLinks.push({ title: 'История действий', link: `/settings/adminstrators/${id}/actions-history` });
  }
  if (isPermitted('settings_admins_history_login')) {
    tabLinks.push({ title: 'История входов', link: `/settings/adminstrators/${id}/login-history` });
  }

  // State
  const [admin, setAdmin] = useState<any>(defaultAdmin);
  const [errorsFields, setErrorsFields] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [blockModalOpened, setBlockModalOpened] = useState<boolean>(false);

  const [crumbs, setCrumbs] = useState<Array<any>>([
    { title: 'Администраторы', link: '/settings/adminstrators/' },
  ]);

  const updateCrumb = (index: number, newCrumb: any) => {
    let newCrumbs = crumbs;
    newCrumbs[index] = newCrumb;
    setCrumbs(newCrumbs);
  };

  const getAdmin = async () => {
    setIsLoading(true);

    try {
      const { data } = await axiosClient.get(`/admins/${id}`);

      if (data && data.id) {
        setAdmin(data);
        updateCrumb(1, {
          title: `${data.name} ${data.surname} (${data.username})`,
          link: tabLinks[0],
        });
      }
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const setAdminBlock = async (block: boolean) => {
    setIsLoading(true);

    if (admin.is_blocked === block) {
      return;
    }

    try {
      await axiosClient.post(`/admins/${id}/${block ? '' : 'un'}block`);
      await getAdmin();

      logger.success(`Админ успешно ${block ? 'за' : 'раз'}блокирован`);
    } catch (e: any) {
      logger.error(e?.response?.data?.message ?? e.message, e);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAvatar = async () => {
    setIsLoading(true);

    try {
      await axiosClient.post(`/admins/${id}/delete-avatar`);

      await getAdmin();

      logger.success('Аватар успешно удалён');
    } catch (e: any) {
      logger.error(`Ошибка при удалении аватара ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const addAvatar = async (newAvatar: any) => {
    const form = new FormData();
    form.append('avatar', newAvatar);

    try {
      await axiosClient.post(`/admins/${id}/upload-avatar`, form);
    } catch (e: any) {
      logger.error(`Ошибка при добавлении аватара ${e.message}`, e);
    }
  };

  const validateForm = (update: any) => {
    let message: string = '';
    const errors: any = {};
    if (!update.name) {
      errors.name = true;
    }
    if (!update.surname) {
      errors.surname = true;
    }
    if (!update.phone) {
      errors.phone = true;
    }
    if (!update.username) {
      errors.username = true;
    }

    if (Object.keys(errors).length > 0) {
      message = 'Все поля обязательны к заполнению';
    }

    if (
      !update.email ||
      !String(update.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      message = 'Адрес почты должен содержать знак @, латиницу и точку';
      errors.email = true;
    }

    if (!!message || Object.keys(errors).length > 0) {
      setErrorsFields(errors);
      logger.error(message);

      throw new Error('Validation error');
    }
  };

  const updateAdmin = async (update: any) => {
    validateForm(update);

    setIsLoading(true);

    try {
      let changes = Object.fromEntries(
        Object.entries(update).filter(([key, value]) => {
          if (key === 'email' || key === 'username') {
            return value !== admin[key];
          }
          return true; //value !== admin[key];
        })
      );

      const { avatar: newAvatar } = changes;
      delete changes.avatar;

      if (changes) {
        await axiosClient.patch(`/admins/${id}`, changes);
      }

      if (newAvatar instanceof File) {
        await addAvatar(newAvatar);
      }

      setErrorsFields({});

      await getAdmin();

      logger.success('Изменения успешно сохранены');
    } catch (e: any) {
      logger.error(e?.response?.data?.message ?? e.message, e);
      if (e?.response?.data?.errors) {
        setErrorsFields(
          Object.keys(e.response.data.errors).reduce((obj, key) => {
            obj[key] = true;
            return obj;
          }, {})
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateAdminFields = (obj: any) => {
    setErrorsFields(
      Object.keys(obj).reduce((o, k) => {
        o[k] = false;
        return o;
      }, errorsFields)
    );
    setAdmin(obj);
  };
  useEffect(() => {
    getAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] min-h-full pb-6`}>
            <BreadCrumbs crumbs={crumbs} />

            <div className="flex justify-between items-center border-b-[1px] border-[#EAEAEA] pl-[6px]">
              <Tabs links={tabLinks} />
              {isPermitted('settings_admins_block') ? (
                <p
                  className={`${
                    admin.is_blocked ? 'text-green-500' : 'text-[#FF4550]'
                  } text-xs mr-4 font-semibold cursor-pointer`}
                  onClick={() => setBlockModalOpened(!blockModalOpened)}
                >
                  {admin.is_blocked ? 'Разблокировать пользователя' : 'Заблокировать пользователя'}
                </p>
              ) : (
                <p
                  className={`${
                    admin.is_blocked ? 'text-[#FF4550]' : 'text-green-500'
                  } text-xs mr-4 font-semibold`}
                >
                  {admin.is_blocked ? 'Заблокирован' : 'Активен'}
                </p>
              )}
            </div>

            <Routes>
              <Route
                path="/general"
                element={
                  <General
                    admin={admin}
                    errorsFields={errorsFields}
                    setAdmin={updateAdminFields}
                    onDeleteAvatar={deleteAvatar}
                    onSave={updateAdmin}
                    isLoading={isLoading}
                  />
                }
              />
              <Route path="/login-history" element={<LoginHistory />} />
              <Route path="/actions-history" element={<ActionsHistory />} />

              <Route path="/access-rights" element={<AccessRights />} />
            </Routes>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ConfirmationModal
          opened={blockModalOpened}
          setOpened={setBlockModalOpened}
          type={admin.is_blocked ? 'primary' : 'danger'}
          title={`${admin.is_blocked ? 'Разблокировать' : 'Заблокировать'} данного пользователя?`}
          confirmButtonText={admin.is_blocked ? 'Разблокировать' : 'Заблокировать'}
          onConfirm={() => setAdminBlock(!admin.is_blocked)}
        >
          Если вы подтвердите данное действие, то этот пользователь{' '}
          {admin.is_blocked
            ? 'сможет авторизоваться в свой аккаунт'
            : 'не сможет авторизоваться в свой аккаунт, а все данные о нём будут удалены из админки'}
          .
        </ConfirmationModal>
      </ModalsSection>
    </>
  );
};

export default Admin;
