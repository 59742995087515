import { FunctionComponent } from 'react';

export const AuotosSelectedField: FunctionComponent<any> = ({ data = {} }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between w-full">
        {data.name && <p className="text-[14px] truncate text-ellipsis max-w-[95%]">{data.name}</p>}
      </div>
      {data?.name && data?.length && data?.height && (
        <div className="flex">
          <p className="text-[10px]">
            {data && data?.name ? `${data?.length}x${data?.width}x${data?.height}` : ''}
            {!!data?.weight && <span className="ml-1">{data.weight}lb</span>}
          </p>
        </div>
      )}
    </div>
  );
};

export const AutoOption: FunctionComponent<any> = ({ option }) => {
  return (
    <>
      <div className="flex flex-col py-[0.1em] cursor-pointer">
        <p className="text-[14px] truncate text-ellipsis max-w-[95%]">{option ? option.name : '-'}</p>
        <div className="flex">
          <p className="text-[10px]">
            {option && option?.name ? `${option?.length}x${option?.width}x${option?.height}` : '-'}
            <span className="ml-1">{option?.weight || '0'}lb</span>
          </p>
        </div>
      </div>
    </>
  );
};
