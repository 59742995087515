import React, { FunctionComponent, ReactNode, useState } from 'react';

interface IOverflowText {
  width: string;
  lineHeight: number;
  children: ReactNode;
}

const OverflowText: FunctionComponent<IOverflowText> = ({ width, lineHeight, children }) => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <div
      style={{
        display: isHidden ? '-webkit-box' : 'block',
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        overflow: isHidden ? 'hidden' : 'unset',
        width,
        WebkitLineClamp: lineHeight,
        WebkitBoxOrient: 'vertical',
      }}
      onClick={() => setIsHidden(!isHidden)}
    >
      {children}
    </div>
  );
};

export default OverflowText;
