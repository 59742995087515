import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'src/redux/userReducer/index';
import layoutReducer from 'src/redux/layoutReducer/index';
import { IUser, ILayout } from 'src/types';

export type IStore = {
  user: IUser;
  layout: ILayout;
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const store: any = configureStore({
  reducer: {
    user: userReducer,
    layout: layoutReducer,
  },
  preloadedState: loadState(),
});

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

export default store;

export type TypedDispatch = typeof store.dispatch;
