import { FunctionComponent, useEffect, useState } from 'react';
import Loader from 'src/components/Custom/Loader';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import logger from 'src/utils/logger';
import MainSection from 'src/components/Semantic/MainSection';
import Tabs from 'src/components/Tabs';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import LegalsGeneral from './general';
import LegalsOrdersHistory from './ordersHistory';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import LegalsManagers from './managers';
import ConfirmationModal from 'src/components/Custom/ConfirmationModal';
import { isPermitted } from 'src/utils/permissions';

export interface ILegal {
  id: number | string;
  company?: string;
  name: string;
  phone: string;
  email: string;
  orders_num: string;
  tax_id: string;
  admins_num: string;
  last_order_addr: string;
  last_order_status: string;
  last_order_price: number | string;
  block: number | string;
  links: {
    total: number | string;
    lastPage: number | string;
    perPage: number | string;
    currentPage: number | string;
  };
}

const Legal: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const links = [{ title: 'Общие данные', link: `/legals/list/${id}/general` }];

  if (isPermitted('legal_entities_history_orders')) {
    links.push({ title: 'История заказов', link: `/legals/list/${id}/orders-history` });
  }
  if (isPermitted('legal_entities_list_managers')) {
    links.push({ title: 'Менеджеры', link: `/legals/list/${id}/managers` });
  }

  const [legal, setLegal] = useState<ILegal>({
    id: 1,
    name: '',
    phone: '',
    email: '',
    orders_num: '',
    tax_id: '',
    admins_num: '',
    last_order_addr: '',
    last_order_status: '',
    last_order_price: '',
    block: '',
    links: {
      total: 0,
      lastPage: 0,
      perPage: 0,
      currentPage: 0,
    },
  });
  const [infoClearModalOpened, setInfoClearModalOpened] = useState(false);

  const [modalAction, setModalAction] = useState('');

  const getLegal = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosClient.get(`/legal-entities/${id}`);
      setLegal(data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const clearLegalInfo = async () => {
    setInfoClearModalOpened(false);
    setIsLoading(val => true);

    try {
      await axiosClient.delete(`/legal-entities/${id}`);
      navigate('/legals/list');
    } catch (e: any) {
      logger.error(`Ошибка при удалении данных ${e.message}`, e);
    } finally {
      setIsLoading(val => false);
    }
  };

  const banLegal = async () => {
    setInfoClearModalOpened(false);
    setIsLoading(val => true);

    try {
      await axiosClient.patch(`/legal-entities/${id}`, {
        block: '1',
      });
      // navigate('/legals/list');
      getLegal();
    } catch (e: any) {
      logger.error(`Ошибка при блокировке юр. лица ${e.message}`, e);
    } finally {
      setIsLoading(val => false);
    }
  };

  const unblockLegal = async () => {
    setIsLoading(val => true);

    try {
      await axiosClient.patch(`/legal-entities/${id}`, {
        block: '0',
      });
      getLegal();
    } catch (e: any) {
      logger.error(`Ошибка при разблокировке юр. лица ${e.message}`, e);
    } finally {
      setIsLoading(val => false);
    }
  };

  useEffect(() => {
    getLegal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="text-[#999999] text-[12px] mb-2 pt-3 pl-5">
              <span className="cursor-pointer" onClick={() => navigate('/legals/list')}>
                Юр. лица
              </span>
              &gt; {legal?.name ?? legal?.company}
            </div>
            <div className="flex justify-between items-center border-b-[1px] border-[#EAEAEA] pl-[6px]">
              <Tabs links={links} />
              {isPermitted('legal_entities_change_status') && (
                <div className="flex text-xl">
                  {String(legal?.block) === '1' ? (
                    <p
                      className="text-[#4580ca] text-lg font-semibold mr-4 font-semibold cursor-pointer"
                      onClick={() => unblockLegal()}
                    >
                      Разблокировать
                    </p>
                  ) : (
                    <p
                      className="text-[#EE0202] text-lg font-semibold mr-4 font-semibold cursor-pointer"
                      onClick={() => {
                        setInfoClearModalOpened(true);
                        setModalAction('ban');
                      }}
                    >
                      Блокировать
                    </p>
                  )}
                  <p
                    className="text-[#EE0202] text-lg font-semibold mr-4 font-semibold cursor-pointer"
                    onClick={() => {
                      setInfoClearModalOpened(true);
                      setModalAction('delete');
                    }}
                  >
                    Удалить
                  </p>
                </div>
              )}
            </div>
            {isLoading ? (
              <Loader size={100} />
            ) : (
              <>
                <Routes>
                  <Route path="/general" element={<LegalsGeneral legal={legal} />} />
                  <Route path="/orders-history" element={<LegalsOrdersHistory legalId={legal.id} />} />
                  <Route path="/managers" element={<LegalsManagers legal={legal} />} />
                </Routes>
              </>
            )}
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ConfirmationModal
          opened={infoClearModalOpened}
          type="danger"
          title={
            modalAction === 'delete' ? 'Удалить информацию о юр. лице?' : 'Заблокировать пользователя?'
          }
          confirmButtonText={modalAction === 'delete' ? 'Удалить' : 'Заблокировать'}
          setOpened={setInfoClearModalOpened}
          onConfirm={() => {
            if (modalAction === 'delete') {
              clearLegalInfo();
            } else {
              banLegal();
            }
          }}
        >
          {modalAction === 'delete'
            ? 'Если вы подтвердите данное действие, то все данные о нем будут удалены из админки.'
            : 'Если вы подтвердите данное действие, то аккаунт пользователя будет залокирован.'}
        </ConfirmationModal>
      </ModalsSection>
    </>
  );
};

export default Legal;
