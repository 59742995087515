import { FunctionComponent, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

interface ICrumb {
  title: string;
  link: string;
}

interface IBreadCrumbs {
  crumbs: Array<ICrumb>;
}

const BreadCrumbs: FunctionComponent<IBreadCrumbs> = ({ crumbs }) => {
  const navigate = useNavigate();

  return (
    <div className="text-[#999999] text-[12px] mb-2 pt-3 pl-5">
      {crumbs.map((crumb: ICrumb, i: number) => (
        <Fragment key={i}>
          <span className="cursor-pointer" onClick={() => navigate(crumb.link)}>
            {crumb.title}
          </span>
          {i < crumbs.length - 1 && <span className="mx-2">&gt;</span>}
        </Fragment>
      ))}
    </div>
  );
};

export default BreadCrumbs;
