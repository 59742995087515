import { FunctionComponent, useState, useEffect } from 'react';
import Input from '../Input';
import 'react-perfect-scrollbar/dist/css/styles.css';
import axiosClient from 'src/libs/axiosClient';
import Loader from 'src/components/Custom/Loader';
import Select from '../Select';

/* OPTIONS */
import { AuotosSelectedField, AutoOption } from './autos';
import { DriversSelectedField, DriverOption } from './drivers';
import { ClientOption, ClientsSelectedField } from './clients';

interface IDropdown {
  value: any;
  type?: 'drivers' | 'autos' | 'clients';
  placeholder?: string;
  searchable?: boolean;
  onSelect?: (option: any) => any;
  maxHeight?: number;
  additionalFilters?: any;
}

const Dropdown: FunctionComponent<IDropdown> = ({
  value,
  type = 'status',
  placeholder = 'Выбрать',
  searchable = true,
  onSelect = (option: any) => {},
  maxHeight = 12,
  additionalFilters = {},
}) => {
  const [options, setOptions] = useState<Array<any>>([]);
  const [search, setSearch] = useState<string>('');
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastPage, setLastPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  const loadOptions = async ({ url, reset = false, limit = 5, filters = {} }) => {
    setLoading(true);

    try {
      const { data: res } = await axiosClient.get(url, {
        params: { page: reset ? 1 : page, limit, ...filters, ...additionalFilters },
      });

      const ids = new Set();
      let totalOptions: any[] = [];
      if (reset || page === 1) {
        totalOptions = res.data;
      } else {
        totalOptions = [...options, ...res.data];
      }

      const updatedOptions = totalOptions.filter(driver => {
        if (ids.has(driver.id)) return false;
        ids.add(driver.id);
        return true;
      });

      setOptions(updatedOptions);

      setLastPage(res.links.lastPage);
    } catch (e: any) {
      console.log(e, e.response);
    } finally {
      setLoading(false);
      setNextLoading(false);
    }
  };

  const loadDrivers = async (reset: boolean = false) => {
    if (search) {
      await loadOptions({ url: '/drivers', reset, filters: { status: 'Утвержден', search: search } });
    } else {
      await loadOptions({ url: '/drivers-recommended', reset, filters: { have_current_order: 0 } });
    }
  };

  const loadClients = async (reset: boolean = false) => {
    await loadOptions({
      url: '/clients',
      reset,
      filters: search.length ? { name_search: search } : {},
    });
  };

  const loadAutos = async (reset: boolean = false) => {
    await loadOptions({ url: '/autos', reset, filters: search.length ? { name: search } : {} });
  };

  const load = async (reset: boolean = false) => {
    switch (type) {
      case 'drivers':
        await loadDrivers(reset);
        break;

      case 'clients':
        await loadClients(reset);
        break;
      case 'autos':
        await loadAutos(reset);
        break;
    }
  };

  const selectedRenderer = option => {
    switch (type) {
      case 'drivers':
        return <DriversSelectedField data={option} />;

      case 'clients':
        return <ClientsSelectedField data={option} />;

      case 'autos':
        return <AuotosSelectedField data={option} />;
    }
  };

  const optionRenderer = option => {
    switch (type) {
      case 'drivers':
        return <DriverOption option={option} type={search.length > 0 ? 'normal' : 'near'} />;

      case 'clients':
        return <ClientOption option={option} />;

      case 'autos':
        return <AutoOption option={option} />;
    }
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    load(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Select
      value={value}
      options={options}
      placeholder={placeholder}
      selectedRenderer={selectedRenderer}
      optionRenderer={optionRenderer}
      beforeOptionsList={() => (
        <div className="bg-white">
          <div className={`p-1 ${searchable ? '' : 'hidden'}`}>
            <Input value={search} type="search" placeholder="Поиск" onChange={setSearch} />{' '}
          </div>
          {type === 'drivers' && !search && (
            <p className="font-bold text-sm my-2 ml-2 opacity-50">Рекомендуемые водители</p>
          )}
        </div>
      )}
      afterOptionsList={() => {
        if (loading || nextLoading) {
          return (
            <div className={`mx-auto rounded-full w-fit bg-white p-1`}>
              <Loader size={35} />
            </div>
          );
        }

        if (search && options.length === 0) {
          return (
            <div className="text-center">
              <span>Нет данных</span>
            </div>
          );
        }

        return <></>;
      }}
      onSelect={onSelect}
      onReachEnd={() => {
        if (nextLoading || loading || page >= lastPage) return;
        setNextLoading(true);
        setPage(page + 1);
      }}
      maxHeight={maxHeight}
    ></Select>
  );
};

export default Dropdown;
