import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logger from 'src/utils/logger';
import Button from 'src/components/UI/Button';
import DoubleCheckbox from 'src/components/UI/DoubleCheckbox';

interface ClientGeneralTypes {
  client: any;
  updateMailingStatus: (s: 1 | 0) => void;
}

const ClientGeneral: FunctionComponent<ClientGeneralTypes> = ({ client = {}, updateMailingStatus }) => {
  const navigate = useNavigate();

  const [mailingStatus, setMailingStatus] = useState<1 | 0>(0);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  useEffect(() => {
    setMailingStatus(client.mailing);
  }, [client]);

  useEffect(() => {
    setIsEdited(client.mailing !== mailingStatus);
  }, [client, mailingStatus]);

  async function saveChanges() {
    try {
      await updateMailingStatus(mailingStatus);
      logger.success('Изменения успешно сохранены');
      setIsEdited(false);
    } catch (err: any) {
      logger.error(`Ошибка при изменении статуса рассылки`, err);
    }
  }

  return (
    <div className="pb-4">
      <div className="px-4 py-2">
        <div className="mt-6">
          <p className="mb-2 text-[#999] text-sm">Создан</p>
          <p>{client.createdBy === 'registred' ? 'Зарегистрирован' : 'Водителем'}</p>
        </div>
        <div className="mt-6">
          <p className="mb-1 text-[#999] text-sm">Имя</p>
          <p>{client.firstName || '-'}</p>
        </div>
        <div className="mt-6">
          <p className="mb-1 text-[#999] text-sm">Фамилия</p>
          <p>{client.lastName || '-'}</p>
        </div>
        <div className="mt-6">
          <p className="mb-1 text-[#999] text-sm">Телефон</p>
          <p>{client.phone || '-'}</p>
        </div>
        <div className="mt-6">
          <p className="mb-1 text-[#999] text-sm">Почта</p>
          <p>{client.email || '-'}</p>
        </div>
        <div className="mt-6">
          <p className="mb-1 text-[#999] text-sm">Компания</p>
          {client.company ? (
            <>
              <p>{client.company.name}</p>
              <p
                className="text-xs text-[#3072c4] cursor-pointer underline"
                onClick={() => navigate(`/transport-companies/list/${client.company.id}/general`)}
              >
                {client.company.id}
              </p>
            </>
          ) : (
            <p>Не указана</p>
          )}
        </div>

        <div className="mt-6">
          <p className="mb-1 text-[#999] text-sm">Согласие на рассылку</p>

          <DoubleCheckbox
            value={mailingStatus === 1 || (mailingStatus as any) === true}
            setValue={e => setMailingStatus(e ? 1 : 0)}
          />
        </div>
        {isEdited && (
          <div className="mt-6">
            <Button onClickHandler={saveChanges}> Сохранить </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientGeneral;
