import { FunctionComponent, useEffect, useState, useRef } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import { FilterBar, FilterButton } from 'src/components/FilterBar';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Pagination from 'src/components/UI/Pagination';
import axiosClient from 'src/libs/axiosClient';
import { OrdersFilters, OrdersFiltersTooltip } from 'src/components/FilterBar/ordersFilters';
import logger from 'src/utils/logger';

// Table
import OrdersListTable from 'src/components/Tables/orders/list';
import ExportModal from 'src/components/ExportModal';
import { parseDateFormat } from 'src/utils/format-date';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';

interface ClientOrdersHistoryTypes {
  clientId: number;
}

const ExportFields = [
  // { title: 'ID', exportField: 'id' },
  { title: 'Водитель и ID', exportField: 'driver' },
  { title: 'Время', exportField: 'start_at' },
  { title: 'Статус', exportField: 'status' },
  { title: 'Оценка', exportField: 'rating' },
  { title: 'Точка начала', exportField: 'start_to' },
  { title: 'Точка назначения', exportField: 'finish_to' },
  { title: 'Расстояние', exportField: 'miles' },
  { title: 'Цена', exportField: 'total_price' },
];

const emptyFilters = {
  status: [],
  start_from: '',
  start_to: '',
  start_from_time: '',
  start_to_time: '',
  search_from: '',
  search_to: '',
  price_from: '',
  price_to: '',
  search_driver: '',
  order_id: '',
};

const ClientOrdersHistory: FunctionComponent<ClientOrdersHistoryTypes> = ({ clientId }) => {
  const filtersButtonRef = useRef<any>(null);

  const [exportModal, setExportModal] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_start', 'asc']);
  const [exportSelected, setExportSelected] = useState<any>(ExportFields.map(c => c.exportField));
  const [selected, setSelected] = useState<IExportSelected>([]);
  const [filtersIsEmpty, setFiltersIsEmpty] = useState<boolean>(true);
  const [filters, setFilters] = useState<any>(emptyFilters);

  const clearFilters = () => {
    setFilters(emptyFilters);
    getOrders(emptyFilters);
  };

  const onFilterFieldChange = (val: any, field: string) => {
    const update = {
      ...filters,
      [field]: val,
    };

    if (field === 'start_to' && !filters.start_to_time) {
      update.start_to_time = '23:59';
    }

    if (field === 'start_from' && !filters.start_from_time) {
      update.start_from_time = '00:00';
    }

    setFilters(update);
  };

  const getQueryFilters = () =>
    Object.keys(filters).reduce(
      (obj, key) => {
        if (!filters[key]) {
          return obj;
        }

        if (key === 'start_from_time' || key === 'start_to_time') {
          return obj;
        }

        switch (key) {
          case 'start_from':
          case 'start_to':
            obj[key] = parseDateFormat(`${filters[key]} ${filters[`${key}_time`]}`, 'YYYY-MM-DD HH:mm');
            break;
          case 'status':
            if (filters[key].length) {
              obj[key] = filters[key].join(',');
            }
            break;
          default:
            obj[key] = filters[key];
        }

        return obj;
      },
      {
        client_id: clientId,
      }
    );

  const getOrders = async (filters: any, p?: number) => {
    const updatedPage = p || page;
    try {
      setIsLoading(val => !val);
      setData((val: any) => []);
      const res = await axiosClient.get('/orders', {
        params: {
          page: updatedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(),
        },
      });
      setDataTotal(res.data.links.lastPage);
      setData((val: any) => res.data.data);
      setIsLoading(val => !val);
      setPage(updatedPage);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    }
  };

  useEffect(() => {
    getOrders(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  useEffect(() => {
    setFiltersIsEmpty(
      !filters.status.length &&
        !filters.start_from &&
        !filters.start_to &&
        !filters.search_from &&
        !filters.search_to &&
        !filters.price_from &&
        !filters.price_from &&
        !filters.search_driver &&
        !filters.order_id
    );
    getOrders(filters, 1);
  }, [filters]);

  return (
    <>
      <div className={`bg-[#fff] h-screen`}>
        <div className="flex justify-end px-[17px] pt-[14px] pb-[12px]">
          <div className="flex items-center">
            <p className="text-[12px] mr-2 text-[#9b9d9c]">
              {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
            </p>
            {isPermitted('clients_download_history_orders') && (
              <div className="mr-6">
                <ButtonSPrimary onClickHandler={() => setExportModal(true)}>
                  Экспорт в Excel
                </ButtonSPrimary>
              </div>
            )}
            <FilterButton
              opened={filterOpen}
              buttonRef={filtersButtonRef}
              filtersIsEmpty={filtersIsEmpty}
              onOpen={() => setFilterOpen(true)}
              onClose={() => setFilterOpen(false)}
              offset={290}
            >
              <OrdersFiltersTooltip
                data={filters}
                filtersIsEmpty={filtersIsEmpty}
                filtersButtonRef={filtersButtonRef}
              />
            </FilterButton>
            <FilterBar setOpened={setFilterOpen} opened={filterOpen} width={280} onClear={clearFilters}>
              <OrdersFilters data={filters} onFilterFieldChange={onFilterFieldChange} />
            </FilterBar>
          </div>
        </div>
        <div className="flex justify-end items-center mb-3 px-[17px]"></div>

        <div className="overflow-auto bg-white">
          <OrdersListTable
            orders={data}
            isLoading={isLoading}
            selected={selected}
            sort={sort}
            onOrderSelect={setSelected}
            onSortUpdate={setSort}
          />

          {!isLoading && data.length !== 0 && (
            <Pagination
              totalPages={dataTotal}
              activePage={page}
              setPage={setPage}
              alignment="justify-end"
            />
          )}
        </div>
      </div>
      <ModalsSection>
        <ExportModal
          opened={exportModal}
          isLoading={isLoading}
          selected={exportSelected}
          options={ExportFields}
          field="exportField"
          setSelected={setExportSelected}
          setOpened={setExportModal}
          exportParams={{
            url: '/export/orders',
            filename: 'orders.xlsx',
            fields: exportSelected,
            filters: getQueryFilters(),
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default ClientOrdersHistory;
