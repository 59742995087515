import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import logger from 'src/utils/logger';
import MainSection from 'src/components/Semantic/MainSection';
import VerticalLayout from 'src/layouts/VerticalLayout';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Tooltip from 'src/components/UI/Tooltip';
import Input from 'src/components/UI/Input';
import Pagination from 'src/components/UI/Pagination';
import { FilterBar, FilterButton } from 'src/components/FilterBar';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import CheckList from 'src/components/UI/CheckList';
import axiosClient from 'src/libs/axiosClient';

import LegalsListTable from 'src/components/Tables/legals/list';
import ExportModal from 'src/components/ExportModal';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';

const statuses = [
  { label: 'Заблокирован', value: 'blocked', color: '#fde6e6', textColor: '#ee0202' },
  { label: 'Текущий заказ', value: 'curOrder' },
];

const ExportFields: Array<any> = [
  { title: 'Компания', field: 'name' },
  { title: 'Заказов', field: 'orders_count' },
  { title: 'Менеджеров', field: 'managers_count' },
  { title: 'Почта', field: 'email' },
  { title: 'Tax ID', field: 'tax_id' },
  { title: 'Последний заказ', field: 'last_order' },
];

const LegalList: FunctionComponent = () => {
  // Legals
  const [legals, setLegals] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingRow, setLoadingRow] = useState<number>(NaN);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  // Export
  const [selected, setSelected] = useState<IExportSelected>([]);
  const [exportModalOpened, setExportModalOpened] = useState<boolean>(false);
  const [exportColumns, setExportColumns] = useState<Array<string>>(ExportFields.map(e => e.field));

  //Sort
  const [sort, setSort] = useState<any>(['sort_order', 'asc']);

  // Filters
  const filtersButtonRef = useRef<any>(null);
  const [filterOpened, setFilterOpened] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({
    status: [],
    driverId: '',
  });

  const clearFilters = () => {
    setFilters({
      status: [],
      driverId: '',
    });
    getLegals(1);
  };

  const onFilterFieldChange = (value: any, field: string) => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const getQueryFilters = () => {
    let filtersParams: any = {};
    if (filters.driverId.length) {
      filtersParams.driver_id = filters.driverId;
    }
    if (filters.status.length) {
      if (filters.status.includes(statuses[0].value)) {
        filtersParams.blocked = 1;
      }
      if (filters.status.includes(statuses[1].value)) {
        filtersParams.search_order = 1;
      }
    }

    return filtersParams;
  };

  const getLegals = async (currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    setIsLoading(true);
    setLegals([]);

    try {
      const { data: res } = await axiosClient.get('/legal-entities', {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(),
        },
      });

      setTotal(res.links.lastPage);
      setLegals(res.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const setStatus = async (legalId: number, status: string, rowIndex: number) => {
    setLoadingRow(rowIndex);
    try {
      await axiosClient.patch(`/legal-entities/${legalId}`, {
        block: status,
      });
      await getLegals(page);
      setLoadingRow(NaN);
    } catch (e: any) {
      logger.error(`Ошибка при обновлении статуса ${e.message}`, e);
      setLoadingRow(NaN);
    }
  };

  useEffect(() => {
    getLegals(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, page]);

  useEffect(() => {
    getLegals(1);
  }, [filters]);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
              <div className="flex items-center">
                <h2 className="text-[24px] text-[#363B39] font-bold">Юр. лица</h2>
              </div>
              <div className="flex justify-end items-center overflow-visible">
                <p className="text-[12px] mr-2 text-[#9b9d9c]">
                  {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
                </p>
                {isPermitted('legal_entities_list') && (
                  <div className="mr-6">
                    <ButtonSPrimary onClickHandler={() => setExportModalOpened(true)}>
                      Экспорт в Excel
                    </ButtonSPrimary>
                  </div>
                )}
                <FilterButton
                  opened={filterOpened}
                  buttonRef={filtersButtonRef}
                  filtersIsEmpty={!filters.driverId && !filters.status.length}
                  onOpen={() => setFilterOpened(true)}
                  onClose={() => setFilterOpened(false)}
                >
                  <Tooltip
                    parentRef={filtersButtonRef}
                    disabled={!filters.driverId && !filters.status.length}
                    align="left"
                    className="w-[15em]"
                  >
                    {!!filters.driverId.length && (
                      <div className="mb-1">
                        <p className="text-sm text-[#9f9f9f]">Водитель</p>
                        <p className="text-sm">{filters.driverId}</p>
                      </div>
                    )}
                    {!!filters.status.length && (
                      <div>
                        <p className="text-sm text-[#9f9f9f]">Статусы</p>
                        <div className="flex flex-col">
                          {filters.status.map((status, i) => (
                            <p className="text-sm" key={i}>
                              {status}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                  </Tooltip>
                </FilterButton>

                <FilterBar setOpened={setFilterOpened} opened={filterOpened} onClear={clearFilters}>
                  <div>
                    <div className="mt-5">
                      <p className="text-[#808080] text-sm">Статусы</p>
                      <div className="w-full mt-2">
                        <CheckList
                          options={statuses}
                          selected={filters.status}
                          field="value"
                          label="label"
                          onChange={newStatuses => onFilterFieldChange(newStatuses, 'status')}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="text-[#808080] text-sm">ID водителя</p>
                      <div className="w-full mt-2 text-sm">
                        <Input
                          className="w-full"
                          value={filters.driverId}
                          onChange={e => onFilterFieldChange(e, 'driverId')}
                        />
                      </div>
                    </div>
                  </div>
                </FilterBar>
              </div>
            </div>
            <div className="overflow-auto bg-white">
              <LegalsListTable
                legals={legals}
                isLoading={isLoading}
                loadingRow={loadingRow}
                selected={selected}
                sort={sort}
                onSortUpdate={setSort}
                onLegalSelect={setSelected}
                onLegalStatusUpdate={setStatus}
              />

              {!isLoading && legals.length !== 0 && (
                <Pagination
                  totalPages={total}
                  activePage={page}
                  setPage={setPage}
                  alignment="justify-end"
                />
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ExportModal
          opened={exportModalOpened}
          isLoading={isLoading}
          selected={exportColumns}
          options={ExportFields}
          setSelected={setExportColumns}
          setOpened={setExportModalOpened}
          exportParams={{
            url: '/export/legals',
            filename: 'legal-entities-export.xlsx',
            fields: exportColumns,
            filters: getQueryFilters(),
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default LegalList;
