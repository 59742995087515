import { IUser } from 'src/types';

const initialState: IUser = {
  accessToken: '',
  refreshToken: '',
  id: 0,
  username: '',
  password: '',
  isBlocked: false,
  blockReason: '',
  bio: '',
  email: '',
  instagram: '',
  twitter: '',
  facebook: '',
  vk: '',
  role: 'client',
  uploadId: 0,
  createdAt: '',
  updatedAt: '',
  notificationsCount: 0,
};

function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_AUTH_INFO':
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case 'SET_USER':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default userReducer;
