import { FunctionComponent, useState, useEffect, Fragment } from 'react';
import Dropdown from 'src/components/UI/Dropdown';
import Input from 'src/components/UI/Input';

interface ICreateOrderThirdStep {
  formData: any;
  selectedDriverTrailer: any;
  onFormDataChange?: (value: any, field: string) => any;
}

interface ICarError {
  message: string;
  fields: Array<string>;
}

const emptyCarInfo = {
  name: '',
  vin: '',
  width: 0,
  height: 0,
  length: 0,
  weight: 0,
};

const CreateOrderThirdStep: FunctionComponent<ICreateOrderThirdStep> = ({
  formData,
  selectedDriverTrailer,
  onFormDataChange = () => {},
}) => {
  const [cars, setCars] = useState<Array<any>>([emptyCarInfo]);
  const [carsError, setCarsError] = useState<ICarError>({
    message: '',
    fields: [],
  });

  const updateCar = (c: any, carIndex: number) => {
    setCars(
      cars.map((car, i) => {
        if (i !== carIndex) return car;

        return { ...car, ...c };
      })
    );
  };

  const updateCarField = (value: any, carField: string, carIndex: number) => {
    if (!cars[carIndex]) return;

    updateCar(
      {
        ...cars[carIndex],
        [carField]: value,
      },
      carIndex
    );
  };

  const saveCarsInFormData = () => {
    onFormDataChange(
      cars.map(car => {
        return {
          car_name: car.name,
          vin: car.vin,
          dimensions: `${car.width}x${car.height}x${car.length}`,
          height: car.height,
          width: car.width,
          length: car.length,
          weight: car.weight,
        };
      }),
      'cars'
    );
  };

  const checkTrailerRequirments = () => {
    if (!selectedDriverTrailer) {
      return;
    }

    const carCharacteristicsSum = {
      width: 0,
      height: 0,
      length: 0,
      weight: 0,
    };

    let newError: ICarError = {
      message: '',
      fields: [],
    };

    for (let car of cars) {
      for (let characteristic of Object.keys(carCharacteristicsSum)) {
        carCharacteristicsSum[characteristic] += car[characteristic];

        if (
          carCharacteristicsSum[characteristic] > selectedDriverTrailer[characteristic] &&
          !newError.fields.includes(characteristic)
        ) {
          newError.fields.push(characteristic);
        }
      }
    }

    if (newError.fields.length) {
      newError.message = 'Параметры авто не соответсвуют параметрам прицепа водителя';
    }

    setCarsError(newError);
  };

  useEffect(() => {
    checkTrailerRequirments();
    saveCarsInFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cars]);

  return (
    <>
      {(selectedDriverTrailer || formData?.selectedDriver?.trailer_type) && (
        <p className="text-[#808080] text-base text-center w-[293px] mx-auto">
          {formData?.selectedDriver?.trailer_type && (
            <span>
              {formData?.selectedDriver?.trailer_type === 'open' ? 'Enclosed' : 'Closed'} trailer
            </span>
          )}
          {selectedDriverTrailer && (
            <span className="ml-1">
              {selectedDriverTrailer?.length || '-'}x{selectedDriverTrailer?.width || '-'}x
              {selectedDriverTrailer?.height || '-'} {selectedDriverTrailer?.weight || '0'} lb
            </span>
          )}
        </p>
      )}

      <div className="mt-2 mb-4 pb-4 px-4">
        {cars.map((car, i) => (
          <Fragment>
            <div key={car.id} className="grid grid-cols-[1fr_1fr_1fr] gap-[5px] mt-4">
              <div className="col-[1_/_4]">
                <p className="flex justify-between text-[#808080] text-sm mt-[5px] mb-[15px]">
                  <span>Название машины</span>
                  <span
                    className={`font-semibold text-[#EE0202] ${
                      cars.length && cars[0].name.length
                        ? 'opacity-100 cursor-pointer'
                        : 'opacity-50 cursor-default'
                    }`}
                    onClick={() => {
                      if (cars.length <= 1) {
                        setCars([emptyCarInfo]);
                      } else {
                        setCars(cars.filter((car, carIndex) => carIndex !== i));
                      }
                    }}
                  >
                    Удалить машину
                  </span>
                </p>
                <Dropdown
                  value={car?.name ? car : null}
                  placeholder="Выберите авто"
                  type="autos"
                  onSelect={(c: any) => updateCar(c, i)}
                />
              </div>
              <div className="col-[1_/_4] mt-[10px]">
                <p className="flex justify-between text-[#808080] text-sm mb-[5px]">VIN номер</p>
                <Input
                  className="mt-[5px]"
                  value={car.vin}
                  onChange={vin => updateCarField(vin, 'vin', i)}
                />
              </div>
              {[
                { title: 'Длина', key: 'length' },
                { title: 'Ширина', key: 'width' },
                { title: 'Высота', key: 'height' },
                { title: 'Вес', key: 'weight', measurement: 'lb' },
              ].map(carField => (
                <div key={carField.key}>
                  <p
                    className={`text-sm mt-[5px] text-[#${
                      carsError.fields.includes(carField.key) ? 'EE0202' : '808080'
                    }]`}
                  >
                    {carField.title}
                    <span className="text-xs">({carField.measurement || 'inch'})</span>
                  </p>
                  <Input
                    className={`w-full mt-[5px] ${
                      carsError.fields.includes(carField.key) && 'text-[#EE0202]'
                    }`}
                    value={car[carField.key]}
                    onChange={value => {
                      if (value.length && !/^\d+$/.test(value)) return;
                      updateCarField(Number(value), carField.key, i);
                    }}
                  />
                </div>
              ))}
            </div>
            {!!carsError?.message?.length && (
              <p className="text-[#EE0202] text-center text-sm mt-[5px]">{carsError.message}</p>
            )}
          </Fragment>
        ))}
      </div>
      <p
        className="text-[#006BB2] mt-4 text-base font-bold text-center cursor-pointer w-[293px]"
        onClick={() => setCars([...cars, emptyCarInfo])}
      >
        + Добавить авто
      </p>
      <div className="grid grid-cols-[1fr_1fr_1fr]">
        <div className="col-[2_/_3]">
          <p className="text-sm mt-[5px] text-center text-[#808080]">Итоговая цена</p>
          <Input
            className="w-full mt-[5px]"
            value={`$${formData.total_price}`}
            onChange={price => {
              let totalPrice = price.slice(1) || '';
              let dots = totalPrice.match(/\./g);
              dots = Array.isArray(dots) ? dots.length : 0;

              if (totalPrice?.length && !(/^[\d.]+$/.test(totalPrice) && dots <= 1)) {
                return;
              }

              totalPrice = totalPrice.slice(0, 6);

              onFormDataChange(totalPrice, 'total_price');
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CreateOrderThirdStep;
