import { GridTableCell } from 'src/components/GridTable';
import Checkbox from 'src/components/UI/Checkbox';

export type IExportSelected = Array<any> | 'all';

interface IExportCheckboxesColumn {
  selected: IExportSelected;
  field?: string | null;
  checkTest?: (a: any, b: any) => boolean;
  onSelect: (newSelected: IExportSelected) => any;
  additionalParams?: { [key: string]: any };
}

const ExportCheckboxesColumn = ({
  selected,
  field = 'id',
  checkTest = (value, selectedValue) => value === selectedValue,
  onSelect = () => {},
  additionalParams = {},
}: IExportCheckboxesColumn) => {
  const selectToggle = (value: any, selected: IExportSelected, update: (value: any) => any) => {
    if (selected === 'all') {
      return update('all');
    }

    const newSelected = [...selected];
    const index = newSelected.findIndex(el => checkTest(value, el));

    if (index === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(index, 1);
    }

    update(newSelected);
  };

  const isAllSelected = selected === 'all';

  return {
    ...additionalParams,
    label: () => (
      <GridTableCell className="px-4 cursor-pointer">
        <Checkbox value={isAllSelected} onClickHandler={() => onSelect(isAllSelected ? [] : 'all')} />
      </GridTableCell>
    ),
    component: (v: any, i: number, row: any) => (
      <GridTableCell className="px-4 cursor-pointer">
        <div className={isAllSelected ? 'opacity-20' : 'opacity-100'}>
          <Checkbox
            value={
              isAllSelected ||
              selected.find(el => checkTest(typeof field === 'string' ? row[field] : row, el)) !==
                undefined
            }
            disabled={isAllSelected}
            onClickHandler={() =>
              selectToggle(typeof field === 'string' ? row[field] : row, selected, onSelect)
            }
          />
        </div>
      </GridTableCell>
    ),

    width: 'max-content',
  };
};

export default ExportCheckboxesColumn;
