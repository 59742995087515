import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import axiosClient from 'src/libs/axiosClient';
import { TypedDispatch } from 'src/redux';
import UnAuthorizedLayout from 'src/layouts/UnAuthorized';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Input from 'src/components/UI/Input';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';

const RecoveryCompanyPage: FunctionComponent = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: TypedDispatch = useDispatch();
  const [recoverModalEmail, setRecoverModalEmail] = useState<boolean>(false);
  const [blockMessageModalOpened, setBlockMessageModalOpened] = useState<boolean>(false);
  const [recoverEmail, setRecoverEmail] = useState<string>('');
  const [recoverError, setRecoverError] = useState<string>('');

  const [recoverModalPassword, setRecoverModalPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [waitingModalOpened, setWaitingModalOpened] = useState<boolean>(false);

  async function onSignInHandler(): Promise<any> {
    try {
      const { data }: any = await axiosClient.post(`/company-login`, {
        email,
        password,
      });

      let user: any = { role: 'companyAdmin', notificationsCount: 0 };

      if (data?.user) {
        user = { ...data.user, ...user };
      }

      dispatch({ type: 'SET_USER', payload: user });
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('accessToken', data.value);
      localStorage.setItem('userRole', 'companyAdmin');
      if (user.permissions) {
        localStorage.setItem(
          'permissions',
          user.permissions.map(e => e.name)
        );
      }

      navigate('/', { replace: true });
    } catch (error: any) {
      if (error?.response?.status === 403) {
        if (error?.response?.data?.message === 'Your company is not verification.') {
          setWaitingModalOpened(true);
        } else {
          setBlockMessageModalOpened(true);
        }
      } else {
        setError(error?.response?.data?.errors);
      }
    }
  }

  async function onRecoverEmailHandler(): Promise<void> {
    try {
      await axiosClient.post(`/forgot-password`, {
        email: recoverEmail,
      });

      setRecoverModalEmail(false);
    } catch (error: any) {
      setRecoverError(error.response.data.errors);
    }
  }

  async function onRecoverPasswordHandler(): Promise<void> {
    try {
      await axiosClient.post('/password-recovery', {
        email: recoverEmail,
        password: newPassword,
        password_confirmation: newPasswordRepeat,
      });

      setRecoverModalPassword(false);
    } catch (error: any) {
      setRecoverError(error.response.data.errors);
    }
  }

  return (
    <>
      <UnAuthorizedLayout>
        <ModalsSection>
          <ModalWindow title="Авторизация компании" close={false}>
            <div className="flex flex-col">
              <div>
                <p className="mb-2">Email</p>
                <Input value={email} onChange={setEmail} placeholder="Email" />
              </div>
              <div>
                <div className="flex mb-2 mt-8 justify-between">
                  <p>Пароль</p>
                  <p
                    className="text-[#2F80ED] cursor-pointer"
                    onClick={() => setRecoverModalEmail(true)}
                  >
                    Забыли пароль?
                  </p>
                </div>
                <Input value={password} onChange={setPassword} placeholder="Пароль" type="password" />
              </div>
              <div className="w-[320px] mt-6 text-[1.1em]">
                <ButtonSPrimary disabled={!email || !password} onClickHandler={onSignInHandler}>
                  Войти
                </ButtonSPrimary>
              </div>
              {error && <p className="text-red-600">{error[Object.keys(error)[0]][0]}</p>}
            </div>
          </ModalWindow>
          {/* </ModalBackdrop> */}
          <ModalBackdrop state={recoverModalEmail}>
            <ModalWindow title="Восстановление пароля" setState={setRecoverModalEmail}>
              <div className="flex flex-col items-center">
                <p className="text-center w-[420px] mb-5">
                  На вашу почту будет выслано письмо с инструкцией по восстановлению пароля
                </p>
                <div className="w-[320px]">
                  <p className="mb-2">Email</p>
                  <Input placeholder="Email" value={recoverEmail} onChange={e => setRecoverEmail(e)} />
                </div>
                <div className="w-[320px] mt-6 text-[1.1em]">
                  <ButtonSPrimary disabled={!recoverEmail} onClickHandler={onRecoverEmailHandler}>
                    Ок
                  </ButtonSPrimary>
                </div>
                {recoverError && (
                  <p className="text-red-600">{recoverError[Object.keys(recoverError)[0]][0]}</p>
                )}
              </div>
            </ModalWindow>
          </ModalBackdrop>
          <ModalBackdrop state={recoverModalPassword}>
            <ModalWindow title="Восстановление пароля" setState={setRecoverModalPassword}>
              <div className="flex flex-col items-center">
                <p className="text-center w-[420px] mb-5">
                  На вашу почту будет выслано письмо с инструкцией по восстановлению пароля
                </p>
                <div className="w-[320px] mb-4">
                  <p className="mb-2">Новый пароль</p>
                  <Input
                    placeholder="Новый пароль"
                    value={newPassword}
                    onChange={e => setNewPassword(e)}
                  />
                </div>
                <div className="w-[320px]">
                  <p className="mb-2">Повторите новый пароль</p>
                  <Input
                    placeholder="Повторите новый пароль"
                    value={newPasswordRepeat}
                    onChange={e => setNewPasswordRepeat(e)}
                  />
                </div>
                <div className="w-[320px] mt-6 text-[1.1em]">
                  <ButtonSPrimary
                    disabled={!newPassword || newPassword !== newPasswordRepeat}
                    onClickHandler={onRecoverPasswordHandler}
                  >
                    Восстановить
                  </ButtonSPrimary>
                </div>
                {recoverError && (
                  <p className="text-red-600">{recoverError[Object.keys(recoverError)[0]][0]}</p>
                )}
              </div>
            </ModalWindow>
          </ModalBackdrop>
          <ModalBackdrop state={waitingModalOpened}>
            <ModalWindow title="Подождите своей верификации" setState={setWaitingModalOpened}>
              <div className="flex flex-col items-center p-4">
                <p className="text-center w-[420px]">Вы получите email с результатом вашей модерации</p>
                <div className="w-[320px] mt-6 text-[1.1em]">
                  <ButtonSPrimary onClickHandler={() => setWaitingModalOpened(false)}>Ок</ButtonSPrimary>
                </div>
              </div>
            </ModalWindow>
          </ModalBackdrop>
          <ModalBackdrop state={blockMessageModalOpened}>
            <ModalWindow title="Не удалось войти в аккаунт" setState={setBlockMessageModalOpened}>
              <div className="flex flex-col items-center px-4">
                <p className="text-center w-[420px]">Ваш аккаунт заблокирован</p>
                <div className="w-[320px] mt-6 text-[1.1em]">
                  <ButtonSPrimary onClickHandler={() => setBlockMessageModalOpened(false)}>
                    Ок
                  </ButtonSPrimary>
                </div>
              </div>
            </ModalWindow>
          </ModalBackdrop>
        </ModalsSection>
      </UnAuthorizedLayout>
    </>
  );
};

export default RecoveryCompanyPage;
