import { FunctionComponent } from 'react';
import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import TimeCell from '../BASIC_CELLS/TimeCell';

interface IActionsHistoryTable {
  actions: Array<any>;
  isLoading: boolean;
  sort: Array<string>;
  selected: IExportSelected;
  onSelect: (newSelected: IExportSelected) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const ActionsHistoryTable: FunctionComponent<IActionsHistoryTable> = ({
  actions,
  isLoading = false,
  sort = [],
  selected = [],
  onSelect = () => {},
  onSortUpdate = () => {},
}) => {
  const TableFields = {
    exportSelect: ExportCheckboxesColumn({ selected, onSelect }),

    date: {
      label: 'Дата и время',
      sortBy: 'sort_date',
      component: (date: string) => <TimeCell date={new Date(date)} />,
    },

    action: {
      label: 'Действие',
      sortBy: 'sort_action',
      component: (action: any) => <ActionCell action={action} />,
    },
  };

  return (
    <GridTable
      data={actions}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

const ActionCell: FunctionComponent<any> = ({ action }) => {
  let linkTo = '';
  let actionParsed = action;
  const findLink = action.match(/<a(.*)<\/a>/);
  const id = action.match(/id=(.*)<\/a>/) ? action.match(/id=(.*)<\/a>/) : action.match(/id(.*)<\/a>/);
  if (action.search('администрат') > 0 && id && id.length) {
    linkTo = ` style="color:#3072C4;font-weight:700;" href='/settings/adminstrators/${id[1]}/general'>id=${id[1]} `;
  }
  if (action.search('заказ') > 0 && id && id.length) {
    linkTo = ` style="color:#3072C4;font-weight:700;" href='/order/${id[1]}'>id=${id[1]} `;
  }
  if (id && id.length && findLink && findLink.length) {
    actionParsed = action.replace(findLink[1], linkTo);
  }

  return (
    <GridTableCell
      className="text-[#999] text-xs flex flex-col"
      dangerouslySetInnerHTML={{
        __html: actionParsed,
      }}
    ></GridTableCell>
  );
};

export default ActionsHistoryTable;
