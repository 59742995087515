import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import MainSection from 'src/components/Semantic/MainSection';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Button from 'src/components/UI/Button';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Pagination from 'src/components/UI/Pagination';
import Textarea from 'src/components/UI/Textarea';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import { FilterBar, FilterButton } from 'src/components/FilterBar';
import { DriversFilters, DriversFiltersTooltip } from 'src/components/FilterBar/driversFilters';

import ExportModal from 'src/components/ExportModal';
import DriversTable from 'src/components/Tables/drivers/list';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import Loader from 'src/components/Custom/Loader';
import DriverMap from 'src/components/Map/DriverMap';
import { connectToSocket } from 'src/hooks/use-socket';

const exportFields = [
  { field: 'id', title: 'ID' },
  { field: 'lastName', title: 'Фамилия' },
  { field: 'firstName', title: 'Имя' },
  { field: 'email', title: 'Почта' },
  { field: 'phoneNumber', title: 'Телефон' },
  { field: 'role', title: 'Статус' },
  { field: 'driverLicenseFront', title: 'Водительские права(Front)' },
  { field: 'driverLicenseBack', title: 'Водительские права(Back)' },
  { field: 'insurance', title: 'Страховка' },
  { field: 'w9_llc', title: 'Компания перевозчик' },
  { field: 'car_brand', title: 'Марка машины' },
  { field: 'car_model', title: 'Модель машины' },
  { field: 'car_win', title: 'Вин номер' },
  { field: 'trailer_type', title: 'Тип прицепа' },
  { field: 'carPhoto', title: 'Фото автомобиля' },
  { field: 'trailerPhoto', title: 'Фото прицепа' },
  { field: 'order', title: 'Текущий заказ' },
  { field: 'count_orders', title: 'Заказов' },
  { field: 'earnings', title: 'Заработано' },
];

const DriversList: FunctionComponent = () => {
  const filtersButtonRef = useRef<any>(null);

  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [cause, setCause] = useState<any>('');
  const [causeModal, setCauseModal] = useState<any>(false);
  const [exportModal, setExportModal] = useState<boolean>(false);
  const [statusError, setStatusError] = useState<any>('');
  const [isMap, setIsMap] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingRow, setLoadingRow] = useState<number>(NaN);

  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_status', 'asc']);
  const [exportSelected, setExportSelected] = useState<any>(exportFields.map(e => e.field));
  const [selected, setSelected] = useState<IExportSelected>([]);
  const [filters, setFilters] = useState<any>({
    status: [],
    search: '',
  });

  const clearFilters = () => {
    setFilters({
      status: [],
      search: '',
    });
    getDrivers(
      {
        status: [],
        search: '',
      },
      1
    );
  };

  const onFilterFieldChange = (val: any, field: string) => {
    setFilters({
      ...filters,
      [field]: val,
    });
  };

  const getQueryFilters = (filters: any) => {
    const have_current_order = filters.status.includes('Есть текущий заказ') ? 1 : undefined;
    const long_time_login = filters.status.includes('Давно не был онлайн') ? 1 : undefined;
    const filterStatuses = filters.status.filter(
      e => e !== 'Есть текущий заказ' && e !== 'Давно не был онлайн'
    );

    return {
      ...(filters.search && { search: filters.search }),
      ...(filterStatuses?.length && { status: filterStatuses.join(',') }),
      have_current_order,
      long_time_login,
    };
  };

  const getDrivers = async (filters: any, currentPage?: number, withLoading: boolean = true) => {
    if (withLoading) setIsLoading(true);
    const selectedPage = currentPage || page;
    setPage(selectedPage);
    try {
      const res = await axiosClient.get('/drivers', {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(filters),
        },
      });
      setDataTotal(res.data.links.lastPage);
      setData((val: any) => res.data.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении водителей ${e.message}`, e);
    } finally {
      if (withLoading) setIsLoading(false);
      if (!isNaN(loadingRow)) setLoadingRow(NaN);
    }
  };

  useEffect(() => {
    getDrivers(filters, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  useEffect(() => {
    const handler = () => {
      getDrivers(filters, page, false);
    };

    const channels = [
      ['admin.orders', '.create.order'],
      ['admin.orders', '.update.order'],
      ['admin.drivers', '.create.driver'],
      ['admin.drivers', '.update.driver'],
    ].map(ch => connectToSocket(ch[0], ch[1], handler));

    channels.forEach(ch => ch.connect());

    return () => channels.forEach(ch => ch.leave());
  }, []);

  const updateStatus = async (id: number, status: string, rowIndex: number = NaN) => {
    setLoadingRow(rowIndex);

    if (status === 'Повторная модерация' && !cause.length) {
      setStatusError('Причина обязательна');
      return;
    }

    try {
      setCause('');
      setCauseModal(false);
      await axiosClient.post(`/drivers/${id}/change-status`, {
        status,
        comment: cause,
      });
      await getDrivers(filters, page, false);
      setLoadingRow(NaN);
    } catch (e: any) {
      logger.error(`Ошибка при смене статуса ${e.message}`, e);
      setLoadingRow(NaN);
    }
  };

  const closeMap = () => {
    setSelected([]);
    setIsMap(false);
  };

  const openMap = () => {
    setIsMap(true);
  };

  const selectedDrivers =
    selected.length === 0 || selected.includes('all')
      ? data
      : data.filter(driver => selected.includes(driver.id));

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
              <h2 className="text-[24px] text-[#363B39] font-bold">Водители</h2>
              <div className="flex justify-end items-center">
                {isMap && (
                  <div className="mr-3">
                    <ButtonSPrimary onClickHandler={() => closeMap()}>
                      <img alt="Таблица" src="/assets/img/table.svg" className="pr-[5px]" />
                      <p>Показать в таблице</p>
                    </ButtonSPrimary>
                  </div>
                )}
                <FilterBar
                  opened={filterOpen}
                  onApply={() => getDrivers(filters, 1)}
                  onClear={clearFilters}
                  setOpened={setFilterOpen}
                >
                  <DriversFilters data={filters} onFilterFieldChange={onFilterFieldChange} />
                </FilterBar>
              </div>
            </div>
            {isMap ? (
              <div className="relative" style={{ height: 'calc(100% - 62px)' }}>
                {isLoading ? (
                  <Loader size={70} />
                ) : (
                  <DriverMap
                    data={{
                      drivers: selectedDrivers,
                    }}
                  />
                )}
              </div>
            ) : (
              <>
                <div className="flex justify-end items-center mr-4">
                  <p className="text-[12px] mr-2 text-[#9b9d9c]">
                    {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
                  </p>
                  {isPermitted('drivers_download_list') && (
                    <div className="mr-6">
                      <ButtonSPrimary onClickHandler={() => setExportModal(true)}>
                        Экспорт в Excel
                      </ButtonSPrimary>
                    </div>
                  )}
                  {(selected === 'all' || selected.length > 0) && (
                    <div className="mr-6">
                      <ButtonSPrimary onClickHandler={() => openMap()}>
                        <img alt="Карта" src="/assets/img/map.svg" className="pr-[5px]" />
                        <p>Показать на карте</p>
                      </ButtonSPrimary>
                    </div>
                  )}
                  <FilterButton
                    opened={filterOpen}
                    buttonRef={filtersButtonRef}
                    filtersIsEmpty={!filters.search && !filters.status.length}
                    onOpen={() => setFilterOpen(true)}
                    onClose={() => setFilterOpen(false)}
                  >
                    <DriversFiltersTooltip data={filters} filtersButtonRef={filtersButtonRef} />
                  </FilterButton>
                </div>
                <div className="overflow-auto bg-white">
                  <DriversTable
                    drivers={data}
                    isLoading={isLoading}
                    loadingRow={loadingRow}
                    selected={selected}
                    sort={sort}
                    onDriverSelect={setSelected}
                    onSortUpdate={setSort}
                    onDriverStatusUpdate={(
                      driverId: number,
                      newSelectedStatus: string,
                      rowIndex: number
                    ) => {
                      if (newSelectedStatus === 'Повторная модерация') {
                        setCauseModal(driverId);
                      } else {
                        updateStatus(driverId, newSelectedStatus, rowIndex);
                      }
                    }}
                  />

                  {!isLoading && data.length !== 0 && (
                    <Pagination
                      totalPages={dataTotal}
                      activePage={page}
                      setPage={setPage}
                      alignment="justify-end"
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ExportModal
          opened={exportModal}
          isLoading={isLoading}
          selected={exportSelected}
          options={exportFields}
          setSelected={setExportSelected}
          setOpened={setExportModal}
          exportParams={{
            url: '/export/drivers',
            filename: 'drivers.xlsx',
            fields: exportSelected,
            filters: getQueryFilters(filters),
            selected,
            setIsLoading,
          }}
        />
        <ModalBackdrop state={causeModal}>
          <ModalWindow title="Повторная модерация" setState={setCauseModal}>
            <div className="flex flex-col">
              <p className="mb-2">Причина</p>
              <Textarea placeholder="" value={cause} setValue={e => setCause(e)} />
              <div className="w-[320px] mt-6">
                <Button
                  variant="default"
                  onClickHandler={() => updateStatus(causeModal, 'Повторная модерация')}
                >
                  Отправить
                </Button>
                {!!statusError && <p className="absolute text-[#EE0202]">{statusError}</p>}
              </div>
            </div>
          </ModalWindow>
        </ModalBackdrop>
      </ModalsSection>
    </>
  );
};

export default DriversList;
