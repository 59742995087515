import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import timeCount from 'src/utils/timeCounter';

import { GridTable, GridTableCell } from 'src/components/GridTable';

// Cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import DriverInfoCell from '../BASIC_CELLS/DriverInfoCell';
import OverflowText from 'src/components/OverflowText';
import { getDateString, getTimeString } from 'src/utils/format-date';
import { findStatus } from 'src/utils/order-statuses';

interface IOrdersListTable {
  orders: Array<any>;
  isLoading: boolean;
  selected?: IExportSelected;
  selectFullOrder?: boolean;
  sort?: Array<string>;

  onSortUpdate: (newSort: Array<string>) => any;
  onOrderSelect: (newSelected: IExportSelected) => any;
}

const OrdersListTable: FunctionComponent<IOrdersListTable> = ({
  orders,
  isLoading = false,
  selected = [],
  selectFullOrder = false,
  sort = [],

  onOrderSelect = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: !selectFullOrder
      ? ExportCheckboxesColumn({
          selected,
          onSelect: onOrderSelect,
          additionalParams: { width: '30px' },
        })
      : ExportCheckboxesColumn({
          selected,
          field: null,
          checkTest: (a, b) => a.id === b.id,
          onSelect: onOrderSelect,
          additionalParams: { width: '30px' },
        }),

    // DRIVER
    driver: {
      label: 'Водитель и ID',
      sortBy: 'sort_id',
      width: '160px',
      component: (driver: any, index: number, order: any) => {
        return (
          <DriverInfoCell
            avatar={driver?.avatar}
            fullname={(driver?.firstName || '') + ' ' + (driver?.lastName || '')}
            orderId={order.order_id || order.id}
            onOrderIdClick={(orderId: string | number) => navigate(`/order/${orderId}`)}
          />
        );
      },
    },

    // TIME
    start_at: {
      label: 'Время начала',
      sortBy: 'sort_start',
      width: '160px',
      component: (time: string, index: number, order: any) => (
        <GridTableCell className="text-[#1066B1] text-[14px]">
          <div className="flex flex-col">
            <span>
              {order.status <= 2 ? 'Starts: ' : ''} {getDateString(time)}
            </span>
            <span>
              {getTimeString(time)}
              <span className="text-[12px] text-[#9b9d9c] pl-1">({timeCount(time)} ост)</span>
            </span>
          </div>
        </GridTableCell>
      ),
    },

    // STATUS
    status: {
      label: 'Статус',
      sortBy: 'sort_status',
      width: '160px',
      component: (status: string, _: number, order: any) => (
        <GridTableCell>
          <div className="px-[6px] py-[2px] bg-[#dbe8f3] rounded-[8px] text-[#1066B1] text-[12px]">
            {typeof status === 'number'
              ? findStatus({ status, arrive: order.arrive, deliver: order.deliver })?.title
              : status}
          </div>
        </GridTableCell>
      ),
    },

    // RATING
    rating: {
      label: 'Оценка',
      sortBy: 'sort_rating',
      width: '80px',
      component: (rating: number) => (
        <GridTableCell className="justify-center items-center text-sm">
          {rating ? (
            <>
              <img src="/assets/img/star.svg" alt="rate" />
              <span className="ml-1">{rating ?? 0}/5</span>
            </>
          ) : (
            <>-</>
          )}
        </GridTableCell>
      ),
    },

    // START ADDRESS
    start_to: {
      label: 'Точка начала',
      sortBy: 'sort_from',
      component: (address: string) => (
        <GridTableCell className="text-xs">
          <OverflowText width="100%" lineHeight={3}>
            <span className="pr-2">{address}</span>
          </OverflowText>
        </GridTableCell>
      ),
    },

    // END ADDRESS
    finish_to: {
      label: 'Точка назначения',
      sortBy: 'sort_to',
      component: (address: string) => (
        <GridTableCell className="text-xs">
          <OverflowText width="100%" lineHeight={3}>
            <span className="pr-2">{address}</span>
          </OverflowText>
        </GridTableCell>
      ),
    },

    // MILES
    miles: {
      label: 'Расстояние',
      sortBy: 'sort_miles',
      width: '140px',
      component: (miles: any) => <GridTableCell className="text-xs">{miles}</GridTableCell>,
    },

    // PRICE FOR CARS
    total_price: {
      label: 'Цена',
      sortBy: 'sort_price',
      width: '140px',
      component: (price: number, index: number, order: any) => (
        <GridTableCell className="text-xs break-all">
          <div className="flex flex-col">
            <p>
              <span className="mr-1">$</span>
              {price}
            </p>
            {Array.isArray(order?.items) && order.items.length ? (
              <p className="text-[#9b9d9c] text-[10px]">for {order.items.length} cars</p>
            ) : (
              ''
            )}
          </div>
        </GridTableCell>
      ),
    },
  };

  return (
    <GridTable
      data={orders}
      fields={TableFields}
      isLoading={isLoading}
      align="start"
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default OrdersListTable;
