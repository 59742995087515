const PERMISSIONS_KEY = 'permissions';

type Permissions =
  | 'orders_download_list'
  | 'orders_change_price'
  | 'orders_change_address'
  | 'orders_change_driver'
  | 'orders_change_sender_receiver'
  | 'orders_show_reports'
  | 'orders_create'
  | 'pushes_create'
  | 'pushes_edit'
  | 'statistics_show'
  | 'clients_download_list'
  | 'clients_block'
  | 'clients_history_orders'
  | 'clients_download_history_orders'
  | 'clients_history_payment'
  | 'clients_download_history_payment'
  | 'legal_entities_list'
  | 'legal_entities_change_status'
  | 'legal_entities_history_orders'
  | 'legal_entities_download_history_orders'
  | 'legal_entities_history_payment'
  | 'legal_entities_download_history_payment'
  | 'legal_entities_list_managers'
  | 'drivers_download_list'
  | 'drivers_change_status'
  | 'drivers_show'
  | 'drivers_change_company'
  | 'drivers_history_orders'
  | 'drivers_download_history_orders'
  | 'drivers_history_violations'
  | 'drivers_download_history_violations'
  | 'drivers_payment'
  | 'drivers_download_payment'
  | 'settings_order_price'
  | 'settings_fine'
  | 'settings_company'
  | 'settings_contract'
  | 'settings_admins_list'
  | 'settings_admins_invitation'
  | 'settings_admins_download_list'
  | 'settings_admins_edit'
  | 'settings_admins_block'
  | 'settings_admins_permissions'
  | 'settings_admins_history_actions'
  | 'settings_admins_history_login'
  | 'companies_download_list'
  | 'companies_change_status'
  | 'companies_edit'
  | 'companies_driver_list'
  | 'companies_download_driver_list'
  | 'companies_admins_list'
  | 'companies_download_admins_list';

export const setPermissions = (data: Permissions[]) => {
  localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(data));
};

export const getPermissions = (): Permissions[] => {
  return (localStorage.getItem(PERMISSIONS_KEY) ?? '').split(',') as Permissions[];
};

export const isPermitted = (data: Permissions) => {
  const permissions = getPermissions();

  return permissions.some(p => p.includes(data)); // permissions.includes(data);
};
