import { FunctionComponent } from 'react';

export const ClientsSelectedField: FunctionComponent<any> = ({ data = {} }) => {
  return (
    <div className="flex w-[95%] max-w-[95%] items-center">
      <div className="ml-2">
        <p className="text-[12px]">
          {data?.fullName ? data?.fullName : '-----'} {data?.email ? data?.email : '-----'}
        </p>
      </div>
    </div>
  );
};

export const ClientOption: FunctionComponent<any> = ({ option }) => {
  return (
    <>
      <div className="flex w-[95%] max-w-[95%] items-center p-1 cursor-pointer">
        <div className="ml-2">
          <p className="text-[12px]">
            {option?.fullName || '----'} {option?.email || '----'}
          </p>
        </div>
      </div>
    </>
  );
};
