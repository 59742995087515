import { ILayout } from 'src/types';

const initialState: ILayout = {
  sidebarOpen: true,
};

function layoutReducer(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_SIDEBAR':
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    default:
      return state;
  }
}

export default layoutReducer;
