import { FunctionComponent, useEffect, useState, useRef } from 'react';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import MainSection from 'src/components/Semantic/MainSection';
import Pagination from 'src/components/UI/Pagination';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import Input from 'src/components/UI/Input';
import Button from 'src/components/UI/Button';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Tooltip from 'src/components/UI/Tooltip';
import { FilterBar, FilterButton } from 'src/components/FilterBar';

import AutosListTable from 'src/components/Tables/autos/list';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import ExportModal from 'src/components/ExportModal';

const filtersTitles = {
  name: 'Марка модель и год',
  height: 'Высота',
  width: 'Ширина',
  length: 'Длина',
  weight: 'Вес',
};

const emptyFilters = {
  name: '',
  height: '',
  width: '',
  length: '',
  weight: '',
};

const ExportFields = [
  { title: 'ID', field: 'id' },
  { title: 'Название', field: 'name' },
  { title: 'Длина', field: 'length' },
  { title: 'Ширина', field: 'width' },
  { title: 'Высота', field: 'height' },
  { title: 'Вес', field: 'weight' },
  // { title: 'Дата изменения', field: 'updated' },
];

const AutoPage: FunctionComponent = () => {
  const filtersButtonRef = useRef<any>(null);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportModal, setExportModal] = useState<boolean>(false);
  const [exportColumns, setExportColumns] = useState<Array<any>>(ExportFields.map(e => e.field));

  const [loadingRows, setLoadingRows] = useState<Array<number>>([]);

  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [selected, setSelected] = useState<IExportSelected>([]);

  const [updateAutoId, setUpdateAutoId] = useState<number>(NaN);
  const [deleteAutoModalOpened, setDeleteAutoModalOpened] = useState<boolean>(false);
  const [autoFormModalType, setAutoFormModalType] = useState<string>('');
  const [autoFormModalError, setAutoFormModalError] = useState<string>('');
  const [autoForm, setAutoForm] = useState<any>({
    name: '',
    height: '',
    width: '',
    length: '',
    weight: '',
  });

  const [filters, setFilters] = useState<any>(emptyFilters);
  const [sort, setSort] = useState<Array<string>>(['sort_date', 'desc']);

  const filtersIsEmpty = () => {
    return !filters.name && !filters.height && !filters.width && !filters.length && !filters.weight;
  };

  const addLoadingRow = (rowId: number) => {
    if (loadingRows.includes(rowId)) return;
    setLoadingRows([...loadingRows, rowId]);
  };

  const dropLoadingRow = (rowId: number) => {
    setLoadingRows(loadingRows.filter(el => el !== rowId));
  };

  const updateFiltersField = (val: any, field: string) => {
    setFilters((filters: any) => {
      return {
        ...filters,
        [field]: val,
      };
    });
  };

  const updateAutoForm = (val: any, field: string) => {
    setAutoForm({ ...autoForm, [field]: val });
  };

  const clearAutoForm = () => {
    setAutoForm({
      name: '',
      height: '',
      width: '',
      length: '',
      weight: '',
    });
  };

  const getQueryFilters = () => {
    return Object.keys(filters).reduce((res, field) => {
      if (!filters[field] || filters[field] === '') {
        return res;
      }

      return { res, [field]: filters[field] };
    }, {});
  };

  const getAutos = async (currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    setIsLoading(true);

    try {
      const { data: res } = await axiosClient.get('/autos', {
        params: { page: selectedPage, [sort[0]]: sort[1], ...getQueryFilters() },
      });

      setData(res.data);
      setDataTotal(Math.ceil(res.links.total / 10));
      setPage(currentPage);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const autoRequest = async (
    url: string,
    method: string,
    withForm: boolean = false,
    rowId: number = NaN
  ) => {
    if (
      withForm &&
      (!autoForm.name || !autoForm.height || !autoForm.length || !autoForm.weight || !autoForm.width)
    ) {
      setAutoFormModalError('Все поля обязательны');
      return;
    }

    if (!isNaN(rowId)) {
      addLoadingRow(rowId);
    } else {
      setIsLoading(true);
    }

    const autoData = {
      ...autoForm,
      height: Number(autoForm.height),
      width: Number(autoForm.width),
      length: Number(autoForm.length),
      weight: Number(autoForm.weight),
    };

    try {
      if (withForm) {
        setAutoFormModalType('');
        await axiosClient[method](url, autoData);
        clearAutoForm();
      } else {
        await axiosClient[method](url);
      }
    } catch (e: any) {
      logger.error(`Ошибка при сохранении данных ${e.message}`, e);
    } finally {
      await getAutos(page);
      if (!isNaN(rowId)) dropLoadingRow(rowId);
    }
  };

  const createAuto = async () => {
    await autoRequest('/autos', 'post', true);
  };

  const updateAuto = async () => {
    if (isNaN(updateAutoId)) return;
    await autoRequest(`/autos/${updateAutoId}`, 'put', true, updateAutoId);
    setUpdateAutoId(NaN);
  };

  const deleteAuto = async () => {
    if (isNaN(updateAutoId)) return;
    await autoRequest(`/autos/${updateAutoId}`, 'delete', false, updateAutoId);
    setUpdateAutoId(NaN);
  };

  const dropFilters = async () => {
    setFilters(emptyFilters);
  };

  useEffect(() => {
    getAutos(1);
  }, [filters]);

  useEffect(() => {
    getAutos(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="flex justify-between px-[17px] pt-[14px] pb-[2px]">
              <div className="flex items-center">
                <h2 className="text-[24px] text-[#363B39] font-bold mr-6">База авто</h2>
                <div>
                  <ButtonSPrimary onClickHandler={() => setAutoFormModalType('create')}>
                    + Добавить
                  </ButtonSPrimary>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-[12px] mr-2 text-[#9b9d9c]">
                  {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
                </p>
                <div className="mr-6">
                  <ButtonSPrimary disabled={isLoading} onClickHandler={() => setExportModal(true)}>
                    Экспорт в Excel
                  </ButtonSPrimary>
                </div>
                <FilterButton
                  opened={filterOpen}
                  buttonRef={filtersButtonRef}
                  onOpen={() => setFilterOpen(true)}
                  onClose={() => setFilterOpen(false)}
                  filtersIsEmpty={filtersIsEmpty()}
                >
                  <Tooltip
                    parentRef={filtersButtonRef}
                    disabled={filtersIsEmpty()}
                    align="left"
                    className="w-[15em]"
                  >
                    {Object.keys(filtersTitles)
                      .filter(key => !!filters[key])
                      .map(key => (
                        <div className="mb-1" key={key}>
                          <p className="text-sm text-[#9f9f9f]">{filtersTitles[key]}</p>
                          <p className="text-sm">{filters[key]}</p>
                        </div>
                      ))}
                  </Tooltip>
                </FilterButton>

                <FilterBar setOpened={setFilterOpen} opened={filterOpen} onClear={dropFilters}>
                  <div>
                    {Object.keys(filtersTitles).map(key => (
                      <div className="mt-3" key={key}>
                        <p className="text-[#808080] text-sm">{filtersTitles[key]}</p>
                        <div className="w-full mt-2 text-sm">
                          <Input
                            className="w-full"
                            value={filters[key]}
                            onChange={value => updateFiltersField(value, key)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </FilterBar>
              </div>
            </div>
            <div className="flex justify-end items-center mb-3 px-[17px]"></div>

            <div className="overflow-auto bg-white">
              <AutosListTable
                autos={data}
                isLoading={isLoading}
                sort={sort}
                selected={selected}
                onSortUpdate={setSort}
                onAutoSelect={setSelected}
                onEditClick={(car: any) => {
                  setUpdateAutoId(car.id);
                  setAutoForm(car);
                  setAutoFormModalType('update');
                }}
                onTrashClick={(car: any) => {
                  setUpdateAutoId(car.id);
                  setDeleteAutoModalOpened(true);
                }}
              />

              {!isLoading && data.length !== 0 && (
                <Pagination
                  totalPages={dataTotal}
                  activePage={page}
                  setPage={setPage}
                  alignment="justify-end"
                />
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ModalBackdrop state={!!autoFormModalType.length}>
          <ModalWindow
            title={autoFormModalType === 'create' ? 'Добавление авто' : 'Редактирование'}
            setState={(): any => {
              setAutoFormModalType('');
              clearAutoForm();
            }}
          >
            <div className="flex flex-col w-[65%]">
              <div className="w-full">
                <p className="text-[#808080] text-sm">Модель, марка, год</p>
                <div className="w-full mt-2">
                  <Input
                    className="w-full"
                    placeholder="Модель, марка, год"
                    value={autoForm.name}
                    onChange={model => {
                      setAutoFormModalError('');
                      updateAutoForm(model || '', 'name');
                    }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 gap-4 my-4">
                <div>
                  <p className="text-[#808080] text-sm">Длина</p>
                  <div className="w-full mt-2">
                    <Input
                      className="w-full"
                      type="number"
                      placeholder="0"
                      value={autoForm.length}
                      onChange={length => {
                        setAutoFormModalError('');
                        updateAutoForm(length, 'length');
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className="text-[#808080] text-sm">Ширина</p>
                  <div className="w-full mt-2">
                    <Input
                      className="w-full"
                      type="number"
                      placeholder="0"
                      value={autoForm.width}
                      onChange={len => {
                        setAutoFormModalError('');
                        updateAutoForm(len, 'width');
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className="text-[#808080] text-sm">Высота</p>
                  <div className="w-full mt-2">
                    <Input
                      className="w-full"
                      type="number"
                      placeholder="0"
                      value={autoForm.height}
                      onChange={height => {
                        setAutoFormModalError('');
                        updateAutoForm(height, 'height');
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className="text-[#808080] text-sm">Вес</p>
                  <div className="w-full mt-2">
                    <Input
                      className="w-full"
                      type="number"
                      placeholder="0"
                      value={autoForm.weight}
                      onChange={weight => {
                        setAutoFormModalError('');
                        updateAutoForm(weight, 'weight');
                      }}
                    />
                  </div>
                </div>
              </div>

              <Button
                variant="primary"
                onClickHandler={() => {
                  if (autoFormModalType === 'create') {
                    createAuto();
                  } else {
                    updateAuto();
                  }
                }}
              >
                {autoFormModalType === 'create' ? 'Добавить' : 'Сохранить изменения'}
              </Button>

              {!!autoFormModalError.length && (
                <div className="mt-4 text-center text-[#EE0202]">{autoFormModalError}</div>
              )}
            </div>
          </ModalWindow>
        </ModalBackdrop>
        <ModalBackdrop state={deleteAutoModalOpened}>
          <div className="flex justify-center items-center h-full">
            <button
              onClick={() => {
                setDeleteAutoModalOpened(false);
                setUpdateAutoId(NaN);
              }}
              className={`absolute right-5 top-6`}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7M13 13L7 7M7 7L13 1M7 7L1 13"
                  stroke="white"
                  strokeOpacity="1"
                  strokeWidth="1.5"
                />
              </svg>
            </button>
            <div className="flex flex-col items-center w-2/5 bg-white p-10">
              <h3 className="text-2xl font-bold mb-4 text-center">Удалить информацию об автомобиле?</h3>
              <p className="mb-8 text-center">
                Если вы подтвердите данное действие, то данные об этом авто не будут доступны для
                быстрого заполнения в админ.панели и в приложениях
              </p>
              <div className="grid grid-cols-2 gap-4 w-3/5">
                <button
                  className="py-[10px] px-7 bg-[#F2F2F2] text-black rounded-sm border border-[#D9D9D9] cursor-pointer"
                  onClick={() => {
                    setDeleteAutoModalOpened(false);
                    setUpdateAutoId(NaN);
                  }}
                  style={{
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <span className="flex justify-center text-base flex items-center">Отмена</span>
                </button>

                <button
                  className="py-[10px] px-7 bg-[#DB482C] text-white rounded-sm border border-[#C2564B] cursor-pointer"
                  onClick={() => {
                    setDeleteAutoModalOpened(false);
                    deleteAuto();
                  }}
                  style={{
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <span className="flex justify-center text-base flex items-center">Удалить</span>
                </button>
              </div>
            </div>
          </div>
        </ModalBackdrop>

        <ExportModal
          opened={exportModal}
          isLoading={isLoading}
          selected={exportColumns}
          options={ExportFields}
          setSelected={setSelected}
          setOpened={setExportModal}
          exportParams={{
            url: `/export/autos`,
            filename: `autos.xlsx`,
            fields: exportColumns,
            filters: getQueryFilters(),
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default AutoPage;
