import React, { FunctionComponent } from 'react';

const Eye: FunctionComponent = () => {
  return (
    <>
      <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.99981 0.799988C4.66699 0.799988 0.499805 5.73749 0.499805 5.73749C0.368555 5.88749 0.368555 6.11249 0.499805 6.26249C0.499805 6.26249 4.66699 11.2 9.99981 11.2C15.3326 11.2 19.4998 6.26249 19.4998 6.26249C19.6311 6.11249 19.6311 5.88749 19.4998 5.73749C19.4998 5.73749 15.3326 0.799988 9.99981 0.799988ZM9.99981 1.59999C11.0514 1.59999 12.0561 1.81718 12.9873 2.14999C13.6123 2.85624 13.9998 3.77968 13.9998 4.79999C13.9998 7.01405 12.2139 8.79999 9.99981 8.79999C7.78574 8.79999 5.99981 7.01405 5.99981 4.79999C5.99981 3.77968 6.37481 2.85624 6.99981 2.14999C7.93418 1.81561 8.94512 1.59999 9.99981 1.59999ZM5.67481 2.73749C5.37481 3.36249 5.19981 4.06249 5.19981 4.79999C5.19981 7.44686 7.35293 9.59999 9.99981 9.59999C12.6467 9.59999 14.7998 7.44686 14.7998 4.79999C14.7998 4.06561 14.6326 3.36249 14.3373 2.73749C16.5967 3.88593 18.2217 5.55155 18.6373 5.99999C17.9873 6.70311 14.3889 10.4 9.99981 10.4C5.61074 10.4 2.0123 6.70311 1.3623 5.99999C1.77793 5.54999 3.41074 3.88593 5.67481 2.73749ZM9.99981 2.79999C8.89512 2.79999 7.99981 3.6953 7.99981 4.79999C7.99981 5.90468 8.89512 6.79999 9.99981 6.79999C11.1045 6.79999 11.9998 5.90468 11.9998 4.79999C11.9998 3.6953 11.1045 2.79999 9.99981 2.79999Z"
          fill="#2F80ED"
        />
      </svg>
    </>
  );
};

export default Eye;
