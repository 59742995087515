export const orderStatuses = [
  { id: 1, title: 'Не передан в поиск', availableStatuses: [12] },
  { id: 2, title: 'Водитель не назначен', availableStatuses: [12] },
  { id: 3, title: 'Водитель принял заказ', availableStatuses: [2, 3, 4, 5, 11, 12] },
  { id: 4, title: 'Водитель прибудет через', availableStatuses: [3, 5, 11, 12] },
  { id: 5, title: 'Водитель прибыл в точку начала', availableStatuses: [3, 4, 11, 12] },
  { id: 6, title: 'Создание акта отправки', availableStatuses: [11, 12] },
  { id: 7, title: 'Водитель доставляет заказ', availableStatuses: [8, 9, 11, 12] },
  { id: 8, title: 'Прибудет в точку выдачи через', availableStatuses: [7, 9, 11, 12] },
  { id: 9, title: 'Водитель прибыл в точку выдачи', availableStatuses: [7, 8, 11, 12] },
  { id: 10, title: 'Создание акта приемки', availableStatuses: [11, 12] },
  { id: 11, title: 'Заказ завершен', availableStatuses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
  { id: 12, title: 'Отменен', availableStatuses: [] },
];

export const progressOrderStatuses = orderStatuses.filter(e => ![1, 2, 11, 12].includes(e.id));

const options = [
  { value: '15', title: ' 15 минут' },
  { value: '60', title: ' 1 час' },
  { value: '240', title: ' 4 часа' },
];

export const countStatuses = (order: { arrive?: number; deliver?: number }) => {
  return orderStatuses.map(status => {
    if (status.id === 4 && order.arrive) {
      return {
        ...status,
        title:
          status.title + options.find(o => Number(o.value) === Number(order.arrive))?.title ??
          `${order.arrive} минут`,
      };
    }

    if (status.id === 8 && order.deliver) {
      return {
        ...status,
        title:
          status.title + options.find(o => Number(o.value) === Number(order.deliver))?.title ??
          `${order.deliver} минут`,
      };
    }

    return status;
  });
};

export const findStatus = (order: { status: number; arrive?: number; deliver?: number }) => {
  let status = orderStatuses.find(e => e.id === order.status);

  if (!status) {
    return orderStatuses[0];
  }

  const item = { ...status };

  if (status.id === 4 && order.arrive) {
    item.title =
      item.title + options.find(o => Number(o.value) === Number(order.arrive))?.title ??
      `${order.arrive} минут`;
  }

  if (status.id === 8 && order.deliver) {
    item.title =
      item.title + options.find(o => Number(o.value) === Number(order.deliver))?.title ??
      `${order.deliver} минут`;
  }

  return item;
};
