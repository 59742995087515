import React, { FunctionComponent, useEffect, useState } from 'react';
import axiosClient from 'src/libs/axiosClient';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ModalBackdrop, { ModalWindow } from '../UI/Modal';
import ButtonSPrimary from '../Custom/ButtonSPrimary';

// Steps
import FirstOrderCreateStep from './steps/first';
import SecondOrderCreateStep from './steps/second';
import ThirdOrderCreateStep from './steps/third';
import { getFormDate, parseDateFormat } from 'src/utils/format-date';

interface CreateOrderModalTypes {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormDataInitialValue = {
  sender_type: 'admin',

  startTo: '',
  start_lat: '',
  start_lng: '',
  finishTo: '',
  finish_lat: '',
  finish_lng: '',
  comment: '',
  startAtDate: '',
  startAtTime: '',
  miles: '',
  payer: {
    first_name: '',
    last_name: '',
    phone_number: '',
  },
  sender: {
    first_name: '',
    last_name: '',
    phone_number: '',
  },
  recipient: {
    first_name: '',
    last_name: '',
    phone_number: '',
  },
  total_price: '',
  driver_id: '',
  payment_type: 'COD',
  payment_where: 3,
  cars: [
    {
      car_name: '',
      vin: '',
      dimensions: 'xx',
      length: '',
      height: '',
      width: '',
      weight: '',
    },
  ],
};

const CreateOrderModal: FunctionComponent<CreateOrderModalTypes> = ({ open, setOpen }) => {
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(FormDataInitialValue);
  const [selectedDriverTrailer, setSelectedDriverTrailer] = useState<any>(null);

  const onFormDataChange = (val: any, field: string) => {
    setFormData(formData => {
      return { ...formData, [field]: val };
    });
  };

  const setMiles = () => {
    if (!formData.start_lat || !formData.start_lng || !formData.finish_lat || !formData.finish_lng) {
      onFormDataChange('', 'miles');
      return;
    }

    const origin = new google.maps.LatLng(+formData.start_lat, +formData.start_lng);
    const destination = new google.maps.LatLng(+formData.finish_lat, +formData.finish_lng);
    const service = new google.maps.DistanceMatrixService();

    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (res, status) => {
        if (
          status !== 'OK' ||
          !Array.isArray(res?.rows) ||
          !res ||
          !res.rows.length ||
          !Array.isArray(res.rows[0]?.elements) ||
          !res.rows[0].elements.length ||
          !res.rows[0].elements[0].distance?.value
        ) {
          onFormDataChange('', 'miles');
          return;
        }

        const answer = res.rows[0].elements[0].distance.value * 0.000621371192;
        onFormDataChange(answer.toFixed(2), 'miles');
      }
    );
  };

  const firstStepCheckErrors = () => {
    if (!formData.startTo) {
      setError('Адрес отправки должен быть полным');
      return true;
    }

    if (!formData.finishTo) {
      setError('Адрес получения должен быть полным');
      return true;
    }

    if (!formData.miles) {
      setError('Не удалось построить маршрут');
      return true;
    }

    if (!formData.startAtDate || !formData.startAtTime) {
      setError('Все поля обязательны к заполнению');
      return true;
    }

    const [year, month, day] = formData.startAtDate.split('-');
    if (
      new Date().getTime() >
      getFormDate(
        {
          year,
          month,
          day,
        },
        formData.startAtTime
      ).getTime()
    ) {
      setError('Введите время которое еще не прошло');
      return true;
    }

    setError('');
    return false;
  };

  const secondStepCheckErrors = () => {
    if (
      !formData.driver_id ||
      !formData.sender.phone_number ||
      !formData.recipient.phone_number ||
      !formData.payment_where
      //(formData.sender_type !== 'admin' &&
      //  !(formData.payer.first_name && formData.payer.last_name && formData.payer.phone_number))
    ) {
      setError('Все поля обязательны к заполнению');
      return true;
    }

    setError('');
    return false;
  };

  const thirdStepCheckErrors = () => {
    if (!formData.total_price) {
      setError('Все поля обязательны к заполнению');
      return true;
    }

    for (let car of formData.cars) {
      if (!car.car_name.length || !car.height || !car.width || !car.weight || !car.length) {
        setError('Все поля обязательны к заполнению');
        return true;
      }
    }

    setError('');
    return false;
  };

  const checkStepErrors = (currentStep: null | number = null) => {
    switch (currentStep || step) {
      case 1:
        return firstStepCheckErrors();
      case 2:
        return secondStepCheckErrors();
      case 3:
        return thirdStepCheckErrors();
    }
  };

  const checkAllErrors = () => {
    return firstStepCheckErrors() || secondStepCheckErrors() || thirdStepCheckErrors();
  };

  const clearForm = () => {
    setStep(1);
    setFormData(FormDataInitialValue);
    setSuccess('');
    setError('');
  };

  const createOrder = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      await axiosClient.post(`/orders`, {
        start_to: formData.startTo,
        start_lat: formData.start_lat.toString(),
        start_lng: formData.start_lng.toString(),
        finish_to: formData.finishTo,
        finish_lat: formData.finish_lat.toString(),
        finish_lng: formData.finish_lng.toString(),
        ...(formData.comment && { comment: formData.comment }),
        start_at: parseDateFormat(
          `${formData.startAtDate} ${formData.startAtTime}`,
          'YYYY-MM-DD HH:mm',
          true
        ),
        miles: formData.miles,
        sender: formData.sender,
        receiver: formData.recipient,
        sender_type: formData.sender_type,
        total_price: formData.total_price,
        driver_id: formData.driver_id,
        cars: formData.cars,
        payment_method: formData.payment_type,
        payment_where: formData.payment_where,
      });

      setSuccess('Заказ успешно создан');
      setTimeout(() => {
        setOpen(false);
        clearForm();
      }, 3000);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (step === 0) {
      setStep(1);
      return;
    }
    setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (step === 0) {
      return;
    }

    setMiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.start_lat, formData.start_lng, formData.finish_lat, formData.finish_lng]);

  return (
    <>
      <ModalBackdrop state={open}>
        <ModalWindow
          title="Создание заказа"
          setState={setOpen}
          step={step}
          setStep={(s: any) => {
            setStep(s);
            checkStepErrors(s);
          }}
          onClose={() => clearForm()}
        >
          <h1 className="text-lg font-bold mb-4">Шаг {step}/3</h1>
          <div className="flex flex-col max-h-[75vh] overflow-y-scroll px-4">
            {step === 1 && (
              <FirstOrderCreateStep formData={formData} onFormDataChange={onFormDataChange} />
            )}
            {step === 2 && (
              <div className="w-[320px]">
                <SecondOrderCreateStep
                  formData={formData}
                  onFormDataChange={onFormDataChange}
                  onTrailerChange={setSelectedDriverTrailer}
                />
              </div>
            )}
            {step === 3 && (
              <div className="w-[320px]">
                <ThirdOrderCreateStep
                  formData={formData}
                  selectedDriverTrailer={selectedDriverTrailer}
                  onFormDataChange={onFormDataChange}
                />
              </div>
            )}
            <div className="mt-6 w-full">
              <ButtonSPrimary
                disabled={isLoading || !!error.length || !!success.length}
                onClickHandler={() => {
                  const errorExist = step !== 3 ? checkStepErrors() : checkAllErrors();
                  if (errorExist) return;
                  if (step < 3) {
                    setStep(step + 1);
                  } else {
                    createOrder();
                  }
                }}
              >
                {step < 3 ? 'Далее' : 'Создать заказ'}
              </ButtonSPrimary>
            </div>
            <div className=" px-0 mt-4">
              {!!error && <p className="text-center text-[#EE0202]">{error}</p>}
              {!!success.length && <p className="text-center text-[green] ">{success}</p>}
            </div>
          </div>
        </ModalWindow>
      </ModalBackdrop>
    </>
  );
};

export default CreateOrderModal;
