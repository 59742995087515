import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridTable, GridTableCell } from 'src/components/GridTable';
import Select from 'src/components/UI/Select';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import CompanyInfoCell from '../BASIC_CELLS/CompanyInfoCell';

const statuses = [
  { label: 'Отложена', value: 'postponed', id: 1, color: '#f2e9ff', textColor: '#7f22fd' },
  { label: 'Выплачено', value: 'paid', id: 3 },
  { label: 'Одобрена', value: 'approved', id: 2, color: '#e9f7ef', textColor: '#27ae60' },
  { label: 'Отменена', value: 'canceled', id: 4, color: '#fde6e6', textColor: '#ee0202' },
];

interface ICompanyPaymentsTable {
  payments: Array<any>;
  isLoading?: boolean;
  loadingRow?: number;
  sort: Array<string>;
  selected: IExportSelected;

  onPaymentSelect: (newSelected: IExportSelected) => any;
  onPaymentStatusUpdate?: (paymentId: number, newStatus: string, rowIndex: number) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const CompanyPaymentsTable: FunctionComponent<ICompanyPaymentsTable> = ({
  payments,
  isLoading = false,
  loadingRow = NaN,
  sort = [],
  selected = [],

  onPaymentSelect = () => {},
  onPaymentStatusUpdate = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: onPaymentSelect,
      additionalParams: { width: '30px' },
    }),

    id: {
      label: 'Компания',
      sortBy: 'sort_company',

      component: (id: number, index: number, payment: any) => (
        <CompanyInfoCell
          id={payment.company_id}
          name={payment?.company_name}
          logo={payment?.company_logo}
          onIdClick={() => navigate(`/transport-companies/list/${id}/general`)}
        />
      ),
    },

    status: {
      label: 'Статус',
      sortBy: 'sort_status',

      component: (status: number, index: number, payment: any, rowIndex: number) => (
        <GridTableCell>
          <Select
            options={statuses}
            value={statuses.find(s => s.id === status)}
            disabled={!isNaN(loadingRow)}
            placeholder="Статус"
            onSelect={status => onPaymentStatusUpdate(payment.id, status.id, rowIndex)}
          />
        </GridTableCell>
      ),
    },

    orderCount: { label: 'Заказов', sortBy: 'sort_order' },
    period: { label: 'Период', sortBy: 'sort_period' },
    summa: {
      label: 'Сумма',
      sortBy: 'sort_sum',

      formatter: (summa: number | string, index: number, payment: any) => {
        return summa ? `${payment.currency || '$'}${summa}` : '-';
      },
    },
  };

  return (
    <GridTable
      data={payments}
      fields={TableFields}
      isLoading={isLoading}
      loadingRow={loadingRow}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default CompanyPaymentsTable;
