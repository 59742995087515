import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import MainSection from 'src/components/Semantic/MainSection';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import MailingsTable from 'src/components/Tables/mailing/list';
import Button from 'src/components/UI/Button';
import CheckList from 'src/components/UI/CheckList';
import DatePicker from 'src/components/UI/DatePicker';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Pagination from 'src/components/UI/Pagination';
import Textarea from 'src/components/UI/Textarea';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import Input from 'src/components/UI/Input';

import { defaultToDayRange } from 'src/utils/calendar';
import { getFormDate, parseDateFormat } from 'src/utils/format-date';
import { isPermitted } from 'src/utils/permissions';

export const isPushSended = (status: string) => {
  return status === 'Отправлен';
};

const Notifications: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_date', 'desc']);
  const [formData, setFormData] = useState<any>({
    recipient: [],
    date_send: defaultToDayRange,
    time_send: '',
    message: '',
  });
  const [createModal, setCreateModal] = useState<any>(false);
  const timeInputRef = useRef();

  const toggleCreateModal = (val: string | number | boolean) => {
    setFormData({
      recipient: [],
      date_send: '',
      time_send: '',
      message: '',
    });
    setCreateModal(() => val);
  };

  const onFormDataFieldChange = (val: any, field: string) => {
    setFormData({
      ...formData,
      [field]: val,
    });
  };

  const getNotification = async (id: string | number) => {
    try {
      setIsLoading(val => true);
      const res = await axiosClient.get(`/pushes/${id}`);

      let [date, time] = res.data.date_send.split(' ');
      date = date.split('-');

      if (date.length < 3) {
        return;
      }

      date = { year: +date[0], month: +date[1], day: +date[2] };

      setFormData({
        id,
        recipient: res.data.recipient.split(','),
        date_send: date,
        time_send: time,
        message: res.data.message,
        status: res.data.status,
      });
      setIsLoading(val => false);
    } catch (e: any) {
      setIsLoading(val => false);
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    }
  };

  const getNotifications = async (currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    try {
      setIsLoading(val => true);
      setData((val: any) => []);
      const res = await axiosClient.get(`/pushes`, {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
        },
      });
      setDataTotal(res.data.links.lastPage);
      setData((val: any) => res.data.data);
      setIsLoading(val => false);
    } catch (e: any) {
      setIsLoading(val => false);
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    }
  };

  useEffect(() => {
    getNotifications(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  const onCreateUpdateHandler = async () => {
    try {
      setIsLoading(val => true);
      const data = {
        recipient: formData.recipient.join(','),
        message: formData.message,
        date_send: parseDateFormat(
          `${formData.date_send.year}-${formData.date_send.month}-${formData.date_send.day} ${formData.time_send}`,
          'YYYY-MM-DD HH:mm'
        ),
      };
      if (typeof createModal === 'number') {
        await axiosClient.patch(`/pushes/${createModal}`, {
          ...data,
        });
      } else {
        await axiosClient.post(`/pushes`, {
          ...data,
        });
      }
      setIsLoading(val => false);
      toggleCreateModal(false);
      getNotifications(page);
    } catch (e: any) {
      setIsLoading(val => false);
      logger.error(e.response.data.message);
    }
  };

  const onDeleteHandler = async () => {
    setIsLoading(true);

    try {
      await axiosClient.delete(`/pushes/${createModal}`);
      toggleCreateModal(false);
      await getNotifications(page);
    } catch (e: any) {
      logger.error(e.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidDate = () => {
    if (isPushSended(formData.status)) return true;

    return new Date().getTime() < getFormDate(formData.date_send, formData.time_send).getTime();
  };
  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="flex justify-between px-[17px] pt-[14px] pb-[12px]">
              <h2 className="text-[24px] text-[#363B39] font-bold">Рассылки</h2>
              <div className="flex items-center">
                {isPermitted('pushes_create') && (
                  <div>
                    <ButtonSPrimary onClickHandler={() => toggleCreateModal(true)}>
                      + Добавить
                    </ButtonSPrimary>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-end items-center mb-3 px-[17px]"></div>
            <div className="overflow-auto bg-white px-3">
              <MailingsTable
                mails={data}
                isLoading={isLoading}
                sort={sort}
                onSortUpdate={setSort}
                onMailEdit={(mailId: number) => {
                  toggleCreateModal(mailId);
                  getNotification(mailId);
                }}
              />

              {!isLoading && data.length !== 0 && (
                <Pagination
                  totalPages={dataTotal}
                  activePage={page}
                  setPage={setPage}
                  alignment="justify-end"
                />
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ModalBackdrop state={!!createModal}>
          <ModalWindow
            title={typeof createModal === 'number' ? 'Изменение рассылки' : 'Создание рассылки'}
            setState={setCreateModal}
            onClose={() => toggleCreateModal(false)}
          >
            <div className="flex flex-col">
              <div>
                <p>Дата и время отправки</p>
                <div className="flex mt-2">
                  <div className="w-[160px] mr-2">
                    <DatePicker
                      value={formData.date_send}
                      min={defaultToDayRange}
                      onChange={e => onFormDataFieldChange(e, 'date_send')}
                    />
                  </div>
                  <div className="w-[120px]">
                    <Input
                      ref={timeInputRef}
                      value={formData.time_send}
                      type="time"
                      style={{ padding: '0.25em 0 0.25em 0.75em' }}
                      min={new Date().toString()}
                      onChange={e => onFormDataFieldChange(e, 'time_send')}
                    />
                  </div>
                </div>
                {/* {new Date(new Date().toISOString().split('T')[0]) > new Date(formData.date_send) && (
                  <p className="text-red-600">Дата не может быть прошедшей</p>
                )} */}
                {formData.date_send && formData.time_send && !isValidDate() && (
                  <p className="text-red-600">* Введите время которое еще не прошло</p>
                )}
              </div>
              <div className="mt-6">
                <p>Получатели</p>
                <div className="mt-2">
                  <CheckList
                    selected={formData.recipient}
                    options={['Водители', 'Клиенты']}
                    onChange={newSelected => {
                      onFormDataFieldChange(newSelected, 'recipient');
                    }}
                  />
                </div>
              </div>
              <div className="mt-6">
                <p>Текст сообщения (не более 500 символов)</p>
                <Textarea
                  maxLength={500}
                  value={formData.message}
                  setValue={e => onFormDataFieldChange(e, 'message')}
                />
              </div>
              <div className="w-[320px] mt-6">
                <Button
                  disabled={() => {
                    if (isPushSended(formData.status)) return true;

                    return !formData.recipient || !formData.message || !isValidDate();
                  }}
                  onClickHandler={() => onCreateUpdateHandler()}
                >
                  {formData.id ? 'Сохранить' : 'Создать'}
                </Button>
              </div>
              {typeof createModal === 'number' && (
                <p
                  className="text-red-600 text-center mt-3 cursor-pointer"
                  onClick={() => onDeleteHandler()}
                >
                  Удалить
                </p>
              )}
            </div>
          </ModalWindow>
        </ModalBackdrop>
      </ModalsSection>
    </>
  );
};

export default Notifications;
