import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import logger from 'src/utils/logger';
import MainSection from 'src/components/Semantic/MainSection';
import Checkbox from 'src/components/UI/Checkbox';
import DatePicker from 'src/components/UI/DatePicker';
import VerticalLayout from 'src/layouts/VerticalLayout';
import Select from 'src/components/UI/Select';
import Input from 'src/components/UI/Input';
import axiosClient from 'src/libs/axiosClient';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux';
import Loader from 'src/components/Custom/Loader';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import { defaultToDayRange, formatDate } from 'src/utils/calendar';
import { getFormDate } from 'src/utils/format-date';
import { isPermitted } from 'src/utils/permissions';
import CommonFile from './file';

interface SettingsFile {
  id: number;
  name: string;
}

interface ISettings {
  id: string;
  timezone: string;
  driver_commission: string;
  driver_privacy_policy: SettingsFile | null;
  client_privacy_policy: SettingsFile | null;
  driver_terms_of_use: SettingsFile | null;
  client_terms_of_use: SettingsFile | null;
  [key: string]: any;
}

const timezones = [
  'GMT-12',
  'GMT-11',
  'GMT-10',
  'GMT-9',
  'GMT-8',
  'GMT-7',
  'GMT-6',
  'GMT-5',
  'GMT-4',
  'GMT-3',
  'GMT-2',
  'GMT-1',
  'GMT-0',
  'GMT+1',
  'GMT+2',
  'GMT+3',
  'GMT+4',
  'GMT+5',
  'GMT+6',
  'GMT+7',
  'GMT+8',
  'GMT+9',
  'GMT+10',
  'GMT+11',
  'GMT+12',
];

const rates = [
  'Стандартный',
  '<5%',
  '>5%',
  '<10%',
  '>10%',
  '<15%',
  '>15%',
  '<20%',
  '>20%',
  '<25%',
  '>25%',
  '<30%',
  '>30%',
  '<35%',
  '>35%',
  '<40%',
  '>40%',
  '<45%',
  '>45%',
  '<50%',
  '>50%',
  '<55%',
  '>55%',
  '<60%',
  '>60%',
  '<65%',
  '>65%',
  '<70%',
  '>70%',
  '<75%',
  '>75%',
  '<80%',
  '>80%',
  '<85%',
  '>85%',
  '<90%',
  '>90%',
];

const periods = [
  { label: 'Месяц', field: 'month' },
  { label: 'Неделя', field: 'week' },
  { label: 'Заказ', field: 'order' },
];

const Common: FunctionComponent = () => {
  const priceTimeInputRef = useRef();
  const billFromTimeInputRef = useRef();
  const billToTimeInputRef = useRef();

  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [ordersPrice, setOrderPrice] = useState({
    rate: '',
    date_to_time: '',
    date_to_date: defaultToDayRange,
    no_limit: '',
  });
  const [errorsFields, setErrorsFields] = useState<{ [key: string]: boolean }>({});
  const [defaultDateTo, setDefaultDateTo] = useState<Date>();
  const [isEditedDateRate, setIsEditedDateRate] = useState(false);

  const onOrdersPriceFieldChange = (val: any, field: string) => {
    setOrderPrice(() => ({
      ...ordersPrice,
      [field]: val,
    }));
  };

  const [fineSettings, setFineSettings] = useState({
    fine: '',
    limit: '',
    period: periods[0],
    max_speed: '',
    night_riding_to: '',
    night_riding_from: '',
    driving_without_rest: '',
  });

  const onFineSettingsFieldChange = (val: any, field: string) => {
    setFineSettings(() => ({
      ...fineSettings,
      [field]: val,
    }));
  };

  const [data, setData] = useState<ISettings>({
    id: '',
    timezone: '',
    driver_commission: '',
    driver_privacy_policy: null,
    client_privacy_policy: null,
    driver_terms_of_use: null,
    client_terms_of_use: null,
  });

  const onDataFieldChange = (val: any, field: string) => {
    setErrorsFields({
      ...errorsFields,
      [field]: false,
    });
    setData(() => ({
      ...data,
      [field]: val,
    }));
  };

  const user = useSelector((store: IStore) => store.user);
  const userRole = user.role || localStorage.getItem('userRole');

  const [driverPrivacyPolicy, setDriverPrivacyPolicy] = useState<any>(null);
  const [clientPrivacyPolicy, setClientPrivacyPolicy] = useState<any>(null);
  const [driverTermsOfUse, setDriverTermsOfUse] = useState<any>(null);
  const [clientTermsOfUse, setClientTermsOfUse] = useState<any>(null);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);
      try {
        const res = await axiosClient.get('/settings');

        setData({
          ...res.data,
        });

        setDriverPrivacyPolicy(data.driver_privacy_policy);
        setClientPrivacyPolicy(data.client_privacy_policy);
        setDriverTermsOfUse(data.driver_terms_of_use);
        setClientTermsOfUse(data.client_terms_of_use);

        setFineSettings({
          ...res.data.fine_settings,
          period: periods.find(p => p.field === res.data.fine_settings.period),
        });

        const orderPrice =
          typeof res.data.order_price === 'string'
            ? JSON.parse(res.data.order_price)
            : res.data.order_price;
        let [date, time] = orderPrice.date_to.split(' ');
        date = date.split('-');

        if (date.length < 3) {
          return;
        }

        date = { year: +date[0], month: +date[1], day: +date[2] };

        setDefaultDateTo(new Date(orderPrice.date_to));
        setOrderPrice({
          rate: orderPrice.rate,
          date_to_date: date,
          date_to_time: time,
          no_limit: orderPrice.no_limit,
        });
      } catch (e: any) {
        logger.error(`Ошибка при получении данных ${e.message}`, e);
      }
      setIsLoading(() => false);
    };
    getData();
  }, []);

  useEffect(() => {
    setIsEditedDateRate(
      !!defaultDateTo &&
        defaultDateTo.getTime() !==
          getFormDate(ordersPrice.date_to_date, ordersPrice.date_to_time).getTime()
    );
  }, [defaultDateTo, ordersPrice]);

  const isValidDate = () => {
    return (
      new Date().getTime() < getFormDate(ordersPrice.date_to_date, ordersPrice.date_to_time).getTime()
    );
  };

  const validateForm = () => {
    let message: string = '';
    const errors: any = {};
    if (!ordersPrice.date_to_time) {
      errors.date_to_time = true;
    }
    if (!ordersPrice.date_to_date) {
      errors.date_to_date = true;
    }
    if (!fineSettings.max_speed) {
      errors.max_speed = true;
    }
    if (!fineSettings.night_riding_from) {
      errors.night_riding_from = true;
    }
    if (!fineSettings.night_riding_to) {
      errors.night_riding_to = true;
    }
    if (!fineSettings.driving_without_rest) {
      errors.driving_without_rest = true;
    }
    if (!fineSettings.limit) {
      errors.limit = true;
    }
    if (!fineSettings.fine) {
      errors.fine = true;
    }

    if (Object.keys(errors).length > 0) {
      message = 'Все поля обязательны к заполнению';
    }

    if (!!message || Object.keys(errors).length > 0) {
      setErrorsFields(errors);
      logger.error(message);

      throw new Error('Validation error');
    }
  };

  const updateSettings = async () => {
    validateForm();

    setIsLoading(() => true);
    try {
      const formData = new FormData();
      formData.append('timezone', data.timezone);
      formData.append('driver_commission', data.driver_commission);
      formData.append('order_price[rate]', ordersPrice.rate);
      formData.append(
        'order_price[date_to]',
        `${formatDate(ordersPrice.date_to_date, '-')} ${ordersPrice.date_to_time}`
      );
      formData.append('order_price[no_limit]', ordersPrice.no_limit ? '1' : '0');

      formData.append('fine_settings[fine]', fineSettings.fine);
      formData.append('fine_settings[limit]', fineSettings.limit);
      formData.append('fine_settings[period]', fineSettings.period.field);
      formData.append('fine_settings[max_speed]', fineSettings.max_speed);
      formData.append('fine_settings[night_riding_to]', fineSettings.night_riding_to);
      formData.append('fine_settings[night_riding_from]', fineSettings.night_riding_from);
      formData.append('fine_settings[driving_without_rest]', fineSettings.driving_without_rest);

      localStorage.setItem('timezone', data.timezone);

      formData.append('driver_privacy_policy', driverPrivacyPolicy?.id ?? null);
      formData.append('client_privacy_policy', clientPrivacyPolicy?.id ?? null);
      formData.append('driver_term_of_use', driverTermsOfUse?.id ?? null);
      formData.append('client_term_of_use', clientTermsOfUse?.id ?? null);

      await axiosClient.post('/settings', formData);

      if (!driverPrivacyPolicy && data.driver_privacy_policy) {
        await axiosClient.delete(`/settings/file/${data.driver_privacy_policy.id}`);
      }
      if (!clientPrivacyPolicy && data.client_privacy_policy) {
        await axiosClient.delete(`/settings/file/${data.client_privacy_policy.id}`);
      }

      if (!driverTermsOfUse && data.driver_terms_of_use) {
        await axiosClient.delete(`/settings/file/${data.driver_terms_of_use.id}`);
      }
      if (!clientTermsOfUse && data.client_terms_of_use) {
        await axiosClient.delete(`/settings/file/${data.client_terms_of_use.id}`);
      }

      setSuccess('Изменения успешно сохранены');
      setTimeout(() => {
        setSuccess(() => '');
      }, 4000);
    } catch (e: any) {
      logger.error(`Ошибка при сохранении настроек ${e.message}`, e);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    if (!ordersPrice.no_limit) {
      onOrdersPriceFieldChange('Стандартный', 'rate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersPrice.no_limit]);

  useEffect(() => {
    if (!ordersPrice.date_to_date || !ordersPrice.date_to_time || !ordersPrice.rate) {
      setIsFormValid(false);
      return;
    }

    if (
      !fineSettings.driving_without_rest ||
      !fineSettings.fine ||
      !fineSettings.limit ||
      !fineSettings.max_speed ||
      !fineSettings.night_riding_from ||
      !fineSettings.night_riding_to ||
      !fineSettings.period
    ) {
      setIsFormValid(false);

      return;
    }
    if (!data.timezone) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);
  }, [fineSettings, ordersPrice, data]);

  const isFormPriceDisabled = () => {
    return !isPermitted('settings_order_price');
  };
  const isFormFineDisabled = () => {
    return !isPermitted('settings_fine');
  };
  const isFormContractDisabled = () => {
    return !isPermitted('settings_contract');
  };

  return (
    <>
      <MainSection>
        <VerticalLayout>
          {isLoading ? (
            <Loader size={100} />
          ) : (
            <div className={`h-full text-[#363B39]`}>
              <div className="bg-[#fff] pl-[18px] pt-3 pb-5">
                <h3 className="text-2xl font-bold">Общие настройки</h3>
                <p className="mt-2 mb-2">Часовой пояс</p>
                <Select
                  value={data.timezone}
                  options={timezones}
                  className="max-w-[20em]"
                  onSelect={option => {
                    onDataFieldChange(option, 'timezone');
                  }}
                  maxHeight={12}
                />
              </div>
              <div className="bg-[#fff] pl-[18px] pt-3 pb-5 mt-2">
                <p className="text-sm font-bold">Комиссия для водителя</p>
                <p className="mt-2 mb-2">Комиссия в % от стоимости заказа</p>
                <Input
                  type="number"
                  value={data.driver_commission}
                  error={errorsFields.max_speed}
                  min={0}
                  max={100}
                  className="flex flex-row-reverse max-w-[20em]"
                  onChange={e => {
                    onDataFieldChange(e, 'driver_commission');
                  }}
                >
                  <span className="text-[0.9em] mx-2 opacity-70">%</span>
                </Input>
              </div>
              <div className="bg-[#fff] pl-[18px] pt-3 pb-5 mt-2">
                <p className="text-sm font-bold">Стоимость заказа</p>
                <p className="mt-2 mb-2">Тариф</p>
                <Select
                  value={ordersPrice.rate}
                  options={rates}
                  className="max-w-[20em]"
                  onSelect={option => onOrdersPriceFieldChange(option, 'rate')}
                  maxHeight={12}
                  disabled={isFormPriceDisabled()}
                />
                <p className="mt-2 mb-2">
                  Период действия до <span className="text-xs">(с момента сохранения изменений)</span>
                </p>
                <div className="flex items-center">
                  <div className="max-w-[10em]">
                    <DatePicker
                      value={ordersPrice.date_to_date}
                      onChange={e =>
                        isFormPriceDisabled() ? {} : onOrdersPriceFieldChange(e, 'date_to_date')
                      }
                      min={isEditedDateRate ? defaultToDayRange : undefined}
                      calendarPopperPosition={'bottom'}
                    />
                  </div>
                  <div className="ml-2 max-w-[8em]">
                    <Input
                      ref={priceTimeInputRef}
                      value={ordersPrice.date_to_time}
                      type="time"
                      error={errorsFields.date_to_time}
                      style={{ padding: '0.25em 0 0.25em 0.75em' }}
                      readonly={isFormPriceDisabled()}
                      onChange={e => onOrdersPriceFieldChange(e, 'date_to_time')}
                    />
                  </div>
                  <div
                    className="flex items-center ml-3 cursor-pointer"
                    onClick={() => onOrdersPriceFieldChange(!ordersPrice.no_limit, 'no_limit')}
                  >
                    <Checkbox
                      value={!!ordersPrice.no_limit}
                      disabled={isFormPriceDisabled()}
                      setValue={e => onOrdersPriceFieldChange(e, 'no_limit')}
                    />
                    <p className="ml-2">Без ограничения</p>
                  </div>
                </div>
                {isEditedDateRate && !isValidDate() && (
                  <p className="text-sm text-red-600">* Введите время которое еще не прошло</p>
                )}
              </div>
              <div className="bg-[#fff] pl-[18px] pt-3 pb-5 mt-2">
                <p className="text-sm font-bold">Настройки штрафа</p>
                <div className="flex mt-3">
                  <div className="w-[400px]">
                    <p className="text-[#808080] text-sm mb-1">Макс.скорость</p>
                    <div className="w-[120px]">
                      <Input
                        theme={fineSettings.max_speed ? 'primary' : 'danger'}
                        type="number"
                        value={fineSettings.max_speed}
                        error={errorsFields.max_speed}
                        min={0}
                        max={200}
                        readonly={isFormFineDisabled()}
                        className="flex flex-row-reverse"
                        onChange={value => {
                          onFineSettingsFieldChange(value, 'max_speed');
                        }}
                      >
                        <span className="text-[0.9em] mx-2 opacity-70">mi/h</span>
                      </Input>
                    </div>
                    <p className="text-[#808080] text-sm mt-4 mb-1">Ночная езда</p>
                    <div className="flex items-center">
                      <p className="pr-2">с</p>
                      <div className="w-[120px]">
                        <Input
                          ref={billFromTimeInputRef}
                          value={fineSettings.night_riding_from}
                          error={errorsFields.night_riding_from}
                          type="time"
                          readonly={isFormFineDisabled()}
                          style={{ padding: '0.25em 0 0.25em 0.75em' }}
                          onChange={e => onFineSettingsFieldChange(e, 'night_riding_from')}
                        />
                      </div>
                      <p className="pl-4 pr-2">по</p>
                      <div className="w-[120px]">
                        <Input
                          ref={billToTimeInputRef}
                          value={fineSettings.night_riding_to}
                          error={errorsFields.night_riding_to}
                          type="time"
                          readonly={isFormFineDisabled()}
                          style={{ padding: '0.25em 0 0.25em 0.75em' }}
                          onChange={e => onFineSettingsFieldChange(e, 'night_riding_to')}
                        />
                      </div>
                    </div>
                    <p className="text-[#808080] text-sm mt-4 mb-1">Езда без отдыха</p>
                    <div className="max-w-[5em]">
                      <Input
                        theme={fineSettings.driving_without_rest ? 'primary' : 'danger'}
                        type="number"
                        readonly={isFormFineDisabled()}
                        value={fineSettings.driving_without_rest}
                        error={errorsFields.driving_without_rest}
                        min={1}
                        max={24}
                        className="flex flex-row-reverse"
                        onChange={value => {
                          onFineSettingsFieldChange(value, 'driving_without_rest');
                        }}
                      >
                        <span className="text-[0.9em] mx-2 opacity-70">ч.</span>
                      </Input>
                    </div>
                  </div>
                  <div className="w-[400px]">
                    <p className="text-[#808080] text-sm mb-1">Период накопления нарушений</p>
                    <div className="w-[120px]">
                      <Select
                        value={fineSettings.period}
                        options={periods}
                        className="max-w-[20em]"
                        onSelect={option => onFineSettingsFieldChange(option, 'period')}
                        maxHeight={12}
                        disabled={isFormFineDisabled()}
                      />
                    </div>
                    <p className="text-[#808080] text-sm mt-4 mb-1">Лимит нарушений</p>
                    <div className="w-[120px]">
                      <Input
                        theme={fineSettings.limit ? 'primary' : 'danger'}
                        error={errorsFields.limit}
                        readonly={isFormFineDisabled()}
                        value={fineSettings.limit}
                        onChange={e => onFineSettingsFieldChange(e, 'limit')}
                      />
                    </div>
                    <p className="text-[#808080] text-sm mt-4 mb-1">
                      Сумма штрафа при достижении лимита
                    </p>
                    <div className="w-[120px]">
                      <Input
                        value={fineSettings.fine}
                        error={errorsFields.fine}
                        theme={fineSettings.fine ? 'primary' : 'danger'}
                        min={0}
                        type="number"
                        readonly={isFormFineDisabled()}
                        onChange={e => onFineSettingsFieldChange(e, 'fine')}
                      >
                        <div className="ml-2 my-2">
                          <img className="w-[0.8em]" src="/assets/img/dollar.svg" alt="" />
                        </div>
                      </Input>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {!isFormContractDisabled() && userRole !== 'companyAdmin' ? (
                  <div className="bg-[#fff] pl-[18px] pt-3 pb-5 mt-2">
                    <div className="flex w-full">
                      <div className="mr-20">
                        <p className="text-sm font-bold">Договоры приложения водителя</p>
                        <CommonFile
                          title="Политика конфиденциальности"
                          file={driverPrivacyPolicy}
                          setFile={setDriverPrivacyPolicy}
                        />

                        <CommonFile
                          title="Пользовательское соглашение"
                          file={driverTermsOfUse}
                          setFile={setDriverTermsOfUse}
                        />
                      </div>

                      <div>
                        <p className="text-sm font-bold">Договоры клиентского приложения</p>
                        <CommonFile
                          title="Политика конфиденциальности"
                          file={clientPrivacyPolicy}
                          setFile={setClientPrivacyPolicy}
                        />
                        <CommonFile
                          title="Пользовательское соглашение"
                          file={clientTermsOfUse}
                          setFile={setClientTermsOfUse}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="bg-[#fff] pl-[18px] pt-3 pb-5 mt-2">
                  <div className="w-[15em] text-[1.25em]">
                    <ButtonSPrimary disabled={!isFormValid} onClickHandler={updateSettings}>
                      Сохранить
                    </ButtonSPrimary>
                  </div>
                  {success && <p className="text-green-500">{success}</p>}
                </div>
              </div>
            </div>
          )}
        </VerticalLayout>
      </MainSection>
    </>
  );
};

export default Common;
