import React, { FunctionComponent } from 'react';
import Logo from 'src/components/UI/Logo';

const UnAuthorizedLayout: FunctionComponent = ({ children }) => {
  return (
    <div className="bg-[#f2f2f2] min-h-full">
      <div className="bg-[#f2f2f2] h-[6vh] w-full shadow-md flex items-center justify-center px-4">
        <Logo />
      </div>
      <div className="w-[100%] min-h-[-webkit-fill-available] h-[fit-content] flex items-center mt-14">
        {children}
      </div>
    </div>
  );
};

export default UnAuthorizedLayout;
