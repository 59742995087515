import { FunctionComponent, useEffect, useState } from 'react';
import AddressInput from 'src/components/Custom/AdressInput';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Loader from 'src/components/Custom/Loader';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import { isPermitted } from 'src/utils/permissions';

export const Addresses: FunctionComponent<any> = ({
  start,
  finish,
  totalPrice,
  miles,
  onStartEditClick = () => {},
  onFinishEditClick = () => {},
}) => {
  return (
    <>
      <p className="text-[#999999] mb-1">Маршрут</p>

      <div className="flex items-center">
        <div className="rounded-full border-[3px] border-[#27AE60] min-w-[12px] h-[12px]"></div>
        <p className="ml-[6px]">{start}</p>
        {isPermitted('orders_change_address') && (
          <img
            src="/assets/img/pencil.svg"
            alt="change"
            className="cursor-pointer"
            onClick={onStartEditClick}
          />
        )}
      </div>
      <div className="flex items-center relative">
        <div className="rounded-full bg-[#c8dced] min-w-[3px] h-[40px] absolute left-[4px]">
          <div className="rounded-full bg-[#1066B1] min-w-[3px] h-[50%] absolute"></div>
        </div>
        <p className="ml-[18px] text-[#999999] mb-1 mt-1">
          {miles}mi (${(totalPrice / miles).toFixed(1)}/mi)
        </p>
      </div>
      <div className="flex items-center">
        <div className="rounded-full border-[3px] border-[#1066B1] min-w-[12px] h-[12px]"></div>
        <p className="ml-[6px]">{finish}</p>
        {isPermitted('orders_change_address') && (
          <img
            src="/assets/img/pencil.svg"
            alt="change"
            className="cursor-pointer"
            onClick={onFinishEditClick}
          />
        )}
      </div>
    </>
  );
};

export const AddressChangeModal: FunctionComponent<any> = ({
  opened = false,
  title = 'Address',
  type = 'from',
  isLoading = false,
  data,
  setOpened = () => {},
  onSave = () => {},
}) => {
  const [error, setError] = useState<string>('');
  const [address, setAddress] = useState();
  const [addressString, setAddressString] = useState('');

  useEffect(() => {
    setAddressString(data);
  }, [opened, data]);

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow title={title} setState={setOpened}>
        <div className="flex flex-col items-center">
          <AddressInput
            value={addressString}
            point={type}
            onChange={(newAddress: any) => {
              if (!newAddress) {
                setError('Адрес отправки должен быть полным');
                return;
              }

              setError('');
              setAddress(newAddress);
              setAddressString(newAddress.title);
            }}
          />
          {error && <p className="text-[#EE0202] px-8 mt-4">{error}</p>}
          {isLoading ? (
            <div className="flex justify-center">
              <Loader size={100} />
            </div>
          ) : (
            <div className="w-[320px] text-[1.1em] mt-6">
              <ButtonSPrimary disabled={!!error.length} onClickHandler={() => onSave(address)}>
                Сохранить
              </ButtonSPrimary>
            </div>
          )}
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};
