import { FunctionComponent } from 'react';
import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import TimeCell from '../BASIC_CELLS/TimeCell';

interface ILoginsHistoryTable {
  data: Array<any>;
  isLoading: boolean;
  sort: Array<string>;
  selected: IExportSelected;
  onSelect: (newSelected: IExportSelected) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const LoginsHistoryTable: FunctionComponent<ILoginsHistoryTable> = ({
  data,
  isLoading = false,
  sort = [],
  selected = [],
  onSelect = () => {},
  onSortUpdate = () => {},
}) => {
  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect,
      additionalParams: { width: '30px' },
    }),

    ip: { label: 'IP', sortBy: 'sort_ip' },
    client: {
      label: 'Устройство',
      sortBy: 'sort_client',
      component: (client: string) => (
        <GridTableCell>
          <span className="max-w-[15rem]">{client}</span>
        </GridTableCell>
      ),
    },
    date: {
      label: 'Дата и время',
      sortBy: 'sort_date',
      component: (date: string) => <TimeCell date={new Date(date)} />,
    },
  };

  return (
    <GridTable
      data={data}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default LoginsHistoryTable;
