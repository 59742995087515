import React, { FunctionComponent, useEffect, useState } from 'react';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import MainSection from 'src/components/Semantic/MainSection';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Button from 'src/components/UI/Button';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Pagination from 'src/components/UI/Pagination';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';
import Textarea from 'src/components/UI/Textarea';
import CompaniesListTable from 'src/components/Tables/companies/list';
import ExportModal from 'src/components/ExportModal';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';

interface ICompany {
  id: number;
  name?: string;
  logo?: string;
  state?: string;
  status: number;
  admin_count?: number;
  driver_count?: number;
  links?: any;
}

const exportColumns = [
  { title: 'ID', field: 'id' },
  { title: 'Почта', field: 'email' },
  { title: 'Название компании', field: 'name' },
  { title: 'Штат', field: 'state' },
  { title: 'Кол-во лет на рынке', field: 'years_on_market' },
  { title: 'DOT/MC', field: 'dot_mc' },
  { title: 'Bank Account Number', field: 'bank_account_number' },
  { title: 'Bank name', field: 'bank_name' },
  { title: 'Routing number', field: 'routing_number' },
  { title: 'Админы', field: 'admin_count' },
  { title: 'Водители', field: 'driver_count' },
];

const CompaniesList: FunctionComponent = () => {
  const [companies, setCompanies] = useState<Array<ICompany>>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<any>(['sort_status', 'desc']);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingRow, setLoadingRow] = useState<number>(NaN);

  const [exportTableColumns, setExportTableColumns] = useState<Array<any>>(
    exportColumns.map(c => c.field)
  );
  const [selected, setSelected] = useState<IExportSelected>([]);
  const [exportModalOpened, setExportModalOpened] = useState<boolean>(false);
  const [commentModalOpened, setCommentModalOpened] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [moderationCompanyId, setModerationCompanyId] = useState<number>(NaN);

  const getCompanies = async (currentPage: number, withLoading: boolean = true) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    if (withLoading) setIsLoading(true);

    try {
      const { data: res }: any = await axiosClient.get(`/companies`, {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
        },
      });

      setCompanies(res.data);
      setTotal(res?.links?.lastPage || 0);
    } catch (err: any) {
      logger.error(`Ошибка при получении компаний ${err.message}`, err);
    } finally {
      if (withLoading) setIsLoading(false);
    }
  };

  const setStatus = async (companyId: number, status: string, rowIndex: number = NaN) => {
    if (!isNaN(loadingRow)) {
      return;
    }

    let update: any = { status };

    if (status === 'Повторная модерация') {
      update.comment = comment;
      setCommentModalOpened(false);
      setComment('');
    }

    setLoadingRow(rowIndex);

    try {
      await axiosClient.put(`/companies/${companyId}/change-status`, update);
    } catch (e: any) {
      logger.error(`Ошибка при смене статуса ${e.message}`, e);
    } finally {
      await getCompanies(page, false);
      setLoadingRow(NaN);
    }
  };

  useEffect(() => {
    getCompanies(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
              <div className="flex items-center">
                <h2 className="text-[24px] text-[#363B39] font-bold">Компании перевозчики</h2>
              </div>
              <div className="flex justify-end items-center">
                <p className="text-[12px] mr-2 text-[#9b9d9c]">
                  {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
                </p>
                {isPermitted('companies_download_list') && (
                  <div className="mr-4">
                    <ButtonSPrimary onClickHandler={() => setExportModalOpened(true)}>
                      Экспорт в Excel
                    </ButtonSPrimary>
                  </div>
                )}
              </div>
            </div>
            <div className="overflow-auto bg-white">
              <CompaniesListTable
                companies={companies}
                isLoading={isLoading}
                loadingRow={loadingRow}
                selected={selected}
                sort={sort}
                onCompanySelect={setSelected}
                onSortUpdate={setSort}
                onCompanyLock={(companyId: number, rowIndex: number) =>
                  setStatus(companyId, 'Заблокирован', rowIndex)
                }
                onCompanyUnlock={(companyId: number, rowIndex: number) =>
                  setStatus(companyId, 'На модерации', rowIndex)
                }
                onCompanyStatusUpdate={(companyId: number, newStatus: string, rowIndex: number) => {
                  if (newStatus === 'Повторная модерация') {
                    setCommentModalOpened(true);
                    setModerationCompanyId(companyId);
                  } else {
                    setStatus(companyId, newStatus, rowIndex);
                  }
                }}
              />

              {!isLoading && companies.length && (
                <Pagination
                  totalPages={total}
                  activePage={page}
                  setPage={setPage}
                  alignment="justify-end"
                />
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ExportModal
          opened={exportModalOpened}
          isLoading={isLoading}
          selected={exportTableColumns}
          options={exportColumns}
          setSelected={setExportTableColumns}
          setOpened={setExportModalOpened}
          exportParams={{
            url: `/export/companies`,
            filename: 'companies.xlsx',
            fields: exportTableColumns,
            filters: {},
            selected,
            setIsLoading,
          }}
        />

        <ModalBackdrop state={commentModalOpened}>
          <ModalWindow title="Повторная модерация" setState={setCommentModalOpened}>
            <div className="flex flex-col">
              <p className="mb-2">Причина</p>
              <Textarea placeholder="" value={comment} setValue={e => setComment(e)} />
              <div className="w-[320px] mt-6">
                <Button
                  variant="default"
                  onClickHandler={() => setStatus(moderationCompanyId, 'Повторная модерация')}
                >
                  Отправить
                </Button>
              </div>
            </div>
          </ModalWindow>
        </ModalBackdrop>
      </ModalsSection>
    </>
  );
};

export default CompaniesList;
