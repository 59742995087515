import { FunctionComponent, useEffect, useState } from 'react';
import { DirectionsRenderer, MarkerClusterer } from '@react-google-maps/api';

import { connectToSocket } from 'src/hooks/use-socket';
import axiosClient from 'src/libs/axiosClient';

import RouteComponent from './Route';
import Loader from 'src/components/Custom/Loader';
import { Map } from '.';
import DriverMarker from './DriverMarker';

interface IDriverDataOrder {
  id: number;
  start_to: string;
  average_cost: string;
}

interface IDriverDataProp {
  id: number;
  lastName: string;
  firstName: string;
  avatar: string;
  phoneNumber: string;
  status: string;
  countOrders: number;
  earned: string;
  company: string;
  order?: IDriverDataOrder;
  links: any;
}

export interface IDriverData extends IDriverDataProp {
  order?: any;
  direction?: any;
  connection_lost: boolean;
  connection_lost_at: string;
  distance: number;
  coordinates: IDriverCoordinates;
  order_id: number;
}

interface IDriverCoordinates {
  lat: string;
  lng: string;
}

interface IDriverMapComponent extends google.maps.MapOptions {
  data: {
    drivers: IDriverDataProp[];
  };
}

const DriverMap: FunctionComponent<IDriverMapComponent> = ({ data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentZoom, setCurrentZoom] = useState(5);
  const [defaultCenter, setDefaultCenter] = useState<{ lat: number; lng: number } | null>(null);
  const [driversData, setDriversData] = useState<Array<IDriverData>>([]);
  const [selectedDrivers, setSelectedDrivers] = useState<number[]>([]);
  const [orderLoaded, setOrderLoaded] = useState<boolean>(false);

  const getOrder = async (driver: IDriverData) => {
    setOrderLoaded(false);
    try {
      const res = await axiosClient.get(`orders/${driver.order.id}`);
      const direction = await getDirection(res.data);
      setDriversData(prevDriversData =>
        prevDriversData.map(dr => {
          if (dr.id !== driver.id) return dr;
          return { ...dr, order: res, direction };
        })
      );
    } catch (error) {
      console.log('Ошибка при загрузке заказа', error);
    } finally {
      setOrderLoaded(true);
    }
  };

  const getDirection = async order => {
    return new Promise<google.maps.DirectionsResult | null>(resolve => {
      const directionsService = new google.maps.DirectionsService();

      const origin = { lat: Number(order.start_lat), lng: Number(order.start_lng) };
      const destination = { lat: Number(order.finish_lat), lng: Number(order.finish_lng) };

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            resolve(result);
          } else {
            resolve(null);
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    });
  };

  const setInitDriverCoordinates = async (driverIds: number[]) => {
    const res = await axiosClient.get<IDriverData[]>('/drivers/map');
    const chosenDrivers = res.data.filter(driver => driverIds.includes(driver.id));
    setDriversData(chosenDrivers);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!data || !data.drivers.length) {
      return;
    }

    let channels: Array<{
      connect: () => void;
      leave: () => void;
    }> = [];

    const driverIds = data.drivers.map(driver => driver.id).filter(id => !!id);

    setInitDriverCoordinates(driverIds);

    channels = driverIds.map(driverId =>
      connectToSocket(`admin.drivers.${driverId}.coordinate`, '.create.coordinate', payload => {
        console.log('socket connected');
        console.log(payload);
        setDriversData(prevDriversData =>
          prevDriversData.map(driver =>
            driver.id === driverId
              ? { ...payload, id: driverId, coordinates: { lat: payload.lat, lng: payload.lng } }
              : driver
          )
        );
      })
    );

    channels.forEach(ch => ch.connect());

    return () => channels.forEach(ch => ch.leave());
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const firstDataWithLocation = driversData.find(
      driver => driver.coordinates.lat && driver.coordinates.lng
    );
    if (firstDataWithLocation) {
      setDefaultCenter({
        lat: Number(firstDataWithLocation.coordinates.lat),
        lng: Number(firstDataWithLocation.coordinates.lng),
      });
    }
  }, [data, isLoading]);

  const handleClick = (driver: IDriverData) => {
    if (driver.order?.data || !driver.order?.id) return;
    getOrder(driver);
    setSelectedDrivers(prevSelectedDrivers => [...prevSelectedDrivers, driver.id as number]);
  };

  return (
    <Map key={'map'} zoom={currentZoom} center={defaultCenter}>
      {isLoading ? (
        <Loader size={50} />
      ) : (
        <>
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={10}
            styles={[
              {
                textColor: '#1066b1',
                height: 30,
                width: 30,
                url: '/assets/img/clusterer.svg',
              },
            ]}
          >
            {clusterer => (
              <>
                {driversData
                  .filter(e => !!e.coordinates.lat && !!e.coordinates.lng)
                  .map(driver => {
                    return (
                      <DriverMarker
                        clusterer={clusterer}
                        handleClick={handleClick}
                        key={`driver-${driver.id}`}
                        driver={driver}
                        order={driver?.order?.data}
                      />
                    );
                  })}
              </>
            )}
          </MarkerClusterer>
          {selectedDrivers.length &&
            orderLoaded &&
            driversData.map(
              driver =>
                selectedDrivers.includes(driver.id as number) && (
                  <>
                    <DirectionsRenderer
                      key={`direction-${driver.id}`}
                      directions={driver.direction}
                      options={{ suppressMarkers: true }}
                    />
                    <RouteComponent key={`route-${driver.id}`} order={driver?.order?.data} />
                  </>
                )
            )}
        </>
      )}
    </Map>
  );
};

export default DriverMap;
