import React, { FunctionComponent, useRef } from 'react';
import Input from '../UI/Input';
import Tooltip from '../UI/Tooltip';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CheckList from '../UI/CheckList';

const statuses = [
  { label: 'Заполнение', color: '#f6f0ff' },
  { label: 'На модерации' },
  { label: 'Повторная модерация' },
  { label: 'Утвержден', color: '#e9f7ef', textColor: '#27ae60' },
  { label: 'Есть текущий заказ' },
  { label: 'Давно не был онлайн' },
  { label: 'Заблокирован', color: '#fde6e6', textColor: '#ee0202' },
];

interface IDriversFilters {
  data: any;
  onFilterFieldChange: (v: any, field: string) => any;
}

interface IDriversFiltersTooltip {
  data: any;
  filtersButtonRef: React.RefObject<HTMLInputElement>;
  filtersIsEmpty?: boolean;
}

const DriversFilters: FunctionComponent<IDriversFilters> = ({
  data,
  onFilterFieldChange = () => {},
}) => {
  const inputRef = useRef(null);
  const checkboxRef = useRef(null);

  return (
    <div>
      <div className="mt-3">
        <p className="text-[#808080] text-sm">ID водителя или ФИО</p>
        <div className="w-full mt-2 text-sm">
          <Input
            ref={inputRef}
            className="w-full"
            value={data.search}
            onChange={e => onFilterFieldChange(e, 'search')}
          />
        </div>
      </div>
      <div className="mt-5">
        <p className="text-[#808080] text-sm">Статусы</p>
        <div className="w-full mt-2">
          <CheckList
            selected={data.status}
            options={statuses}
            field="label"
            onChange={newStatus => onFilterFieldChange(newStatus, 'status')}
          />
        </div>
      </div>
    </div>
  );
};

const DriversFiltersTooltip: FunctionComponent<IDriversFiltersTooltip> = ({
  data: filters,
  filtersButtonRef,
}) => {
  return (
    <Tooltip
      parentRef={filtersButtonRef}
      disabled={!filters.search && !filters.status.length}
      align="left"
      className="w-[15em]"
    >
      {!!filters.search.length && (
        <div className="mb-1">
          <p className="text-sm text-[#9f9f9f]">Водитель</p>
          <p className="text-sm">{filters.search}</p>
        </div>
      )}
      {!!filters.status.length && (
        <div>
          <p className="text-sm text-[#9f9f9f]">Статусы</p>
          <div className="flex flex-col">
            {filters.status.map(status => (
              <p className="text-sm" key={status}>
                {status}
              </p>
            ))}
          </div>
        </div>
      )}
    </Tooltip>
  );
};

export { DriversFilters, DriversFiltersTooltip };
