import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridTable, GridTableCell } from 'src/components/GridTable';
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';

// Cells
import TimeCell from '../BASIC_CELLS/TimeCell';

interface IClientsPaymentsTable {
  payments: Array<any>;
  isLoading: boolean;
  sort: Array<string>;
  selected: IExportSelected;

  onPaymentSelect?: (newSelected: IExportSelected) => any;
  onSortUpdate?: (newSort: Array<string>) => any;
}

const statuses = {
  succeeded: { label: 'Завершена', color: '#dff3e7', textColor: '#32b368' },
  payment_failed: { label: 'Ошибка', color: '#fcd9d9', textColor: '#f01d1d' },
  processing: { label: 'В процессе', color: '#dbe8f3', textColor: '#2976b9' },
};

const ClientsPaymentsTable: FunctionComponent<IClientsPaymentsTable> = ({
  payments,
  isLoading = false,
  sort = [],
  selected = [],

  onPaymentSelect = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: onPaymentSelect,
    }),

    id: { label: 'ID', sortBy: 'sort_id' },

    date: {
      label: 'Время',
      sortBy: 'sort_date',
      component: (date: string) => <TimeCell date={new Date(date)} />,
    },

    type: {
      label: 'Тип',
      sortBy: 'sort_type',
      component: (type: string, index: number, payment: any) => {
        if (type === 'payment' && payment?.order) {
          return (
            <GridTableCell>
              <div className="flex flex-col">
                Оплата заказа id:
                <span
                  className="text-[#2976b9] cursor-pointer ml-2"
                  onClick={() => navigate(`/order/${payment.order.id}`)}
                >
                  {payment.order.id}
                </span>
              </div>
            </GridTableCell>
          );
        }
        if (type !== 'order') {
          return (
            <GridTableCell className="px-[6px] py-2px bg-[#dbe8f3] rounded-[8px] text-[#1066B1] text-[12px]">
              Возврат средств
            </GridTableCell>
          );
        }

        return <GridTableCell>-</GridTableCell>;
      },
    },

    amount: {
      label: 'Сумма',
      sortBy: 'sort_amount',
      formatter: (amount: any) => '$' + amount,
    },

    receipt: {
      label: 'Чек',
      sortBy: 'sort_receipt',
      component: (receiptDownloadLink: string) => (
        <GridTableCell>
          {receiptDownloadLink?.includes('http') ? (
            <a
              href={receiptDownloadLink}
              target="_blank"
              rel="noreferrer"
              download
              className="text-sm text-[#2976b9]"
            >
              Скачать
            </a>
          ) : (
            <>-</>
          )}
        </GridTableCell>
      ),
    },

    status: {
      label: 'Статус',
      sortBy: 'sort_status',
      component: (status: string) => {
        const defaultStatus = 'processing';
        let s = status || defaultStatus;
        if (!statuses[s]) {
          s = defaultStatus;
        }

        return (
          <GridTableCell>
            <div
              className="px-[1em] py-[0.5em] rounded-[8px] text-[12px]"
              style={{
                backgroundColor: statuses[s].color,
                color: statuses[s].textColor,
              }}
            >
              {statuses[s].label}
            </div>
          </GridTableCell>
        );
      },
    },
  };

  return (
    <GridTable
      data={payments}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default ClientsPaymentsTable;
