import { FunctionComponent } from 'react';
import { GridTableCell } from 'src/components/GridTable';

interface IDriverInfoCell {
  avatar: string;
  fullname: string;
  orderId: string | number;
  onOrderIdClick?: (orderId: string | number) => any;
}

const DriverInfoCell: FunctionComponent<IDriverInfoCell> = ({
  avatar,
  fullname,
  orderId,
  onOrderIdClick = () => {},
}) => {
  if (!avatar && !fullname && !orderId) {
    return <GridTableCell>-</GridTableCell>;
  }

  return (
    <GridTableCell>
      <div className="flex flex-col">
        <div className="flex items-center">
          <img
            alt=""
            src={avatar || '/assets/img/clients.svg'}
            className="w-[30px] h-[30px] object-cover rounded-full"
          />
          <p
            className="text-[#1066B1] underline text-[10px] pl-[6px] cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              onOrderIdClick(orderId);
            }}
          >
            #{orderId}
          </p>
        </div>
        <p className="text-[#9b9d9c] text-[12px]">{fullname}</p>
      </div>
    </GridTableCell>
  );
};

export default DriverInfoCell;
