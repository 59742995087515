import { getAccessKey } from 'src/utils/auth-store';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import logger from 'src/utils/logger';

declare global {
  interface Window {
    io: any;
    Pusher: any;
    Echo: Echo;
  }
}

function createSocketConnection(token: string) {
  const domain = process.env.REACT_APP_WEBSOCKETS_DOMAIN;
  const pusherAppKey = process.env.REACT_APP_PUSHER_APP_KEY;
  const cluster = process.env.REACT_APP_PUSHER_APP_CLUSTER;

  if (!domain || !pusherAppKey || !cluster) {
    logger.error('Что-то не так с реактивным обновлением страницы');
    return;
  }

  if (!window.Echo) {
    const PusherClient = new Pusher(pusherAppKey, {
      cluster: cluster,
      wsHost: domain,
      forceTLS: true,
      auth: {
        headers: {
          Authorization: token,
        },
      },
      authEndpoint: `https://${domain}/broadcasting/auth`,
    });
    window.Echo = new Echo({
      broadcaster: 'pusher',
      client: PusherClient,
      key: pusherAppKey,
    });
  }
}

export const connectToSocket = (
  channel: string,
  event: string,
  callback: (payload: any, additionalData?: any) => void
): { connect: () => void; leave: () => void } => {
  const accessToken = getAccessKey();

  if (!accessToken) {
    throw new Error('No access token');
  }

  createSocketConnection(accessToken);

  if (!window.Echo) {
    console.error('Echo is not defined, no env');
    return {
      connect() {},
      leave() {},
    };
  }

  return {
    connect(additionalData?: any) {
      window.Echo.private(channel).listen(event, (payload: any) => {
        callback(payload, additionalData);
      });
    },
    leave() {
      window.Echo.leaveChannel(channel);
    },
  };
};
