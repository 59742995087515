import React, { FunctionComponent, useRef, useState } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from 'src/libs/axiosClient';
import UnAuthorizedLayout from 'src/layouts/UnAuthorized';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Input from 'src/components/UI/Input';
import { ModalWindow } from 'src/components/UI/Modal';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';

const AuthorizationPage: FunctionComponent = () => {
  const navigate: NavigateFunction = useNavigate();
  const [error, setError] = useState<any>('');
  const [searchParams] = useSearchParams();
  const fileInput = useRef<any>(null);
  const [file, setFile] = useState<any>(null);
  const [formData, setFormData] = useState<any>({
    hash: searchParams.get('hash'),
    email: searchParams.get('email'),
    username: '',
    name: '',
    surname: '',
    password: '',
    password_confirmation: '',
    avatar: '',
  });

  const onFormDataFieldChange = (val: any, field: string) => {
    setFormData(() => ({
      ...formData,
      [field]: val,
    }));
  };

  if (searchParams.get('hash') === null || searchParams.get('email') === null) {
    navigate('/');
  }

  async function onRegisterHandler(): Promise<void> {
    try {
      // const file = e.target.files[i];
      const data = new FormData();
      data.append('hash', formData.hash);
      data.append('email', formData.email);
      data.append('username', formData.username);
      data.append('name', formData.name);
      data.append('surname', formData.surname);
      data.append('password', formData.password);
      data.append('password_confirmation', formData.password_confirmation);
      if (file) {
        data.append('avatar', file);
      }
      let error = false;
      if (!error) {
        await axiosClient.post(`/register`, data);
        navigate('/authorization');
      }
    } catch (error: any) {
      setError(error.response.data.errors);
    }
  }

  const onAddAvatar = () => {
    /*Collecting node-element and performing click*/
    fileInput?.current.click();
  };

  return (
    <>
      <UnAuthorizedLayout>
        <ModalsSection>
          {/* <ModalBackdrop state={authModal}> */}
          <ModalWindow title="Регистрация администратора" close={false}>
            <div className="flex flex-col items-center">
              <img
                src={file ? URL.createObjectURL(file) : '/assets/img/user.png'}
                alt=""
                className="w-[64px] h-[64px] rounded-full"
              />
              <p
                className="mt-3 text-[#615FC8] cursor-pointer"
                onClick={() => {
                  onAddAvatar();
                }}
              >
                Добавить фото
              </p>
              <input
                ref={fileInput}
                type="file"
                name="avatar"
                accept="image/*"
                className="hidden"
                onChange={e => {
                  if (e?.target.files) {
                    setFile(e?.target?.files[0]);
                  }
                }}
              />
              <div className="w-[320px] mt-4">
                <p className="mb-2">Ваш никнейм</p>
                <Input
                  placeholder="Ваш никнейм"
                  value={formData.username}
                  onChange={e => onFormDataFieldChange(e, 'username')}
                />
              </div>
              <div className="w-[320px] mt-4">
                <p className="mb-2">Ваше имя</p>
                <Input
                  placeholder="Ваше имя"
                  value={formData.name}
                  onChange={e => onFormDataFieldChange(e, 'name')}
                />
              </div>
              <div className="w-[320px] mt-4">
                <p className="mb-2">Ваша фамилия</p>
                <Input
                  placeholder="Ваша фамилия"
                  value={formData.surname}
                  onChange={e => onFormDataFieldChange(e, 'surname')}
                />
              </div>
              <div className="w-[320px] mt-4">
                <p className="mb-2">Придумайте пароль</p>
                <Input
                  placeholder="Пароль"
                  type="password"
                  value={formData.password}
                  onChange={e => onFormDataFieldChange(e, 'password')}
                />
              </div>
              <div className="w-[320px] mt-4">
                <p className="mb-2">Повторите пароль</p>
                <Input
                  placeholder="Повторный пароль"
                  type="password"
                  value={formData.password_confirmation}
                  onChange={e => onFormDataFieldChange(e, 'password_confirmation')}
                />
              </div>
              <div className="w-[320px] mt-6 text-[1.1em]">
                <ButtonSPrimary onClickHandler={onRegisterHandler}>Зарегистрироваться</ButtonSPrimary>
              </div>
              {error && <p className="text-red-600">{error[Object.keys(error)[0]][0]}</p>}
            </div>
          </ModalWindow>
          {/* </ModalBackdrop> */}
        </ModalsSection>
      </UnAuthorizedLayout>
    </>
  );
};

export default AuthorizationPage;
