import React, { FunctionComponent, useState } from 'react';
import logger from 'src/utils/logger';
import Select from 'src/components/UI/Select';
import { useParams } from 'react-router-dom';
import axiosClient from 'src/libs/axiosClient';
import states from 'src/utils/states';
import Input from 'src/components/UI/Input';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import { isPermitted } from 'src/utils/permissions';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ModalBackdrop from 'src/components/UI/Modal';
import { Carousel } from 'react-responsive-carousel';

const CompanyAdminGeneral: FunctionComponent<any> = ({
  company,
  isLoading = false,
  getData = () => {},
  setData,
  setIsLoading = () => {},
}) => {
  const { id } = useParams();
  const [newLogo, setNewLogo] = useState<any>({});

  const [activeSlide, setActiveSlide] = useState(0);
  const [carouselOpened, setCarouselOpened] = useState<boolean>(false);
  const [carouselImages, setCarouselImages] = useState<Array<any>>(['', '']);

  const handleKeyDown = e => {
    if (!carouselOpened) return;
    if (e.key === 'ArrowRight')
      setActiveSlide(prevSlide => (prevSlide === carouselImages.length - 1 ? 0 : prevSlide + 1));
    if (e.key === 'ArrowLeft')
      setActiveSlide(prevSlide => (prevSlide === 0 ? carouselImages.length - 1 : prevSlide - 1));
    if (e.key === 'Escape') setCarouselOpened(false);
  };

  const updateDataField = (value: any, field: string) => {
    setData({ ...company, [field]: value });
  };

  const updateLogo = async e => {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];

    if ((file.type !== 'image/jpeg' && file.type !== 'image/png') || file.size / 1024 / 1024 >= 5) {
      return;
    }

    const form = new FormData();
    form.append('logo', file);
    setIsLoading(true);

    try {
      await axiosClient.post(`/companies/${id}/logo/create`, form);
    } catch (e: any) {
      logger.error(`Ошибка при сохранении логотипа ${e.message}`, e);
    } finally {
      getData();
    }
  };

  const deleteLogo = async () => {
    setIsLoading(true);
    try {
      await axiosClient.delete(`/companies/${id}/logo/delete`);
    } catch (e: any) {
      logger.error(`Ошибка при удалении компании ${e.message}`, e);
    } finally {
      getData();
    }
  };

  const isFormDisabled = () => {
    return !isPermitted('companies_edit');
  };

  const validation = (): boolean => {
    return !!(
      company.name &&
      company.email &&
      company.years_on_market &&
      company.region &&
      company.dot_mc &&
      company.bank_account_number &&
      company.routing_number &&
      company.bank_name &&
      company.state
    );
  };

  const onSave = async () => {
    if (!validation()) {
      logger.error('Все поля обязательны для заполнения');
      return;
    }

    try {
      await axiosClient.put(`/companies/${id}`, {
        name: company.name,
        email: company.email,
        years_on_market: String(company.years_on_market),
        region: company.region,
        dot_mc: company.dot_mc,
        bank_account_number: String(company.bank_account_number),
        routing_number: company.routing_number,
        bank_name: company.bank_name,
        state: company.state,
        block: '0',
        repeat_moderation: 'Comment',
      });

      await getData();
      logger.success('Изменения успешно сохранены');
    } catch (e: any) {
      logger.error('Не удалось изменить данные');
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="pl-5 mt-6">
        <div className="flex flex-col">
          <h1 className="text-[18px] text-[#363B39] font-bold">Общие данные</h1>
          <div className="mt-6 mr-8">
            <p className="mb-4 text-[#999]">Логотип</p>
            <div className="flex items-center">
              <div className="flex justify-center items-center bg-[#f2e9ff] overflow-hidden max-w-[5rem] max-h-[5rem] min-w-[5rem] min-h-[5rem] rounded-full">
                <img
                  alt="avatar"
                  className="object-cover h-[5rem] max-w-[none]"
                  src={company.logo || (newLogo && newLogo.src) || '/assets/img/clients.svg'}
                />
              </div>
              {!isFormDisabled() && (
                <>
                  {(company.logo && company.logo.length) || (newLogo && newLogo.src) ? (
                    <div className="ml-4">
                      <p className="mb-1 font-semibold">{newLogo.filename || '-'}</p>
                      <p
                        className="font-semibold text-[#EE0202] cursor-pointer"
                        onClick={() => (newLogo.src ? setNewLogo(null) : deleteLogo())}
                      >
                        Удалить фото
                      </p>
                    </div>
                  ) : (
                    <div className="ml-4 flex flex-col">
                      <label>
                        <p className="font-bold text-[#1066B1] cursor-pointer">+ Добавить фото</p>
                        <input
                          type="file"
                          accept="image/jpeg,image/png"
                          onChange={updateLogo}
                          className="hidden"
                        />
                      </label>
                      <p className="flex flex-col text-xs text-[#A9A1A1]">
                        <span>не более 5.00mb jpg, png, jpeg.</span>
                        <span>Рекомендуемый размер 150x150</span>
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">Название компании</p>
            <Input
              value={company.name || ''}
              placeholder="Название компании"
              readonly={isFormDisabled()}
              onChange={value => updateDataField(value, 'name')}
            />
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">Лет на рынке</p>
            <Input
              value={company.years_on_market || ''}
              placeholder="Лет на рынке"
              readonly={isFormDisabled()}
              onChange={value => {
                if (value.length && !/^\d+$/.test(value)) return;
                updateDataField(Number(value), 'years_on_market');
              }}
            />
          </div>
        </div>
        <div className="flex">
          <div className="min-w-[15em] mt-6 mr-8">
            <p className="mb-2 text-[#999]">Регион</p>
            <Select
              options={states}
              value={company.region}
              placeholder="Регион"
              onSelect={(region: any) => setData({ ...company, region, state: region })}
              maxHeight={12}
              disabled={isFormDisabled()}
            />
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">Email</p>
            <Input
              value={company.email || ''}
              readonly={isFormDisabled()}
              placeholder="Email"
              onChange={value => updateDataField(value, 'email')}
            />
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">W9 документ (pdf)</p>
            {company.w9 ? (
              <a href={company.w9} download={`w9-${company.name}`} className="underline text-[#1066B1]">
                Скачать
              </a>
            ) : (
              <p className="">-</p>
            )}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">Водительские права</p>
            <div className="flex">
              <div className="rounded-[4px] w-12 h-12 overflow-hidden mr-2">
                <img
                  onClick={() => {
                    setCarouselImages(() => [
                      company.owners_id_front || '',
                      company.owners_id_back || '',
                    ]);
                    setActiveSlide(() => 0);
                    setCarouselOpened(() => true);
                  }}
                  src={company.owners_id_front}
                  alt=""
                  className="w-12 h-12 bg-[#DADADA] object-cover border-0 cursor-pointer"
                />
              </div>
              <div className="rounded-[4px] w-12 h-12 overflow-hidden mr-2">
                <img
                  onClick={() => {
                    setCarouselImages(() => [
                      company.owners_id_front || '',
                      company.owners_id_back || '',
                    ]);
                    setActiveSlide(() => 1);
                    setCarouselOpened(() => true);
                  }}
                  src={company.owners_id_back}
                  alt=""
                  className="w-12 h-12 bg-[#DADADA] object-cover border-0 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">COI certificate of insurance (pdf)</p>
            {company.coi ? (
              <a
                href={company.coi}
                download={`coi-${company.company_name}`}
                className="underline text-[#1066B1]"
              >
                Скачать
              </a>
            ) : (
              <p className="">-</p>
            )}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">Motor Carrier Authorization (pdf)</p>
            {company.motor ? (
              <a
                href={company.motor}
                download={`motor-${company.company_name}`}
                className="underline text-[#1066B1]"
              >
                Скачать
              </a>
            ) : (
              <p className="">-</p>
            )}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">DOT/MC</p>
            <Input
              value={company.dot_mc || ''}
              readonly={isFormDisabled()}
              placeholder="DOT/MC"
              onChange={value => updateDataField(value, 'dot_mc')}
            />
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">Номер банковского аккаунта</p>
            <Input
              value={company.bank_account_number || ''}
              readonly={isFormDisabled()}
              placeholder="Номер банковского аккаунта"
              onChange={value => updateDataField(value, 'bank_account_number')}
            />
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">Номер маршрута</p>
            <Input
              value={company.routing_number || ''}
              readonly={isFormDisabled()}
              placeholder="Номер маршрута"
              onChange={value => updateDataField(value, 'routing_number')}
            />
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mr-8 mb-8">
            <p className="mb-2 text-[#999]">Название банка</p>
            <Input
              value={company.bank_name || ''}
              readonly={isFormDisabled()}
              placeholder="Название банка"
              onChange={value => updateDataField(value, 'bank_name')}
            />
          </div>
        </div>
        {!isFormDisabled() && (
          <div className="mt-1">
            <div className="text-[1.2em] max-w-[15em]">
              <ButtonSPrimary onClickHandler={onSave}>Сохранить</ButtonSPrimary>
            </div>
          </div>
        )}
      </div>

      <ModalsSection>
        <ModalBackdrop state={carouselOpened}>
          <div onKeyDown={handleKeyDown} className="flex items-center h-full">
            <button
              onClick={() => {
                setCarouselOpened(false);
              }}
              className={`absolute right-5 top-6`}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7M13 13L7 7M7 7L13 1M7 7L1 13"
                  stroke="white"
                  strokeOpacity="1"
                  strokeWidth="1.5"
                />
              </svg>
            </button>
            <div className="w-full content-between">
              <Carousel
                autoFocus
                useKeyboardArrows
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                selectedItem={activeSlide}
                onChange={setActiveSlide}
                renderArrowPrev={() => (
                  <button
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      width: carouselImages[1] === '' ? 0 : 60,
                      height: 60,
                      minWidth: carouselImages[1] === '' ? 0 : 60,
                      color: '#fff',
                      zIndex: 1000,
                    }}
                    type="button"
                    onClick={() =>
                      setActiveSlide(prevSlide =>
                        prevSlide !== 0 ? prevSlide - 1 : carouselImages.length - 1
                      )
                    }
                  >
                    {'<'}
                  </button>
                )}
                renderArrowNext={() => (
                  <button
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: 0,
                      width: carouselImages[1] === '' ? 0 : 60,
                      height: 60,
                      minWidth: carouselImages[1] === '' ? 0 : 60,
                      color: '#fff',
                    }}
                    type="button"
                    onClick={() =>
                      setActiveSlide(prevSlide =>
                        prevSlide !== carouselImages.length - 1 ? prevSlide + 1 : 0
                      )
                    }
                  >
                    {'>'}
                  </button>
                )}
              >
                {carouselImages.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: 'block',
                        marginInline: 'auto',
                        background: 'white',
                        width: window.innerWidth - 200,
                        height: '500px',
                        textAlign: 'center',
                        lineHeight: '240px',
                        boxSizing: 'border-box',
                      }}
                      key={index}
                    >
                      <img
                        src={item}
                        alt="Картинка не загрузилась"
                        className="object-contain w-full h-full"
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </ModalBackdrop>
      </ModalsSection>
    </>
  );
};

export default CompanyAdminGeneral;
