import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';

interface IAdminsListTalbe {
  admins: Array<any>;
  isLoading: boolean;
  sort: Array<string>;
  selected: IExportSelected;
  onSelect: (newSelected: IExportSelected) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const AdminsListTalbe: FunctionComponent<IAdminsListTalbe> = ({
  admins,
  isLoading = false,
  sort = [],
  selected = [],
  onSelect = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect,
      additionalParams: { width: '30px' },
    }),

    id: {
      label: 'ID',
      sortBy: 'sort_id',
      width: '70px',
      component: (id: number) => (
        <GridTableCell>
          <span
            className="text-[#1066B1] underline text-[12px] cursor-pointer"
            onClick={() => navigate(`/settings/adminstrators/${id}/general`)}
          >
            #{id}
          </span>
        </GridTableCell>
      ),
    },

    name: {
      label: 'Пользователь',
      sortBy: 'sort_username',
      component: (name: string, index: number, admin: any) => {
        return (
          <GridTableCell>
            <div className="flex items-center">
              <img
                src={admin.avatar ?? '/assets/img/user.png'}
                className="min-w-[22px] h-[22px] object-cover"
                alt=""
              />
              <div className="pl-[6px]">
                <p className="text-[#333333] text-[12px]">{admin.username}</p>
                <p className="text-[#9b9d9c] text-[12px] font-semibold">
                  {name} {admin.surname}
                </p>
              </div>
            </div>
          </GridTableCell>
        );
      },
    },

    email: { label: 'Почта', sortBy: 'sort_email' },
    phone: { label: 'Телефон', sortBy: 'sort_phone' },
    last_login: { label: 'Дата послед. входа', sortBy: 'sort_last_login' },
  };

  return (
    <GridTable
      data={admins}
      fields={TableFields}
      isLoading={isLoading}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

export default AdminsListTalbe;
