import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';

interface IDubleCheckbox {
  value?: boolean;
  setValue?: Dispatch<SetStateAction<boolean>>;
  positiveTitle?: string;
  negativeTitle?: string;
  disabled?: boolean;
}

const DoubleCheckbox: FunctionComponent<IDubleCheckbox> = ({
  value = false,
  setValue = () => {},
  positiveTitle = 'Да',
  negativeTitle = 'Нет',
  disabled = false,
}) => {
  return (
    <>
      <div className="flex">
        <div className="flex mr-4">
          <input
            className="mr-1"
            type="radio"
            checked={value}
            disabled={disabled}
            onChange={() => setValue(true)}
          />
          <span>{positiveTitle}</span>
        </div>
        <div className="flex">
          <input
            className="mr-1"
            type="radio"
            checked={!value}
            disabled={disabled}
            onChange={() => setValue(false)}
          />
          <span>{negativeTitle}</span>
        </div>
      </div>
    </>
  );
};

export default DoubleCheckbox;
