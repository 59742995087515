import React, { Dispatch, FunctionComponent, SetStateAction, useMemo } from 'react';

interface IPagination {
  totalPages: number;
  setPage: Dispatch<SetStateAction<number>>;
  activePage: number;
  disabled?: boolean;
  alignment: 'justify-end' | 'justify-start';
}

const Pagination: FunctionComponent<IPagination> = ({
  totalPages = 1,
  setPage,
  disabled = false,
  activePage = 1,
  alignment = 'justify-start',
}) => {
  const paginationClick = (pageNum: number) => {
    if (disabled || pageNum === activePage || pageNum > totalPages || pageNum < 1) {
      return;
    }

    setPage(pageNum);
  };

  const range = (start, end) => {
    let length = end - start + 1;
    /*
      Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const siblingCount = 2;
  const DOTS = '...';
  const paginationContent = useMemo<Array<number>>(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + activePage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(activePage - siblingCount, 1);
    const rightSiblingIndex = Math.min(activePage + siblingCount, totalPages);

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 1 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPages];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 1 + 2 * siblingCount;
      let rightRange = range(totalPages - rightItemCount + 1, totalPages);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return range(1, totalPages);
  }, [totalPages, activePage]);

  return (
    <div className={`flex flex-row w-full ${alignment} pr-4 pl-4`}>
      <div className="flex flex-row gap-1 px-2 py-5">
        {paginationContent.map(pageNum => (
          <div
            key={pageNum}
            onClick={() => paginationClick(pageNum)}
            className={
              'w-[32px] h-[32px] text-[#3072C4] flex items-center justify-center cursor-pointer' +
              (pageNum === activePage ? ' bg-[#E5E5E5] rounded-full text-black' : '') +
              (disabled ? ' opacity-50' : '')
            }
          >
            {pageNum}
          </div>
        ))}
        {activePage !== totalPages && totalPages > 1 && (
          <div
            onClick={() => paginationClick(activePage + 1)}
            className={'ml-3 h-[32px] text-[#3072C4] flex items-center justify-center cursor-pointer'}
          >
            {'Дальше >'}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
