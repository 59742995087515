import React, { FunctionComponent, useEffect, useState } from 'react';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Pagination from 'src/components/UI/Pagination';
import axiosClient from 'src/libs/axiosClient';
import DriversViolationsTable from 'src/components/Tables/drivers/violations';
import ExportModal from 'src/components/ExportModal';
import { isPermitted } from 'src/utils/permissions';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import { connectToSocket } from 'src/hooks/use-socket';

interface DriversViolationsHistoryTypes {
  data: any;
  setData: any;
  setIsLoading: any;
}

const ExportFields = [
  {
    title: 'Время',
    exportField: 'created_at',
  },
  {
    title: 'Нарушение',
    exportField: 'violation',
  },
  {
    title: 'ID заказа',
    exportField: 'order_id',
  },
];

const DriversViolationsHistory: FunctionComponent<DriversViolationsHistoryTypes> = ({
  data,
  setData,
  setIsLoading,
}) => {
  const [exportModal, setExportModal] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [tableData, setTableData] = useState<any>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_date', 'desc']);
  const [exportSelected, setExportSelected] = useState<any>(ExportFields.map(e => e.exportField));
  const [selected, setSelected] = useState<IExportSelected>([]);

  const getViolations = async (currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    setIsTableLoading(true);
    try {
      setTableData([]);
      const res = await axiosClient.get(`/drivers/${data.id}/violations`, {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
        },
      });
      setDataTotal(res.data.links.lastPage);
      setTableData(res.data.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    }
    setIsTableLoading(false);
  };

  useEffect(() => {
    if (data.id) {
      getViolations(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, data]);

  useEffect(() => {
    const { connect, leave } = connectToSocket(
      `admin.drivers.${data.id}.violations`,
      '.create.violation',
      payload => {
        getViolations(page);
      }
    );

    connect();

    return () => leave();
  }, [data.id]);

  return (
    <>
      <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
        <div className="flex items-center">
          <p>
            <span className="font-bold">Всего нарушений за последний месяц: </span>
            {dataTotal < 9 && tableData.length}
          </p>
        </div>
        <div className="flex justify-end items-center">
          <span className="text-[12px] text-[#9b9d9c] mr-2">
            {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
          </span>

          {isPermitted('drivers_download_history_violations') && (
            <div className="mr-2">
              <ButtonSPrimary onClickHandler={() => setExportModal(true)}>
                Экспорт в Excel
              </ButtonSPrimary>
            </div>
          )}
        </div>
      </div>
      <div className="overflow-auto bg-white">
        <DriversViolationsTable
          violations={tableData}
          isLoading={isTableLoading}
          sort={sort}
          selected={selected}
          onViolationSelect={setSelected}
          onSortUpdate={setSort}
        />

        {!isTableLoading && tableData.length !== 0 && (
          <Pagination
            totalPages={dataTotal}
            activePage={page}
            setPage={setPage}
            alignment="justify-end"
          />
        )}
      </div>
      <ModalsSection>
        <ExportModal
          opened={exportModal}
          isLoading={isTableLoading}
          selected={exportSelected}
          options={ExportFields}
          field="exportField"
          setSelected={setExportSelected}
          setOpened={setExportModal}
          exportParams={{
            url: `/export/${data.id}/driver-violations`,
            filename: `driver-violation-${data.id}.xlsx`,
            fields: exportSelected,
            filters: {},
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default DriversViolationsHistory;
