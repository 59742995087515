import { FunctionComponent } from 'react';
import { timeGone } from 'src/utils/format-date';

export const DriverConnectionStatus: FunctionComponent<{
  connection_lost: boolean;
  connection_lost_at: string;

  className?: string;
}> = ({ connection_lost, connection_lost_at, className }) => {
  if (connection_lost) {
    return (
      <p className={`text-[#EE0202] ${className}`} style={{ fontSize: '10px' }}>
        Offline {connection_lost_at ? timeGone(connection_lost_at) : ''}
      </p>
    );
  }

  return (
    <p className={`text-[#27AE60] ${className}`} style={{ fontSize: '10px' }}>
      Online
    </p>
  );
};
