import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';

interface ISelect {
  value?: any;
  options?: Array<any>;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  defaultColor?: string;
  defaultTextColor?: string;
  className?: string;
  maxHeight?: string | number;
  rounded?: boolean;
  withShadow?: boolean;
  selectedRenderer?: undefined | ((option: any) => any);
  optionRenderer?: undefined | ((option: any) => any);
  beforeOptionsList?: undefined | (() => any);
  afterOptionsList?: undefined | (() => any);
  onSelect?: (option: any) => any;
  onReachEnd?: () => any;
}

const Select: FunctionComponent<ISelect> = ({
  value,
  options = [],
  disabled = false,
  label = 'label',
  placeholder = 'Select',
  defaultColor = '#FFFFFF',
  defaultTextColor = 'black',
  className = '',
  maxHeight,
  rounded = true,
  withShadow = true,
  beforeOptionsList,
  afterOptionsList,
  selectedRenderer,
  optionRenderer,
  onReachEnd = () => {},
  onSelect = () => {},
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const selectRef = useRef<any>(null);
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!opened || !optionsContainerRef.current || !value) return;
    const optionElemsArr = Array.from(optionsContainerRef.current.children);
    const optionElems = optionElemsArr.filter(option =>
      option.classList.contains('select-option')
    ) as HTMLDivElement[];
    const selectedIndex = options.indexOf(options.find(option => option.label === value.label));
    setSelectedValue(selectedIndex);
    const selectedOption = optionElems[selectedIndex];
    if (selectedOption) selectedOption.focus();
  }, [opened]);

  const onKeyDown = e => {
    if (e.target !== document.activeElement) return;
    if (e.key === 'Enter') {
      opened ? setOpened(false) : setOpened(true);
    }
    onOptionKeyDown(e);
  };

  const onOptionKeyDown = e => {
    if (!selectedValue) setSelectedValue(0)
    if (!optionsContainerRef.current) return;
    const optionElemsArr = Array.from(optionsContainerRef.current.children);
    const optionElems = optionElemsArr.filter(option =>
      option.classList.contains('select-option')
    ) as HTMLDivElement[];
    if (e.key === 'ArrowUp' && selectedValue !== null) {
      const nextValue = selectedValue === 0 ? options.length - 1 : selectedValue - 1;
      optionElems[nextValue].focus();
      setSelectedValue(nextValue);
    }
    if (e.key === 'ArrowDown' && selectedValue !== null) {
      const nextValue = selectedValue === options.length - 1 ? 0 : selectedValue + 1;
      optionElems[nextValue].focus();
      setSelectedValue(nextValue);
    }
    if (e.key === 'Enter' && selectedValue !== null) {
      onSelect(options[selectedValue]);
      setOpened(false);
    }
  };

  const onClick = e => {
    // e.stopPropagation();

    if (disabled) {
      return;
    }

    setOpened(!opened);
  };

  const onOptionClick = (option, e) => {
    e.stopPropagation();

    if (disabled || option?.disabled) {
      return;
    }

    onSelect(option);
    setOpened(false);
  };

  const onScroll = (e: any) => {
    const optionsList = e.target;

    if (optionsList.scrollHeight - 5 <= optionsList.scrollTop + optionsList.clientHeight) {
      onReachEnd();
    }
  };

  const getSelected = () => {
    if (!value) return <span className="opacity-60">{placeholder}</span>;

    if (typeof selectedRenderer === 'function') return selectedRenderer(value);

    return label && value[label] ? value[label] : String(value);
  };

  const getOption = (option: any) => {
    if (typeof optionRenderer === 'function') {
      return optionRenderer(option);
    }

    return typeof option === 'string' ? option : option[label];
  };

  useEffect(() => {
    const selectCloseListener = e => {
      if (!selectRef.current || selectRef.current.contains(e.target)) {
        return;
      }

      setOpened(false);
    };

    document.addEventListener('click', selectCloseListener);

    return () => document.removeEventListener('click', selectCloseListener);
  }, []);

  return (
    <div
      tabIndex={1}
      onKeyDown={onKeyDown}
      className={`${className} w-full relative text-[0.9rem] min-w-[5em] ${
        rounded ? (opened ? 'rounded-t-lg' : 'rounded-lg') : ''
      }`}
      style={{
        boxShadow: withShadow ? '0px 2px 6px 0px rgba(0, 0, 0, 0.2)' : '',
        backgroundColor: value?.color || defaultColor,
        color: value?.textColor || defaultTextColor,
      }}
      ref={selectRef}
    >
      <div
        className={`px-3 py-[0.35em] w-full flex justify-between items-center cursor-pointer border-b-[2px] transition-all duration-200 ${
          opened ? 'border-[#006bb2]' : 'border-[#00000000]'
        } ${disabled ? 'opacity-50' : ''}`}
        onClick={onClick}
      >
        <div className="overflow-ellipsis overflow-hidden whitespace-nowrap">{getSelected()}</div>
        <div
          className={`transform scale-125 transition-all duration-200 ${
            opened ? 'rotate-180' : 'rotate-0'
          }`}
          style={{ marginTop: '3px' }}
        >
          <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 3L2 0C1.52222 0.0388886 1.23056 0.205555 1.125 0.5C1.01944 0.788889 0.977778 0.955555 1 1L5 5L9 1C9.02222 0.955555 8.98056 0.788889 8.875 0.5C8.76944 0.205555 8.47778 0.0388886 8 0L5 3Z"
              fill="#A0A0A0"
            />
          </svg>
        </div>
      </div>
      <div
        className="flex flex-col rounded-b-lg bg-[#F5F5F5] absolute overflow-y-auto overflow-x-hidden z-[150] transition-all duration-150 origin-top select-container"
        style={{
          width: '100%',
          maxHeight: maxHeight ? `${maxHeight}em` : 'unset',
          boxShadow: withShadow ? '0px 2px 6px 0px rgba(0, 0, 0, 0.2)' : '',
          transform: `scaleY(${Number(opened)})`,
        }}
        onScroll={onScroll}
        ref={optionsContainerRef}
      >
        {typeof beforeOptionsList === 'function' && beforeOptionsList()}
        {options.map((option, index) => (
          <div
            className={`overflow-ellipsis border-y-[1px] px-3 py-1 w-full cursor-pointer ${
              option?.disabled ? 'opacity-50' : 'hover:opacity-70 hover:border-[#006bb2]'
            } select-option`}
            style={{
              backgroundColor: option?.color || defaultColor,
              color: option?.textColor || defaultTextColor,
            }}
            tabIndex={0}
            key={typeof option === 'string' ? option : option?.id || option[label]}
            onClick={e => onOptionClick(option, e)}
          >
            {getOption(option)}
          </div>
        ))}
        {typeof afterOptionsList === 'function' && afterOptionsList()}
      </div>
    </div>
  );
};

export default Select;
