import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IStore, TypedDispatch } from 'src/redux';
import { jwtInterceptor } from 'src/utils/jwtInterceptor';
import axiosClient from 'src/libs/axiosClient';
import { getAccessKey } from 'src/utils/auth-store';
import logger from 'src/utils/logger';
import { getPermissions, setPermissions } from 'src/utils/permissions';

const TheLayout: FunctionComponent = ({ children }) => {
  const store: IStore = useSelector((store: IStore) => store);
  const dispatch: TypedDispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  const getUnreadNotificationsCount = async (): Promise<number> => {
    try {
      const { data } = await axiosClient.post('/notifications/not-read');

      if (!data || isNaN(data?.num)) {
        return 0;
      }

      return data.num;
    } catch (err: any) {
      logger.error(`Ошибка при получении кол-ва уведомлений ${err.message}`);
      return 0;
    }
  };

  jwtInterceptor(dispatch, store);

  async function setTimezone() {
    const timezone = localStorage.getItem('timezone');

    if (!timezone || !timezone.includes('GMT')) {
      const { data } = await axiosClient.get('/settings');
      localStorage.setItem('timezone', data.timezone);
    }
  }

  async function requestPermissions() {
    const { data } = await axiosClient.get('/my-permissions');
    setPermissions(data.map(e => e.name) as any);
  }

  function getData() {
    setTimezone().catch(console.log);
    requestPermissions().catch(console.log);
  }

  useEffect(() => {
    if (store.user && store.user.accessToken) {
      getData();
    }
  }, [navigate]);

  useEffect(() => {
    const updateUnreadNotificationsCount = async () => {
      if (getAccessKey() && localStorage.getItem('userRole') !== 'companyAdmin') {
        const notificationsCount = await getUnreadNotificationsCount();
        dispatch({ type: 'SET_USER', payload: { notificationsCount } });
      }
    };

    updateUnreadNotificationsCount();
    const notificationsUpdateIntervalId = setInterval(updateUnreadNotificationsCount, 20_000);

    return () => clearInterval(notificationsUpdateIntervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main>
        <div className="h-full">{children}</div>
      </main>
    </>
  );
};

export default TheLayout;
