import { FunctionComponent } from 'react';
import Input from 'src/components/UI/Input';
import Dropdown from 'src/components/UI/Dropdown';
import RadioList from 'src/components/Custom/RadioList';

interface IFirstRegistrationStep {
  formData: any;
  onTrailerChange?: any;
  onFormDataChange?: (value: any, field: string) => any;
}

const FirstRegistrationStep: FunctionComponent<IFirstRegistrationStep> = ({
  formData,
  onFormDataChange = () => {},
  onTrailerChange = () => {},
}) => {
  return (
    <>
      <p className="text-[#808080] text-sm w-[293px]">Водитель</p>
      <div className="w-full mt-2">
        <Dropdown
          value={formData.selectedDriver}
          type="drivers"
          onSelect={(driver: any) => {
            onTrailerChange(driver?.trailer_params);
            onFormDataChange(driver.id, 'driver_id');
            onFormDataChange(driver, 'selectedDriver');
          }}
          maxHeight={18}
          additionalFilters={{
            lat: formData.start_lat,
            lng: formData.start_lng,
          }}
        />
      </div>
      <div className="mt-5">
        <RadioList
          value={formData.sender_type}
          options={[
            { title: 'Оплачивает отправитель', value: 'sender' },
            { title: 'Оплачивает получатель', value: 'recipient' },
            { title: 'Заказ на администратора', value: 'admin' },
          ]}
          onChange={option => onFormDataChange(option.value, 'sender_type')}
        />
      </div>
      {formData.sender_type === 'admin' && (
        <div className="mt-2 flex flex-col">
          <p className="text-[#808080] text-sm mt-4">Тип оплаты</p>
          <div className="flex">
            <RadioList
              value={formData.payment_type}
              options={[
                { title: 'COD', value: 'COD' },
                { title: 'COP', value: 'COP' },
                { title: 'NOP', value: 'NOP' },
              ]}
              onChange={option => onFormDataChange(option.value, 'payment_type')}
            />
          </div>
        </div>
      )}
      <div className="mt-2 mb-5">
        <RadioList
          value={formData.payment_where}
          options={[
            { title: 'Ожидает оплаты до старта', value: 2 },
            { title: 'Оплата после выполнения', value: 3 },
            { title: 'Оплачен', value: 1 },
          ]}
          onChange={option => onFormDataChange(option.value, 'payment_where')}
        />
      </div>
      {[
        { title: 'Отправитель', field: 'sender' },
        { title: 'Получатель', field: 'recipient' },
      ].map(participant => (
        <div className="text-sm mt-[10px]" key={participant.field}>
          <p className="text-[#808080] text-sm w-[293px]">{participant.title}</p>
          {formData.sender_type === 'admin' ? (
            <div className="grid grid-cols-[1fr_1fr] gap-[5px] mt-[5px]">
              <Input
                value={formData[participant.field].first_name}
                className="col-[1_/_2]"
                placeholder="Имя"
                onChange={(name: string) =>
                  onFormDataChange(
                    { ...formData[participant.field], first_name: name },
                    participant.field
                  )
                }
              />

              <Input
                value={formData[participant.field].last_name}
                className="col-[2_/_3]"
                placeholder="Фамилия"
                onChange={(surname: string) =>
                  onFormDataChange(
                    { ...formData[participant.field], last_name: surname },
                    participant.field
                  )
                }
              />

              <Input
                value={formData[participant.field].phone_number}
                className="col-[1_/_3]"
                placeholder="Номер телефона"
                onChange={(phone: string) =>
                  onFormDataChange(
                    { ...formData[participant.field], phone_number: phone },
                    participant.field
                  )
                }
              />
            </div>
          ) : (
            <div className="w-full my-2 relative">
              <Dropdown
                value={formData[participant.field + 'Selected']}
                placeholder="Выбрать клиента"
                type="clients"
                searchable={true}
                onSelect={(selectedUser: any) => {
                  const firstName = selectedUser.fullName.split(' ')[0];
                  const lastName = selectedUser.fullName.split(' ')[1];

                  onFormDataChange(selectedUser, participant.field + 'Selected');
                  onFormDataChange(
                    {
                      id: selectedUser.id,
                      first_name: firstName,
                      last_name: lastName,
                      phone_number: selectedUser.phone,
                    },
                    participant.field
                  );
                }}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default FirstRegistrationStep;
