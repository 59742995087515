import { FunctionComponent } from 'react';
import DatePicker, { DayRange, Day } from 'react-modern-calendar-datepicker';
import localeRU, { formatDate } from 'src/utils/calendar';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import Input from '../Input';

import './index.css';

interface IDatePicker {
  value: any;
  min?: Day;
  max?: Day;
  placeholder?: string;
  onChange: (newRange: DayRange) => any;
  calendarPopperPosition?: 'auto' | 'top' | 'bottom';
}

const CustomDatePicker: FunctionComponent<IDatePicker> = ({
  value,
  min,
  max,
  placeholder = 'дд.мм.гггг',
  onChange = () => {},
  calendarPopperPosition = 'auto',
}) => {
  const renderInput: FunctionComponent<any> = ({ ref }) => (
    <Input
      ref={ref}
      readonly={true}
      value={
        value?.to && value?.from
          ? `${formatDate(value.from)} - ${formatDate(value.to)}`
          : formatDate(value)
      }
      type="date"
      placeholder={placeholder}
      className="cursor-pointer"
      style={{ cursor: 'pointer' }}
      onChange={onChange}
    />
  );

  return (
    <DatePicker
      renderInput={renderInput}
      value={value}
      minimumDate={min}
      maximumDate={max}
      colorPrimary="#006bb2"
      colorPrimaryLight="#e2ecf3"
      locale={localeRU}
      onChange={onChange}
      calendarPopperPosition={calendarPopperPosition}
    />
  );
};

export default CustomDatePicker;
