import { FunctionComponent } from 'react';
import { findStatus } from 'src/utils/order-statuses';
import timeCount from 'src/utils/timeCounter';
import DriverInfo from './DriverInfo';

const OrderInfoWindow: FunctionComponent<any> = ({ order }) => {
  const startDate = new Date(order?.start_at);
  const startDateFormatted = startDate.toLocaleString('ru', {
    weekday: 'long',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
  const startDateString = startDateFormatted.charAt(0).toUpperCase() + startDateFormatted.slice(1);
  return (
    <a href={`/order/${order?.id}`}>
      <div className="w-fit bg-white rounded p-[8px] flex flex-col max-w-[300px] text-[1.05em]">
        <div className="w-fit text-[#4f86c5] mb-2 font-medium">
          {startDate.getTime() > new Date().getTime()
            ? `Начало: ${startDateString} (${timeCount(order?.start_at)} ост)`
            : `${startDateString}`}
        </div>
        <div className="mb-2 w-fit px-[0.7em] py-[0.35em] rounded-full bg-[#dbe8f3] text-[#1066B1]">
          {findStatus(order).title}
        </div>

        <div className="grid grid-rows-[auto_max-content_auto] grid-cols-[min-content_auto] gap-x-[0.5em] items-center w-full mb-2">
          <div className="col-[1_/_2] row-[1_/_2] bg-white w-[1.25em] h-[1.25em] border-4 border-[#3cb15b] rounded-full"></div>
          <div className="col-[1_/_2] row-[2_/_3] w-[3px] bg-[#c5d9f3] min-h-[1em] h-full mx-[auto] rounded-full">
            <div className="h-[4px] w-full bg-[#1c6bb4] rounded-full"></div>
          </div>
          <div className="col-[1_/_2] row-[3_/_4] bg-white w-[1.25em] h-[1.25em] border-4 border-[#2a68b1] rounded-full"></div>
          <div className="col-[2_/_3] row-[1_/_2]">{order?.start_to}</div>
          <div className="col-[2_/_3] row-[3_/_4]">{order?.finish_to}</div>
        </div>

        <div className="grid grid-cols-[auto_max-content] items-center text-[#9e9e9e]">
          <DriverInfo driver={order?.driver} />
          <div className="flex flex-col">
            <div className="text-[1.3em] text-[#000000] text-end mb-[4px]">
              ${order?.total_price || 0}
            </div>
            <div className="text-start">for {order?.items?.length || '-----'} cars</div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default OrderInfoWindow;
