import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import logger from 'src/utils/logger';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';
import Textarea from 'src/components/UI/Textarea';
import Select from 'src/components/UI/Select';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Input from 'src/components/UI/Input';
import { isPermitted } from 'src/utils/permissions';
import { DriverConnectionStatus } from 'src/components/Driver/ConnectionStatus';

const statuses = [
  { label: 'Утвержден', color: '#e9f7ef', textColor: '#27ae60' },
  { label: 'Заполнение', color: '#f6f0ff' },
  { label: 'На модерации' },
  { label: 'Заблокирован', color: '#fde6e6', textColor: '#ee0202' },
  { label: 'Повторная модерация' },
];

interface DriversGeneralTypes {
  data: any;
  setData: any;
  getData: any;
  saveData: any;
  updateAvatar: any;
  deleteAvatar: any;

  updateStatus: any;
  setIsLoading: any;
  setCarouselImages: any;
  setCarouselOpen: any;
  setActiveSlide: any;
  companies: Array<any>;
  setCompanies: any;
  response: string;
  customAlert: any;
}

const changableGeneralFields = [
  { key: 'firstName', title: 'Имя' },
  { key: 'lastName', title: 'Фамилия' },
  { key: 'phoneNumber', title: 'Телефон' },
  { key: 'email', title: 'Почта' },
];

const DriversGeneral: FunctionComponent<DriversGeneralTypes> = ({
  data,
  setData,
  getData,
  saveData,
  updateStatus,
  updateAvatar,
  deleteAvatar,

  setIsLoading,
  setCarouselImages,
  setCarouselOpen,
  setActiveSlide,
  companies,
  setCompanies,
  response,
  customAlert,
}) => {
  const [cause, setCause] = useState<any>('');
  const [causeModal, setCauseModal] = useState<any>(false);
  const [company, setCompany] = useState<any>({});
  const [errorsFields, setErrorsFields] = useState<{ [key: string]: boolean }>({});
  const [error, setError] = useState<any>('');

  const updateDataField = (value: any, field: string) => {
    setData({ ...data, [field]: value });
  };

  const setNewAvatar = async e => {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];

    if ((file.type !== 'image/jpeg' && file.type !== 'image/png') || file.size / 1024 / 1024 >= 5) {
      return;
    }

    let formData = new FormData();

    formData.append('uin', data.uin);
    formData.append('avatar', file);

    try {
      await updateAvatar(formData);
      await getData();
    } catch (e: any) {
      logger.error(`Ошибка при обновление аватара ${e.message}`, e);
    }
  };

  const removeAvatar = async () => {
    try {
      await deleteAvatar();
      await getData();
    } catch (e: any) {
      logger.error(`Ошибка при удалении аватара ${e.message}`, e);
      return;
    }
  };

  const validateForm = () => {
    let message: string = '';
    const errors: any = {};
    if (!data.firstName) {
      errors.firstName = true;
    }
    if (!data.lastName) {
      errors.lastName = true;
    }
    if (!data.phoneNumber) {
      errors.phoneNumber = true;
    }

    if (Object.keys(errors).length > 0) {
      message = 'Все поля обязательны к заполнению';
    }

    if (
      !data.email ||
      !String(data.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      message = 'Адрес почты должен содержать знак @, латиницу и точку';
      errors.email = true;
    }

    if (!!message || Object.keys(errors).length > 0) {
      setErrorsFields(errors);
      logger.error(message);

      throw new Error('Validation error');
    }
  };

  const updateGeneralInfo = async () => {
    validateForm();

    setCause('');
    setCauseModal(false);
    saveData();
  };

  const updateDirverStatus = async (status: string) => {
    if (status === 'Повторная модерация' && !cause.length) {
      setError('Причина обязательна');
      return;
    }
    setError('');
    setCauseModal(false);

    try {
      await updateStatus(status, cause);
      await getData();
    } catch (e: any) {
      logger.error(`Ошибка при обновление статуса ${e.message}`, e);
    } finally {
      setCause('');
    }
  };

  useEffect(() => {
    if (data) {
      setCompany(companies.find(c => c.id === data.companyId) ?? {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.companyId]);

  useEffect(() => {
    let update = typeof data?.companyId === 'number' ? data.companyId : data?.company;
    setCompany(companies.find(opt => update === opt.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="pl-5 pb-6 mt-6 ">
        <div className="flex items-center">
          <Select
            options={statuses}
            value={statuses.find(stat => data.status === stat.label)}
            placeholder="Статус"
            defaultColor="#f2e9ff"
            defaultTextColor="#7f22fd"
            className="max-w-md"
            maxHeight={12}
            disabled={!isPermitted('drivers_change_status')}
            onSelect={({ label: status }: any) => {
              if (status === 'Повторная модерация') {
                setCauseModal(data.id);
              } else {
                updateDirverStatus(status);
              }
            }}
          />

          <DriverConnectionStatus
            connection_lost={data.connection_lost}
            connection_lost_at={data.connection_lost_at}
            className="!text-sm ml-5"
          />
        </div>

        <div className="flex">
          <div className="mt-6 mr-8">
            <p className="mb-2 text-[#999]">Фото</p>
            <div className="flex items-center">
              <div className="flex justify-center items-center bg-[#f2e9ff] overflow-hidden max-w-[5rem] max-h-[5rem] min-w-[5rem] min-h-[5rem] rounded-full">
                <img
                  alt="avatar"
                  className="object-cover w-[5em] h-[5em]"
                  src={data.avatar || '/assets/img/clients.svg'}
                />
              </div>
              <div>
                {data.avatar ? (
                  <div className="ml-4">
                    <p className="mb-1 font-semibold">{data.avatar?.filename}</p>
                    <p className="font-semibold text-[#EE0202] cursor-pointer" onClick={removeAvatar}>
                      Удалить фото
                    </p>
                  </div>
                ) : (
                  <div className="ml-4 flex flex-col">
                    <label>
                      <p className="font-bold text-[#1066B1] cursor-pointer">+ Добавить фото</p>
                      <input
                        type="file"
                        accept="image/jpeg,image/png"
                        onChange={setNewAvatar}
                        className="hidden"
                      />
                    </label>
                    <p className="flex flex-col text-xs text-[#A9A1A1]">
                      <span>не более 5.00mb jpg, png, jpeg.</span>
                      <span>Рекомендуемый размер 150x150</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-wrap">
          {changableGeneralFields.map(field => (
            <div key={field.key} className="mt-6 mr-8">
              <p className="mb-2 text-[#999]">{field.title}</p>
              <Input
                value={data[field.key] || ''}
                error={errorsFields[field.key]}
                placeholder={field.title}
                onChange={value => updateDataField(value, field.key)}
              />
            </div>
          ))}
        </div>

        <div className="mt-6">
          <p className="mb-2 text-[#999]">Заработано</p>
          <p>
            $
            {data.earned.length
              ? data.earned.reduce((partialSum, item) => partialSum + item.amount, 0)
              : data.earned}
          </p>
        </div>
        <div className="mt-6">
          <p className="mb-2 text-[#999]">Водительские права</p>
          <div className="flex">
            <div className="rounded-[4px] w-12 h-12 overflow-hidden mr-2">
              <img
                onClick={() => {
                  setCarouselImages(() => [
                    data.driverLicenseFront,
                    data.driverLicenseBack,
                    data.driverLicenseWithUser,
                  ]);
                  setActiveSlide(() => 0);
                  setCarouselOpen(() => true);
                }}
                src={data.driverLicenseFront}
                alt=""
                className="w-12 h-12 bg-[#DADADA] object-cover border-0 cursor-pointer"
              />
            </div>
            <div className="rounded-[4px] w-12 h-12 overflow-hidden mr-2">
              <img
                onClick={() => {
                  setCarouselImages(() => [
                    data.driverLicenseFront,
                    data.driverLicenseBack,
                    data.driverLicenseWithUser,
                  ]);
                  setActiveSlide(() => 1);
                  setCarouselOpen(() => true);
                }}
                src={data.driverLicenseBack}
                alt=""
                className="w-12 h-12 bg-[#DADADA] object-cover border-0 cursor-pointer"
              />
            </div>
            <div className="rounded-[4px] w-12 h-12 overflow-hidden mr-2">
              <img
                onClick={() => {
                  setCarouselImages(() => [
                    data.driverLicenseFront,
                    data.driverLicenseBack,
                    data.driverLicenseWithUser,
                  ]);
                  setActiveSlide(() => 2);
                  setCarouselOpen(() => true);
                }}
                src={data.driverLicenseWithUser}
                alt=""
                className="w-12 h-12 bg-[#DADADA] object-cover border-0 cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div className="mt-6">
          <p className="mb-2 text-[#999]">Страховка</p>
          <div className="rounded-[4px] w-12 h-12 overflow-hidden mr-2">
            <img
              onClick={() => {
                setCarouselImages(() => [data.insurance, '']);
                setActiveSlide(() => 0);
                setCarouselOpen(() => true);
              }}
              src={data.insurance}
              alt=""
              className="w-12 h-12 bg-[#DADADA] object-cover border-0 cursor-pointer"
            />
          </div>
        </div>

        <div className="mt-8 max-w-md">
          <p className="mb-2 text-[#999]">Компания перевозчик</p>
          <Select
            options={companies}
            value={company}
            placeholder="Название"
            label="name"
            disabled={!isPermitted('drivers_change_company')}
            onSelect={newCompany => updateDataField(newCompany.id, 'companyId')}
          />
        </div>
        <div className="mt-6">
          <div className="max-w-[15em] text-[1.25em]">
            <ButtonSPrimary onClickHandler={updateGeneralInfo}>Сохранить</ButtonSPrimary>
          </div>

          {response && (
            <p
              className={response === 'Изменения успешно сохранены' ? 'text-green-500' : 'text-red-500'}
            >
              {response}
            </p>
          )}
        </div>
      </div>
      <ModalsSection>
        <ModalBackdrop state={causeModal}>
          <ModalWindow title="Повторная модерация" setState={setCauseModal}>
            <div className="flex flex-col">
              <p className="mb-2">Причина</p>
              <Textarea placeholder="" value={cause} setValue={e => setCause(e)} />
              <div className="w-[320px] mt-6">
                <ButtonSPrimary onClickHandler={() => updateDirverStatus('Повторная модерация')}>
                  Отправить
                </ButtonSPrimary>

                {response && (
                  <p
                    className={
                      response === 'Изменения успешно сохранены' ? 'text-green-500' : 'text-red-500'
                    }
                  >
                    {response}
                  </p>
                )}

                {!!error && <p className="absolute text-[#EE0202]">{error}</p>}
              </div>
            </div>
          </ModalWindow>
        </ModalBackdrop>
      </ModalsSection>
    </>
  );
};

export default DriversGeneral;
