import { FunctionComponent } from 'react';
import OrderInfoWindow from './OrderInfoWindow';
import MarkerWithWindow from './MarkerWithWindow';

interface IOrder {
  driver: any;
  start_to: string;
  start_lat: string;
  start_lng: string;
  finish_to: string;
  finish_lat: string;
  finish_lng: string;
}

interface IRouteComponent {
  order: IOrder;
}

const RouteComponent: FunctionComponent<IRouteComponent> = ({ order }) => {
  return (
    <>
      <MarkerWithWindow
        icon="/assets/img/mapStart.svg"
        title={order.start_to || 'Start'}
        position={{ lat: Number(order.start_lat), lng: Number(order.start_lng) }}
      >
        <OrderInfoWindow order={order} />
      </MarkerWithWindow>

      <MarkerWithWindow
        icon="/assets/img/mapFinish.svg"
        title={order.finish_to || 'Finish'}
        position={{ lat: Number(order.finish_lat), lng: Number(order.finish_lng) }}
      >
        <OrderInfoWindow order={order} />
      </MarkerWithWindow>
    </>
  );
};

export default RouteComponent;
