import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import OrderInfoCell from '../BASIC_CELLS/OrderInfoCell';
import StatusSelectCell from '../BASIC_CELLS/StatusSelectCell';
import UserLockCell from '../BASIC_CELLS/UserLockCell';
import { isPermitted } from 'src/utils/permissions';
import { DriverConnectionStatus } from 'src/components/Driver/ConnectionStatus';

interface IDriversTable {
  drivers: Array<any>;
  isLoading?: boolean;
  loadingRow?: number;
  blockable?: boolean;
  sort: Array<string>;
  selected: IExportSelected;

  onDriverSelect: (newSelected: IExportSelected) => any;
  onDriverStatusUpdate: (driverId: number, newStatus: any, rowIndex: number) => any;
  onSortUpdate: (newSort: Array<string>) => any;
}

const DriversTable: FunctionComponent<IDriversTable> = ({
  drivers,
  isLoading = false,
  loadingRow = NaN,
  blockable = false,
  sort = [],
  selected = [],

  onDriverSelect = () => {},
  onDriverStatusUpdate = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: onDriverSelect,
      additionalParams: { width: '30px' },
    }),

    driver: {
      label: 'Водитель',
      sortBy: 'sort_driver',

      component: (v: any, index: number, driver: any) => (
        <CustomDriverCell
          driverInfo={driver}
          onIdClick={() => navigate(`/drivers/list/${driver?.id}/general`)}
        />
      ),
    },

    status: {
      label: 'Статус',
      sortBy: 'sort_status',

      component: (status: string, index: number, driver: any, rowIndex: number) => (
        <StatusSelectCell
          value={status}
          disabled={!isPermitted('drivers_change_status') || !isNaN(loadingRow)}
          onSelect={(newSelected: any) => onDriverStatusUpdate(driver?.id, newSelected.label, rowIndex)}
        />
      ),
    },

    order: {
      label: 'Текущий заказ',
      sortBy: 'sort_last_order',
      component: (currentOrder: any) => {
        return (
          <OrderInfoCell
            order={currentOrder}
            fromPath={currentOrder?.start_to}
            toPath={currentOrder?.finish_to}
            status={currentOrder?.status}
            price={currentOrder?.average_cost}
            onClick={() => navigate(`/order/${currentOrder?.id}`)}
          />
        );
      },
    },

    countOrders: {
      label: 'Заказов',
      sortBy: 'sort_orders_count',
      width: '100px',
    },

    earned: {
      label: 'Заработано',
      sortBy: 'sort_earning',
      formatter: (earned: string | number) => '$' + earned,
    },

    company: !blockable
      ? { label: 'Компания', sortBy: 'sort_company' }
      : {
          component: (v: any, i: number, driver: any, rowIndex: number) => (
            <UserLockCell
              locked={driver.status === 'Заблокирован'}
              onLock={() => onDriverStatusUpdate(driver.id, 'Заблокирован', rowIndex)}
              onUnlock={() => onDriverStatusUpdate(driver.id, 'На модерации', rowIndex)}
            />
          ),
        },
  };

  return (
    <GridTable
      data={drivers}
      fields={TableFields}
      isLoading={isLoading}
      loadingRow={loadingRow}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

/* ----------------------- TABLE CELLS ----------------------- */

const CustomDriverCell: FunctionComponent<any> = ({ driverInfo, onIdClick = () => {} }) => {
  return (
    <GridTableCell className="items-center">
      <div className="flex justify-center items-center w-[1.75em] h-[1.75em] rounded-full overflow-hidden">
        <img
          alt=""
          src={driverInfo?.avatar || '/assets/img/clients.svg'}
          className="w-full h-full object-cover  mt-1"
        />
      </div>

      <div className="pl-[6px]">
        <p className="text-[#333333] text-[12px]">
          {driverInfo?.lastName ? driverInfo?.lastName : '----'}{' '}
          {driverInfo?.firstName ? driverInfo?.firstName : '----'}
        </p>
        <div className="text-[#9b9d9c] text-[9px] flex">
          {isPermitted('drivers_show') ? (
            <p className="text-[#1066B1] underline text-[9px] cursor-pointer mr-1" onClick={onIdClick}>
              {driverInfo.id}
            </p>
          ) : (
            <p className="text-[9px] mr-1">{driverInfo.id}</p>
          )}
          <p className="mr-1">{driverInfo.phoneNumber}</p>
          <DriverConnectionStatus
            connection_lost={driverInfo.connection_lost}
            connection_lost_at={driverInfo.connection_lost_at}
          />
        </div>
      </div>
    </GridTableCell>
  );
};

export default DriversTable;
