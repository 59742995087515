import React, { FunctionComponent } from 'react';
import Checkbox from 'src/components/UI/Checkbox';

interface ICheckList {
  selected?: Array<any>;
  options: Array<any>;
  field?: string;
  label?: string;
  onChange?: (update: any) => any;
}

const selectToggle = (value: any, selected: Array<any>, update: (value: any) => any) => {
  const newSelected = [...selected];
  if (!newSelected.includes(value)) {
    newSelected.push(value);
  } else {
    const index = newSelected.indexOf(value);
    if (index > -1) {
      newSelected.splice(index, 1);
    }
  }
  update(newSelected);
};

const LabeledCheckbox: FunctionComponent<any> = ({ option, value, disabled, label, onSelect }) => {
  return (
    <div
      className={`flex items-center cursor-pointer ${disabled ? 'opacity-50' : 'opacity-100'}`}
      onClick={() => {
        if (!disabled) {
          onSelect(option);
        }
      }}
    >
      <div className="min-w-[16px] mr-2">
        <Checkbox value={value} disabled={disabled} onClickHandler={() => onSelect(option)} />
      </div>
      <p className="text-sm text-[#333]">{option[label] ? option[label] : String(option)}</p>
    </div>
  );
};

const CheckList: FunctionComponent<ICheckList> = ({
  selected = [],
  options,
  field = 'id',
  label = 'label',
  onChange = () => {},
}) => {
  const onSelect = option => {
    const update = option[field] ? option[field] : String(option);
    selectToggle(update, selected, onChange);
  };

  return (
    <div tabIndex={0} className="flex flex-col">
      {options.map((option: any, index: number) => {
        const isSelected = selected.includes(option[field] ? option[field] : String(option));

        return (
          <div key={option[field] || String(option) || index}>
            <LabeledCheckbox value={isSelected} option={option} label={label} onSelect={onSelect} />

            {Array.isArray(option.sub) && (
              <div className="ml-4">
                {option.sub.map((subOption: any, subOptionIndex: number) => {
                  const isSubOptionSelected = selected.includes(
                    subOption[field] ? subOption[field] : String(subOption)
                  );

                  return (
                    <LabeledCheckbox
                      key={subOption[field] || String(subOption) || subOptionIndex}
                      value={isSelected && isSubOptionSelected}
                      disabled={!isSelected}
                      option={subOption}
                      label={label}
                      onSelect={onSelect}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CheckList;
