import React, { FunctionComponent, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from 'src/libs/axiosClient';
import UnAuthorizedLayout from 'src/layouts/UnAuthorized';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Input from 'src/components/UI/Input';
import { ModalWindow } from 'src/components/UI/Modal';

const RecoveryPage: FunctionComponent = () => {
  const navigate: NavigateFunction = useNavigate();
  const [error, setError] = useState<string>('');
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState<any>({
    hash: searchParams.get('hash'),
    email: searchParams.get('email'),
    password: '',
    password_confirmation: '',
  });

  const validate = () => {
    let message: string = '';
    let response: boolean = true;

    if (formData.password !== formData.password_confirmation) {
      message = 'Пароли должны совподать';
      response = false;
    }

    if (
      formData.password.length < 6 ||
      !/[0-9]/.test(formData.password) ||
      !/[A-z]/.test(formData.password)
    ) {
      message = 'Пароль должен быть не менее 6 символов в длину и содержать как буквы, так и цифры';
      response = false;
    }

    return { response, message };
  };

  const onFormDataFieldChange = (val: any, field: string) => {
    setFormData(() => ({
      ...formData,
      [field]: val,
    }));
  };

  useEffect(() => {
    if (searchParams.get('hash') === null || searchParams.get('email') === null) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!error.length) return;

    const validation = validate();
    setError(validation.response ? '' : validation.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.password, formData.password_confirmation]);

  async function onRecoveryHandler(): Promise<void> {
    const validation = validate();

    if (!validation.response) {
      setError(validation.message);
      return;
    }

    try {
      const { data: res } = await axiosClient.post(`/password-recovery`, formData);
      navigate(`/${res.returnURL || 'authorization'}`);
    } catch (error: any) {
      let message = error.response.data.errors;
      message = message[Object.keys(message)[0]][0];
      setError(message);
    }
  }

  return (
    <>
      <UnAuthorizedLayout>
        <ModalsSection>
          <ModalWindow title="Восстановление пароля" close={false}>
            <div className="flex flex-col">
              <div>
                <p className="mb-2">Новый пароль</p>
                <Input
                  type="password"
                  placeholder="Придумайте новый пароль"
                  value={formData.password}
                  onChange={e => onFormDataFieldChange(e, 'password')}
                />
              </div>
              <div className="mt-6">
                <p className="mb-2">Повторите пароль</p>
                <Input
                  type="password"
                  placeholder="Повторите новый пароль"
                  value={formData.password_confirmation}
                  onChange={e => onFormDataFieldChange(e, 'password_confirmation')}
                />
              </div>
              <div className="w-[320px] mt-6">
                <ButtonSPrimary
                  disabled={!formData.password_confirmation || !formData.password}
                  onClickHandler={onRecoveryHandler}
                >
                  Ок
                </ButtonSPrimary>
              </div>
              {error && (
                <p className="flex justify-center flex-wrap w-full max-w-[20em] mt-4 text-center text-red-600">
                  {error}
                </p>
              )}
            </div>
          </ModalWindow>
        </ModalsSection>
      </UnAuthorizedLayout>
    </>
  );
};

export default RecoveryPage;
