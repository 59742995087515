import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import DatePicker from 'src/components/UI/DatePicker';
import Input from 'src/components/UI/Input';
import Pagination from 'src/components/UI/Pagination';
import axiosClient from 'src/libs/axiosClient';

import ActionsHistoryTable from 'src/components/Tables/admins/actionHistory';
import { formatDate } from 'src/utils/calendar';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ExportModal from 'src/components/ExportModal';

const ActionsHistoryExportModalFields = [
  { title: 'Дата и время', field: 'created_at' },
  { title: 'Действие', field: 'text' },
];

const ActionsHistory: FunctionComponent<any> = () => {
  const { id } = useParams();

  const [actionsHistory, setActionsHistory] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_date', 'desc']);
  const [filters, setFilters] = useState<any>({
    search: '',
    from: '',
    to: '',
  });

  const [actionsHistoryModal, setActionsHistoryModal] = useState<boolean>(false);
  const [actionsExportColumns, setActionsExportColumns] = useState<Array<any>>(
    ActionsHistoryExportModalFields.map(e => e.field)
  );
  const [selected, setSelected] = useState<IExportSelected>([]);

  const onFilterFieldChange = (val: any, field: string) => {
    setFilters({
      ...filters,
      [field]: val,
    });
  };

  const getQueryFilters = (filters: any) => {
    const { search, from, to } = filters;

    return {
      ...(search && { search }),
      ...(from && { from: formatDate(from, '-') }),
      ...(to && { to: formatDate(to, '-') }),
    };
  };

  const getActionsHistory = async (filters: any, currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    setIsLoading(true);
    setActionsHistory([]);

    try {
      const { data: res } = await axiosClient.get(`/admins/${id}/history-action`, {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(filters),
        },
      });

      setTotal(res.links.lastPage);
      setActionsHistory(res.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActionsHistory(filters, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort]);

  useEffect(() => {
    getActionsHistory(filters, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <div className={`bg-[#fff] h-screen`}>
        <div className="mb-3 px-[17px] pt-[14px] flex flex-wrap justify-between items-center">
          <div className="flex flex-wrap items-center">
            <div className="text-sm mr-2 mt-1">
              <Input
                value={filters.search}
                onChange={e => onFilterFieldChange(e, 'search')}
                type="search"
                placeholder="Поиск"
                clearable
              />
            </div>

            <div className="flex items-center mt-1">
              <p className="ml-2 mr-1">Начиная с:</p>
              <div className="ml-2 text-[0.9em]">
                <DatePicker value={filters.from} onChange={e => onFilterFieldChange(e, 'from')} />
              </div>
            </div>

            <div className="flex items-center mt-1">
              <p className="ml-2 mr-1">до:</p>
              <div className="ml-2 text-[0.9em]">
                <DatePicker value={filters.to} onChange={e => onFilterFieldChange(e, 'to')} />
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center mt-1">
            <p className="text-[12px] mr-2 text-[#9b9d9c]">
              {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
            </p>
            <div className="mr-2">
              <ButtonSPrimary onClickHandler={() => setActionsHistoryModal(true)}>
                Экспорт в Excel
              </ButtonSPrimary>
            </div>
          </div>
        </div>
        <div className="overflow-auto bg-white">
          <ActionsHistoryTable
            actions={actionsHistory}
            isLoading={isLoading}
            sort={sort}
            selected={selected}
            onSortUpdate={setSort}
            onSelect={setSelected}
          />

          {!isLoading && actionsHistory.length > 0 && (
            <Pagination totalPages={total} activePage={page} setPage={setPage} alignment="justify-end" />
          )}
        </div>
      </div>

      <ModalsSection>
        <ExportModal
          opened={actionsHistoryModal}
          isLoading={isLoading}
          selected={actionsExportColumns}
          options={ActionsHistoryExportModalFields}
          setSelected={setActionsExportColumns}
          setOpened={setActionsHistoryModal}
          exportParams={{
            url: `/export/${id}/admins/action-history`,
            filename: `admin-action-history-${id}.xlsx`,
            fields: actionsExportColumns,
            filters: getQueryFilters(filters),
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default ActionsHistory;
