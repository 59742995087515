import React, { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';

// Auth
import AuthorizationPage from './pages/authorization';
import RegistrationPage from './pages/registration';
import RecoveryPage from './pages/recovery';
import RecoveryCompanyPage from './pages/authorization/company';
import RegistrationCompanyPage from './pages/registration/company';

// Drivers
import DriversList from './pages/drivers/list';
import DriversPayments from './pages/drivers/payments';
import Driver from './pages/drivers/id';

// Clients
import ClientsList from './pages/clients/list';
import Client from './pages/clients/id';

// Orders
import HistoryPage from './pages/history';
import NewOrders from './pages/new-orders';
import InProgress from './pages/inProgress';
import OrderPage from './pages/orders';

// Companies
import CompaniesList from './pages/companies/list';
import CompanyAdmin from './pages/companies/id';
import CompaniesPaymentsList from './pages/companies/payments/list';

// Legals
import LegalList from './pages/legals/list';
import Legal from './pages/legals/id';

// Settings
import Admin from './pages/settings/id/index';
import AdminsList from './pages/settings/admin';
import Common from './pages/settings/common/index';
import Auto from './pages/settings/auto';
import Price from './pages/settings/price';

import Mailing from './pages/mailing';
import Notifications from './pages/notifications';
import Stats from './pages/stats';

const TheRouter: FunctionComponent = () => {
  return (
    <>
      <Routes>
        <Route path="/authorization" element={<AuthorizationPage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/recovery" element={<RecoveryPage />} />

        <Route path="/authorization/company" element={<RecoveryCompanyPage />} />
        <Route path="/registration/company" element={<RegistrationCompanyPage />} />

        <Route path="/" element={<HistoryPage />} />
        <Route path="/history" element={<HistoryPage />} />
        <Route path="/order/:id" element={<OrderPage />} />
        <Route path="/new-orders" element={<NewOrders />} />
        <Route path="/progress" element={<InProgress />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/mailing" element={<Mailing />} />
        <Route path="/notifications" element={<Notifications />} />

        <Route path="/drivers/list" element={<DriversList />} />
        <Route path="/drivers/list/:id/*" element={<Driver />} />
        <Route path="/drivers/payments" element={<DriversPayments />} />

        <Route path="/clients/list" element={<ClientsList />} />
        <Route path="/clients/list/:id/*" element={<Client />} />

        <Route path="/legals/list" element={<LegalList />} />
        <Route path="/legals/list/:id/*" element={<Legal />} />

        <Route path="/transport-companies/list" element={<CompaniesList />} />
        <Route path="/transport-companies/list/:id/*" element={<CompanyAdmin />} />
        <Route path="/transport-companies/payments/list" element={<CompaniesPaymentsList />} />

        <Route path="/settings/auto" element={<Auto />} />

        <Route path="/settings/adminstrators" element={<AdminsList />} />
        <Route path="/settings/adminstrators/:id/*" element={<Admin />} />
        <Route path="/settings/common" element={<Common />} />
        <Route path="/settings/price" element={<Price />} />
      </Routes>
    </>
  );
};

export default TheRouter;
