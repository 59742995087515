import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from 'src/components/Custom/Loader';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import logger from 'src/utils/logger';
import MainSection from 'src/components/Semantic/MainSection';
import Tabs from 'src/components/Tabs';
import VerticalLayout from 'src/layouts/VerticalLayout';
import axiosClient from 'src/libs/axiosClient';

//SubPages
import CompanyAdminGeneral from './general';
import CompanyAdminDrivers from './drivers';
import CompanyAdminList from './admins';
import CompanyAdminBranding from './branding';
import { isPermitted } from 'src/utils/permissions';

interface ICompany {
  id?: string | number;
  email?: string;
  password?: string;
  logo?: any;
  name?: string;
  years_on_market?: number | string;
  region?: string;
  state?: string;
  w9?: any;
  coi?: any;
  motor?: any;
  owners_id_front?: any;
  owners_id_back?: any;
  dot_mc?: string;
  bank_account_number?: string;
  routing_number?: string;
  bank_name?: string;
  status?: 1 | 2 | 3 | 4;
}

const CompanyAdmin: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const tabLinks = [
    { title: 'Общие данные', link: `/transport-companies/list/${id}/general` },
    { title: 'Брендинг', link: `/transport-companies/list/${id}/branding` },
  ];

  if (isPermitted('companies_driver_list')) {
    tabLinks.push({ title: 'Водители', link: `/transport-companies/list/${id}/drivers` });
  }
  if (isPermitted('companies_admins_list')) {
    tabLinks.push({ title: 'Администраторы', link: `/transport-companies/list/${id}/admins` });
  }

  const [company, setCompany] = useState<ICompany>({});
  const [branding, setBranding] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCompany = async () => {
    setIsLoading(true);

    try {
      const { data } = await axiosClient.get(`/companies/${id}`);
      setCompany(data);
    } catch (e: any) {
      logger.error(`Ошибка при получении компании ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const getBranding = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosClient.get(`/companies/${id}/branding`);
      setBranding(data);
    } catch (e: any) {
      logger.error(`Ошибка при получении компании ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateBranding = async data => {
    setIsLoading(true);

    try {
      await axiosClient.post(`/companies/${id}/branding`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await getBranding();
    } catch (e: any) {
      logger.error(`Ошибка при сохранении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  const setCompanyStatus = async (status: number) => {
    const statuses = ['Утвержден', 'На модерации', 'Заблокирован', 'Повторная модерация'];
    let update: any = { status: statuses[status - 1] };

    try {
      await axiosClient.put(`/companies/${company.id}/change-status`, update);
    } catch (e: any) {
      logger.error(`Ошибка при смене статуса ${e.message}`, e);
    } finally {
      await getCompany();
    }
  };

  const pageUpdate = async () => {
    if (window.location.href.includes('general') || !company.id) {
      await getCompany();
    }

    if (window.location.href.includes('branding')) {
      await getBranding();
    }
  };

  useEffect(() => {
    pageUpdate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] min-h-full pb-6`}>
            <div className="text-[#999999] text-[12px] mb-2 pt-3 pl-5">
              <span className="cursor-pointer" onClick={() => navigate('/transport-companies/list')}>
                Компании перевозчики
              </span>{' '}
              &gt; {company.name}
            </div>
            <div className="flex justify-between items-center border-b-[1px] border-[#EAEAEA] pl-[6px]">
              <Tabs links={tabLinks} />

              <div className="flex">
                {company.status === 3 && (
                  <p
                    className="text-[#EE0202] text-xs mr-4 font-semibold cursor-pointer"
                    onClick={() => setCompanyStatus(1)}
                  >
                    Разблокировать
                  </p>
                )}
                {company.status !== 3 && (
                  <p
                    className="text-[#EE0202] text-xs mr-4 font-semibold cursor-pointer"
                    onClick={() => setCompanyStatus(3)}
                  >
                    Блокировать
                  </p>
                )}
              </div>
            </div>
            {isLoading ? (
              <Loader size={100} />
            ) : (
              <>
                <Routes>
                  <Route
                    path="/general"
                    element={
                      <CompanyAdminGeneral
                        company={company}
                        isLoading={isLoading}
                        getData={getCompany}
                        setData={setCompany}
                        setIsLoading={setIsLoading}
                      />
                    }
                  />
                  <Route path="/drivers" element={<CompanyAdminDrivers />} />
                  <Route path="/admins" element={<CompanyAdminList />} />
                  <Route
                    path="/branding"
                    element={
                      <CompanyAdminBranding
                        branding={branding}
                        setBranding={setBranding}
                        onSave={updateBranding}
                      />
                    }
                  />
                </Routes>
              </>
            )}
          </div>
        </VerticalLayout>
      </MainSection>
    </>
  );
};

export default CompanyAdmin;
