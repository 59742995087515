import { FunctionComponent } from 'react';
import { GridTableCell } from 'src/components/GridTable';
import { getFormattedDate, parseDateFormat, timeGone } from 'src/utils/format-date';

interface ITimeCell {
  date: Date;
  backgroundColor?: string;
}

const TimeCell: FunctionComponent<ITimeCell> = ({ date, backgroundColor = 'white' }) => {
  return (
    <GridTableCell style={{ backgroundColor }}>
      <div className="w-fit">
        <span className="text-[15px] block">
          {date ? getFormattedDate(date, 'MM/DD/YYYY, hh:mm:ss A') : '-'}
        </span>
        <span className="text-[13px] opacity-75 block">{timeGone(date)}</span>
      </div>
    </GridTableCell>
  );
};

export default TimeCell;
