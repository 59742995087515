import React, { FunctionComponent, useCallback, useEffect } from 'react';
import Cross from '../Cross';

interface IModalBackdrop {
  state?: boolean;
}

const ModalBackdrop: FunctionComponent<IModalBackdrop> = ({ state = false, children }) => {
  useEffect(() => {
    if (state === true) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = 'visible';
      };
    }
  }, [state]);

  return (
    <>
      {state && (
        <div
          className="fixed w-screen h-screen z-[150] top-0 left-0"
          style={{ background: 'rgba(0, 0, 0, 0.5)' }}
        >
          {children}
        </div>
      )}
    </>
  );
};

interface IModalWindow {
  title?: string;
  step?: number;
  setStep?: React.Dispatch<React.SetStateAction<number>>;
  setState?: React.Dispatch<React.SetStateAction<boolean>>;
  leftClose?: boolean;
  close?: boolean;
  onClose?: () => void;
  className?: string;
}

export const ModalWindow: FunctionComponent<IModalWindow> = ({
  children,
  title = '',
  step,
  setStep,
  setState = () => {},
  leftClose = false,
  close = true,
  onClose = () => {},
  className = 'w-[480px]',
}) => {
  const onCloseHandler = useCallback(
    function () {
      setState(false);
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    const modal = document.querySelector('.modal') as HTMLDivElement;
    if (modal) modal.focus();
  }, []);

  const handleEnterKey = e => {
    if (document.activeElement?.nodeName !== 'INPUT' || e.key !== 'Enter') return;
    const modal = document.querySelector('.modal') as HTMLDivElement;
    const focusedElement = document.activeElement;
    if (!modal.contains(focusedElement)) return;
    const focusableElements = modal.querySelectorAll('input');
    const focusableArr = Array.from(focusableElements);
    const currentIndex = focusableArr.indexOf(document.activeElement as HTMLInputElement);
    if (currentIndex === focusableArr.length - 1) {
      const submitBtn = Array.from(modal.querySelectorAll('button')).at(-1);
      submitBtn?.focus();
      e.preventDefault();
    }
    focusableArr[currentIndex + 1].focus();
  };

  useEffect(() => {
    const closeModalOnEsc = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      onCloseHandler();
    };
    document.addEventListener('keydown', e => closeModalOnEsc(e));
    return document.removeEventListener('keydown', e => closeModalOnEsc(e));
  }, [onCloseHandler]);

  return (
    <>
      <div
        tabIndex={0}
        onKeyDown={handleEnterKey}
        className="modal w-full h-full flex items-center justify-center"
      >
        <div className={`${className} pb-8 bg-white relative`}>
          {step && step > 1 && (
            <button onClick={() => setStep && setStep(step - 1)} className={`absolute left-5 top-6`}>
              <img src="/assets/img/back.svg" alt="" />
            </button>
          )}
          {close && (
            <button
              tabIndex={-1}
              onClick={onCloseHandler}
              className={`absolute ${leftClose ? 'left-5' : 'right-5'} top-6`}
            >
              <Cross />
            </button>
          )}
          <div className="text-center pb-6 font-medium text-2xl mt-4">{title}</div>
          <div className="w-full flex flex-col justify-center items-center">{children}</div>
        </div>
      </div>
    </>
  );
};

export default ModalBackdrop;
