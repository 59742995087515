import { FunctionComponent } from 'react';
import { GridTableCell } from 'src/components/GridTable';

interface ICompanyInfoCell {
  id?: number;
  logo?: string;
  name?: string;
  onIdClick: (id: any) => any;
}

const CompanyInfoCell: FunctionComponent<ICompanyInfoCell> = ({
  id,
  logo,
  name,
  onIdClick = () => {},
}) => {
  console.log({ logo });
  return (
    <GridTableCell>
      <div className="w-full flex items-center">
        <div className="flex items-center">
          <div className="flex justify-center items-center bg-[#f2e9ff] overflow-hidden max-w-[2.5rem] max-h-[2.5rem] min-w-[2.5rem] min-h-[2.5rem] rounded-full">
            {logo && <img src={logo} alt="" className="object-cover h-[5rem] max-w-[none]" />}
          </div>
        </div>
        <div className="flex flex-col ml-4">
          <p className="text-sm">{name}</p>
          <p className="text-[#4580ca] text-xs cursor-pointer underline" onClick={() => onIdClick(id)}>
            {id}
          </p>
        </div>
      </div>
    </GridTableCell>
  );
};

export default CompanyInfoCell;
