import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logger from 'src/utils/logger';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import DatePicker from 'src/components/UI/DatePicker';
import Input from 'src/components/UI/Input';
import Pagination from 'src/components/UI/Pagination';
import axiosClient from 'src/libs/axiosClient';

import LoginsHistoryTable from 'src/components/Tables/admins/loginHistory';
import { formatDate } from 'src/utils/calendar';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import ExportModal from 'src/components/ExportModal';
import { IExportSelected } from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';

const LoginHistoryExportModalFields = [
  { title: 'IP', field: 'ip' },
  { title: 'Устройство', field: 'device' },
  { title: 'Дата и время', field: 'date_time_entry' },
];

interface ILoginHistory {}

const LoginHistory: FunctionComponent<ILoginHistory> = () => {
  const { id } = useParams();

  const [loginHistory, setLoginHistory] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<Array<string>>(['sort_date', 'desc']);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    search: '',
    from: '',
    to: '',
  });

  const [selected, setSelected] = useState<IExportSelected>([]);
  const [loginHistoryModal, setLoginHistoryModal] = useState<boolean>(false);
  const [loginExportColumns, setLoginExportColumns] = useState<Array<any>>(
    LoginHistoryExportModalFields.map(e => e.field)
  );

  const updateFilterField = (value: any, field: string) => {
    setFilters({ ...filters, [field]: value });
  };

  const getQueryFilters = (filters: any) => {
    const { search, from, to } = filters;

    return {
      ...(search && { search }),
      ...(from && { from: formatDate(from, '-') }),
      ...(to && { to: formatDate(to, '-') }),
    };
  };

  const getLoginHistory = async (filters: any = {}, currentPage: number) => {
    const selectedPage = currentPage || page;
    setPage(selectedPage);

    setIsLoading(true);
    setLoginHistory([]);

    try {
      const { data: history } = await axiosClient.get(`/admins/${id}/history-login`, {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(filters),
        },
      });

      setTotal(history.links.lastPage);
      setLoginHistory(history.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLoginHistory(filters, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  useEffect(() => {
    getLoginHistory(filters, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
        <div className="flex items-center">
          <div className="text-sm mr-2">
            <Input
              value={filters.search}
              onChange={value => updateFilterField(value, 'search')}
              type="search"
              placeholder="Поиск"
              clearable
            />
          </div>
          <p className="ml-2 mr-1">Начиная с:</p>
          <div className="ml-2 text-[0.9em]">
            <DatePicker value={filters.from} onChange={value => updateFilterField(value, 'from')} />
          </div>
          <p className="ml-4 mr-1">до:</p>
          <div className="ml-2 text-[0.9em]">
            <DatePicker value={filters.to} onChange={value => updateFilterField(value, 'to')} />
          </div>
        </div>
        <div className="flex justify-end items-center">
          <p className="text-[12px] mr-2 text-[#9b9d9c]">
            {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
          </p>
          <div className="mr-2">
            <ButtonSPrimary onClickHandler={() => setLoginHistoryModal(true)}>
              Экспорт в Excel
            </ButtonSPrimary>
          </div>
        </div>
      </div>
      <div className="overflow-auto bg-white">
        <LoginsHistoryTable
          data={loginHistory}
          isLoading={isLoading}
          selected={selected}
          sort={sort}
          onSortUpdate={setSort}
          onSelect={setSelected}
        />

        {!isLoading && loginHistory.length !== 0 && (
          <Pagination totalPages={total} activePage={page} setPage={setPage} alignment="justify-end" />
        )}
      </div>

      <ModalsSection>
        <ExportModal
          opened={loginHistoryModal}
          isLoading={isLoading}
          selected={loginExportColumns}
          options={LoginHistoryExportModalFields}
          setSelected={setLoginExportColumns}
          setOpened={setLoginHistoryModal}
          exportParams={{
            url: `/export/${id}/admins/login-history`,
            filename: `admin-login-history-${id}.xlsx`,
            fields: loginExportColumns,
            filters: getQueryFilters(filters),
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default LoginHistory;
