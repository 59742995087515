import { FunctionComponent, useMemo } from 'react';
import { GridTableCell } from 'src/components/GridTable';
import { countStatuses } from 'src/utils/order-statuses';

interface IOrderInfoCell {
  order: any;
  fromPath?: string;
  toPath?: string;
  status?: number;
  price?: string;
  maxWidth?: string;
  backgroundColor?: string;

  onClick: () => any;
}

const OrderInfoCell: FunctionComponent<IOrderInfoCell> = ({
  order,
  fromPath,
  toPath,
  status,
  price,
  maxWidth = '30rem',
  backgroundColor = 'white',
  onClick = () => {},
}) => {
  const statuses = useMemo(() => {
    if (!order || !status) {
      return [];
    }

    const counted = countStatuses(order);

    const activeStatus = counted.find(e => e.id === status);

    return counted.filter(e => e.id === status || activeStatus?.availableStatuses.includes(e.id));
  }, [order, status]);

  if (!fromPath && !toPath && !price) {
    return <GridTableCell className="text-sm">Нет</GridTableCell>;
  }

  return (
    <GridTableCell style={{ backgroundColor }}>
      <div className="flex flex-col">
        <p
          className={`cursor-pointer text-[#4580ca] text-sm underline`}
          style={{ maxWidth }}
          onClick={onClick}
        >
          {fromPath}
          {toPath ? `- ${toPath}` : ''}
        </p>
        <p className="text-xs">
          <span className="text-[#4580ca]">{statuses.find(s => s.id === status)?.title ?? ''}</span>
          <span className="ml-2">${price}</span>
        </p>
      </div>
    </GridTableCell>
  );
};

export default OrderInfoCell;
