import { FunctionComponent } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Loader from 'src/components/Custom/Loader';
import Button from 'src/components/UI/Button';
import ModalBackdrop, { ModalWindow } from 'src/components/UI/Modal';

export const DoneConfirmModal: FunctionComponent<{
  opened: boolean;
  setOpened: (v: boolean) => void;
  isLoading: boolean;
  onSave: () => void;
}> = ({ opened, setOpened, isLoading, onSave }) => {
  return (
    <ModalBackdrop state={opened}>
      <ModalWindow
        title={'Вы действительно хотите завершить заказ?'}
        setState={e => setOpened(e as boolean)}
      >
        <div className="p-5">
          <p className="text-sm text-center">
            При одобрении и завершении заказа изменить статус этого заказа будет нельзя и будет считаться
            успешно выполненным.
          </p>

          {isLoading ? (
            <Loader size={100} />
          ) : (
            <div className="flex gap-4 w-full text-[1.1em] mt-6">
              <Button variant="default" onClickHandler={() => setOpened(false)}>
                Отмена
              </Button>
              <ButtonSPrimary onClickHandler={() => onSave()}>Завершить</ButtonSPrimary>
            </div>
          )}
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};
