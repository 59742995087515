import { FunctionComponent } from 'react';

// Components
import ModalBackdrop from '../UI/Modal';
import { ModalWindow } from '../UI/Modal';
import CheckList from '../UI/CheckList';
import Loader from '../Custom/Loader';
import Button from '../UI/Button';
import { IExportSelected } from '../Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import axiosClient from 'src/libs/axiosClient';
import fileDownload from 'js-file-download';
import logger from 'src/utils/logger';

interface IExportModal {
  title?: string;
  opened: boolean;
  isLoading?: boolean;
  selected: Array<any>;
  options: Array<any>;
  field?: string;
  label?: string;

  exportParams: {
    url: string;
    fields: string[];
    selected: IExportSelected;
    filename: string;
    filters: any;
    setIsLoading: (boolean) => void;
  };
  setSelected: (newSelected: any) => any;
  setOpened: (newOpened: any) => any;
}

const ExportModal: FunctionComponent<IExportModal> = ({
  title = 'Экспорт в Excel',
  opened,
  selected,
  options,
  field = 'field',
  label = 'title',
  isLoading = false,
  setOpened,
  setSelected,

  exportParams,
}) => {
  const onExport = async () => {
    const { url, fields, selected, filename, filters, setIsLoading } = exportParams;

    setIsLoading(true);
    try {
      const res = await axiosClient({
        url,
        method: 'POST',
        data: {
          fields,
          selected: selected === 'all' ? undefined : selected,
          ...filters,
        },
        responseType: 'blob',
      });
      fileDownload(res.data, filename);
    } catch (e: any) {
      logger.error(`Ошибка при экспорте данных ${e.message}`, e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalBackdrop state={opened}>
      <ModalWindow title={title} setState={setOpened}>
        <div className="flex flex-col">
          <div className="flex flex-col max-h-[40vh] overflow-y-auto">
            <CheckList
              selected={selected}
              options={options}
              field={field}
              label={label}
              onChange={setSelected}
            />
          </div>
          <div className="w-[320px] mt-4">
            {isLoading ? (
              <Loader size={100} />
            ) : (
              <>
                <Button
                  variant="primary"
                  disabled={() => selected.length === 0}
                  onClickHandler={onExport}
                >
                  Экспортировать
                </Button>
              </>
            )}
          </div>
        </div>
      </ModalWindow>
    </ModalBackdrop>
  );
};

export default ExportModal;
