import { FunctionComponent } from 'react';
import DriverInfo from './DriverInfo';

const DriverInfoWindow: FunctionComponent<any> = ({ driver }) => {
  return (
    <div className="w-fit bg-white rounded flex items-center max-w-[250px] text-[1.05em] text-[#9e9e9e]">
      <DriverInfo driver={driver} />
    </div>
  );
};

export default DriverInfoWindow;
