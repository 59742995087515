import { FunctionComponent, useEffect, useState } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import Loader from 'src/components/Custom/Loader';
import Input from 'src/components/UI/Input';
import { isPermitted } from 'src/utils/permissions';

interface IAdminGeneral {
  admin: any;
  errorsFields: { [key: string]: boolean };
  isLoading?: boolean;
  setAdmin: any;
  onDeleteAvatar?: () => any;
  onSave?: (form: any) => any;
}

const AdminGeneral: FunctionComponent<IAdminGeneral> = ({
  admin,
  errorsFields,
  isLoading,
  setAdmin,
  onDeleteAvatar = () => {},
  onSave = () => {},
}) => {
  const [form, setForm] = useState<any>({ avatar: null });

  const addAvatar = (event: any) => {
    if (!event?.target || !event.target.files?.length) {
      return;
    }

    updateFormField(event.target.files[0], 'avatar');
  };

  const deleteAvatar = (event: any) => {
    event.preventDefault();

    if (form.avatar !== null) {
      updateFormField(null, 'avatar');
      return;
    }

    updateFormField(null, 'avatar');
    onDeleteAvatar();
  };

  const updateFormField = (value: any, field: string) => {
    setForm({ ...form, [field]: value });
  };

  useEffect(() => {
    setForm({ ...admin, avatar: null });
  }, [admin]);

  const isFormDisabled = () => {
    return !isPermitted('settings_admins_edit');
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="pl-5 mt-6">
      <h1 className="text-[#333] font-bold text-lg">Общие данные</h1>

      <div className="mt-6">
        <p className="mb-2">Фото</p>
        <label>
          <div className="flex items-center">
            <div className="grid grid-cols-1 grid-rows-1 rounded-full overflow-hidden">
              <div
                className={
                  'z-20 col-[1_/_2] row-[1_/_2] flex justify-center items-center text-2xl' +
                  ' text-white bg-[#00000052] cursor-pointer opacity-0 hover:opacity-100'
                }
              >
                +
              </div>
              <img
                src={
                  form.avatar ? URL.createObjectURL(form.avatar) : admin.avatar ?? '/assets/img/user.png'
                }
                alt=""
                className="z-10 col-[1_/_2] row-[1_/_2] w-[64px] h-[64px] rounded-full cursor-pointer object-cover"
              />
              {!isFormDisabled() && (
                <input
                  type="file"
                  name="avatar"
                  accept="image/*"
                  className="hidden"
                  onChange={addAvatar}
                />
              )}
            </div>

            {!isFormDisabled() && (
              <>
                {form.avatar !== null || admin.avatar ? (
                  <div className="ml-5">
                    <p
                      className="text-[#EE0202] text-xs font-semibold cursor-pointer mt-1 py-2"
                      onClick={deleteAvatar}
                    >
                      Удалить фото
                    </p>
                  </div>
                ) : (
                  <div className="ml-5">
                    <p className="text-xs font-semibold">{form.avatar?.name || admin.avatar}</p>
                    <p className="text-[#3072C4] text-xs cursor-pointer mt-1 font-bold">
                      + Добавить фото
                    </p>
                    <p className="text-[#00000080] text-xs">не более 5.00mb jpg, png, jpeg.</p>
                    <p className="text-[#00000080] text-xs">Рекомендуемый размер 150х150</p>
                  </div>
                )}
              </>
            )}
          </div>
        </label>
      </div>
      <div className="mt-6 max-w-[25em]">
        <p className="mb-2">Имя</p>
        <Input
          value={form.name || ''}
          readonly={isFormDisabled()}
          error={errorsFields.name}
          onChange={value => updateFormField(value, 'name')}
        />
      </div>
      <div className="mt-6 max-w-[25em]">
        <p className="mb-2">Фамилия</p>
        <Input
          value={form.surname || ''}
          readonly={isFormDisabled()}
          error={errorsFields.surname}
          onChange={value => updateFormField(value, 'surname')}
        />
      </div>
      <div className="mt-6 max-w-[25em]">
        <p className="mb-2">Никнейм</p>
        <Input
          value={form.username || ''}
          readonly={isFormDisabled()}
          error={errorsFields.username}
          onChange={value => updateFormField(value, 'username')}
        />
      </div>
      <div className="mt-6 max-w-[25em]">
        <p className="mb-2">Почта</p>
        <Input
          value={form.email || ''}
          readonly={isFormDisabled()}
          error={errorsFields.email}
          onChange={value => updateFormField(value, 'email')}
        />
      </div>
      <div className="mt-6 max-w-[25em]">
        <p className="mb-2">Телефон</p>
        <Input
          value={form.phone || ''}
          readonly={isFormDisabled()}
          error={errorsFields.phone}
          onChange={value => updateFormField(value, 'phone')}
        />
      </div>
      {!isFormDisabled() && (
        <div className="mt-8 max-w-[15em] text-[1.1em]">
          <ButtonSPrimary onClickHandler={() => onSave(form)}>Сохранить</ButtonSPrimary>
        </div>
      )}
    </div>
  );
};

export default AdminGeneral;
