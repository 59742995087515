import { Locale } from 'react-modern-calendar-datepicker';

const localeRU: Locale = {
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Aвгуст',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],

  weekDays: [
    { name: 'Понедельник', short: 'Пн' },
    { name: 'Вторник', short: 'Вт' },
    { name: 'Среда', short: 'Ср' },
    { name: 'Четверг', short: 'Чт' },
    { name: 'Пятница', short: 'Пт' },
    { name: 'Суббота', short: 'Сб', isWeekend: true },
    { name: 'Воскресенье', short: 'Вс', isWeekend: true },
  ],

  weekStartingIndex: 6,

  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  transformDigit(digit) {
    return digit;
  },

  nextMonth: 'Следующий месяц',
  previousMonth: 'Предыдущий месяц',
  openMonthSelector: 'Выбрать месяц',
  openYearSelector: 'Выбрать год',
  closeMonthSelector: 'Закрыть',
  closeYearSelector: 'Закрыть',
  defaultPlaceholder: 'Выбор...',

  from: 'from',
  to: 'to',

  digitSeparator: ',',
  yearLetterSkip: 0,
  isRtl: false,
};

export const formatDate = (someDate: any, separator: '.' | '-' = '.') => {
  if (!someDate?.day || !someDate?.month || !someDate?.year) {
    return '';
  }

  const { day, month, year } = someDate;

  let result = [`${day <= 9 ? '0' : ''}${day}`, `${month <= 9 ? '0' : ''}${month}`, `${year}`];

  result = separator === '-' ? result.reverse() : result;

  return result.join(separator);
};

export const weekBefore = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
export const today = new Date();

export const defaultFromDayRange = {
  day: weekBefore.getDate(),
  month: weekBefore.getMonth() + 1,
  year: weekBefore.getFullYear(),
};

export const defaultToDayRange = {
  day: today.getDate(),
  month: today.getMonth() + 1,
  year: today.getFullYear(),
};

export default localeRU;
