import React, { FunctionComponent } from 'react';

const Cross: FunctionComponent<{
  stroke?: string;
  strokeWidth?: string;
  strokeOpacity?: string;
}> = ({ stroke = 'black', strokeWidth = '1.5', strokeOpacity = '0.37' }) => {
  return (
    <>
      <svg
        className="m-auto w-[1em] h-[1em]"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L7 7M13 13L7 7M7 7L13 1M7 7L1 13"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeWidth={strokeWidth}
        />
      </svg>
    </>
  );
};

export default Cross;
