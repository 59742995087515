import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ButtonSPrimary from 'src/components/Custom/ButtonSPrimary';
import MainSection from 'src/components/Semantic/MainSection';
import ModalsSection from 'src/components/Semantic/ModalsSection';
import Input from 'src/components/UI/Input';
import Pagination from 'src/components/UI/Pagination';
import VerticalLayout from 'src/layouts/VerticalLayout';
import { useNavigate } from 'react-router-dom';
import Select from 'src/components/UI/Select';
import ExportModal from 'src/components/ExportModal';
import axiosClient from 'src/libs/axiosClient';
import logger from 'src/utils/logger';
import GridTable, { GridTableCell } from 'src/components/GridTable';
import ExportCheckboxesColumn, {
  IExportSelected,
} from 'src/components/Tables/BASIC_COLUMNS/ExportCheckboxesColumn';
import { FilterBar, FilterButton } from 'src/components/FilterBar';
import CheckList from 'src/components/UI/CheckList';
import { isPermitted } from 'src/utils/permissions';

const statuses = [
  { value: 'paid', label: 'Выплачено', color: '#e9f7ef', textColor: '#27ae60' },
  { value: 'expect', label: 'Ожидает', color: '#f2e9ff', defaultColor: '#7F22FD' },
  { value: 'cancel', label: 'Отменено', color: '#fde6e6', textColor: '#ee0202' },
  { value: 'approved', label: 'Одобрено', color: '#dbe8f3', textColor: '#1066B1' },
];

const exportFields = [
  // { field: 'id', title: 'ID' },
  { field: 'driver', title: 'Водитель' },
  { field: 'status', title: 'Статус' },
  { field: 'order', title: 'Выполненный заказ' },
  { field: 'amount', title: 'Сумма' },
  { field: 'company', title: 'Компания' },
];

const defaultFilters = {
  status: [],
  order: '',
  driver: '',
};

const DriversPayments: FunctionComponent = () => {
  const navigate = useNavigate();

  const filtersButtonRef = useRef<any>(null);

  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [exportModal, setExportModal] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingRow, setLoadingRow] = useState<number>(NaN);

  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [sort, setSort] = useState<any>(['sort_status', 'asc']);
  const [exportSelected, setExportSelected] = useState<any>(exportFields.map(e => e.field));
  const [selected, setSelected] = useState<IExportSelected>([]);
  const [filters, setFilters] = useState<any>(defaultFilters);

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: setSelected,
      additionalParams: { width: '30px' },
    }),

    driver: {
      label: 'Водитель',
      sortBy: 'sort_driver',

      component: (driver: any) =>
        driver ? (
          <GridTableCell>
            <div className="flex items-center">
              {driver.avatar ? (
                <img
                  src={driver?.avatar}
                  className="w-[22px] h-[22px] object-cover rounded-full"
                  alt=""
                />
              ) : (
                <div className="w-[22px] h-[22px] rounded-full" style={{ backgroundColor: '#DADADA' }} />
              )}
              <div className="pl-[6px]">
                <p className="text-[#333333] text-[12px]">
                  {driver.lastName} {driver.firstName}
                </p>
                <div className="text-[#9b9d9c] text-[9px] flex">
                  <p
                    className="text-[#1066B1] underline text-[9px] cursor-pointer mr-1"
                    onClick={() => navigate(`/drivers/list/${driver.id}/general`)}
                  >
                    {driver.id}
                  </p>
                </div>
              </div>
            </div>
          </GridTableCell>
        ) : (
          <GridTableCell>-</GridTableCell>
        ),
    },

    status: {
      label: 'Статус',
      sortBy: 'sort_status',
      width: '150px',

      component: (status: string, index: number, payment: any, rowIndex: number) => (
        <GridTableCell>
          <Select
            value={statuses.find(e => e.value === status)}
            placeholder="Ожидает"
            options={statuses}
            defaultColor={'#f2e9ff'}
            defaultTextColor={'#7F22FD'}
            onSelect={option => updateStatus(payment.id, option.value, rowIndex)}
          />
        </GridTableCell>
      ),
    },

    order: {
      label: 'Выполненный заказ',
      sortBy: 'sort_order',
      component: (order: any) =>
        order ? (
          <GridTableCell>
            <div>
              <p
                className="cursor-pointer text-sm text-[#1066B1] underline"
                onClick={() => navigate(`/order/${order.id}`)}
              >
                {order.start_to} - {order.finish_to}
              </p>
            </div>
          </GridTableCell>
        ) : (
          <GridTableCell>-</GridTableCell>
        ),
    },

    amount: {
      label: 'Сумма',
      sortBy: 'sort_amount',
      width: '100px',
      component: (amount: number) => (
        <GridTableCell>
          <p>${amount}</p>
        </GridTableCell>
      ),
    },

    company: {
      label: 'Компания',
      sortBy: 'sort_company',
      width: '150px',
      component: (company: any, index: number, payment: any) =>
        company ? (
          <GridTableCell>
            <div onClick={() => navigate(`/transport-companies/list/${company.id}/general`)}>
              <p className="text-xs">{company.name}</p>
              <p className="text-[#1066B1] underline text-[9px] cursor-pointer">{company.id}</p>
            </div>
          </GridTableCell>
        ) : (
          <GridTableCell>-</GridTableCell>
        ),
    },
  };

  const clearFilters = () => {
    setFilters(defaultFilters);
    getPayments(defaultFilters, 1);
  };

  const onFilterFieldChange = (val: any, field: string) => {
    setFilters({
      ...filters,
      [field]: val,
    });
  };

  const getQueryFilters = (filters: any) => {
    const filterStatuses = filters?.status
      ?.map(s => statuses.find(e => e.label === s)?.value)
      .filter(e => !!e);

    return {
      ...(filters.driver && { search_driver: filters.driver }),
      ...(filters.order && { search_order: filters.order }),
      ...(filterStatuses?.length && { status: filterStatuses.join(',') }),
    };
  };

  const getPayments = async (filters: any, currentPage?: number, withLoading: boolean = true) => {
    if (withLoading) setIsLoading(true);
    const selectedPage = currentPage || page;
    setPage(selectedPage);
    try {
      const res = await axiosClient.get('/drivers/earnings', {
        params: {
          page: selectedPage,
          [sort[0]]: sort[1],
          ...getQueryFilters(filters),
        },
      });

      setDataTotal(res.data.links.lastPage);
      setData((val: any) => res.data.data);
    } catch (e: any) {
      logger.error(`Ошибка при получении выплат ${e.message}`, e);
    } finally {
      if (withLoading) setIsLoading(false);
      if (!isNaN(loadingRow)) setLoadingRow(NaN);
    }
  };

  useEffect(() => {
    getPayments(filters, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, filters]);

  const updateStatus = async (id: number, status: string, rowIndex: number = NaN) => {
    setLoadingRow(rowIndex);

    try {
      await axiosClient.post(`/drivers/earnings/${id}`, {
        status,
      });
      await getPayments(filters, page, false);
      setLoadingRow(NaN);
    } catch (e: any) {
      logger.error(`Ошибка при смене статуса ${e.message}`, e);
      setLoadingRow(NaN);
    }
  };

  const updateMultipleStatus = async (status: string) => {
    setIsLoading(true);

    const success: number[] = [];
    const error: any[] = [];
    for (const earningId of selected) {
      try {
        await axiosClient.post(`/drivers/earnings/${earningId}`, {
          status,
        });
        success.push(earningId);
      } catch (err) {
        error.push({
          earningId,
          err,
        });
      }
    }

    if (success.length > 0) {
      logger.success(`Успешно обновлён статус для ${success.length} записей`);
    }
    if (error.length > 0) {
      logger.error(
        `Не удалось обновить статус для ${error.length} записей; ${error.map(e => e.err?.message)}`,
        error[0].err
      );
    }

    await getPayments(filters, page, false);

    setIsLoading(false);
  };

  return (
    <>
      <MainSection>
        <VerticalLayout>
          <div className={`bg-[#fff] h-screen`}>
            <div className="mb-3 px-[17px] pt-[14px] flex justify-between items-center">
              <div className="flex items-center">
                <h2 className="text-[24px] text-[#363B39] font-bold">Выплаты водителям</h2>
              </div>
              <div className="flex justify-end items-center">
                <p className="text-[12px] mr-2 text-[#9b9d9c]">
                  {selected === 'all' ? 'Выбраны все' : `Выбрано ${selected.length}`}:
                </p>
                {isPermitted('drivers_download_payment') && (
                  <div className="mr-4">
                    <ButtonSPrimary onClickHandler={() => setExportModal(true)}>
                      Экспорт в Excel
                    </ButtonSPrimary>
                  </div>
                )}
                <div className="mr-4">
                  <Select
                    placeholder="Изменить статус "
                    options={statuses}
                    onSelect={status => updateMultipleStatus(status.value)}
                    // value={statusFilter}
                    defaultColor="rgba(39, 174, 96, 0.1)"
                    maxHeight={12}
                    disabled={selected.length === 0}
                  />
                </div>

                <FilterButton
                  opened={filterOpen}
                  buttonRef={filtersButtonRef}
                  filtersIsEmpty={!filters.search && !filters.status.length}
                  onOpen={() => setFilterOpen(true)}
                  onClose={() => setFilterOpen(false)}
                />

                <FilterBar setOpened={setFilterOpen} opened={filterOpen} onClear={clearFilters}>
                  <div>
                    <div className="mt-3">
                      <p className="text-[#808080] text-sm">ID водителя или ФИО</p>
                      <div className="w-full mt-2 text-sm">
                        <Input
                          value={filters.driver}
                          onChange={e => onFilterFieldChange(e, 'driver')}
                          className="w-full"
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="text-[#808080] text-sm">ID заказа</p>
                      <div className="w-full mt-2 text-sm">
                        <Input
                          value={data.order}
                          onChange={e => onFilterFieldChange(e, 'order')}
                          className="w-full"
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <p className="text-[#808080] text-sm">Статусы</p>
                      <div className="w-full mt-2">
                        <CheckList
                          selected={filters.status}
                          options={statuses}
                          field="label"
                          onChange={newStatus => onFilterFieldChange(newStatus, 'status')}
                        />
                      </div>
                    </div>
                  </div>
                </FilterBar>
              </div>
            </div>
            <div className="overflow-auto bg-white">
              <GridTable
                data={data}
                fields={TableFields}
                isLoading={isLoading}
                loadingRow={loadingRow}
                sort={sort}
                onSortUpdate={setSort}
              />

              {!isLoading && data.length !== 0 && (
                <Pagination
                  totalPages={dataTotal}
                  activePage={page}
                  setPage={setPage}
                  alignment="justify-end"
                />
              )}
            </div>
          </div>
        </VerticalLayout>
      </MainSection>
      <ModalsSection>
        <ExportModal
          opened={exportModal}
          isLoading={isLoading}
          selected={exportSelected}
          options={exportFields}
          setSelected={setExportSelected}
          setOpened={setExportModal}
          exportParams={{
            url: '/export/earnings',
            filename: 'driver-earnings.xlsx',
            fields: exportSelected,
            filters: getQueryFilters(filters),
            selected,
            setIsLoading,
          }}
        />
      </ModalsSection>
    </>
  );
};

export default DriversPayments;
