import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridTable, GridTableCell } from 'src/components/GridTable';

// Basic cells
import ExportCheckboxesColumn, { IExportSelected } from '../BASIC_COLUMNS/ExportCheckboxesColumn';
import UserLockCell from '../BASIC_CELLS/UserLockCell';
import OrderInfoCell from '../BASIC_CELLS/OrderInfoCell';
import { orderStatuses } from 'src/utils/order-statuses';
import { isPermitted } from 'src/utils/permissions';

const statuses = orderStatuses.reduce((obj, status) => {
  obj[status.id] = status.title;
  return obj;
}, {});

interface ILegalsListTable {
  legals: Array<any>;
  isLoading: boolean;
  loadingRow: number;
  selected?: IExportSelected;
  sort?: Array<string>;

  onSortUpdate?: (newSort: Array<string>) => any;
  onLegalSelect?: (newSelected: IExportSelected) => any;
  onLegalStatusUpdate?: (legalId: number, newStatus: string, rowIndex: number) => any;
}

const LegalsListTable: FunctionComponent<ILegalsListTable> = ({
  legals,
  isLoading = false,
  loadingRow = NaN,
  selected = [],
  sort = [],

  onLegalSelect = () => {},
  onLegalStatusUpdate = () => {},
  onSortUpdate = () => {},
}) => {
  const navigate = useNavigate();

  const TableFields = {
    exportSelect: ExportCheckboxesColumn({
      selected,
      onSelect: onLegalSelect,
      additionalParams: { width: '30px' },
    }),

    company: {
      label: 'Компания',
      sortBy: 'sort_name',

      component: (companyName: string, index: number, legal: any) => (
        <CompanyCell
          companyName={companyName}
          legalId={legal.id}
          onClick={() => navigate(`/legals/list/${legal.id}/general`)}
        />
      ),
    },

    orders_num: { label: 'Заказов', sortBy: 'sort_order', width: '100px' },
    managers_count: { label: 'Менеджеров', sortBy: 'sort_manager', width: '130px' },
    mail: { label: 'Почта', sortBy: 'sort_email' },
    tax_id: { label: 'Tax ID', sortBy: 'sort_tax_id' },

    lastOrder: {
      label: 'Последний заказ',
      sortBy: 'sort_last_order_addr',

      component: (v: any, index: number, legal: any) => (
        <OrderInfoCell
          order={legal.lastOrder ?? {}}
          fromPath={legal?.lastOrder?.fromPath}
          toPath={legal?.lastOrder?.toPath}
          price={legal?.lastOrder?.price}
          status={+legal?.lastOrder?.status}
          onClick={() => navigate(`/order/${legal.lastOrder?.id}`)}
        />
      ),
    },

    events: {
      component: (v: any, i: number, legal: any, rowIndex: number) => {
        return (
          <UserLockCell
            locked={String(legal.block) === '1'}
            disabled={!isPermitted('legal_entities_change_status')}
            onLock={() => {
              onLegalStatusUpdate(legal.id, '1', rowIndex);
            }}
            onUnlock={() => onLegalStatusUpdate(legal.id, '0', rowIndex)}
          />
        );
      },
    },
  };

  return (
    <GridTable
      data={legals}
      fields={TableFields}
      isLoading={isLoading}
      loadingRow={loadingRow}
      sort={sort}
      onSortUpdate={onSortUpdate}
    />
  );
};

/* ----------------------- TABLE CELLS ----------------------- */

const CompanyCell: FunctionComponent<any> = ({ companyName, legalId, onClick = () => {} }) => {
  if (!companyName || !legalId) {
    return <GridTableCell>-</GridTableCell>;
  }

  return (
    <GridTableCell>
      <div className="flex flex-col">
        <p className="text-sm">{companyName || '-'}</p>
        <p className="text-[#1066B1] underline text-xs cursor-pointer mr-1" onClick={onClick}>
          {legalId || '-'}
        </p>
      </div>
    </GridTableCell>
  );
};

export default LegalsListTable;
